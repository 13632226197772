<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template #activator="{ on: menu }">
      <div v-if="withValidationProvider">
        <ValidationProvider
          v-slot="{ errors }"
          :rules="validationRules"
          :name="name"
        >
          <v-text-field
            :value="value"
            :label="title"
            clearable
            :rules="rules"
            readonly
            :disabled="disabled"
            :error-messages="errors"
            @click:clear="clearValue"
            v-on="{ ...menu }"
          />
        </ValidationProvider>
      </div>
      <div v-else>
        <v-text-field
          :value="value"
          :label="title"
          clearable
          :rules="rules"
          readonly
          :disabled="disabled"
          :error-messages="errorMessages"
          @click:clear="clearValue"
          v-on="{ ...menu }"
        />
      </div>
    </template>
    <v-time-picker
      locale="es"
      :value="value"
      format="24hr"
      :disabled="disabled"
      @input="inputValue"
    >
      <v-spacer />
      <v-btn
        text
        @click.stop="clickBorrar"
      >
        Borrar
      </v-btn>
      <v-btn
        text
        color="success"
        @click.stop="clickAceptar"
      >
        Aceptar
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      // hh:mm
      type: [String],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withValidationProvider: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      showMenu: false,
    }
  },
  methods: {
    clickBorrar () {
      this.clearValue()
      this.showMenu = false
    },
    clickAceptar () {
      this.showMenu = false
    },
    inputValue (value) {
      this.$emit('input', value)
    },
    clearValue () {
      this.$emit('input', null)
    }
  }
}
</script>
