import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'
import { v4 as uuidv4 } from 'uuid'
import { TINCLUSION, LORDEN_TRABAJO } from '@/utils/consts'
import filters from '@/utils/filters'
import { likeRegExp } from '@/offline/database'

export default class LparteTrabajoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    const allowedRemoteParams = [
      'idlparte_trabajo',
      'idparte_trabajo',
      'idlorden_trabajo',
      'descripcion',
      'unidades',
      'estado',
      'unidades_orig',
      'idarticulo',
      'codigo',
      'idaccion_ejec',
      'idsubsis',
      'facturar',
      'orden',
      'kilometraje',
      'idtmotivo_nofacturable',
      'observaciones',
      'idaccion_correctora_ot',
      'idalmacen',
      'imputa_al_proyecto',
    ]
    await this.Vue.$offline.sync.insert({
      method: 'lparteTrabajo.insertSync',
      params: {
        values: _.pickBy(values, (value, key) => allowedRemoteParams.indexOf(key) >= 0),
      },
    })
    // restar unidades pendientes de instalar en la OT
    if (values.idlorden_trabajo) {
      const currentLOT = await this.Vue.$offline.lordenTrabajo.row(values.idlorden_trabajo)
      await this.Vue.$offline.lordenTrabajo.update({
        idlorden_trabajo: values.idlorden_trabajo,
        unidades_pend_instalar: currentLOT.lorden_trabajo.unidades_pend_instalar - values.unidades,
      })
    }
    return await super.insert(values, options)
  }
  async deleteSync (idlparteTrabajo) {
    // sumar unidades pendientes de instalar en la OT
    const currentLparte = await this.Vue.$offline.lparteTrabajo.row(idlparteTrabajo)
    if (currentLparte.lparte_trabajo.idlorden_trabajo) {
      const currentLOT = await this.Vue.$offline.lordenTrabajo.row(currentLparte.lparte_trabajo.idlorden_trabajo)
      await this.Vue.$offline.lordenTrabajo.update({
        idlorden_trabajo: currentLparte.lparte_trabajo.idlorden_trabajo,
        unidades_pend_instalar: (
          currentLOT.lorden_trabajo.unidades_pend_instalar + currentLparte.lparte_trabajo.unidades
        ),
      })
    }
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'lparteTrabajo.deleteSync',
      params: {
        idlparte_trabajo: idlparteTrabajo
      }
    })
    await this.Vue.$offline.lparteTrabajo.delete({
      where: tables.lparte_trabajo.idlparte_trabajo.eq(idlparteTrabajo)
    })
  }
  async updateSync (values) {
    const currentLparte = await this.Vue.$offline.lparteTrabajo.row(values.idlparte_trabajo)
    // actualizar unidades pendientes de instalar en la OT
    if (currentLparte.lparte_trabajo.idlorden_trabajo) {
      const currentLOT = await this.Vue.$offline.lordenTrabajo.row(currentLparte.lparte_trabajo.idlorden_trabajo)
      // actualizar además todos los datos que comparten en común lparte_trabajo con lorden_trabajo
      await this.Vue.$offline.lordenTrabajo.update({
        idlorden_trabajo: currentLparte.lparte_trabajo.idlorden_trabajo,
        unidades_pend_instalar: (
          currentLOT.lorden_trabajo.unidades_pend_instalar + currentLparte.lparte_trabajo.unidades - values.unidades
        ),
      })
    }
    // Obtener proyecto y articulo para saber si tiene que actualizar el imputa_al_proyecto
    if (currentLparte.lparte_trabajo.idalmacen != values.idalmacen) {
      // Hago un select de lparte aunque tenga otro en currentLparte, para no afectar mucho al rendimiento
      const tables = this.db.tables
      const lparte = (await this.db
        .select()
        .from(tables.lparte_trabajo)
        .innerJoin(
          tables.parte_trabajo,
          tables.lparte_trabajo.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
        )
        .innerJoin(
          tables.orden_trabajo,
          tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
        )
        .innerJoin(
          tables.articulo,
          tables.lparte_trabajo.idarticulo.eq(tables.articulo.idarticulo)
        )
        .where(
          this.db.op.and(
            tables.lparte_trabajo.idlparte_trabajo.eq(values.idlparte_trabajo)
          )
        )
        .exec())[0]
      values.imputa_al_proyecto = await this.Vue.$offline.proyectoContable.lparteTrabajoImputaAlProyecto(
        lparte.orden_trabajo.idproyecto_contable, values.idalmacen, lparte.articulo.esmanodeobra
      )
    } else {
      // El campo no es nulable
      values.imputa_al_proyecto = currentLparte.lparte_trabajo.imputa_al_proyecto
    }
    await this.Vue.$offline.sync.insert({
      method: 'lparteTrabajo.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async rowNordenMaterialInstalar (idparteTrabajo) {
    const tables = this.db.tables
    return (await this.db
      .select(this.db.fn.max(tables.lparte_trabajo.orden).as('orden'))
      .from(tables.lparte_trabajo)
      .where(tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec())[0]
  }
  async deleteLordenYDetalleSync(idlordenTrabajo) {
    const tables = this.db.tables
    const selectEscandallo = await this.Vue.$offline.lordenTrabajoDetalle.select({
      where: tables.lorden_trabajo_detalle.idlorden_trabajo.eq(idlordenTrabajo)
    })
    for (let escandallo of selectEscandallo) {
      // no es necesario sincronizar la eliminación de LORDEN_TRABAJO_DETALLE
      // porque se borra desde PKG_ORDEN_TRABAJO_TRG al eliminar LORDEN_TRABAJO
      await this.Vue.$offline.lordenTrabajoDetalle.delete({
        where: tables.lorden_trabajo_detalle.idlorden_trabajo_detalle.eq(
          escandallo.lorden_trabajo_detalle.idlorden_trabajo_detalle
        )
      })
    }
    await this.Vue.$offline.lordenTrabajo.deleteSync(idlordenTrabajo)
  }
  async deleteLparteLordenYDetalleSync (idlparteTrabajo) {
    const lparteTrabajo = await this.row(idlparteTrabajo)
    await this.Vue.$offline.lparteTrabajo.deleteSync(idlparteTrabajo)
    this.deleteLordenYDetalleSync(lparteTrabajo.lparte_trabajo.idlorden_trabajo)
  }
  async deleteLparteUpdateUnidadesLordenSync (idlparteTrabajo, deleteOT) {
    const tables = this.db.tables
    const lparteTrabajo = await this.row(idlparteTrabajo)
    await this.Vue.$offline.lparteTrabajo.deleteSync(idlparteTrabajo)
    if (deleteOT) {
      const lordenTrabajo = await this.Vue.$offline.lordenTrabajo.row(lparteTrabajo.lparte_trabajo.idlorden_trabajo)
      // descontar las unidades de la línea de parte que se elimina
      const unidadesLOT = lordenTrabajo.lorden_trabajo.unidades - lparteTrabajo.lparte_trabajo.unidades
      const unidadesPendInstLOT = lordenTrabajo.lorden_trabajo.unidades_pend_instalar - lparteTrabajo.lparte_trabajo.unidades
      await this.Vue.$offline.lordenTrabajo.updateSync({
        idlorden_trabajo: lparteTrabajo.lparte_trabajo.idlorden_trabajo,
        unidades: unidadesLOT,
        unidades_pend_instalar: unidadesPendInstLOT,
      })
      if (unidadesLOT == 0) {
        await this.Vue.$offline.lordenTrabajo.updateSync({
          idlorden_trabajo: lparteTrabajo.lparte_trabajo.idlorden_trabajo,
          idlest_orden_trabajo: LORDEN_TRABAJO.estados.anulado
        })
      }
      // actualizar escandallo
      const selectEscandallo = await this.Vue.$offline.lordenTrabajoDetalle.select({
        where: tables.lorden_trabajo_detalle.idlorden_trabajo.eq(lparteTrabajo.lparte_trabajo.idlorden_trabajo)
      })
      for (let escandallo of selectEscandallo) {
        const escandalloArticulo = (await this.db
          .select()
          .from(tables.articulo_escandallo)
          .where(tables.articulo_escandallo.idescandallo.eq(escandallo.lorden_trabajo_detalle.idarticulo))
          .exec()
        )
        if (escandalloArticulo.length > 0) {
          await this.Vue.$offline.lordenTrabajoDetalle.updateSync({
            idlorden_trabajo_detalle: escandallo.lorden_trabajo_detalle.idlorden_trabajo_detalle,
            unidades: escandalloArticulo[0].unidades * unidadesLOT,
          })
        }
      }
    }
  }
  async insertLparteLordenYDetalleSync (
    idparteTrabajo,
    codigoArticulo,
    unidades,
    idsubsis,
    tinclusion = TINCLUSION.instalacion,
    facturar = true,
    idtmotivoNofacturable = null,
    descripcionArticulo = null,
    kilometraje = 0,
    idaccionEjec = null,
    observaciones = null,
    idaccionCorrectoraOt = null,
    idalmacen = null,
  ) {
    const tables = this.db.tables
    const ordenTrabajo = (await this.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.regimen_fiscal,
        tables.cliente.idregimen_fiscal.eq(tables.regimen_fiscal.idregimen_fiscal)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    )[0]
    const articulo = await this.Vue.$offline.articulo.buscarArticulo(
      codigoArticulo,
      ordenTrabajo.orden_trabajo.idttarifa,
      ordenTrabajo.orden_trabajo.idbanco_precio,
      ordenTrabajo.cliente.ididioma,
      ordenTrabajo.orden_trabajo.idtoperacion_impuesto,
      tinclusion
    )

    // El campo lorden_trabajo.imputa_al_proyecto hereda de lparte_trabajo.imputa_al_proyecto cuando se inserta directamente la línea de parte
    const lparteTrabajoImputaAlProyecto = await this.Vue.$offline.proyectoContable.lparteTrabajoImputaAlProyecto(
      ordenTrabajo.orden_trabajo.idproyecto_contable, idalmacen, articulo.esmanodeobra
    )
    const idlordenTrabajo = await this.Vue.$offline.lordenTrabajo.insertLordenYDetalleSync(
      ordenTrabajo.orden_trabajo.idorden_trabajo,
      codigoArticulo,
      unidades,
      idsubsis,
      tinclusion,
      facturar,
      idtmotivoNofacturable,
      descripcionArticulo,
      kilometraje,
      observaciones,
      LORDEN_TRABAJO.estados.en_instalacion, // idlestOrdenTrabajo
      null, // idsolicitudMaterial
      lparteTrabajoImputaAlProyecto
    )
    const maxOrden = await this.rowNordenMaterialInstalar(idparteTrabajo)
    if (!idalmacen) {
      idalmacen = ordenTrabajo.parte_trabajo.idalmacen
    }
    const idlparteTrabajo = uuidv4()
    await this.Vue.$offline.lparteTrabajo.insertSync({
      idlparte_trabajo: idlparteTrabajo,
      idparte_trabajo: idparteTrabajo,
      idlorden_trabajo: idlordenTrabajo,
      descripcion: descripcionArticulo || articulo.descripcion,
      unidades,
      estado: 1,
      unidades_orig: 0,
      idarticulo: articulo.idarticulo,
      codigo: codigoArticulo,
      idsubsis,
      facturar,
      idaccion_ejec: idaccionEjec,
      orden: maxOrden.orden + 1,
      idtmotivo_nofacturable: idtmotivoNofacturable,
      kilometraje,
      idfabricante: articulo.data.articulo.idfabricante,
      idagente_extintor: articulo.data.articulo.idagente_extintor,
      idagente_propelente: articulo.data.articulo.idagente_propelente,
      volumen: articulo.data.articulo.volumen,
      peso_total: articulo.data.articulo.peso_total,
      peso_agente_extintor: articulo.data.articulo.peso_agente_extintor,
      eficacia: articulo.data.articulo.eficacia,
      presion: articulo.data.articulo.presion,
      observaciones: observaciones,
      idaccion_correctora_ot: idaccionCorrectoraOt,
      idalmacen: idalmacen,
      imputa_al_proyecto: lparteTrabajoImputaAlProyecto,
    })
    return { idlordenTrabajo, idlparteTrabajo }
  }
  resumenFichaTecnica (lparteTrabajoRow) {
    if (
      !_.has(lparteTrabajoRow, 'lparte_trabajo') ||
      !_.has(lparteTrabajoRow, 'tsubsis') ||
      !_.has(lparteTrabajoRow, 'articulo')
    ) {
      console.error('Falta propiedad lparte_trabajo/tsubsis ó lparte_trabajo/articulo')
    }
    const resumenFicha = []
    resumenFicha.push(`Unidades: ${filters.decimal(lparteTrabajoRow.lparte_trabajo.unidades)}`)
    if (lparteTrabajoRow.tsubsis.ver_mat_nserie) {
      const nserieObligado = filters.humanizeBoolean(lparteTrabajoRow.articulo.nserie_obligado)
      resumenFicha.push(`Nº de serie obligatorio: ${nserieObligado}`)
      if (lparteTrabajoRow.lparte_trabajo.nserie) {
        resumenFicha.push(`Nº de serie: ${lparteTrabajoRow.lparte_trabajo.nserie}`)
      }
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_nelemento && lparteTrabajoRow.lparte_trabajo.nelemento) {
      resumenFicha.push(`Nº de elemento: ${lparteTrabajoRow.lparte_trabajo.nelemento}`)
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_ubicacion && lparteTrabajoRow.lparte_trabajo.ubicacion) {
      resumenFicha.push(`Ubicación: ${lparteTrabajoRow.lparte_trabajo.ubicacion}`)
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_netiqueta && lparteTrabajoRow.lparte_trabajo.netiqueta) {
      resumenFicha.push(`Nº de etiqueta: ${lparteTrabajoRow.lparte_trabajo.netiqueta}`)
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_num_zona && lparteTrabajoRow.lparte_trabajo.numzona) {
      resumenFicha.push(`Nº de zona: ${lparteTrabajoRow.lparte_trabajo.numzona}`)
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_particion && lparteTrabajoRow.lparte_trabajo.particion) {
      resumenFicha.push(`Partición: ${lparteTrabajoRow.lparte_trabajo.particion}`)
    }
    if (lparteTrabajoRow.tsubsis.ver_mat_ffabricacion && lparteTrabajoRow.lparte_trabajo.ffabricacion) {
      const ffabricacion = filters.shortDate(lparteTrabajoRow.lparte_trabajo.ffabricacion)
      resumenFicha.push(`Fabricación: ${ffabricacion}`)
    }
    // if (lparteTrabajoRow.tsubsis.ver_mat_fult_revision && lparteTrabajoRow.lparte_trabajo.fult_revision) {
    //   resumenFicha.push(`Última revisión: ${lparteTrabajoRow.lparte_trabajo.fult_revision}`)
    // }
    // (no existe aún en offline, es un campo calculado en el servidor)
    // if (lparteTrabajoRow.tsubsis.ver_mat_fprox_revision && ) {
    //   resumenFicha.push(`Próxima revisión: ${}`, )
    // }
    if (lparteTrabajoRow.tsubsis.ver_mat_fult_retimbrado && lparteTrabajoRow.lparte_trabajo.fult_retimbrado) {
      const fultRetimbrado = filters.shortDate(lparteTrabajoRow.lparte_trabajo.fult_retimbrado)
      resumenFicha.push(`Último retimbrado: ${fultRetimbrado}`)
    }
    // (no existe aún en offline, es un campo calculado en el servidor)
    // if (lparteTrabajoRow.tsubsis.ver_mat_fprox_retimbrado && ) {
    //   resumenFicha.push(`Próximo retimbrado: ${}`, )
    // }
    // if (lparteTrabajoRow.tsubsis.ver_mat_fcaducidad && lparteTrabajoRow.lparte_trabajo.fcaducidad) {
    //   resumenFicha.push(`Fecha de caducidad: ${lparteTrabajoRow.lparte_trabajo.fcaducidad}`)
    // }
    // if (lparteTrabajoRow.tsubsis.ver_mat_ffin_garantia && lparteTrabajoRow.lparte_trabajo.ffin_garantia) {
    //   resumenFicha.push(`Fin de garantía: ${lparteTrabajoRow.lparte_trabajo.ffin_garantia}`)
    // }
    return resumenFicha
  }
  searchFilter (value) {
    const tables = this.db.tables
    let whereSearch = []
    const reQ = likeRegExp(value)
    whereSearch.push(
      this.db.op.or(
        tables.lparte_trabajo.codigo.match(reQ),
        tables.lparte_trabajo.nserie.match(reQ),
        tables.lparte_trabajo.descripcion.match(reQ),
        tables.lparte_trabajo.nelemento.match(reQ),
        tables.lparte_trabajo.ubicacion.match(reQ),
        tables.lparte_trabajo.netiqueta.match(reQ),
        tables.lparte_trabajo.numzona.match(reQ),
        tables.lparte_trabajo.particion.match(reQ),
      )
    )
    return whereSearch
  }
}