<template>
  <div
    v-if="withValidationProvider"
  >
    <ValidationProvider
      v-slot="{ errors }"
      :name="name"
      :rules="validationRules"
    >
      <v-checkbox
        v-bind="$props"
        :error-messages="errors"
        @change="$emit('change', $event)"
      >
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </v-checkbox>
    </ValidationProvider>
  </div>
  <div
    v-else
  >
    <v-checkbox
      v-bind="$props"
      @change="$emit('change', $event)"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'

export default {
  extends: VCheckbox,
  props: {
    name: {
      type: String,
      default: ''
    },
    withValidationProvider: {
      type: Boolean,
      default: true,
    },
    // No se llama "rules" porque el componente tiene una prop llamada igual
    validationRules: {
      type: Object,
      default: () => {},
    }
  },
}
</script>
