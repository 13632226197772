import loadImage from 'blueimp-load-image'

/**
 * Dispara la descarga del navegador
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 */
export const downloadDataURL = (Vue, fileType, fileData, fileLabel) => {
  if (fileData) {
    let element = document.createElement('a')
    let finalFileType = fileType
    if (!finalFileType) {
      finalFileType = 'application/octet-stream'
    }
    element.setAttribute('href', `data:${finalFileType};base64,${fileData}`)
    element.setAttribute('download', fileLabel)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  } else {
    Vue.$alert.showSnackbarError('El fichero no está disponible')
  }
}

export const ReadFileBase64 = (inputFile, resizeImages = true) => {
  const MAX_WIDTH = 1024
  const MAX_HEIGHT = 1024
  // https://github.com/kartoteket/vue-image-upload-resize
  const reader = new window.FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new Error('Error leyendo fichero'))
    }
    reader.onload = async () => {
      const mimetype = inputFile.type.split('/')
      const isImage = mimetype[0] === 'image'
      if (isImage && resizeImages) {
        const img = await loadImage(
          inputFile,
          {
            maxWidth: MAX_WIDTH,
            maxHeight: MAX_HEIGHT,
            meta: true,
            orientation: true,
            canvas: true,
            imageSmoothingEnabled: true
          }
        )
        const dataUrl = img.image.toDataURL(inputFile.type)
        resolve(dataUrl.split(',').pop())
      } else {
        resolve(reader.result.split(',').pop())
      }
    }
    reader.readAsDataURL(inputFile)
  })
}

// How to convert Base64 String to javascript file object like as from file input form?
// https://stackoverflow.com/a/38935990
export const URLToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => {
      return res.arrayBuffer()
    })
    .then((buf) => {
      return new File([buf], filename, { type: mimeType })
    })
}

export const AsyncTextFileReader = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.onerror = reject
    reader.readAsText(file)
  })
}

export const extractFileExtension = (filename) => {
  return filename.split('.').pop().toLowerCase()
}

export const extractFileName = (URI) => {
  return URI.replace(/^.*[\\/]/, '')
}

export const randomPictureFileName = () => {
  const caracteres = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const longitud = 10
  let cadenaAleatoria = ''
  for (let i = 0; i < longitud; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length)
    cadenaAleatoria += caracteres.charAt(indiceAleatorio)
  }
  return `fotografía_${cadenaAleatoria}.jpg`
}