import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'

export default class ParteTrabajoTecnicoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajoTecnico.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajoTecnico.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idparteTrabajoTecnico) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajoTecnico.deleteSync',
      params: {
        idparte_trabajo_tecnico: idparteTrabajoTecnico
      }
    })
    await this.Vue.$offline.parteTrabajoTecnico.delete({
      where: tables.parte_trabajo_tecnico.idparte_trabajo_tecnico.eq(idparteTrabajoTecnico)
    })
  }
  async inPartesDescargados (idtecnico) {
    const tables = this.db.tables
    const partesTecnico = await this.Vue.$offline.db
      .select(tables.parte_trabajo_tecnico.idparte_trabajo)
      .from(tables.parte_trabajo_tecnico)
      .where(
        tables.parte_trabajo_tecnico.idtecnico.eq(idtecnico),
      )
      .exec()
    return _.map(partesTecnico, 'idparte_trabajo')
  }
  // TODO: buscar otros sitios donde se hace una función similar y remplazarlo por una llamada a esta función
  async selectTecnicosAsignados (idparteTrabajo) {
    const tables = this.Vue.$offline.db.tables
    return this.Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.empleado,
        tables.tecnico.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
  }
}
