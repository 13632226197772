import BaseOnlineResource from '@/online/base'
import { TABLA } from '@/utils/consts'
import _ from '@/utils/lodash'

export default class TablaOnlineResource extends BaseOnlineResource {
  getIdTablaDeRouteParams (routeParams) {
    for (const key in routeParams) {
      const nombreTabla = key.replace('id', '')
      if (TABLA.hasOwnProperty(nombreTabla)) {
        return TABLA[nombreTabla]['idtabla']
      }
    }
  }
  getTitleSingularById(idTabla) {
    const tabla = _.find(TABLA, { idtabla: idTabla });
    return tabla ? tabla.titleSingular : null;
  }
}
