import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import {  nonEmpty } from '@/utils/templates'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class SistemaOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.nabonado1) {
      return `${row.descripcion} (${row.codigo} - Abonado ${row.nabonado1}) ${row.cliente_nombre}`
    }
    else {
      return `${row.descripcion} (${row.codigo}) ${row.cliente_nombre}`
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`${row.sistema_direccion}`)
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('cliente_nombre', value)
      .addILike('razon_social', value)
      .addILike('descripcion', value)
      .addILike('codigo', value)
      .addILike('nabonado1', value)
  }
}
