import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'movimientoscaja__movimiento-caja-list',
    component: load('movimientosCaja/movimientoCajaList/MovimientoCajaList'),
    props: {
      addRoute: 'movimientoscaja__movimiento-caja-add',
      viewRoute: 'movimientoscaja__movimiento-caja-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.movimientosCaja.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'add',
    name: 'movimientoscaja__movimiento-caja-add',
    component: load('movimientosCaja/movimientoCajaAdd/MovimientoCajaAdd'),
    props: {
      viewRoute: 'movimientoscaja__movimiento-caja-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.movimientosCaja.id,
        access: ACCESS.add,
      },
    },
  },
  {
    path: ':idmovcaja',
    name: 'movimientoscaja__movimiento-caja-view',
    component: load('movimientosCaja/movimientoCajaView/MovimientoCajaView'),
    props: {
      editRoute: 'movimientoscaja__movimiento-caja-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.movimientosCaja.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idmovcaja/edit',
    name: 'movimientoscaja__movimiento-caja-edit',
    component: load('movimientosCaja/movimientoCajaEdit/MovimientoCajaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.movimientosCaja.id,
        access: ACCESS.edit,
      },
    },
  },
]