import _ from '@/utils/lodash'
import { make } from 'vuex-pathify'

export const GenericStoreBase = class {
  constructor () {
    this.state = {
      nextRoute: null,
      readonly: false,
      scrollPosition: 0,
    }
    this.getters = {}
    this.mutations = {}
    this.actions = {
      async resetStore ({ commit }) {
        commit('SET_NEXT_ROUTE', null)
        commit('SET_READONLY', false)
      },
      async resetScroll ({ commit }) {
        commit('SET_SCROLL_POSITION', 0)
      },
    }
  }
}

export const ViewStoreBase = class {
  constructor () {
    this.state = {
      nextRoute: null,
      readonly: false,
      scrollPosition: 0,
    }
    this.getters = {}
    this.mutations = {}
    this.actions = {
      async resetStore ({ commit }) {
        commit('SET_NEXT_ROUTE', null)
        commit('SET_READONLY', false)
      },
      async resetScroll ({ commit }) {
        commit('SET_SCROLL_POSITION', 0)
      },
    }
  }
}

export const FormStoreBase = class {
  constructor () {
    this.state = {
      formData: {},
      nextRoute: null,
      scrollPosition: 0,
    }
    this.getters = {}
    this.mutations = {
      RESET_FORM_DATA: (state) => {
        for (const field in state.formData) {
          delete state.formData[field]
        }
      },
    }
    this.actions = {
      async resetStore ({ commit }) {
        commit('RESET_FORM_DATA')
        commit('SET_NEXT_ROUTE', null)
      },
      async resetScroll ({ commit }) {
        commit('SET_SCROLL_POSITION', 0)
      },
    }
  }
}

// https://stackoverflow.com/a/55144293
export const ListStoreBase = class {
  constructor () {
    this.state = {
      filter: {},
      sorter: [],
      sorterDesc: [],
      search: '',
      items: [],
      selectedListItems: [],
      showLoadMore: false,
      currentPage: 0,
      scrollPosition: 0,
      nextRoute: null,
      readonly: false,
    }
    this.getters = {}
    this.mutations = {
      APPEND_SELECTED_ITEM: (state, item) => {
        state.selectedListItems.push(item)
      },
      REMOVE_SELECTED_ITEM: (state, item) => {
        state.selectedListItems.splice(item, 1)
      },
      DELETE_ITEM: (state, payload) => {
        state.items.splice(payload, 1)
      },
      RESET_FILTER: (state) => {
        for (const field of _.filter(state.filter, { dynamic: true })) {
          delete state.filter[field.field]
        }
        for (const field in state.filter) {
          state.filter[field].value = null
          state.filter[field].options = undefined
        }
      },
      RESET_STORE_CUSTOM: () => {
        // definido en descendientes para borrar campos especiales (selects)
      },
      PERSIST_FILTER: (state, payload) => {
        for (const field in state.filter) {
          state.filter[field].value = payload[field].value
        }
      },
      CLEAR_SELECTION: (state) => {
        state.selectedListItems = []
      }
    }
    this.actions = {
      deleteItem ({ commit }, payload) {
        commit('DELETE_ITEM', payload)
      },
      clearSelection ({ commit }) {
        commit('CLEAR_SELECTION')
      },
      async resetScroll ({ commit }) {
        commit('SET_SCROLL_POSITION', 0)
      },
      async resetList ({ commit, dispatch }) {
        commit('SET_ITEMS', [])
        commit('SET_SHOW_LOAD_MORE', false)
        commit('SET_CURRENT_PAGE', 0)
        await dispatch('clearSelection')
      },
      async resetStore ({ commit, dispatch }) {
        await dispatch('resetList')
        commit('RESET_FILTER')
        commit('SET_SEARCH', '')
        commit('SET_NEXT_ROUTE', null)
        commit('SET_READONLY', false)
        commit('RESET_STORE_CUSTOM')
      },
      async search ({ state, commit, dispatch }) {
        const searchBackup = state.search
        await dispatch('resetList')
        commit('SET_SEARCH', searchBackup)
      },
      async filter ({ state, commit, dispatch }) {
        const filterBackup = _.cloneDeep(state.filter)
        await dispatch('resetList')
        commit('PERSIST_FILTER', filterBackup)
      }
    }
  }
}

export const CreateStore = (
  baseStore,
  customPageStore = { state: {}, getters: {}, mutations: {}, actions: {} },
) => {
  const state = {
    ...baseStore.state,
    ...customPageStore.state,
  }
  const getters = {
    ...baseStore.getters,
    ...customPageStore.getters,
  }
  const mutations = {
    ...baseStore.mutations,
    ...customPageStore.mutations,
    ...make.mutations(state),
  }
  const actions = {
    ...baseStore.actions,
    ...customPageStore.actions,
    ...make.actions(state),
  }
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  }
}
