import {
  setInteractionMode,
  localize,
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import { email, required, required_if, size, max } from 'vee-validate/dist/rules'
import { isValidNIF, validarNumeroOracle, calcularMaxDecimalMaxInteger } from '@/utils/validate'

export const initVeeValidate = (Vue) => {
  setInteractionMode('eager')
  localize('es')
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
  extend(
    'required',
    {
      ...required,
      message: 'Campo requerido',
    }
  )
  extend(
    'required_if',
    {
      ...required_if,
      message: 'Campo requerido',
    }
  )
  extend(
    'email',
    {
      ...email,
      message: 'El correo electrónico no es correcto',
    }
  )
  extend(
    'size',
    {
      ...size,
      message: 'El fichero es demasiado grande',
    }
  )
  extend(
    'validNIF',
    {
      message: 'El DNI no es correcto',
      validate: (value) => {
        return {
          valid: isValidNIF(value)
        }
      }
    }
  )
  extend(
    'max', {
      ...max,
      message: function (fieldName, placeholders) {
        return `No puede ser mayor a ${placeholders.length} caracteres.`
      }
    }
  )
  extend(
    'oracleNumber',
    {
      message: function (fieldName, placeholders) {
        const [maxIntegerDigits, maxDecimalDigits] = calcularMaxDecimalMaxInteger(placeholders[0])
        return `El número no puede tener más de ${maxIntegerDigits} dígitos antes del punto decimal y ${maxDecimalDigits} dígitos después del punto decimal.`
      },
      validate: (value, precision) => {
        return {
          valid: validarNumeroOracle(value, precision)
        }
      }
    }
  )
}
