import { format } from '@/utils/date'
import initials from 'initials'
import { capitalize } from './format'
import humanizeDuration from 'humanize-duration'

export default {
  decimal: (value) => {
    return (new Intl.NumberFormat('es-ES', {
      style: 'decimal',
    })).format(value)
  },
  currency: (value) => {
    return (new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    })).format(value)
  },
  capitalize: (value) => {
    return capitalize(value)
  },
  lowercase: (value) => {
    return value.toLowerCase()
  },
  uppercase: (value) => {
    return value.toUpperCase()
  },
  shortDate: (value) => {
    if (value) {
      return format(value, 'dd/MM/yyyy')
    } else {
      return ''
    }
  },
  shortMonth: (value) => {
    if (value) {
      return format(value, 'MM/yyyy')
    } else {
      return ''
    }
  },
  shortTime: (value) => {
    if (value) {
      return format(value, 'HH:mm')
    } else {
      return ''
    }
  },
  shortDateTime: (value) => {
    if (value) {
      return format(value, 'dd/MM/yyyy HH:mm')
    } else {
      return ''
    }
  },
  linebreaksBr: (value) => {
    if (value) {
      // reemplazar \n por <br/>
      return value.toString().trim().replace(/\n/g, '<br/>')
    } else {
      return ''
    }
  },
  addBase64ImagePrefix: (value, prefix = 'png') => {
    return `data:image/${prefix};base64,${value}`
  },
  humanizeDuration: (milliseconds) => {
    return humanizeDuration(
      milliseconds,
      { language: 'es', round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm'] }
    )
  },
  humanizeBoolean: (value, colorized = false) => {
    if (value) {
      if (colorized) {
        return '<span class="green--text"><strong>Sí</strong></span>'
      } else {
        return 'Sí'
      }
    } else {
      if (colorized) {
        return '<span class="red--text"><strong>No</strong></span>'
      } else {
        return 'No'
      }
    }
  },
  pluralize: (value, count, pluralSuffix, singularSuffix = '') => {
    const isArray = Array.isArray(count)
    const isNumber = typeof count === 'number'
    if (isArray || isNumber) {
      if ((isArray && count.length === 1) || (isNumber && count === 1)) {
        return value + singularSuffix
      } else {
        if (pluralSuffix) {
          return value + pluralSuffix
        } else {
          return value + 's'
        }
      }
    } else {
      return value
    }
  },
  initials: (value) => {
    let allInitials = initials(value)
    return allInitials.length > 3 ? allInitials.slice(0, 3) : allInitials
  },
  hideIBAN: (value) => {
    const myRegexp = /\w{7}(.*)\w{4}/g
    const match = myRegexp.exec(value)
    if (match?.length > 0) {
      return value.replace(match[1], '*********')
    } else {
      return value
    }
  },
  percent: (value) => {
    return (new Intl.NumberFormat('es-ES', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })).format(value/100)
  },
  round: (value, decimal) => {
    const num = Number(value)
    if (!isNaN(num)) {
      const factor = Math.pow(10, decimal)
      return Math.round(num * factor) / factor
    } else {
      throw new Error(`No se puede convertir ${value} a número`)
    }
  },
}
