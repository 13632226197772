import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // presupuestos
  {
    path: 'presupuestos/:id/:idtabla',
    name: 'datos__presupuestos-dato-list',
    component: load('datos/datoList/DatoList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliDato.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'datos__presupuestos-dato-edit',
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/edit',
    name: 'datos__presupuestos-dato-edit',
    component: load('datos/datoEdit/DatoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliDato.id,
        access: ACCESS.edit,
      },
    },
  },
  // contratos
  {
    path: 'contratos/:id/:idtabla',
    name: 'datos__contratos-dato-list',
    component: load('datos/datoList/DatoList'),
    meta: {
      permission: {
        idobjeto: permissions.contratoDato.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'datos__contratos-dato-edit',
    },
  },
  {
    path: 'contratos/:id/:idtabla/edit',
    name: 'datos__contratos-dato-edit',
    component: load('datos/datoEdit/DatoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.contratoDato.id,
        access: ACCESS.edit,
      },
    },
  },
  // lparteNovedad
  {
    path: 'novedades/:id/:idtabla',
    name: 'datos__novedades-dato-list',
    component: load('datos/datoList/DatoList'),
    meta: {
      permission: {
        idobjeto: permissions.lParteNovedadDato.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'datos__novedades-dato-edit',
    },
  },
  {
    path: 'novedades/:id/:idtabla/edit',
    name: 'datos__novedades-dato-edit',
    component: load('datos/datoEdit/DatoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.lParteNovedadDato.id,
        access: ACCESS.edit,
      },
    },
  },
  // operacioncrm
  {
    path: 'operaciones/:id/:idtabla',
    name: 'datos__operaciones-dato-list',
    component: load('datos/datoList/DatoList'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesDato.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'datos__operaciones-dato-edit',
    },
  },
  {
    path: 'operaciones/:id/:idtabla/edit',
    name: 'datos__operaciones-dato-edit',
    component: load('datos/datoEdit/DatoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesDato.id,
        access: ACCESS.edit,
      },
    },
  },
]
