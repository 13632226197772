import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'

export default class TanomaliaChecklistOtOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'tanomaliaChecklistOt.insertSync',
      params: { values },
    })
    const localValues = _.cloneDeep(values)
    localValues.estado = 1
    return await super.insert(localValues, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'tanomaliaChecklistOt.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idtanomaliaChecklistOt) {
    const tables = this.db.tables
    // eliminar acciones correctoras en cascada
    const accionesCorrectoras = await this.Vue.$offline.accionCorrectoraOt.select({
      where: tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(idtanomaliaChecklistOt)
    })
    for (let accion of accionesCorrectoras) {
      await this.Vue.$offline.accionCorrectoraOt.deleteSync(accion.accion_correctora_ot.idaccion_correctora_ot)
    }
    await this.Vue.$offline.sync.insert({
      method: 'tanomaliaChecklistOt.deleteSync',
      params: {
        idtanomalia_checklist_ot: idtanomaliaChecklistOt
      }
    })
    await this.Vue.$offline.tanomaliaChecklistOt.delete({
      where: tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(idtanomaliaChecklistOt)
    })
  }
}
