import _ from '@/utils/lodash'
import { TINCLUSION, ACCION_EJEC, MACCION, GRUPO_CHECKLIST, PREGUNTA_CHECKLIST, GRADO_ANOMALIA } from '@/utils/consts'
import BaseOfflineResource from '@/offline/base'

export default class OrdenTrabajoMatsistOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoMatsist.insertSync',
      params: { values },
    })
    const toReturn = await super.insert(values, options)
    await this.Vue.$offline.checklistOt.annadirPreguntasFaltan(values.idorden_trabajo)
    return toReturn
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoMatsist.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idordenTrabajoMatsist, idparteTrabajo) {
    const deleteValorColumnaOt = async (idpreguntaChecklistOt) => {
      const valorColumnaOtDummy = this.Vue.$offline.valorColumnaOt
      const valorColumnaOt = await this.db
        .select()
        .from(tables.valor_columna_ot)
        .innerJoin(valorColumnaOtDummy.dummyTable, valorColumnaOtDummy.pk.eq(valorColumnaOtDummy.dummyPk))
        .where(
          tables.valor_columna_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt)
        )
        .exec()
      for (const valor of valorColumnaOt) {
        await this.Vue.$offline.valorColumnaOt.deleteSync(valor.valor_columna_ot.idvalor_columna_ot)
      }
    }
    const deleteTanomaliaChecklistOt = async (idpreguntaChecklistOt) => {
      const tanomaliaChecklistOtDummy = this.Vue.$offline.tanomaliaChecklistOt
      const tanomaliaChecklistOt = await this.db
        .select()
        .from(tables.tanomalia_checklist_ot)
        .innerJoin(tanomaliaChecklistOtDummy.dummyTable, tanomaliaChecklistOtDummy.pk.eq(tanomaliaChecklistOtDummy.dummyPk))
        .where(
          tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt)
        )
        .exec()
      for (const tanomalia of tanomaliaChecklistOt) {
        // el tanomaliaChecklistOt.deleteSync borra las acciones correctoras en cascada
        await this.Vue.$offline.tanomaliaChecklistOt.deleteSync(tanomalia.tanomalia_checklist_ot.idtanomalia_checklist_ot)
      }
    }
    const deleteAgrupacionPreguntaChecklistOt = async (idgrupoChecklistOt) => {
      const agrupacionPreguntaOtDummy = this.Vue.$offline.agrupacionPreguntaOt
      const agrupacionPreguntaOt = await this.db
        .select()
        .from(tables.agrupacion_pregunta_ot)
        .innerJoin(agrupacionPreguntaOtDummy.dummyTable, agrupacionPreguntaOtDummy.pk.eq(agrupacionPreguntaOtDummy.dummyPk))
        .where(
          tables.agrupacion_pregunta_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt)
        )
        .exec()
      for (const agrupacion of agrupacionPreguntaOt) {
        await this.Vue.$offline.agrupacionPreguntaOt.deleteSync(agrupacion.agrupacion_pregunta_ot.idagrupacion_pregunta_ot)
      }
    }
    const tables = this.db.tables
    // eliminar preguntas del checklist en cascada
    const preguntas = await this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.estado.gt(0),
        )
      )
      .exec()
    for (const pregunta of preguntas) {
      if (pregunta.idagrupacion_pregunta_ot) {
        // Valores de columnas
        await deleteValorColumnaOt(pregunta.idpregunta_checklist_ot)
      }
      // Anomalías y acciones correctoras
      await deleteTanomaliaChecklistOt(pregunta.idpregunta_checklist_ot)
      await this.Vue.$offline.preguntaChecklistOt.deleteSync(pregunta.idpregunta_checklist_ot)
    }
    // Agrupaciones de preguntas
    // Comprobar si está vacío antes de borrarlo
    const idgrupoChecklistOt = _.uniqBy(
      _.map(
        preguntas, 'idgrupo_checklist_ot'
      ), 'idgrupo_checklist_ot'
    )
    for (const idgrupo of idgrupoChecklistOt) {
      const preuntasGrupoChecklistOtDummy = this.Vue.$offline.preguntaChecklistOt
      const preuntasGrupoChecklistOt = await this.db
        .select()
        .from(tables.pregunta_checklist_ot)
        .innerJoin(preuntasGrupoChecklistOtDummy.dummyTable, preuntasGrupoChecklistOtDummy.pk.eq(preuntasGrupoChecklistOtDummy.dummyPk))
        .where(
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupo)
        )
        .exec()
      if (preuntasGrupoChecklistOt.length === 0) {
        // borrar agrupaciones de preguntas del grupo
        await deleteAgrupacionPreguntaChecklistOt(idgrupo)
        // Eliminar el grupo vacío
        await this.Vue.$offline.grupoChecklistOt.deleteSync(idgrupo)
      }
    }

    // eliminar acciones en cascada
    const acciones = await this.db
      .select()
      .from(tables.accion_ejec)
      .where(
        this.db.op.and(
          tables.accion_ejec.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.accion_ejec.estado.gt(0),
        )
      )
      .exec()

    for (const accion of acciones) {
      await this.Vue.$offline.accionEjec.deleteSync(accion.idaccion_ejec, idparteTrabajo)
    }
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoMatsist.deleteSync',
      params: {
        idorden_trabajo_matsist: idordenTrabajoMatsist
      }
    })
    await this.Vue.$offline.ordenTrabajoMatsist.delete({
      where: tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist)
    })
  }
  async updateMaterialContestadoCorrecto (idparteTrabajo, idchecklistOt, idordenTrabajoMatsist, idtecnico) {
    const tables = this.db.tables
    const preguntasSinContestar = await this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(this.db.op.and(
        tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
        tables.pregunta_checklist_ot.idgrado_anomalia.isNull()
      ))
      .exec()
    const idsPregunta = _.map(preguntasSinContestar, 'pregunta_checklist_ot.idpregunta_checklist_ot')
    const gradoOk = await this.Vue.$offline.gradoAnomalia.selectGradoAnomaliaCorrecto()
    const gradoAnomaliaOk = gradoOk[0]
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoMatsist.updateSyncMaterialCheckCorrecto',
      params:
        {
          idchecklist_ot: idchecklistOt,
          idorden_trabajo_matsist: idordenTrabajoMatsist,
          idtecnico: idtecnico
        }
    })
    await this.db
      .update(tables.pregunta_checklist_ot)
      .set(tables.pregunta_checklist_ot.idgrado_anomalia, gradoAnomaliaOk.idgrado_anomalia)
      .set(tables.pregunta_checklist_ot.idtecnico, idtecnico)
      .set(tables.pregunta_checklist_ot.resultado, PREGUNTA_CHECKLIST.resultado.ok)
      .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(idsPregunta))
      .exec()
    // marcar acciones de revisión pendientes -> realizadas
    const clasificacionesRevision = [
      MACCION.clasificacion.revision_anual,
      MACCION.clasificacion.revision_semestral,
      MACCION.clasificacion.revision_trimestral,
      MACCION.clasificacion.revision_mensual,
    ]
    const revisiones = await this.db
      .select(
        tables.accion_ejec.idaccion_ejec,
        tables.accion_ejec.idmaccion,
        tables.accion_ejec.facturable,
        tables.accion_ejec.idest_accion_ejec,
        tables.maccion.clasificacion,
        tables.maccion.orden,
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        this.db.op.and(
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
          tables.maccion.clasificacion.in(clasificacionesRevision),
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist)
        )
      )
      .orderBy(tables.maccion.orden)
      .exec()
    for (const revision of revisiones) {
      await this.Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
        revision.accion_ejec.idaccion_ejec,
        idchecklistOt,
        revision.accion_ejec.facturable,
        null,
        TINCLUSION.suministro,
        idtecnico,
        idparteTrabajo
      )
    }
    await this.Vue.$offline.ordenTrabajoMatsist.updateTecnicoPreguntasMaterialArrastradas(idchecklistOt, idordenTrabajoMatsist, idtecnico)
  }
  async updateMaterialNoContestado (idchecklistOt, idordenTrabajoMatsist) {
    const tables = this.db.tables
    const gradoOk = await this.Vue.$offline.gradoAnomalia.selectGradoAnomaliaCorrecto()
    const gradoAnomaliaOk = gradoOk[0]
    const preguntasOK = await this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(this.db.op.and(
        tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(gradoAnomaliaOk.idgrado_anomalia)
      ))
      .exec()
    const idsPregunta = _.map(preguntasOK, 'pregunta_checklist_ot.idpregunta_checklist_ot')
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoMatsist.updateSyncMaterialCheckNoContestado',
      params:
        {
          idchecklist_ot: idchecklistOt,
          idorden_trabajo_matsist: idordenTrabajoMatsist
        }
    })
    return this.db
      .update(tables.pregunta_checklist_ot)
      .set(tables.pregunta_checklist_ot.idgrado_anomalia, null)
      .set(tables.pregunta_checklist_ot.resultado, null)
      .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(idsPregunta))
      .exec()
  }
  async updateTecnicoPreguntasMaterialArrastradas (idchecklistOt, idordenTrabajoMatsist, idtecnico) {
    const tables = this.db.tables
    // preguntas arrastradas desde otro checklist
    // - sin técnico
    // - con anomalías
    const preguntasSinTecnico = await this.db
      .select(
        this.db.fn.distinct(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('idpregunta_checklist_ot')
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idtecnico.isNull(),
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
        )
      )
      .exec()
    for (const preguntaSinTecnico of preguntasSinTecnico) {
      const pregunta = _.cloneDeep(await this.Vue.$offline.preguntaChecklistOt.row(preguntaSinTecnico.idpregunta_checklist_ot))
      pregunta.pregunta_checklist_ot.idtecnico = idtecnico
      await this.Vue.$offline.preguntaChecklistOt.updateSync(pregunta.pregunta_checklist_ot)
    }
  }
  async resultadoGrupo (idordenTrabajoMatsist) {
    const tables = this.db.tables
    // alguna anomalía  -> GRUPO_CHECKLIST.resultado.conAnomalias
    const anomalias = await this.Vue.$offline.ordenTrabajoMatsist.selectAnomalias(idordenTrabajoMatsist)
    if (anomalias.length > 0) {
      return GRUPO_CHECKLIST.resultado.conAnomalias
    } else {
      // preguntas desconocidas implican incorrecto
      const desconocidas = await this.Vue.$offline.ordenTrabajoMatsist.selectDesconocidas(idordenTrabajoMatsist)
      if (desconocidas.length > 0) {
        return GRUPO_CHECKLIST.resultado.conAnomalias
      } else {
        // todas ok -> GRUPO_CHECKLIST.resultado.ok
        // preguntas sin contestar -> GRUPO_CHECKLIST.resultado.sinContestar
        // sino (combinación de ok/no procede) -> GRUPO_CHECKLIST.resultado.ok
        const preguntas = await this.db
          .select()
          .from(tables.pregunta_checklist_ot)
          .leftOuterJoin(
            tables.grado_anomalia,
            tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
          )
          .where(
            this.db.op.and(
              tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
              tables.pregunta_checklist_ot.estado.gt(0),
            )
          )
          .exec()
        const preguntasOk = _.filter(preguntas, (value) => {
          return (value.pregunta_checklist_ot.resultado === PREGUNTA_CHECKLIST.resultado.ok)
        })
        if (preguntas.length === preguntasOk.length) {
          return GRUPO_CHECKLIST.resultado.ok
        } else {
          const preguntasSinContestar = _.filter(preguntas, (value) => {
            return (value.pregunta_checklist_ot.resultado === null)
          })
          if (preguntasSinContestar.length > 0) {
            return GRUPO_CHECKLIST.resultado.sinContestar
          } else {
            return GRUPO_CHECKLIST.resultado.ok
          }
        }
      }
    }
  }
  async selectAnomaliasResumen (idordenTrabajoMatsist) {
    const tables = this.db.tables
    return await this.Vue.$offline.db
      .select(
        tables.grado_anomalia.idgrado_anomalia,
        tables.grado_anomalia.alias,
        tables.grado_anomalia.descripcion,
        tables.grado_anomalia.orden,
        this.Vue.$offline.db.fn.count(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot).as('count'),
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grado_anomalia,
        tables.tanomalia_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.tanomalia_checklist_ot.estado.gt(0)
        )
      )
      .groupBy(
        tables.grado_anomalia.idgrado_anomalia,
        tables.grado_anomalia.alias,
        tables.grado_anomalia.descripcion,
        tables.grado_anomalia.orden,
      )
      .orderBy(tables.grado_anomalia.orden, this.Vue.$offline.db.order.DESC)
      .exec()
  }
  async selectAccionesCorrectorasResumen (idordenTrabajoMatsist) {
    const tables = this.Vue.$offline.db.tables
    return (await this.Vue.$offline.db
      .select(
        this.Vue.$offline.db.fn.count(tables.accion_correctora_ot.idaccion_correctora_ot).as('count'),
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.accion_correctora_ot,
        tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(tables.accion_correctora_ot.idtanomalia_checklist_ot)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.tanomalia_checklist_ot.estado.gt(0),
          tables.accion_correctora_ot.estado.gt(0)
        )
      )
      .exec())[0]
  }
  selectAnomalias (idordenTrabajoMatsist) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.tanomalia_checklist_ot.estado.gt(0),
        )
      )
      .exec()
  }
  selectDesconocidas (idordenTrabajoMatsist) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grado_anomalia,
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.desconocido),
        )
      )
      .exec()
  }
  async selectPorIdmaterialSistema (idordenTrabajo, idmaterialSistema) {
    const tables = this.Vue.$offline.db.tables
    return this.Vue.$offline.db
      .select()
      .from(tables.orden_trabajo_matsist)
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.estado.gt(0),
          tables.orden_trabajo_matsist.idorden_trabajo.eq(idordenTrabajo),
          tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema)
        )
      )
      .exec()
  }
  async maccionEnOrdenTrabajoMatsist(idordenTrabajoMatsist) {
    // Devuelve las acciones que tiene ese material en el parte, independientemente de su estado
    const tables = this.Vue.$offline.db.tables
    return this.Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .where(
        this.Vue.$offline.db.op.and(
          tables.accion_ejec.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.accion_ejec.estado.gt(0),
        )
      )
      .exec()
  }
  async selectMaterialSistemaDeIdordenTrabajoMatsist (idordenTrabajoMatsist) {
    const tables = this.db.tables
    return (await this.db
      .select()
      .from(tables.orden_trabajo_matsist)
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        this.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.orden_trabajo_matsist.estado.gt(0)
        )
      ).exec())[0]
  }
}
