import _ from '@/utils/lodash'

export const ACCESS = {
  view: 'view',
  insert: 'insert',
  edit: 'edit',
  delete: 'delete',
}

export const permissions = {
  // NO USADOS AUN
  /*
  almacen: {
    verArticulos: 751859235,
  },
  clientes: {
    insertarCliente: 244337232,
  },
  comerciales: {
    verAgentes: 402180058,
    verPresupuestos: 700854185,
    modificarResponsableOperacion: 488938510,
  },
  crm: {
    verOperacionesAbiertas: 696689265,
    verOperacionesCerradas: 133740361,
    verOperacionesInformadas: 570651668,
  },
  presupuestos: {
    verImportes: 139029063,
  },
  sistemas: {
    modificarDatosSistema: 44418309,
    borrarDatosSistema: 840850921,
    posicionarAdjuntosPlano: 965681218,
    desinstalarMaterialInstalado: 159549174, // botón desinstalar sin acción
    generarRevisiones: 433969355,
    insertarSistema: 279919576,
    insertarAdjuntos: 493444926,
    modificarAdjuntos: 252496025,
    borrarAdjuntos: 820851139,
    insertarMaterialInstalado: 679303720,
    modificarMaterialInstalado: 976866690,
    borrarMaterialInstalado: 156499149,
  },
  partes: {
    reasignarMios: 614891402,
    reasignarOtros: 132137596,
    borrarLineaOt: 890653317,
    borrarLparteTrabajo: 416431481,
    modificarLparteTrabajo: 97262157,
  },
  */
  articulos: {
    verTarifas: 798897329,
  },
  clientes: {
    verFormaPago: 219503157,
    verRiesgoDisponible: 441462438,
  },
  comerciales: {
    verClientesPotenciales: 596676828,
    verFacturas: 177336178,
  },
  clientesPotenciales: {
    modificarDatosContacto: 374289622,
    borrarDatosContacto: 135728485,
  },
  sistemas: {
    modificarDatosContacto: 270192539,
    borrarDatosContacto: 774951447,
    modificarViasComunicacion: 57530728,
    borrarViasComunicacion: 1180548,
    verAdjuntos: 890070301,
    verPersonasContactoCodigoCRA: 428415629,
    verPersonasContactoCodigoCoaccionCRA: 640506672,
    verSistemas: 585286154,
    borrarMaterialInstalado: 156499149,
  },
  ordenes: {
    verPendientes: 232895829,
    verFinalizadas: 178725234,
    verFinalizadasOtros: 718649742,
    asignarAMi: 322395344,
    asignarAOtros: 525854,
  },
  partes: {
    modificarManoObraFacturable: 521486982,
    modificarDesplazamientoFacturable: 506597010,
    modificarMaterialFacturable: 157966363,
    finalizarParteYOt: 319101614,
    finalizarParteConChecklistPendiente: 167604390,
    finalizarChecklistSinContestar: 178167941,
    marcarPreguntasTodoCorrecto: 223742202,
    marcarAccionesTodoRealizado: 941444910,
    verAsignadosOtros: 194718134,
    verAsignadosAMi: 268058819,
    modificarFechasPlanificacion: 229971470,
    nuevoParteTrabajo: 76296154,
    anular: 255269975,
    annadirTecnico: 67750717,
    cobrarIntervencion: 126236083,
    reabrir: 559663072,
  },
  planificacion: {
    agenda: 229181730,
  },
  presencia: {
    verEntradasYSalidas: 764930511,
    registrarEntrada: 912263857,
    registrarSalida: 210115869,
  },
  sesion: {
    cambiarContrasena: 23291854,
  },
  tiempos: {
    introducirTiemposMasAllaFechaActual: 25761795,
    introducirManual: 274486891,
    introducirOtrosTecnicos: 60986996,
  },
  vehiculos: {
    verMaterial: 182541675,
    verStock: 775248503,
  },
  vigilancia: {
    verRondas: 745953677,
    verPartesNovedad: 891291469,
  },
  // checklists
  accionCorrectoraAdjunto: {
    id: 203149174,
  },
  anomaliaAdjunto: {
    id: 87227076,
  },
  anomaliaAgregarADefinicionChecklist: {
    id: 661583937
  },
  anomaliaTextoLibre: {
    id: 361902818
  },
  // clientes
  cliente: {
    id: 558578322,
  },
  clienteAdjunto: {
    id: 561991025,
  },
  clienteCuenta: {
    id: 174274173,
  },
  clienteDireccion: {
    id: 199749163,
  },
  clienteEstudioFinanciero: {
    id: 533599838,
  },
  clienteFacturas: {
    id: 674922962,
  },
  clienteRepresentante: {
    id: 631944902,
  },
  clienteTelefono: {
    id: 222920610,
  },
  // clientes potenciales
  clientePotencial: {
    id: 614001656,
  },
  clientePotencialAdjunto: {
    id: 882241706,
  },
  clientePotencialCuenta: {
    id: 419870345,
  },
  clientePotencialDireccion: {
    id: 73862611,
  },
  clientePotencialEstudioFinanciero: {
    id: 569912828,
  },
  clientePotencialRepresentante: {
    id: 315574130,
  },
  clientePotencialTelefono: {
    id: 517098663,
  },
  // empleado
  empleado: {
    id: 349453020,
  },
  empleadoAdjunto: {
    id: 512016043,
  },
  // sistemas
  sistema: {
    id: 962552800,
  },
  sistemaCuota: {
    id: 973269644
  },
  materialSistema: {
    id: 17976943
  },
  sistemaAdjunto: {
    id: 541665727,
  },
  sistemaDato: {
    id: 572609823,
  },
  sistemaTelefono: {
    id: 159044485,
  },
  // órdenes de trabajo
  ordenTrabajoAdjunto: {
    id: 816950098,
  },
  // partes de trabajo
  parteTrabajoAdjunto: {
    id: 731521128,
  },
  parteTrabajoSolicitudMaterial: {
    id: 249760343,
  },
  parteTrabajoTiempoTrabajado:
  {
    id: 301487466,
  },
  // presupuestos
  presupuestocli: {
    id: 950594429,
  },
  presupuestoCuota: {
    id: 331938926,
  },
  presupuestocliAdjunto: {
    id: 344080339,
  },
  presupuestocliDato: {
    id: 344080339,
  },
  presupuestocliDocumento: {
    id: 323986966,
  },
  presupuestocliSobre: {
    id: 738299139,
  },
  lpresupuestocli: {
    id: 522988424,
  },
  presupuestocliMant: {
    id: 263820836,
  },
  presupuestocliSubsis: {
    id: 927711597,
  },
  // contrato
  contrato: {
    id: 867767469,
  },
  contratoCuota: {
    id: 738558283,
  },
  contratoMaterial: {
    id: 233485736,
  },
  servicioContratado: {
    id: 283932561,
  },
  contratoDato: {
    id: 296776137,
  },
  contratoAdjunto: {
    id: 134746789,
  },
  // puestos de servicio
  puestoServicio: {
    id: 476762538,
  },
  puestoServicioAdjunto: {
    id: 253123951,
  },
  // servicios
  servicio: {
    id: 718414013,
  },
  // turnos servicio
  tturnoServicio: {
    id: 95017978,
  },
  // partes de novedad
  lparteNovedadAdjunto: {
    id: 441522461,
  },
  lParteNovedadDato: {
    id: 80066283,
  },
  // cuadrantes
  cuadrante: {
    id: 206153759,
  },
  // vigilantes
  vigilante: {
    id: 319243842,
  },
  // Citas
  citas: {
    id: 162436622,
  },
  // Operaciones
  operaciones: {
    id: 976632242,
  },
  operacionesAdjunto: {
    id: 456397987,
  },
  operacionesContactos: {
    id: 620061832,
  },
  operacionesDato: {
    id: 967336339,
  },
  // Personas de contacto
  personaContactoParaTodosLosSistemas: {
    id: 636016302,
  },
  // Movimientos de cja
  movimientosCaja: {
    id: 787669117,
  },
  movimientosCajaAdjunto: {
    id: 591969013,
  },
  rondaAdjunto: {
    id: 250522733,
  },
  // PS_CHECK
  puntoRondaAdjunto: {
    id: 942641985,
  },
  nota: {
    id: 25853359,
  },
}

const findPerm = (userPermissions, perm) => {
  return _.find(userPermissions, { idobjeto: perm })
}

export const hasViewPerm = (userPermissions, perm) => {
  const existePermiso = findPerm(userPermissions, perm)
  return (existePermiso) ? (existePermiso.visualizar) : (false)
}

export const hasInsertPerm = (userPermissions, perm) => {
  const existePermiso = findPerm(userPermissions, perm)
  return (existePermiso) ? (existePermiso.insertar) : (false)
}

export const hasEditPerm = (userPermissions, perm) => {
  const existePermiso = findPerm(userPermissions, perm)
  return (existePermiso) ? (existePermiso.editar) : (false)
}

export const hasDeletePerm = (userPermissions, perm) => {
  const existePermiso = findPerm(userPermissions, perm)
  return (existePermiso) ? (existePermiso.borrar) : (false)
}

// Este tipo de permiso es para cuando es de tipo opción (7)
export const hasPerm = (userPermissions, perm) => {
  const existePermiso = findPerm(userPermissions, perm)
  return (existePermiso) ? (existePermiso.visualizar) : (false)
}

export const addAppToRoute = (route, appName) => {
  route.forEach(object => {
    if (object.meta && object.meta.permission) {
      object.meta.permission.app = appName;
    } else {
      object.meta = { permission: { app: appName } };
    }
  });
}
