import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class TipoOperacionCrmOnlineResource extends BaseOnlineResource {
  buildApiFilterVisualizacion (esTecnico, esComercial, esVigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    if (esTecnico || esComercial || esVigilante) {
      apiFilterVisualizacion
        .setOperator(API_FILTER_OPERATORS.OR)
      if (esTecnico) {
        apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
      }
      if (esComercial) {
        apiFilterVisualizacion.addExact('visible_movilidad_comercial', esComercial)
      }
      if (esVigilante) {
        apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esVigilante)
      }
      apiFilter.addNestedFilter(apiFilterVisualizacion)
    }
    return apiFilter
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
  }
}
