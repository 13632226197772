import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'empleados__empleado-list',
    component: load('empleados/empleadoList/EmpleadoList'),
    meta: {
      permission: {
        idobjeto: permissions.empleado.id,
        access: ACCESS.view,
      },
    },
  },
]
