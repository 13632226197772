import iban from 'iban'

export const isValidIBAN = (str) => {
  return iban.isValid(str)
}

const NIFLetters = 'TRWAGMYFPDXBNJZSQVHLCKET'
const NIFRegExp = '^\\d{8}[a-zA-Z]{1}$'
const CIFRegExp = '^[a-zA-Z]{1}\\d{7}[a-jA-J0-9]{1}$'

const checkNIF = (nif) => {
  // Comprueba la longitud. Los DNI antiguos tienen 7 digitos.
  if ((nif.length !== 8) && (nif.length !== 9)) return false
  if (nif.length === 8) nif = '0' + nif
  // Comprueba el formato
  let regExp = new RegExp(NIFRegExp)
  if (!nif.match(regExp)) return false
  let long = nif.charAt(nif.length - 1)
  let dni = nif.substring(0, nif.length - 1)
  let letra = NIFLetters.charAt(dni % 23)
  return (letra === long.toUpperCase())
}

const checkTR = (tr) => {
  if ((tr.length !== 10) && (tr.length !== 9)) return false
  if ((tr.charAt(0).toUpperCase() !== 'X') && (tr.charAt(0).toUpperCase() !== 'Y') && (tr.charAt(0).toUpperCase() !== 'Z')) return false
  let leftNum = '0'
  if (tr.charAt(0).toUpperCase() === 'Y') leftNum = '1'
  if (tr.length === 9) {
    return checkNIF(leftNum + tr.substring(1, tr.length))
  } else {
    return checkNIF(tr.substring(1, tr.length))
  }
}

const checkCIF = (cif) => {
  let v1 = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
  let tempStr = cif.toUpperCase()
  let temp = 0
  let dc
  // Comprueba el formato
  let regExp = new RegExp(CIFRegExp)
  if (!tempStr.match(regExp)) return false
  if (!/^[ABCDEFGHKLMNPQS]/.test(tempStr)) return false
  for (let i = 2; i <= 6; i += 2) {
    temp = temp + v1[ parseInt(cif.substr(i - 1, 1)) ]
    temp = temp + parseInt(cif.substr(i, 1))
  }
  temp = temp + v1[ parseInt(cif.substr(7, 1)) ]
  temp = (10 - (temp % 10))
  if (temp === 10) {
    temp = 0
  }
  dc = cif.toUpperCase().charAt(8)
  return (
    (parseInt(dc) === temp) ||
    (temp === 1 && dc === 'A') ||
    (temp === 2 && dc === 'B') ||
    (temp === 3 && dc === 'C') ||
    (temp === 4 && dc === 'D') ||
    (temp === 5 && dc === 'E') ||
    (temp === 6 && dc === 'F') ||
    (temp === 7 && dc === 'G') ||
    (temp === 8 && dc === 'H') ||
    (temp === 9 && dc === 'I') ||
    (temp === 0 && dc === 'J')
  )
}

export const isValidNIF = (value) => {
  if (value) {
    if (checkCIF(value)) {
      return true
    } else if (checkTR(value)) {
      return true
    } else if (checkNIF(value)) {
      return true
    } else {
      return false
    }
  }
}
export const calcularMaxDecimalMaxInteger = (precision) => {
  const precisionString = precision.toString()
  const [prec, scale] = precisionString.split('.').map(Number);
  const maxIntegerDigits = prec - scale
  const maxDecimalDigits = scale
  return [maxIntegerDigits, maxDecimalDigits]
}
export const validarNumeroOracle = (value, precision) => {
  // el campo precision tiene que ser un valor decimal, por ejemplo 18.8
  // Permite valores vacíos
  if (!value) {
    return true
  }
  const valueString = value.toString()
  const numValue = Number(valueString)
  // Verificar la cantidad de dígitos enteros y decimales
  const parts = valueString.split('.')
  const integerPart = parts[0] || ''
  const decimalPart = parts[1] || ''
  const [maxIntegerDigits, maxDecimalDigits] = calcularMaxDecimalMaxInteger(precision)
  return integerPart.length <= maxIntegerDigits && decimalPart.length <= maxDecimalDigits && integerPart.length + decimalPart.length <= precision && !isNaN(numValue)
}
