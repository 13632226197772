import BaseOfflineResource from '@/offline/base'
import { v4 as uuidv4 } from 'uuid'
import { ARTICULO_ESCANDALLO, CRONOMETRO, TFACTURACION_KILOMETROS, PARAMETRO } from '@/utils/consts'
import store from '@/store'
import { currentDateTime, differenceInMinutes } from '@/utils/date'

export default class TiempoTrabajadoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    let tecnico = await this.Vue.$offline.tecnico.selectTecnicoDeEmpleado(values.idempleado)
    const tecnicoTtarea = await this.Vue.$offline.tecnicoTtarea.selectTtarea(tecnico[0].idtecnico, values.idttarea)
    if (tecnicoTtarea.length > 0) {
      values.coste_hora = tecnicoTtarea[0].tecnico_ttarea.coste_hora
    } else {
      values.coste_hora = 0
    }
    const costeKilometro = await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.COSTE_KILOMETRO)
    if (costeKilometro) {
      values.coste_kilometro = parseFloat(costeKilometro.replace(',', '.'))
    }
    const idttiempoTrabajado = await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.IDTTIEMPO_TRA_DEFECTO)
    if (idttiempoTrabajado) {
      values.idttiempo_trabajado = parseInt(idttiempoTrabajado)
    }
    await this.Vue.$offline.sync.insert({
      method: 'tiempoTrabajado.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'tiempoTrabajado.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idTiempoTrabajado) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'tiempoTrabajado.deleteSync',
      params: {
        idtiempo_trabajado: idTiempoTrabajado
      }
    })
    await this.Vue.$offline.tiempoTrabajado.delete({
      where: tables.tiempo_trabajado.idtiempo_trabajado.eq(idTiempoTrabajado)
    })
  }
  async iniciarTrabajoParte (para, idparteTrabajo, latitud, longitud, esDesplazamiento) {
    const tables = this.db.tables
    const parteTrabajoTecnicoTable = this.Vue.$offline.parteTrabajoTecnico
    const finicio = currentDateTime()
    if (para === CRONOMETRO.para.paraTodos) {
      const parteTrabajoTecnico = await this.db
        .select()
        .from(tables.parte_trabajo_tecnico)
        .innerJoin(parteTrabajoTecnicoTable.dummyTable, parteTrabajoTecnicoTable.pk.eq(parteTrabajoTecnicoTable.dummyPk))
        .where(tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo))
        .exec()
      for (let item of parteTrabajoTecnico) {
        await this.Vue.$offline.parteTrabajoTecnico.updateSync({
          idparte_trabajo_tecnico: item.parte_trabajo_tecnico.idparte_trabajo_tecnico,
          finicio,
          latitud,
          longitud,
          es_desplazamiento: esDesplazamiento,
        })
      }
    } else {
      const parteTrabajoTecnico = await this.db
        .select()
        .from(tables.parte_trabajo_tecnico)
        .innerJoin(parteTrabajoTecnicoTable.dummyTable, parteTrabajoTecnicoTable.pk.eq(parteTrabajoTecnicoTable.dummyPk))
        .where(
          this.db.op.and(
            tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo),
            tables.parte_trabajo_tecnico.idtecnico.eq(store.get('usuario/idtecnico'))
          )
        )
        .exec()
      await this.Vue.$offline.parteTrabajoTecnico.updateSync({
        idparte_trabajo_tecnico: parteTrabajoTecnico[0].parte_trabajo_tecnico.idparte_trabajo_tecnico,
        finicio,
        latitud,
        longitud,
        es_desplazamiento: esDesplazamiento,
      })
    }
  }
  async insertarTiempoTrabajadoParte (
    kilometros, observaciones, idttarea,
    manodeobrafacturable, kilometrosFacturables, esDesplazamiento,
    para, idtecnico, finicio, ffin,
    latitudInicio, longitudInicio, latitudFin, longitudFin,
    idparteTrabajo, idsubsis, cronometro, idvehiculo
  ) {
    const tables = this.db.tables
    let kilometrosGuardar
    const parteTrabajoTecnico = await this.db
      .select(
        tables.parte_trabajo_tecnico.idparte_trabajo_tecnico,
        tables.parte_trabajo_tecnico.idtecnico,
        tables.tecnico.idempleado,
        tables.parte_trabajo.idorden_trabajo,
        tables.parte_trabajo_tecnico.finicio,
        tables.orden_trabajo.idproyecto_contable,
      )
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.parte_trabajo_tecnico.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
    for (let item of parteTrabajoTecnico) {
      if (para === CRONOMETRO.para.paraTodos || item.parte_trabajo_tecnico.idtecnico === idtecnico) {
        if (cronometro) {
          await this.Vue.$offline.parteTrabajoTecnico.updateSync({
            idparte_trabajo_tecnico: item.parte_trabajo_tecnico.idparte_trabajo_tecnico,
            finicio: null,
            latitud: null,
            longitud: null
          })
        }
        let idttareaCalc
        let idVehiculoPrincipal
        // si es el técnico que está registrando el tiempo -> tipo de tarea del desplegable
        if (item.parte_trabajo_tecnico.idtecnico === idtecnico) {
          idttareaCalc = idttarea
          // sólo mete el idvehiculo y el kilometraje en el técnico que logea el tiempo
          idVehiculoPrincipal = idvehiculo
          kilometrosGuardar = kilometros
        } else {
          idVehiculoPrincipal = null
          kilometrosGuardar = 0
          // si el técnico "secundario" tiene definido el mismo tipo de tarea
          const tecnicoTtarea = await this.Vue.$offline.tecnicoTtarea.selectTtarea(item.parte_trabajo_tecnico.idtecnico, idttarea)
          if (tecnicoTtarea.length > 0) {
            idttareaCalc = tecnicoTtarea[0].ttarea.idttarea
          } else {
            // sino el tipo de tarea por defecto del técnico "secundario"
            const tecnicoTtareaDefecto = await this.Vue.$offline.tecnicoTtarea.selectTtareaPrincipal(item.parte_trabajo_tecnico.idtecnico)
            if (tecnicoTtareaDefecto.length > 0) {
              idttareaCalc = tecnicoTtareaDefecto[0].ttarea.idttarea
            } else {
              // si el técnico "secundario" no tiene tipo de tarea por defecto -> tipo de tarea del desplegable
              idttareaCalc = idttarea
            }
          }
        }
        // hay algunos campos que se mandan a null porque es insertSync quien se encarga de rellenarlos
        await this.Vue.$offline.tiempoTrabajado.insertSync({
          idtiempo_trabajado: uuidv4(),
          idparte_trabajo: idparteTrabajo,
          idttarea: idttareaCalc,
          idempleado: item.tecnico.idempleado,
          finicio,
          ffin,
          kilometros: parseInt(kilometrosGuardar),
          manodeobrafacturable: manodeobrafacturable,
          kilometros_facturables: kilometrosFacturables,
          observaciones: observaciones,
          latitud_inicio: latitudInicio,
          longitud_inicio: longitudInicio,
          latitud_fin: latitudFin,
          longitud_fin: longitudFin,
          estado: 1,
          idsubsis,
          es_desplazamiento: esDesplazamiento,
          cronometro,
          idvehiculo: idVehiculoPrincipal,
          idproyecto_contable: item.orden_trabajo.idproyecto_contable,
        })
      }
    }
  }
  async borrarManoObraFacturable (idparteTrabajo) {
    // mano de obra facturable relacionada por TIEMPO_TRABAJADO.IDLPARTE_TRABAJO
    const tables = this.db.tables
    const lineasParteMO = await this.db
      .select(
        tables.tiempo_trabajado.idlparte_trabajo,
        this.db.fn.count(tables.tiempo_trabajado.idlparte_trabajo).as('dummy'),
      )
      .from(tables.tiempo_trabajado)
      .innerJoin(
        tables.lparte_trabajo,
        tables.tiempo_trabajado.idlparte_trabajo.eq(tables.lparte_trabajo.idlparte_trabajo)
      )
      .where(
        this.db.op.and(
          tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
          tables.tiempo_trabajado.idlparte_trabajo.isNotNull(),
          tables.tiempo_trabajado.es_desplazamiento.eq(false)
        )
      )
      .groupBy(
        tables.tiempo_trabajado.idlparte_trabajo,
      )
      .exec()
    // desenlazar TIEMPO_TRABAJADO/LPARTE_TRABAJO y luego eliminar LPARTE_TRABAJO de MO
    for (const lineaParteMO of lineasParteMO) {
      // una linea de parte de mano de obra agrupa n tiempos trabajados (agrupados por artículo de facturación del técnico y subsistema)
      const tiemposTrabajadoUnaLinea = await this.db
        .select()
        .from(tables.tiempo_trabajado)
        .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
        .where(tables.tiempo_trabajado.idlparte_trabajo.eq(lineaParteMO.tiempo_trabajado.idlparte_trabajo))
        .exec()
      for (const tiempoTrabajado of tiemposTrabajadoUnaLinea) {
        await this.Vue.$offline.tiempoTrabajado.updateSync({
          idtiempo_trabajado: tiempoTrabajado.tiempo_trabajado.idtiempo_trabajado,
          idlparte_trabajo: null,
        })
      }
      await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
        lineaParteMO.tiempo_trabajado.idlparte_trabajo
      )
    }
  }
  async borrarDesplazamientoFacturable (idparteTrabajo) {
    // desplazamiento facturable relacionado por:
    // TIEMPO_TRABAJADO.IDLPARTE_TRABAJO -> tiempo de desplazamiento
    // TIEMPO_TRABAJADO.IDLPARTE_TRABAJO_KILOMETROS -> kms de desplazamiento
    const tables = this.db.tables
    const parte = await this.Vue.$offline.parteTrabajo.row(idparteTrabajo)
    if (parte.parte_trabajo.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosTecnico) {
      // TIEMPO_TRABAJADO.IDLPARTE_TRABAJO -> tiempo de desplazamiento
      const lineasParteDesplazamientoTiempo = await this.db
        .select(
          tables.tiempo_trabajado.idlparte_trabajo,
          this.db.fn.count(tables.tiempo_trabajado.idlparte_trabajo).as('dummy'),
        )
        .from(tables.tiempo_trabajado)
        .innerJoin(
          tables.lparte_trabajo,
          tables.tiempo_trabajado.idlparte_trabajo.eq(tables.lparte_trabajo.idlparte_trabajo)
        )
        .where(
          this.db.op.and(
            tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
            tables.tiempo_trabajado.idlparte_trabajo.isNotNull(),
            tables.tiempo_trabajado.es_desplazamiento.eq(true)
          )
        )
        .groupBy(
          tables.tiempo_trabajado.idlparte_trabajo,
        )
        .exec()
      // desenlazar TIEMPO_TRABAJADO/LPARTE_TRABAJO y luego eliminar LPARTE_TRABAJO de desplazamiento
      for (const lineaParteDesplazamiento of lineasParteDesplazamientoTiempo) {
        // una linea de parte de desplazamiento agrupa n tiempos trabajados (agrupados por subsistema)
        const tiemposTrabajadoUnaLinea = await this.db
          .select()
          .from(tables.tiempo_trabajado)
          .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
          .where(tables.tiempo_trabajado.idlparte_trabajo.eq(lineaParteDesplazamiento.tiempo_trabajado.idlparte_trabajo))
          .exec()
        for (const tiempoTrabajado of tiemposTrabajadoUnaLinea) {
          await this.Vue.$offline.tiempoTrabajado.updateSync({
            idtiempo_trabajado: tiempoTrabajado.tiempo_trabajado.idtiempo_trabajado,
            idlparte_trabajo: null,
          })
        }
        await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
          lineaParteDesplazamiento.tiempo_trabajado.idlparte_trabajo
        )
      }
      // TIEMPO_TRABAJADO.IDLPARTE_TRABAJO_KILOMETROS -> kms de desplazamiento
      const lineasParteDesplazamientoKms = await this.db
        .select(
          tables.tiempo_trabajado.idlparte_trabajo_kilometros,
          this.db.fn.count(tables.tiempo_trabajado.idlparte_trabajo_kilometros).as('dummy'),
        )
        .from(tables.tiempo_trabajado)
        .innerJoin(
          tables.lparte_trabajo,
          tables.tiempo_trabajado.idlparte_trabajo_kilometros.eq(tables.lparte_trabajo.idlparte_trabajo)
        )
        .where(
          this.db.op.and(
            tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
            tables.tiempo_trabajado.idlparte_trabajo_kilometros.isNotNull(),
            tables.tiempo_trabajado.es_desplazamiento.eq(true)
          )
        )
        .groupBy(
          tables.tiempo_trabajado.idlparte_trabajo_kilometros,
        )
        .exec()
      // desenlazar TIEMPO_TRABAJADO/LPARTE_TRABAJO y luego eliminar LPARTE_TRABAJO de desplazamiento
      for (const lineaParteDesplazamiento of lineasParteDesplazamientoKms) {
        // una linea de parte de desplazamiento agrupa n tiempos trabajados (agrupados por subsistema)
        const tiemposTrabajadoUnaLinea = await this.db
          .select()
          .from(tables.tiempo_trabajado)
          .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
          .where(tables.tiempo_trabajado.idlparte_trabajo_kilometros.eq(lineaParteDesplazamiento.tiempo_trabajado.idlparte_trabajo_kilometros))
          .exec()
        for (const tiempoTrabajado of tiemposTrabajadoUnaLinea) {
          await this.Vue.$offline.tiempoTrabajado.updateSync({
            idtiempo_trabajado: tiempoTrabajado.tiempo_trabajado.idtiempo_trabajado,
            idlparte_trabajo_kilometros: null,
          })
        }
        await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
          lineaParteDesplazamiento.tiempo_trabajado.idlparte_trabajo_kilometros
        )
      }
    } else if (parte.parte_trabajo.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosZona) {
      // LPARTE_TRABAJO.KILOMETRAJE = 1
      const lineasParteKilometrajeZona = await this.db
        .select()
        .from(tables.lparte_trabajo)
        .innerJoin(
          tables.parte_trabajo,
          tables.lparte_trabajo.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
        )
        .where(
          this.db.op.and(
            tables.lparte_trabajo.kilometraje.eq(true),
            tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          )
        )
        .exec()
      for (const lineaParteKilometraje of lineasParteKilometrajeZona) {
        await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
          lineaParteKilometraje.lparte_trabajo.idlparte_trabajo
        )
      }
    }
  }
  async tiempoFacturableAgrupado(idparteTrabajo) {
    const tables = this.db.tables
    return await this.db
      .select(
        tables.parte_trabajo_tecnico.idarticulo,
        tables.articulo.codigo,
        tables.articulo.descripcion,
        tables.tiempo_trabajado.idsubsis,
        this.db.fn.count(tables.tiempo_trabajado.idtiempo_trabajado).as('dummy'),
      )
      .from(tables.tiempo_trabajado)
      .innerJoin(
        tables.tecnico,
        tables.tiempo_trabajado.idempleado.eq(tables.tecnico.idempleado)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.tiempo_trabajado.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.parte_trabajo_tecnico,
        this.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(tables.parte_trabajo_tecnico.idparte_trabajo),
          tables.tecnico.idtecnico.eq(tables.parte_trabajo_tecnico.idtecnico),
        )
      )
      .innerJoin(
        tables.articulo,
        tables.parte_trabajo_tecnico.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        this.db.op.and(
          tables.tiempo_trabajado.manodeobrafacturable.eq(true),
          tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
        )
      )
      .groupBy(
        tables.parte_trabajo_tecnico.idarticulo,
        tables.articulo.codigo,
        tables.articulo.descripcion,
        tables.tiempo_trabajado.idsubsis,
      )
      .exec()
  }
  async generarManoObraFacturable (idparteTrabajo) {
    // mano de obra facturable relacionada por TIEMPO_TRABAJADO.IDLPARTE_TRABAJO
    // introduce una LPARTE_TRABAJO agrupando TIEMPO_TRABAJADO por: artículo de facturación del técnico y subsistema
    const tables = this.db.tables
    const tiempoFacturableAgrupado = await this.tiempoFacturableAgrupado(idparteTrabajo)
    let minutosPrimerTramo = 0
    const paramPrimerTramo = (await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.TRAMOTIEMPO_1_FACT))
    if (paramPrimerTramo) {
      minutosPrimerTramo = parseInt(paramPrimerTramo)
    }
    let minutosSiguienteTramo = 0
    const paramSiguienteTramo = (await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.TRAMOTIEMPO_SIG_FACT))
    if (paramSiguienteTramo !== null) {
      minutosSiguienteTramo = parseInt(paramSiguienteTramo)
    }
    for (const tiempoAgrupado of tiempoFacturableAgrupado) {
      const tiempoFacturableDesglosado = await this.db
        .select(
          tables.tiempo_trabajado.finicio,
          tables.tiempo_trabajado.ffin,
        )
        .from(tables.tiempo_trabajado)
        .innerJoin(
          tables.tecnico,
          tables.tiempo_trabajado.idempleado.eq(tables.tecnico.idempleado)
        )
        .innerJoin(
          tables.parte_trabajo,
          tables.tiempo_trabajado.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
        )
        .innerJoin(
          tables.parte_trabajo_tecnico,
          this.db.op.and(
            tables.parte_trabajo.idparte_trabajo.eq(tables.parte_trabajo_tecnico.idparte_trabajo),
            tables.tecnico.idtecnico.eq(tables.parte_trabajo_tecnico.idtecnico),
          )
        )
        .where(
          this.db.op.and(
            tables.tiempo_trabajado.manodeobrafacturable.eq(true),
            tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
            tables.tiempo_trabajado.idsubsis.eq(tiempoAgrupado.tiempo_trabajado.idsubsis),
            tables.parte_trabajo_tecnico.idarticulo.eq(tiempoAgrupado.parte_trabajo_tecnico.idarticulo),
          )
        )
        .exec()
      let minutosTrabajados = 0
      for (const tiempoDesglosado of tiempoFacturableDesglosado) {
        minutosTrabajados += differenceInMinutes(tiempoDesglosado.tiempo_trabajado.ffin, tiempoDesglosado.tiempo_trabajado.finicio)
      }
      let minutosIniciales = 0
      let tramosTrabajados = 0
      if (minutosTrabajados <= minutosPrimerTramo) {
        minutosIniciales = minutosPrimerTramo
        minutosTrabajados = 0
      } else {
        minutosIniciales = minutosPrimerTramo
        minutosTrabajados -= minutosPrimerTramo
        if (minutosSiguienteTramo !== 0) {
          tramosTrabajados = Math.trunc(minutosTrabajados / minutosSiguienteTramo)
          if ((minutosTrabajados % minutosSiguienteTramo) > 0) {
            tramosTrabajados = tramosTrabajados + 1
          }
          minutosTrabajados = tramosTrabajados * minutosSiguienteTramo
        }
      }
      minutosTrabajados = minutosTrabajados + minutosIniciales
      const tiempoFacturable = Math.trunc(minutosTrabajados / 60) + (minutosTrabajados % 60) / 60
      const lparteTrabajoIns = await this.Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
        idparteTrabajo,
        tiempoAgrupado.articulo.codigo,
        tiempoFacturable,
        tiempoAgrupado.tiempo_trabajado.idsubsis,
        ARTICULO_ESCANDALLO.tinclusion.soloSuministro
      )
      // enlazar TIEMPO_TRABAJADO/LPARTE_TRABAJO
      const tiempoFacturableEnlazar = await this.db
        .select(
          tables.tiempo_trabajado.idtiempo_trabajado,
        )
        .from(tables.tiempo_trabajado)
        .innerJoin(
          tables.tecnico,
          tables.tiempo_trabajado.idempleado.eq(tables.tecnico.idempleado)
        )
        .innerJoin(
          tables.parte_trabajo,
          tables.tiempo_trabajado.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
        )
        .innerJoin(
          tables.parte_trabajo_tecnico,
          this.db.op.and(
            tables.parte_trabajo.idparte_trabajo.eq(tables.parte_trabajo_tecnico.idparte_trabajo),
            tables.tecnico.idtecnico.eq(tables.parte_trabajo_tecnico.idtecnico),
          )
        )
        .where(
          this.db.op.and(
            tables.tiempo_trabajado.manodeobrafacturable.eq(true),
            tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
            tables.tiempo_trabajado.idsubsis.eq(tiempoAgrupado.tiempo_trabajado.idsubsis),
            tables.parte_trabajo_tecnico.idarticulo.eq(tiempoAgrupado.parte_trabajo_tecnico.idarticulo),
          )
        )
        .exec()
      for (const tiempoEnlazar of tiempoFacturableEnlazar) {
        await this.Vue.$offline.tiempoTrabajado.updateSync({
          idtiempo_trabajado: tiempoEnlazar.tiempo_trabajado.idtiempo_trabajado,
          idlparte_trabajo: lparteTrabajoIns.idlparteTrabajo,
        })
      }
    }
  }
  async generarDesplazamientoFacturable (idparteTrabajo) {
    const tables = this.db.tables
    const idarticuloDesplazamiento = (
      await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.IDARTICULO_DESPLAZAMIENTO)
    )
    if (idarticuloDesplazamiento) {
      const articuloDesplazamiento = await this.Vue.$offline.articulo.row(idarticuloDesplazamiento)
      const parte = await this.Vue.$offline.parteTrabajo.row(idparteTrabajo)
      if (parte.parte_trabajo.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosTecnico) {
        const kmsFacturablesAgrupado = await this.db
          .select(
            tables.tiempo_trabajado.idsubsis,
            this.db.fn.sum(tables.tiempo_trabajado.kilometros).as('kilometros'),
          )
          .from(tables.tiempo_trabajado)
          .where(
            this.db.op.and(
              tables.tiempo_trabajado.kilometros_facturables.eq(true),
              tables.tiempo_trabajado.es_desplazamiento.eq(true),
              tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
            )
          )
          .groupBy(
            tables.tiempo_trabajado.idsubsis,
          )
          .exec()
        for (const kmsFacturable of kmsFacturablesAgrupado) {
          const lparteTrabajoIns = await this.Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
            idparteTrabajo,
            articuloDesplazamiento.articulo.codigo,
            kmsFacturable.kilometros,
            kmsFacturable.idsubsis,
            ARTICULO_ESCANDALLO.tinclusion.soloSuministro,
            true,
            null,
            null,
            1
          )
          // enlazar TIEMPO_TRABAJADO/LPARTE_TRABAJO
          const kmsFacturableEnlazar = await this.db
            .select(
              tables.tiempo_trabajado.idtiempo_trabajado,
            )
            .from(tables.tiempo_trabajado)
            .innerJoin(
              tables.parte_trabajo,
              tables.tiempo_trabajado.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
            )
            .where(
              this.db.op.and(
                tables.tiempo_trabajado.kilometros_facturables.eq(true),
                tables.tiempo_trabajado.es_desplazamiento.eq(true),
                tables.tiempo_trabajado.idsubsis.eq(kmsFacturable.idsubsis),
                tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
              )
            )
            .exec()
          for (const kmsEnlazar of kmsFacturableEnlazar) {
            await this.Vue.$offline.tiempoTrabajado.updateSync({
              idtiempo_trabajado: kmsEnlazar.tiempo_trabajado.idtiempo_trabajado,
              idlparte_trabajo_kilometros: lparteTrabajoIns.idlparteTrabajo,
            })
          }
        }
      } else if (parte.parte_trabajo.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosZona) {
        const zona = await this.db
          .select(tables.zona.kilometros)
          .from(tables.orden_trabajo)
          .innerJoin(
            tables.sistema,
            tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
          )
          .innerJoin(
            tables.cliente_direccion,
            tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
          )
          .innerJoin(
            tables.zona,
            tables.cliente_direccion.idzona_tecnico.eq(tables.zona.idzona)
          )
          .where(
            tables.orden_trabajo.idorden_trabajo.eq(parte.parte_trabajo.idorden_trabajo)
          )
          .exec()
        if (zona.length > 0 && zona[0].zona.kilometros > 0) {
          const kilometros = zona[0].zona.kilometros
          const subsisPrincipal = await this.Vue.$offline.parteTrabajo.subsisPrincipal(idparteTrabajo)
          const lparteTrabajoIns = await this.Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
            idparteTrabajo,
            articuloDesplazamiento.articulo.codigo,
            // cuando se factura el desplazamiento por zonas el kilometraje no se indica en las unidades
            1,
            subsisPrincipal.subsis.idsubsis,
            ARTICULO_ESCANDALLO.tinclusion.soloSuministro,
            true,
            null,
            null,
            1
          )
          const lordenTrabajo = await this.Vue.$offline.lordenTrabajo.row(lparteTrabajoIns.idlordenTrabajo)
          await this.Vue.$offline.lordenTrabajo.updateSync({
            idlorden_trabajo: lparteTrabajoIns.idlordenTrabajo,
            precio: lordenTrabajo.lorden_trabajo.precio * kilometros,
            costo: lordenTrabajo.lorden_trabajo.costo * kilometros,
          })
        }
      }
    }
  }
}
