<template>
  <v-app>
    <b10-loading
      :showing="loading.visible"
      :message="loading.message"
      :disable-u-i="loading.disableUI"
    />
    <!--
      Al navegar a la misma ruta con diferentes parámetros no reutilice el componente
      (LparteTrabajoMaterialAfectadoView siguiente/anterior)
      https://github.com/vuejs/vue-router/issues/1490#issuecomment-306029174
    -->
    <router-view
      :key="$route.fullPath"
    />
    <v-snackbar
      v-model="updateExists"
      multi-line
      color="info"
      :timeout="-1"
    >
      Hay una nueva versión disponible
      <v-spacer />
      <v-btn
        dark
        text
        @click.stop="refreshApp"
      >
        Actualizar ahora
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  name: 'App',
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    loading: get('loading'),
  },
  created () {
    // once -> allows the listener to be called only once AND removes the listener once invoked
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // para prevenir múltiples refresh
    navigator.serviceWorker.addEventListener('controllerchange', this.pageReload)
  },
  mounted() {
    this.setGmtOffsetCookie();
  },
  methods: {
    ...call('usuario', {
      usuarioCerrarSesion: 'cerrarSesion',
    }),
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      // enviar un mensaje 'skip waiting' solo si el service worker esta esperando
      if (this.registration && this.registration.waiting) {
        // enviar el mensaje al service worker para que deje de esperar y active el nuevo service worker
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    pageReload () {
      if (!this.refreshing) {
        this.refreshing = true
        this.$loading.showManual('Actualizando a la nueva versión...')
        window.location.reload()
      }
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    setGmtOffsetCookie() {
      // Obtiene el GMT offset del navegador en minutos y lo convierte a horas
      const gmtOffset = -(new Date()).getTimezoneOffset() / 60;
      this.gmtOffset = gmtOffset;
      this.setCookie('GMT_OFFSET', gmtOffset, 365);
    }
  }
}
</script>
