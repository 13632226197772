import BaseOnlineResource from '@/online/base'
import { APIFilter } from '@/utils/api'

export default class ClienteCuentaOnlineResource extends BaseOnlineResource {
  async existeIban(idcliente, idclientePotencial, iban) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('iban', iban)
    if (idclientePotencial) {
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
    } else {
      apiFilter.addExact('idcliente', idcliente)
    }
    const resp = await this.Vue.$api.call(
      'clienteCuenta.select', {
        page: 0,
        filter: apiFilter,
      },
    )
    return resp.data.result.dataset.length > 0
  }
}
