import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'
import { COMPORTAMIENTO_ACCION_REVISION, PARAMETRO, MACCION, ACCION_EJEC } from '@/utils/consts'
import { currentDateTime } from '@/utils/date'

export default class AccionEjecOfflineResource extends BaseOfflineResource {
  async cambiarFacturableDeRevisiones(idordenTrabajoMatsist, idparteTrabajo, facturablePasan) {
    const tables = this.db.tables
    const clasificacionesRevision = [
      MACCION.clasificacion.revision_anual,
      MACCION.clasificacion.revision_semestral,
      MACCION.clasificacion.revision_trimestral,
      MACCION.clasificacion.revision_mensual,
    ]
    const revisiones = await this.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.accion_ejec.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.accion_ejec.estado.gt(0),
          tables.accion_ejec.facturable.eq(!facturablePasan),
          this.Vue.$offline.db.op.or(
            tables.accion_ejec.idparte_trabajo.eq(idparteTrabajo),
            tables.accion_ejec.idparte_trabajo.isNull(),
          ),
          tables.maccion.clasificacion.in(clasificacionesRevision)
        )
      )
      .exec()
    for (const revision of revisiones) {
      await this.Vue.$offline.accionEjec.updateSync({
        idaccion_ejec: revision.accion_ejec.idaccion_ejec,
        idparte_trabajo: idparteTrabajo,
        facturable: facturablePasan,
      })
      if (revision.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.realizada) {
        if (facturablePasan) {
          // si estaba realizada -> pasa a no realizada (por el momento, lo mejor sería agregar la línea facturable)
          await this.Vue.$offline.accionEjec.updateSync({
            idaccion_ejec: revision.accion_ejec.idaccion_ejec,
            idparte_trabajo: idparteTrabajo,
            idest_accion_ejec: ACCION_EJEC.estados.pendiente,
          })
        } else {
          // si estaba realizada -> borrar linea de parte de facturación
          const selectLParteTrabajo = await this.Vue.$offline.db
            .select()
            .from(tables.lparte_trabajo)
            .innerJoin(
              tables.lorden_trabajo,
              tables.lparte_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
            )
            .where(
              tables.lparte_trabajo.idaccion_ejec.eq(revision.accion_ejec.idaccion_ejec)
            )
            .exec()
          if (selectLParteTrabajo.length > 0) {
            await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
              selectLParteTrabajo[0].lparte_trabajo.idlparte_trabajo
            )
          }
        }
      }
    }
  }
  async insertSync (values = {}, options = {}) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'accionEjec.insertSync',
      params: { values },
    })
    const localValues = _.cloneDeep(values)
    localValues.estado = 1
    const toReturn = super.insert(localValues, options)
    const accionEjec = (await this.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.tsubfamilia,
        tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
      )
      .where(
        tables.accion_ejec.idaccion_ejec.eq(values.idaccion_ejec)
      )
      .exec()
    )[0]
    // agregando un retimbrado y se factura junto revisión y retimbrado
    // revisiones facturables -> no facturables
    if (
      accionEjec.maccion.clasificacion === MACCION.clasificacion.retimbrado &&
      accionEjec.tsubfamilia.factura_junto_revi_retim
    ) {
      await this.Vue.$offline.accionEjec.cambiarFacturableDeRevisiones(
        values.idorden_trabajo_matsist, values.idparte_trabajo, false
      )
    }
    return toReturn
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'accionEjec.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idaccionEjec, idparteTrabajo) {
    const tables = this.db.tables
    // recuperar la acción que se va a borrar
    const accionEjec = (await this.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.tsubfamilia,
        tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
      )
      .where(
        tables.accion_ejec.idaccion_ejec.eq(idaccionEjec)
      )
      .exec()
    )[0]
    await this.Vue.$offline.sync.insert({
      method: 'accionEjec.deleteSync',
      params: {
        idaccion_ejec: idaccionEjec,
        idparte_trabajo: idparteTrabajo,
      },
    })
    await this.delete({
      where: tables.accion_ejec.idaccion_ejec.eq(idaccionEjec)
    })
    // borrando un retimbrado y se factura junto revisión y retimbrado
    if (
      accionEjec.maccion.clasificacion === MACCION.clasificacion.retimbrado &&
      accionEjec.tsubfamilia.factura_junto_revi_retim
    ) {
      await this.Vue.$offline.accionEjec.cambiarFacturableDeRevisiones(
        accionEjec.orden_trabajo_matsist.idorden_trabajo_matsist, idparteTrabajo, true
      )
    }
  }
  async _rowArticuloAfectado (idaccionEjec) {
    const tables = this.db.tables
    const query = await this.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.timpuesto,
        tables.articulo.idtimpuesto.eq(tables.timpuesto.idtimpuesto)
      )
      .where(
        tables.accion_ejec.idaccion_ejec.eq(idaccionEjec)
      )
      .exec()
    return query[0]
  }
  selectArticuloFacturar (idmaccion, idarticuloAfectado) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.maccion_fact)
      .innerJoin(
        tables.articulo,
        tables.maccion_fact.idarticulo_facturar.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.timpuesto,
        tables.articulo.idtimpuesto.eq(tables.timpuesto.idtimpuesto)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.maccion_fact.idmaccion.eq(idmaccion),
          tables.maccion_fact.idarticulo_afectado.eq(idarticuloAfectado)
        )
      )
      .exec()
  }
  async obtenerArticuloFacturacion (idaccionEjec, idmaccion) {
    const articuloAfectado = await this._rowArticuloAfectado(idaccionEjec)
    const articuloFacturar = await this.selectArticuloFacturar(
      idmaccion, articuloAfectado.material_sistema.idarticulo
    )
    return articuloFacturar
  }
  async marcarAccionEjecRealizadaSync (
    idaccionEjec,
    idchecklistOt,
    facturar,
    idtmotivoNofacturable,
    tinclusion,
    idtecnico,
    idparteTrabajo,
    idarticuloFacturacionForzado = null,
    idalmacenLparte = null, // Esto es para lparte_trabajo.idalmacen, no para accion_ejec.idalmacen
  ) {
    let idlparteTrabajo = null
    let idlordenTrabajo = null
    const tables = this.db.tables
    const rowAccionEjec = await this.row(idaccionEjec)
    const rowMaccion = await this.Vue.$offline.maccion.row(rowAccionEjec.accion_ejec.idmaccion)
    const articuloAfectado = await this._rowArticuloAfectado(idaccionEjec)
    const esRevision = MACCION.esRevision(rowMaccion.maccion.clasificacion)
    const esRetiradaTemporal = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.retirada_temporal)
    const esBajaDefinitiva = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.baja_definitiva)
    const esSustitucion = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.sustitucion)
    const esRecuperacion = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.recuperacion)
    let articuloFacturar = null
    let annadirLparte = false
    // caso especial de sustitución y recuperación
    if (esSustitucion || esRecuperacion) {
      annadirLparte = true
      articuloFacturar = await this.Vue.$offline.articulo.row(idarticuloFacturacionForzado)
    // demás clasificaciones de acciones
    } else {
      if (facturar) {
        articuloFacturar = (await this.selectArticuloFacturar(
          rowAccionEjec.accion_ejec.idmaccion, articuloAfectado.material_sistema.idarticulo
        ))[0]
        if (!articuloFacturar) {
          throw new Error(
            `No se encuentra definido el artículo de facturación para la acción "${rowMaccion.maccion.descripcion}" sobre el artículo "${articuloAfectado.material_sistema.descripcion} (${articuloAfectado.material_sistema.codigo})"`
          )
        }
        annadirLparte = true
      } else {
        annadirLparte = false
      }
    }
    if (annadirLparte) {
      const ordenTrabajo = (await this.Vue.$offline.db
        .select()
        .from(tables.accion_ejec)
        .innerJoin(
          tables.orden_trabajo_matsist,
          tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
        )
        .innerJoin(
          tables.orden_trabajo,
          tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
        )
        .innerJoin(
          tables.sistema,
          tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
        )
        .innerJoin(
          tables.cliente,
          tables.sistema.idcliente.eq(tables.cliente.idcliente)
        )
        .innerJoin(
          tables.regimen_fiscal,
          tables.cliente.idregimen_fiscal.eq(tables.regimen_fiscal.idregimen_fiscal)
        )
        .innerJoin(
          tables.parte_trabajo,
          tables.orden_trabajo.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
        )
        .where(
          tables.accion_ejec.idaccion_ejec.eq(idaccionEjec)
        )
        .exec()
      )[0]
      let unidades = articuloAfectado.material_sistema.unidades
      if (esRecuperacion) {
        unidades = articuloAfectado.material_sistema.unidades * -1
      }
      const parteTrabajoIns = await this.Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
        ordenTrabajo.parte_trabajo.idparte_trabajo, //idparteTrabajo,
        articuloFacturar.articulo.codigo, // codigoArticulo,
        unidades, // unidades,
        articuloAfectado.material_sistema.idsubsis, // idsubsis,
        tinclusion, // tinclusion
        facturar, // facturar
        idtmotivoNofacturable, // idtmotivoNofacturable
        null, // descripcionArticulo
        0, // kilometraje
        idaccionEjec, // idaccionEjec
        null, // observaciones = null,
        null, // idaccionCorrectoraOt
        idalmacenLparte, // idalmacen
      )
      idlordenTrabajo = parteTrabajoIns.idlordenTrabajo
      idlparteTrabajo = parteTrabajoIns.idlparteTrabajo
      // cuando el artículo es nuevo se pide nserie, si no le mete el nserie del material afectado
      let nserie
      if (esSustitucion) {
        nserie = null
      } else {
        nserie = articuloAfectado.material_sistema.nserie
      }
      await this.Vue.$offline.lparteTrabajo.updateSync({
        idlparte_trabajo: idlparteTrabajo,
        nelemento: articuloAfectado.material_sistema.nelemento,
        ubicacion: articuloAfectado.material_sistema.ubicacion,
        netiqueta: articuloAfectado.material_sistema.netiqueta,
        numzona: articuloAfectado.material_sistema.num_zona,
        evento: articuloAfectado.material_sistema.evento,
        funcion: articuloAfectado.material_sistema.funcion,
        particion: articuloAfectado.material_sistema.particion,
        nserie: nserie,
      })
    }
    await this.Vue.$offline.accionEjec.updateSync({
      idaccion_ejec: idaccionEjec,
      idest_accion_ejec: ACCION_EJEC.estados.realizada,
      idparte_trabajo: idparteTrabajo,
    })
    // comportamientos especiales según MACCION
    if (esRevision && idchecklistOt ) {
      // asignar técnico a preguntas con anomalías arrastradas desde otro checklist
      await this.Vue.$offline.ordenTrabajoMatsist.updateTecnicoPreguntasMaterialArrastradas(
        idchecklistOt, rowAccionEjec.accion_ejec.idorden_trabajo_matsist, idtecnico
      )
      const comportamientoRevision = parseInt(
        await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.COMPORTAMIENTO_ACCION_REVISION)
      )
      if (comportamientoRevision === COMPORTAMIENTO_ACCION_REVISION.tipos.marcarPreguntasCorrectas) {
        await this.Vue.$offline.ordenTrabajoMatsist.updateMaterialContestadoCorrecto(
          idparteTrabajo, idchecklistOt, rowAccionEjec.accion_ejec.idorden_trabajo_matsist, idtecnico
        )
      }
    } else if (esRetiradaTemporal && idtecnico) {
      const tecnico = await this.Vue.$offline.tecnico.row(idtecnico)
      if (tecnico !== null) {
        await this.Vue.$offline.materialSistema.updateSync({
          idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
          idalmacen: tecnico.tecnico.idalmacen
        })
      }
    } else if (esBajaDefinitiva) {
      await this.Vue.$offline.materialSistema.updateSync({
        idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
        fdesinstalacion: currentDateTime()
      })
    } else if (esSustitucion || esRecuperacion) {
      await this.Vue.$offline.materialSistema.updateSync({
        idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
        fdesinstalacion: currentDateTime()
      })
    }
    return { idlordenTrabajo, idlparteTrabajo }
  }
  async marcarAccionEjecNoRealizadaSync (idaccionEjec, idchecklistOt, idparteTrabajo) {
    const tables = this.db.tables
    const rowAccionEjec = await this.row(idaccionEjec)
    // comparación por igualdad abstracta == porque idparte_trabajo puede ser string o number
    if (!rowAccionEjec.accion_ejec.idparte_trabajo || rowAccionEjec.accion_ejec.idparte_trabajo == idparteTrabajo) {
      const rowMaccion = await this.Vue.$offline.maccion.row(rowAccionEjec.accion_ejec.idmaccion)
      const esRevision = MACCION.esRevision(rowMaccion.maccion.clasificacion)
      const esRetiradaTemporal = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.retirada_temporal)
      const esBajaDefinitiva = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.baja_definitiva)
      const esSustitucion = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.sustitucion)
      const esRecuperacion = (rowMaccion.maccion.clasificacion === MACCION.clasificacion.recuperacion)
      const articuloAfectado = await this._rowArticuloAfectado(idaccionEjec)
      let borrarLparte = false
      if (rowAccionEjec.accion_ejec.facturable) {
        borrarLparte = true
      } else if (esSustitucion || esRecuperacion) {
        // caso especial de sustitución y recuperación
        borrarLparte = true
      }
      if (borrarLparte) {
        const selectLParteTrabajo = await this.Vue.$offline.db
          .select()
          .from(tables.lparte_trabajo)
          .innerJoin(
            tables.lorden_trabajo,
            tables.lparte_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
          )
          .where(
            tables.lparte_trabajo.idaccion_ejec.eq(idaccionEjec)
          )
          .exec()
        if (selectLParteTrabajo.length > 0) {
          const lparteTrabajo = selectLParteTrabajo[0]
          await this.Vue.$offline.lparteTrabajo.deleteLparteLordenYDetalleSync(
            lparteTrabajo.lparte_trabajo.idlparte_trabajo
          )
        }
      }
      await this.Vue.$offline.accionEjec.updateSync({
        idaccion_ejec: idaccionEjec,
        idest_accion_ejec: ACCION_EJEC.estados.pendiente,
        idparte_trabajo: idparteTrabajo,
      })
      // comportamientos especiales según MACCION
      if (esRevision && idchecklistOt) {
        await this.Vue.$offline.ordenTrabajoMatsist.updateMaterialNoContestado(
          idchecklistOt, rowAccionEjec.accion_ejec.idorden_trabajo_matsist
        )
      } else if (esRetiradaTemporal) {
        await this.Vue.$offline.materialSistema.updateSync({
          idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
          idalmacen: null
        })
      } else if (esBajaDefinitiva) {
        await this.Vue.$offline.materialSistema.updateSync({
          idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
          fdesinstalacion: null
        })
      } else if (esSustitucion || esRecuperacion) {
        await this.Vue.$offline.materialSistema.updateSync({
          idmaterial_sistema: articuloAfectado.material_sistema.idmaterial_sistema,
          fdesinstalacion: null
        })
      }
    } else {
      throw new Error('No se puede modificar la acción porque fue realizada en otro parte de trabajo')
    }
  }
}
