import BaseOfflineResource from '@/offline/base'
import { SOLICITUD_MATERIAL } from '@/utils/consts'
import filters from '@/utils/filters'
import { nonEmpty } from '@/utils/templates'

export default class SolicitudMaterialOfflineResource extends BaseOfflineResource {
  title (row) {
    return row.descripcion
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`
      Fecha de solicitud: ${filters.shortDate(row.fsolicitud)} - Fecha de solicitud de entrega: ${filters.shortDate(row.fsolicitada_entrega)}`
    )
  }
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'solicitudMaterial.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }

  async deleteSync (idsolicitudMaterial) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'solicitudMaterial.deleteSync',
      params: {
        idsolicitud_material: idsolicitudMaterial
      }
    })
    await this.Vue.$offline.solicitudMaterial.delete({
      where: tables.solicitud_material.idsolicitud_material.eq(idsolicitudMaterial)
    })
  }

  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'solicitudMaterial.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }

  async anular (idsolicitudMaterial) {
    const values = {
      'idsolicitud_material': idsolicitudMaterial,
      'estado': 0,
      'idest_solicitud_material': SOLICITUD_MATERIAL.estados.anulada,
    }
    await this.updateSync(values)
  }

  async selectParteDeSolicitud (idsolicitudMaterial) {
    const tables = this.Vue.$offline.db.tables
    return (await this.Vue.$offline.db
      .select()
      .from(
        tables.solicitud_material
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.solicitud_material.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .where(
        tables.solicitud_material.idsolicitud_material.eq(idsolicitudMaterial)
      )
      .exec())[0]
  }
}
