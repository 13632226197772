import { ListStoreBase, CreateStore } from '@/store/pages/base'
import { RONDA } from '@/utils/consts'
import filters from '@/utils/filters'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fcomienzo'
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fcomienzo'
      },
      estado: {
        label: 'Estado',
        type: 'multiple-select',
        value: null,
        items: [
          {
            estado: RONDA.estados.enCurso,
            descripcion: filters.capitalize(RONDA.descripcionEstado.enCurso)
          },
          {
            estado: RONDA.estados.finalizada,
            descripcion: filters.capitalize(RONDA.descripcionEstado.finalizada)
          },
        ],
        field: 'estado',
        selectItemValue: 'estado',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
