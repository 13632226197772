import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { quitarCerosIzquierda } from '@/utils/strings'
import axios from 'axios'
import { make } from 'vuex-pathify'
import { APPS, PARAMETRO, DatabaseVersionError } from '@/utils/consts'
import _ from '@/utils/lodash'

const state = {
  idusuario: null,
  idempleado: null,
  idtecnico: null,
  idcomercial: null,
  idvigilante: null,
  idalmacen: null,
  iddelegacion: null,
  nombre: null,
  login: null,
  email: null,
  token: null,
  instalacion: null,
  ultimaDescargaGlobales: null,
  tiempoInactividad: null,
  capturarGeolocalizacion: null,
  ultimaActividad: null,
  permisos: [],
  appComerciales: false,
  appTecnicos: false,
  appVigilantes: false,
  appCustodiaLlaves: false,
  appAlmacen: false,
  appPresencia: false,
}

const getters = {
  appComercialesDisponible (state) {
    return !!state.idcomercial && state.appComerciales
  },
  appTecnicosDisponible (state) {
    return !!state.idtecnico && state.appTecnicos
  },
  appVigilantesDisponible (state) {
    return !!state.idvigilante && state.appVigilantes
  },
  appCustodiaLlavesDisponible (state) {
    return state.appCustodiaLlaves
  },
  appAlmacenDisponible (state) {
    return state.appAlmacen
  },
  appPresenciaDisponible (state) {
    return state.appPresencia
  },
  appsDisponibles (state, getters) {
    let toReturn = []
    if (getters.appComercialesDisponible) {
      toReturn.push(APPS.comerciales)
    }
    if (getters.appTecnicosDisponible) {
      toReturn.push(APPS.tecnicos)
    }
    if (getters.appVigilantesDisponible) {
      toReturn.push(APPS.vigilantes)
    }
    if (getters.appCustodiaLlavesDisponible) {
      toReturn.push(APPS.custodiaLlaves)
    }
    if (getters.appAlmacenDisponible) {
      toReturn.push(APPS.almacen)
    }
    if (getters.appPresenciaDisponible) {
      toReturn.push(APPS.presencia)
    }
    return toReturn
  }
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
  async _borrarUsuario ({ commit }) {
    commit('SET_TOKEN', null)
    commit('SET_IDUSUARIO', null)
    commit('SET_IDEMPLEADO', null)
    commit('SET_IDTECNICO', null)
    commit('SET_IDCOMERCIAL', null)
    commit('SET_IDVIGILANTE', null)
    commit('SET_IDALMACEN', null)
    commit('SET_IDDELEGACION', null)
    commit('SET_NOMBRE', null)
    commit('SET_LOGIN', null)
    commit('SET_EMAIL', null)
    commit('SET_INSTALACION', null)
    commit('SET_PERMISOS', [])
    commit('SET_APP_COMERCIALES', false)
    commit('SET_APP_TECNICOS', false)
    commit('SET_APP_VIGILANTES', false)
    commit('SET_APP_CUSTODIA_LLAVES', false)
    commit('SET_APP_ALMACEN', false)
    commit('SET_APP_PRESENCIA', false)
    commit('SET_TIEMPO_INACTIVIDAD', null)
    commit('SET_CAPTURAR_GEOLOCALIZACION', null)
    // presencia
    commit('presencia/SET_FENTRADA', null, { root: true })
    commit('presencia/SET_DESCRIPCION', null, { root: true })
    commit('presencia/SET_IDPUESTO_SERVICIO', null, { root: true })
    commit('presencia/SET_IDPUESTO_SERVICIO_SERVICIO', null, { root: true })
    // cronometro
    commit('cronometro/SET_FINICIO', null, { root: true })
    commit('cronometro/SET_IDPARTE', null, { root: true })
    commit('cronometro/SET_LATITUD', null, { root: true })
    commit('cronometro/SET_LONGITUD', null, { root: true })
    commit('cronometro/SET_PARA', null, { root: true })
    // almacen
    commit('almacen/SET_IDTLINEA_PRODUCCION', null, { root: true })
  },
  async autenticar ({ commit, state }, payload) {
    let baseURL = ''
    if (process.env.NODE_ENV === 'development') {
      baseURL = `https://${process.env.VUE_APP_DOMAIN}:${process.env.VUE_APP_API_PORT}/`
    }
    // Error al decodificar utf-8 strings
    // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
    let tokenResponse
    try {
      const loginData = new FormData()
      loginData.append('username', payload.username)
      loginData.append('password', payload.password)
      tokenResponse = await axios({
        baseURL: baseURL,
        url: '/token/',
        method: 'post',
        data: loginData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    } catch (e) {
      throw new Error('No hay conexión con el servidor')
    }
    if (tokenResponse.data?.token) {
      // token
      commit('SET_TOKEN', tokenResponse.data.token)
      try {
        // usuario
        let apiCalls = []
        const apiFilterUser = new APIFilter()
        apiFilterUser.addExact('login', payload.username)
        apiFilterUser.addGT('estado', 0)
        apiCalls.push({ name: 'selectUsuariosistema', method: 'usuariosistema.select', params: { filter: apiFilterUser } })
        // parámetros
        const parametrosNecesarios = [
          PARAMETRO.parametros.TIEMPO_INACTIVIDAD_MINUTOS,
          PARAMETRO.parametros.VERSION_BASE_DATOS
        ]
        const apiFilterParametro = new APIFilter()
        apiFilterParametro.addIn('idparametro', parametrosNecesarios)
        apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametro } })
        const apiCallResponse = await payload.$api.batchCall(apiCalls)
        // Si la versión de la base de datos no se corresponde con la versión de la aplicación no dejo logearte
        const parametroVersionBaseDeDatos = _.find(apiCallResponse.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.VERSION_BASE_DATOS })
        const versionSinCeros = quitarCerosIzquierda(parametroVersionBaseDeDatos.valor)
        if (process.env.NODE_ENV !== 'development' && __BETA10_VERSION__ !== versionSinCeros) {
          throw new DatabaseVersionError(`La versión de la aplicación (${__BETA10_VERSION__}) no se corresponde con la versión de la base de datos (${versionSinCeros})`)
        }
        const usuario = apiCallResponse.data.selectUsuariosistema.result.dataset[0]
        const parametroTiempoInactividad = _.find(apiCallResponse.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIEMPO_INACTIVIDAD_MINUTOS })
        commit('SET_IDUSUARIO', usuario.idusuario)
        commit('SET_IDEMPLEADO', usuario.idempleado)
        commit('SET_IDTECNICO', usuario.idtecnico)
        commit('SET_IDCOMERCIAL', usuario.idcomercial)
        commit('SET_IDVIGILANTE', usuario.idvigilante)
        commit('SET_IDALMACEN', usuario.idalmacen)
        commit('SET_IDDELEGACION', usuario.iddelegacion)
        commit('SET_NOMBRE', usuario.nombre)
        commit('SET_LOGIN', usuario.login)
        commit('SET_EMAIL', usuario.email)
        commit('SET_INSTALACION', usuario.instalacion_nombre)
        commit('SET_TIEMPO_INACTIVIDAD', parametroTiempoInactividad.valor)
        commit('SET_CAPTURAR_GEOLOCALIZACION', usuario.capturar_geolocalizacion)
        // permisos
        const permisosResponse = await payload.$api.call('permiso.select', {
          idusuario: state.idusuario
        })
        commit('SET_PERMISOS', _.map(
          permisosResponse.data.result.dataset,
          item => {
            return {
              idobjeto: item.idobjeto,
              visualizar: item.visualizar,
              insertar: item.insertar,
              editar: item.editar,
              borrar: item.borrar,
            }
          }
        ))
        // apps disponibles
        // Crear un objeto resultado inicial con todos los idmodulo_app establecidos en false
        const modulosActivos = _.mapvalues(APPS.idmoduloApp, () => false)
        const apiFilterUsuarioModulo = new APIFilter()
        apiFilterUsuarioModulo
          .addGT('estado', 0)
          .addExact('idusuariosistema', usuario.idusuario)
          .addIn('idmodulo_app', _.map(APPS.moduloApp))
        const UsuarioModuloResponse = await payload.$api.call(
          'usuarioModuloApp.select', {
            filter: apiFilterUsuarioModulo,
          }
        )
        if (UsuarioModuloResponse.data.result.dataset.length > 0) {
          const dataset = UsuarioModuloResponse.data.result.dataset
          for (const item of dataset) {
            // Si tiene algún módulo en estado activo lo seteo
            modulosActivos[item.idmodulo_app] = item.estado
          }
        } else {
          // Si no devuelve nada tengo que ver realmente si tengo que controlar licencias:
          const apiFilterModulo = new APIFilter()
          apiFilterModulo
            .addGT('activo', 0)
            .addIn('idmodulo_app', _.map(APPS.moduloApp))
          const ModuloResponse = await payload.$api.call(
            'moduloApp.select', {
              filter: apiFilterModulo,
            }
          )
          const dataset = ModuloResponse.data.result.dataset
          // Si tiene el módulo activo y NO controla licencias, entonces puede verlo.
          for (const item of dataset) {
            modulosActivos[item.idmodulo_app] = item.activo && !!!item.controlar_licencias
          }
        }
        for (const modulo in modulosActivos) {
          commit(APPS.setApps[APPS.idmoduloApp[modulo]], modulosActivos[modulo])
        }
        const apiFilterPresencia = new APIFilter()
        apiFilterPresencia
          .setOperator(API_FILTER_OPERATORS.AND)
          .addExact('idempleado', usuario.idempleado)
          .addIsNull('fsalida')
        const presenciaResponse = await payload.$api.call('presencia.select', {
          filter: apiFilterPresencia,
          sorter: [
            { 'field': 'fentrada', 'asc': false }
          ]
        })
        if (presenciaResponse.data.result.dataset.length > 0) {
          const presencia = presenciaResponse.data.result.dataset[0]
          commit('presencia/SET_FENTRADA', presencia.fentrada, { root: true })
          // puesto de servicio
          if (presencia.idpuesto_servicio) {
            commit('presencia/SET_DESCRIPCION', presencia.puesto_servicio_descripcion, { root: true })
            commit('presencia/SET_IDPUESTO_SERVICIO', presencia.idpuesto_servicio, { root: true })
            commit('presencia/SET_IDPUESTO_SERVICIO_SERVICIO', presencia.idpuesto_servicio_servicio, { root: true })
          }
        }
      } catch (e) {
        commit('SET_TOKEN', null)
        throw new Error(e)
      }
    } else if (tokenResponse.data?.error) {
      throw new Error(tokenResponse.data.error)
    }
  },
  cerrarSesion ({ dispatch }) {
    dispatch('_borrarUsuario')
  },
  async refreshToken ({ commit, state, dispatch }, payload) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('token', state.token)
    try {
      const resp = await payload.$api.call(
        'usuariosistemaToken.select', { filter: apiFilter }, { silentMessages: true, disableUI: false }
      )
      if (resp.data && resp.data.result && resp.data.result.dataset && resp.data.result.dataset.length > 0) {
        commit('SET_TOKEN', resp.data.result.dataset[0].token)
      }
    } catch (error) {
      if ((error.response) && (error.response.status === 403)) {
        dispatch('_borrarUsuario')
      } else {
        throw new Error(error)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
