<template>
  <div v-if="withValidationProvider">
    <ValidationProvider
      v-slot="{ errors }"
      :name="name"
      :rules="validationRules"
    >
      <v-autocomplete
        v-bind="$attrs"
        :error-messages="errors"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </div>
  <div v-else>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    withValidationProvider: {
      type: Boolean,
      // Por ahora es false porque se usa en muchos sitios
      default: false,
    },
    // No se llama "rules" porque el componente tiene una prop llamada igual
    validationRules: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: ''
    },
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        const hasValue = val => val != null ? val : ''
        let text = _.deburr(hasValue(itemText).toString().toLowerCase())
        let query = _.deburr(hasValue(queryText).toString().toLowerCase())
        return text.indexOf(query) > -1
      },
    },
  },
}
</script>
