<template>
  <v-alert
    v-if="!filterIsEmpty && visible"
    color="info"
    class="mt-2 mx-2 py-1"
    dark
    small
    dismissible
    @click="clickFilter"
  >
    {{ filterDesc }}
    <template #close>
      <v-btn
        icon
        @click.stop="clickClearFilter"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
import _ from '@/utils/lodash'
import { toDate } from '@/utils/date'

export default {
  props: {
    listFilter: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      visible: true,
    }
  },
  computed: {
    filterDesc () {
      const withValue = _.filter(this.listFilter, (field) => {
        if (
          (field.type === 'string' || field.type === 'number' || field.type === 'search' || field.type === 'date' || field.type === 'select' || field.type === 'check') &&
          (field.value !== null)
        ) {
          return field
        } else if (
          (field.type === 'multiple-select') &&
          (field.value !== null) &&
          (field.value.length > 0)
        ) {
          return field
        }
      })
      return _.map(withValue, (field) => {
        return `${field.label} = ${this.fieldValue(field)}`
      }).join(', ').toLowerCase()
    },
    filterIsEmpty () {
      return this.filterDesc.length === 0
    }
  },
  methods: {
    fieldValue (field) {
      if (field.type === 'date') {
        return this.$options.filters.shortDate(toDate(field.value))
      } else if (field.type === 'check') {
        return field.value ? 'sí' : 'no'
      } else {
        return field.value
      }
    },
    clickClearFilter () {
      this.$emit('clear-filter')
    },
    clickFilter () {
      this.$emit('click-filter')
    },
  }
}
</script>
