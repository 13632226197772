<template>
  <v-dialog
    v-model="internalShowing"
    transition="false"
    fullscreen
    scrollable
  >
    <v-sheet>
      <v-app-bar
        dark
        fixed
        color="primary"
      >
        <v-btn
          icon
          @click="clickClose"
        >
          <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="localDeviceId"
        >
          Cámara <small>({{ localDeviceId.substring(0, 10) }}...)</small>
        </v-toolbar-title>
        <v-toolbar-title
          v-else
        >
          Cámara
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="clickFlip"
        >
          <v-icon>{{ $vuetify.icons.values.cameraFlip }}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container
        pa-0
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <vue-web-cam
            ref="webcam"
            width="100%"
            height="100%"
            :device-id="localDeviceId"
            @cameras="onCameras"
          />
        </v-layout>
      </v-container>
    </v-sheet>
    <b10-fab-button
      x-large
      :icon="$vuetify.icons.values.shutter"
      :style="{ left: '50%', transform: 'translateX(-50%)' }"
      @click="clickShutter"
    />
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { WebCam } from 'vue-web-cam'
import { URLToFile } from '@/utils/files'
import _ from '@/utils/lodash'
import { randomPictureFileName } from '@/utils/files'

export default {
  components: {
    'vue-web-cam': WebCam,
  },
  mixins: [internalShowingMixin],
  props: {
    filename: {
      type: String,
      default: randomPictureFileName(),
    },
    mimeType: {
      type: String,
      default: 'image/jpeg',
    },
    deviceId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      devices: [],
      localDeviceId: null,
    }
  },
  methods: {
    changeCamera (deviceId) {
      this.localDeviceId = deviceId
      this.$emit('change-deviceid', deviceId)
    },
    onCameras (cameras) {
      this.devices = cameras
      if (this.deviceId) {
        this.changeCamera(this.deviceId)
      } else if (this.devices.length > 0) {
        this.changeCamera(this.devices[0].deviceId)
      }
    },
    clickClose () {
      this.internalShowing = false
    },
    async clickShutter () {
      this.$refs.webcam.start()
      const img = this.$refs.webcam.capture()
      this.$refs.webcam.stop()
      const imgFile = await URLToFile(img, this.filename, this.mimeType)
      this.$emit('click-shutter', imgFile)
      this.internalShowing = false
    },
    async clickFlip () {
      const deviceIndexSiguiente = _.findIndex(this.devices, { deviceId: this.deviceId }) + 1
      this.changeCamera(
        this.devices[(deviceIndexSiguiente % this.devices.length + this.devices.length) % this.devices.length].deviceId
      )
    },
  }
}
</script>
