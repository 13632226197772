import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      nombreFichero: {
        label: 'Nombre del fichero',
        type: 'string',
        value: null,
        field: 'nombre_fichero',
      },
      observaciones: {
        label: 'Observaciones',
        type: 'string',
        value: null,
        field: 'observaciones',
      },
      tipoFichero: {
        label: 'Tipo de fichero',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtfichero',
        selectItemValue: 'idtfichero',
        selectItemText: 'descripcion',
      },
      tipoFicheroClasificacion: {
        label: 'Clasificación',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idclasificacion_tfichero',
        selectItemValue: 'idclasificacion',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.tipoFicheroClasificacion.items = []
      state.filter.tipoFichero.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
