import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'forden',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'forden',
      },
      zona: {
        label: 'Zona',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idzona',
        selectItemValue: 'idzona',
        selectItemText: 'descripcion',
      },
      sistemaDireccion: {
        label: 'Dirección (calle, localidad, C.P., etc)',
        type: 'string',
        value: null,
        field: 'sistema_direccion',
      },
      tactuacion: {
        label: 'Tipo de actuación',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtactuacion',
        selectItemValue: 'idtactuacion',
        selectItemText: 'descripcion',
      },
      tsistema: {
        label: 'Tipo de sistema',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtsistema',
        selectItemValue: 'idtsistema',
        selectItemText: 'descripcion',
      },
      comercial: {
        label: 'Comercial',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idcomercial',
        selectItemValue: 'idcomercial',
        selectItemText: 'nombre',
      },
      grupoCliente: {
        label: 'Grupo de clientes',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idgrupo_cliente',
        selectItemValue: 'idgrupo_cliente',
        selectItemText: 'nombre',
      },
      tmotivoBloqueoOt: {
        label: 'Motivo de bloqueo',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtmotivo_bloqueo_ot',
        selectItemValue: 'idtmotivo_bloqueo_ot',
        selectItemText: 'descripcion',
      },
      tecnico: {
        label: 'Técnico previsto',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtecnico_previsto',
        selectItemValue: 'idtecnico',
        selectItemText: 'nombre_y_apellido',
      },
      conFfijada: {
        label: 'Con fecha fijada',
        type: 'check',
        value: null,
        field: 'con_ffijada',
      },
      ffijada: {
        label: 'Fecha fijada',
        type: 'date',
        value: null,
        field: 'ffijada',
      },
      ffinparte: {
        label: 'Fecha de finalización',
        type: 'date',
        value: null,
        field: 'ffinalizacion',
      },
      pendienteDevolucion: {
        label: 'Pendiente de devolución',
        type: 'check',
        value: null,
        field: 'pendiente_devolucion',
      },
      soyResponsable: {
        label: 'Soy responsable',
        type: 'check',
        value: null,
        field: 'idtecnico_responsable',
      },
      idordenTrabajo: {
        label: 'Id. orden trabajo',
        type: 'number',
        value: null,
        field: 'idorden_trabajo',
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.zona.items = []
      state.filter.tactuacion.items = []
      state.filter.tsistema.items = []
      state.filter.comercial.items = []
      state.filter.grupoCliente.items = []
      state.filter.tmotivoBloqueoOt.items = []
      state.filter.tecnico.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
