import BaseOfflineResource from '@/offline/base'

export default class vAlmacenesParteOfflineResource extends BaseOfflineResource {
  async selectAlmacenesDelParte (idparteTrabajo) {
    const tables = this.Vue.$offline.db.tables
    return (await this.Vue.$offline.db
      .select()
      .from(
        tables.v_almacenes_parte
      )
      // No hago inner join con almacén porque puede que no lo tenga descargado
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.Vue.$offline.db.op.and(
          tables.v_almacenes_parte.idparte_trabajo.eq(idparteTrabajo),
          tables.v_almacenes_parte.estado.gt(0),
        )
      )
      .exec())
  }
}
