import BaseOnlineResource from '@/online/base'
import { APIFilter } from '@/utils/api'
import { PARTE_NOVEDAD } from '@/utils/consts'
import { isSameDay, currentDate, currentDateTime } from '@/utils/date'
import filters from '@/utils/filters'


export default class ParteNovedadOnlineResource extends BaseOnlineResource {
  async CreateParteNovedad(idpuestoServicio, idempresa) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGT('estado', 0)
    const puestoServicioRow = await this.Vue.$api.call(
      'puestoServicio.select',
      {
        filter: apiFilter,
      },
    )
    const parteNovedad = await this.Vue.$api.call(
      'parteNovedad.insert',
      {
        values: {
          fparte: currentDateTime(),
          idpuesto_servicio: idpuestoServicio,
          observaciones: '',
          idtparte_novedad: puestoServicioRow.data.result.dataset[0].idtparte_novedad,
          idempresa: idempresa,
        }
      }
    )
    return parteNovedad.data.result.dataset[0]
  }
  async getOrCreateParteNovedad(idpuestoServicio, idempresa) {
    if (idpuestoServicio && idempresa) {
      const sorter = [
        {
          'field': 'fparte',
          'asc': false
        },
        {
          'field': 'idparte_novedad',
          'asc': false
        }
      ]
      const apiFilterParteNovedad = new APIFilter()
      apiFilterParteNovedad
        .addExact('idpuesto_servicio', idpuestoServicio)
        .addExact('idempresa', idempresa)
        .addExact('estado', PARTE_NOVEDAD.estados.pendiente)
      let parteNovedad = await this.Vue.$api.call(
        'parteNovedad.select',
        {
          page: 0,
          filter: apiFilterParteNovedad,
          sorter: sorter,
        },
      )
      if (parteNovedad.data.result.dataset.length === 0) {
        this.Vue.$alert.showSnackbarWarning(
          `No existe un parte de novedad pendiente para el puesto de servicio, se crea uno.`
        )
        return this.CreateParteNovedad(idpuestoServicio, idempresa)
      } else {
        const fechaParte = parteNovedad.data.result.dataset[0].fparte
        const elParteEsDeHoy = isSameDay(currentDate(), fechaParte)
        if (elParteEsDeHoy) {
          this.Vue.$alert.showSnackbarInfo(
            `Añadiendo novedad al parte ${parteNovedad.data.result.dataset[0].idparte_novedad}`
          )
          return parteNovedad.data.result.dataset[0]
        } else {
          const resCrearParteNuevo = await this.Vue.$alert.showConfirm(
            `Hay un parte pendiente del día ${filters.shortDate(fechaParte)}, ¿quieres añadir la novedad a ese parte o crear uno nuevo?`,
            'Confirmación',
            { false: 'Usar ese parte', true: { text: 'Crear nuevo', color: 'primary', flat: false } }
          )
          if (typeof resCrearParteNuevo !== 'undefined') {
            if (resCrearParteNuevo) {
              return this.CreateParteNovedad(idpuestoServicio, idempresa)
            } else {
              return parteNovedad.data.result.dataset[0]
            }
          }
        }
      }
    } else {
      this.Vue.$alert.showDialogError('Se ha producido un error al obtener los datos desde el registro de presencia, si el error persiste registre la salida y la entrada nuevamente.')
    }
  }
  async selectPuestoServicioDeParte(idparteNovedad) {
    const parteApiFilter = new APIFilter()
    parteApiFilter.addExact('idparte_novedad', idparteNovedad)
    const respParte = await this.Vue.$api.call('parteNovedad.select', { filter: parteApiFilter })
    const parte = respParte.data.result.dataset[0]
    const puestoApiFilter = new APIFilter()
    puestoApiFilter.addExact('idpuesto_servicio', parte.idpuesto_servicio)
    const resp = await this.Vue.$api.call('puestoServicio.select', { filter: puestoApiFilter })
    return resp.data.result.dataset[0]
  }
}