import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class PresenciaOnlineResource extends BaseOnlineResource {
  async selectPresenciaSinSalida (idempleado) {
    const apiFilterPresencia = new APIFilter()
    apiFilterPresencia
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idempleado', idempleado)
      .addIsNull('fsalida')
    return await this.Vue.$api.call('presencia.select', {
      filter: apiFilterPresencia,
      sorter: [
        { 'field': 'fentrada', 'asc': false }
      ]
    })
  }
}


