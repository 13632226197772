<template>
  <v-app-bar
    :color="color"
    dark
    app
    extension-height="64"
  >
    <v-menu
      v-model="showingToolbarMenu"
    >
      <v-list>
        <v-list-item
          @click.stop="clickBack"
        >
          <v-list-item-avatar>
            <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Volver atrás</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click.stop="clickHome"
        >
          <v-list-item-avatar>
            <v-icon>{{ $vuetify.icons.values.home }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Volver al inicio</v-list-item-title>
        </v-list-item>
        <slot name="menuItemsLongPress" />
      </v-list>
    </v-menu>
    <v-btn
      v-if="back && !selecting"
      v-longpress="onLongPressBack"
      icon
      @click.stop="clickBack"
    >
      <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
    </v-btn>
    <v-btn
      v-if="selecting"
      icon
      @click.stop="clickClearSelection"
    >
      <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
    </v-btn>
    <slot name="left" />
    <v-toolbar-title
      v-if="selecting"
    >
      {{ selectedCount }}
    </v-toolbar-title>
    <v-toolbar-title
      v-else
      v-text="title"
    />
    <v-spacer />
    <v-btn
      v-if="selecting"
      icon
      @click.stop="clickSelectAll"
    >
      <v-icon>{{ $vuetify.icons.values.checkAll }}</v-icon>
    </v-btn>
    <slot name="right" />
    <v-menu
      v-if="options"
      v-model="showingFilter"
      max-height="90vh"
    >
      <template #activator="{ on: menu }">
        <v-btn
          v-show="!selecting && hasVisibleOptions"
          icon
          v-on="{ ...menu }"
        >
          <v-icon>{{ $vuetify.icons.values.more }}</v-icon>
        </v-btn>
      </template>
      <b10-toolbar-menu
        :options="options"
        @click-option="clickOption"
      />
    </v-menu>
    <template
      slot="extension"
    >
      <v-text-field
        v-if="withSearch && !selecting"
        ref="searchInput"
        v-model="internalSearch"
        prepend-inner-icon="search"
        flat
        dense
        solo-inverted
        hide-details
        single-line
        clearable
        @input="updateSearch"
        @keyup.enter="submitSearch"
      />
      <v-btn
        v-if="withFilter && !selecting"
        icon
        @click="clickFilter"
      >
        <v-icon
          small
        >
          {{ $vuetify.icons.values.filter }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="withSorter && !selecting"
        icon
        @click="clickSorter"
      >
        <v-icon
          small
        >
          {{ $vuetify.icons.values.sort }}
        </v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import _ from '@/utils/lodash'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    back: {
      type: Boolean,
      default: true,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withFilter: {
      type: Boolean,
      default: false,
    },
    withSorter: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: false,
      validator: (value) => {
        for (let option in value) {
          if ((!_.has(value[option], 'title')) ||
              (!_.has(value[option], 'visible')) ||
              (typeof value[option].title !== 'string') ||
              (typeof value[option].visible !== 'boolean')) {
            return false
          }
        }
        return true
      },
      default: () => {},
    },
    selecting: {
      type: Boolean,
      default: false,
    },
    selectedCount: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      showingToolbarMenu: false,
      showingFilter: false,
      internalSearch: this.search
    }
  },
  computed: {
    color () {
      if (this.selecting) {
        return colors.grey.base
      } else {
        return 'primary'
      }
    },
    isExtended () {
      return this.withSearch && !this.selecting
    },
    hasVisibleOptions () {
      const visibleOptions = []
      for (const option in this.options) {
        if (this.options[option].visible) {
          visibleOptions.push(this.options[option])
        }
      }
      return visibleOptions.length > 0
    }
  },
  watch: {
    internalSearch (newInternalSearch) {
      this.$emit('update:search', newInternalSearch)
    },
    search (newSearch) {
      this.internalSearch = newSearch
    }
  },
  methods: {
    updateSearch () {
      this.$emit('input', this.$refs.searchInput.lazyValue)
    },
    submitSearch () {
      this.$emit('submit-search', this.$refs.searchInput.lazyValue)
    },
    clickOption (option) {
      this.showingFilter = false
      this.$emit('click-option', option)
    },
    clickBack () {
      this.$emit('click-back')
      this.$appRouter.go(-1)
    },
    clickHome () {
      this.$appRouter.push({ name: 'index' })
    },
    onLongPressBack () {
      this.showingToolbarMenu = true
    },
    clickClearSelection () {
      this.$emit('clear-selection')
    },
    clickSelectAll  () {
      this.$emit('select-all')
    },
    clickFilter  () {
      this.$emit('click-filter')
    },
    clickSorter  () {
      this.$emit('click-sorter')
    },
  }
}
</script>
