import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import IdleJs from 'idle-js'
// directives
import longPressDirective from '@/directives/longpress'
import { VueMaskDirective } from 'v-mask'
// plugins Vue app
import vuetify from '@/plugins/vuetify'
import alert from '@/plugins/alert'
import api from '@/plugins/api'
import dirty from '@/plugins/dirty'
import filters from '@/plugins/filters'
import loading from '@/plugins/loading'
import offline from '@/plugins/offline'
import online from '@/plugins/online'
import appRouter from '@/plugins/router'
import VueSignature from "vue-signature-pad"
import VueSnip from 'vue-snip'
import { initVeeValidate } from '@/plugins/veeValidate'
// vuetify
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import DatetimePicker from 'vuetify-datetime-picker'
// VJsf (forms dinámicos)
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'
// Sentry
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { beforeSend } from './sentry'
// plugin PWA
import './registerServiceWorker'
// componentes globales
import '@/components/globals'
import { call, get, sync } from 'vuex-pathify'
import { differenceInMinutes } from '@/utils/date'

Sentry.init({
  dsn: 'https://d831b039d85944018cddd46e21ed2401@sentry.io/1335712',
  autoSessionTracking: false,
  integrations: (integrations) => {
    let defaultIntegrationsNoDedupe = integrations.filter((integration) => {
      return integration.name !== 'Dedupe'
    })
    defaultIntegrationsNoDedupe.push(new Integrations.Vue({ Vue, attachProps: true, logErrors: true }))
    return defaultIntegrationsNoDedupe
  },
  environment: process.env.NODE_ENV,
  release: `beta10-movil@${__BETA10_VERSION__}`,
  beforeSend: async (event, hint) => {
    return await beforeSend(Vue, store, event, hint)
  }
})

const initApp = async () => {
  // detectar "back" del navegador
  window.popStateDetected = false
  Vue.use(VueSignature)
  Vue.use(VuetifyDialog, { context: { vuetify } })
  Vue.use(DatetimePicker)
  Vue.use(alert)
  Vue.use(offline)
  Vue.use(api)
  Vue.use(dirty)
  Vue.use(filters)
  Vue.use(loading)
  Vue.use(online)
  Vue.use(appRouter)
  Vue.use(VueSnip)
  Vue.directive('longpress', longPressDirective({ delay: 600, interval: 0 }))
  Vue.directive('mask', VueMaskDirective)
  initVeeValidate(Vue)
  Vue.config.productionTip = (process.env.NODE_ENV === 'production')
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    components: { App },
    data () {
      return {
        intervaloRefreshToken: null,
        intervaloNuevaVersion: null,
        minutosEntreSincronizaciones: 0,
      }
    },
    computed: {
      usuario: get('usuario'),
      usuarioTiempoInactividad: get('usuario/tiempoInactividad'),
      usuarioUltimaActividad: get('usuario/ultimaActividad'),
      networkOnline: sync('network/online'),
      syncing: get('sync/syncing'),
      usuarioUltimaDescargaGlobales: sync('usuario/ultimaDescargaGlobales'),
    },
    watch: {
      usuarioTiempoInactividad (newValue) {
        this.initIdle(newValue)
      }
    },
    async created () {
      this.$api.init(this.$alert, this.$router)
      this.$alert.init(this.$dialog)
      // cambios online / offline
      window.addEventListener('online', this.onNetworkOn)
      window.addEventListener('offline', this.onNetworkOff)
      // detectar "back" del navegador
      window.addEventListener('popstate', this.popStateDetected)
      // cada 15 minutos
      // * comprobar la expiración del token y cerrar sesión si expiró
      // * intentar refrescar el token y cerrar sesión si ya no es válido
      const cuartoHoraMs = 15 * 60 * 1000
      this.intervaloRefreshToken = setInterval(this.refreshToken, cuartoHoraMs)
      this.networkOnline = navigator.onLine
      this.$appRouter.init(this.$router)
      // no funciona indicando configureScope en el Sentry.init
      Sentry.configureScope((scope) => {
        scope.setExtra('idusuario', this.usuario.idusuario)
        scope.setExtra('email', this.usuario.email)
        scope.setExtra('nombre', this.usuario.nombre)
        scope.setTag('instalacion', this.usuario.instalacion)
        // scope.setTag('dbVersion', db.getSchema().version())
        scope.setTag('version', __BETA10_VERSION__)
        scope.setTag('domain', process.env.VUE_APP_DOMAIN)
      })
    },
    beforeDestroy () {
      clearInterval(this.intervaloRefreshToken)
      clearInterval(this.intervaloNuevaVersion)
      window.removeEventListener('online', this.onNetworkOn)
      window.removeEventListener('offline', this.onNetworkOff)
      window.removeEventListener('popstate', this.popStateDetected)
    },
    methods: {
      ...call('usuario', {
        usuarioRefreshToken: 'refreshToken',
        usuarioCerrarSesion: 'cerrarSesion',
      }),
      onNetworkOn () {
        this.networkOnline = true
      },
      onNetworkOff () {
        this.networkOnline = false
      },
      async refreshToken () {
        // si esta autenticado y tiene red -> intentar refrescar el token
        // si el token se invalidó desde el servidor -> cerrar la sesión
        if (this.usuario.token && this.networkOnline) {
          await this.usuarioRefreshToken({ $api: this.$api })
          if (!this.usuario.token) {
            this.$appRouter.replace({ name: 'login' })
          }
        }
      },
      popStateDetected () {
        window.popStateDetected = true
      },
      async logout () {
        this.dialogLogout = false
        this.$alert.showSnackbarError(`La sesión se ha cerrado debido a inactividad.`)
        await this.usuarioCerrarSesion()
        this.$appRouter.replace({ name: 'login' })
      },
      async comprobarTiempoInactividad (tiempoInactividad) {
        if (this.usuario.idusuario && this.networkOnline) {
          const ahora = new Date()
          const minutosDesdeUltimaActividad = differenceInMinutes(ahora, this.usuarioUltimaActividad)
          if (minutosDesdeUltimaActividad > tiempoInactividad) {
            this.logout()
          }
        }
      },
      async initIdle(tiempoInactividad) {
        let idle = null
        if (tiempoInactividad) {
          // El parámetro está en minutos y el valor tiene que ser en ms
          const tiempoInactividadEnMS = tiempoInactividad * 60000
          idle = new IdleJs({
            idle: tiempoInactividadEnMS,
            events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
            onIdle: () => {
              if (this.usuario.idusuario) {
                if (this.networkOnline) {
                  idle.stop()
                  this.logout()
                } else {
                  // Si está offline reseteo el idle para empezar de nuevo
                  idle.stop()
                    .reset()
                    .start()
                }
              }
            },
            onHide: async () => {
              const ahora = new Date()
              await store.set('usuario/ultimaActividad', ahora)
            },
            onShow: () => {
              this.comprobarTiempoInactividad(tiempoInactividad)
            },
            onActive: () => {
              this.comprobarTiempoInactividad(tiempoInactividad)
            },
            keepTracking: true, // set it to false if you want to be notified only on the first idleness change
            startAtIdle: false // set it to true if you want to start in the idle state
          });
          idle.start()
        } else if (idle) {
          idle.stop()
        }
      },
    },
    template: '<App/>',
  })
}

initApp()
