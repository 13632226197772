import { load } from '@/router/utils'
import { CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // ordenes
  {
    path: 'pendientes',
    name: 'ordenes__pendientes-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      clasificacionEstado: [CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente],
      soloMisOT: false,
    },
  },
  {
    path: 'finalizadas-tecnico',
    name: 'ordenes__finalizadas-tecnico-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      clasificacionEstado: [CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.finalizada],
      soloMisOT: true,
    },
  },
  {
    path: 'finalizadas',
    name: 'ordenes__finalizadas-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      clasificacionEstado: [CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.finalizada],
      soloMisOT: false,
    },
  },
  {
    path: ':idorden_trabajo',
    name: 'ordenes__orden-trabajo-view',
    component: load('ordenes/ordenTrabajoView/OrdenTrabajoView'),
  },
  {
    path: ':idorden_trabajo/asignar',
    name: 'ordenes__orden-trabajo-asignar',
    component: load('ordenes/ordenTrabajoAsignar/OrdenTrabajoAsignar'),
  },
  {
    path: ':idorden_trabajo/material-afectado',
    name: 'ordenes__orden_trabajo_material_afectado-subsis-list',
    component: load('ordenes/ordenTrabajoMatsistSubsisList/OrdenTrabajoMatsistSubsisList'),
    props: {
      viewRoute: 'ordenes__orden_trabajo_material_afectado-list',
    },
  },
  {
    path: ':idorden_trabajo/material-afectado/:idsubsis',
    name: 'ordenes__orden_trabajo_material_afectado-list',
    component: load('ordenes/ordenTrabajoMatsistList/OrdenTrabajoMatsistList'),
  },
  {
    path: ':idorden_trabajo/material-instalar',
    name: 'ordenes__orden_trabajo_subsis-list',
    component: load('ordenes/ordenTrabajoSubsisList/OrdenTrabajoSubsisList'),
    props: {
      viewRoute: 'ordenes__lorden-trabajo-list',
    },
  },
  {
    path: ':idorden_trabajo/material-instalar/:idsubsis',
    name: 'ordenes__lorden-trabajo-list',
    component: load('ordenes/lordenTrabajoList/LordenTrabajoList'),
  },
  {
    path: ':idorden_trabajo/partes',
    name: 'ordenes__parte-trabajo-list',
    component: load('partes/parteTrabajoList/ParteTrabajoList'),
    props: {
      viewRoute: 'partes__parte-trabajo-view',
    },
  },
  // Notas
  {
    path: ':id/:idtabla/notas',
    name: 'ordenes__orden-nota-list',
    component: load('notas/notaList/NotaList'),
    props: {
      viewRoute: 'ordenes__orden-nota-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.nota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':id/:idtabla/notas/:idnota',
    name: 'ordenes__orden-nota-view',
    component: load('notas/notaView/NotaView'),
    meta: {
      permission: {
        idobjeto: permissions.nota.id,
        access: ACCESS.view,
      },
    },
  },
  // tecnicos asignados
  {
    path: ':idorden_trabajo/tecnicos',
    name: 'ordenes__orden-trabajo-tecnico-list',
    component: load('ordenes/ordenTrabajoTecnicoList/OrdenTrabajoTecnicoList'),
  },
]
