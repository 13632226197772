import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'
import { currentDate } from '@/utils/date'

export default class ClienteAvisoOfflineResource extends BaseOfflineResource {
  async selectAvisos (idcliente, idsistema, esTecnico, esComercial) {
    const tables = this.db.tables
    let whereFilter = []
    let whereVisibilidad = []
    if (idcliente) {
      whereFilter.push(
        this.Vue.$offline.db.op.and(
          tables.cliente_aviso.idcliente.eq(idcliente),
          tables.cliente_aviso.idsistema.isNull(),
        )
      )
    }
    if (idsistema) {
      whereFilter.push(
        this.Vue.$offline.db.op.and(
          tables.cliente_aviso.idsistema.eq(idsistema),
        )
      )
    }
    if (esTecnico) {
      whereVisibilidad.push(tables.cliente_aviso.imprime_sat.eq(true))
    }
    if (esComercial) {
      whereVisibilidad.push(tables.cliente_aviso.imprime_comercial.eq(true))
    }
    const hoy = currentDate()
    return await this.db
      .select()
      .from(tables.cliente_aviso)
      .where(
        this.Vue.$offline.db.op.and(
          tables.cliente_aviso.estado.gt(0),
          this.db.op.or(
            ...whereFilter
          ),
          this.db.op.or(
            ...whereVisibilidad
          ),
          this.db.op.and(
            this.db.op.or(
              tables.cliente_aviso.finicio.lte(hoy),
              tables.cliente_aviso.finicio.isNull(),
            ),
            this.db.op.or(
              tables.cliente_aviso.ffin.gte(hoy),
              tables.cliente_aviso.ffin.isNull(),
            ),
          ),
        )
      )
      .exec()
  }
  async avisos (idcliente, idsistema, esTecnico, esComercial) {
    const toReturn = await this.selectAvisos(idcliente, idsistema, esTecnico, esComercial)
    return _.map(toReturn, 'aviso').join('\n')
  }
}
