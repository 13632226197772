import BaseOnlineResource from '@/online/base'
import { TDATO_TIPO_DATO } from '@/utils/consts'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import filters from '@/utils/filters'
import {  toDateTime, currentDate, parseHHmm } from '@/utils/date'

export default class ArticuloOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.valor || row.valor_fecha || row.valor_numerico ) {
      let valorUtilizado = null
      if (TDATO_TIPO_DATO.tiposNumber.includes(row.tdato_idtipo_tdato)){
        valorUtilizado = filters.decimal(row.valor_numerico)
      } else if (row.valor_fecha != null) {
        if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.fecha) {
          valorUtilizado = filters.shortDate(row.valor_fecha)
        } else if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.fechaHora) {
          valorUtilizado = filters.shortDateTime(row.valor_fecha)
        } else if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.hora) {
          valorUtilizado = filters.shortTime(row.valor_fecha)
        }
      } else {
        valorUtilizado = row.valor
      }
      return `${row.tdato_descripcion}: "${valorUtilizado}"`
    } else {
      return row.tdato_descripcion
    }
  }
  subtitle (row) {
    if (row.tgrupo_tdato_descripcion) {
      return row.tgrupo_tdato_descripcion
    } else {
      return 'Sin agrupación'
    }
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addIContainsWords('tdato_descripcion', value)
      .addIContainsWords('valor', value)
      .addIContainsWords('valor_fecha', value)
      .addIContainsWords('valor_numerico', value)
  }
  async _valuesForBatchCall(formDataDatos, fieldname, identificador, pkKey) {
    let datoValue = formDataDatos[fieldname]
    let values = {
      estado: 1,
      id: identificador,
    }
    if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.tiposNumber)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.tiposNumber.length))
      values.valor_numerico = datoValue
    } else if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.fechaHora)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.fechaHora.length))
      values.valor_fecha = datoValue
    } else if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.hora)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.hora.length))
      values.valor_fecha = parseHHmm(datoValue, currentDate())
    } else if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.fecha)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.fecha.length))
      values.valor_fecha = toDateTime(datoValue)
    } else if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.firma)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.firma.length))
      values.valor_firma = datoValue
    } else if (fieldname.startsWith(TDATO_TIPO_DATO.formFieldNames.tiposString)) {
      values[pkKey] = parseInt(fieldname.substring(TDATO_TIPO_DATO.formFieldNames.tiposString.length))
      values.valor = datoValue
    }
    return values
  }
  async batchCallInsertDeFormData (identificador, formDataDatos) {
    let apiCalls = []
    let ordenDato = 1
    for (const fieldname in formDataDatos) {
      const values = await this._valuesForBatchCall(formDataDatos, fieldname, identificador, 'idtdato')
      values.orden = ordenDato
      if (values.idtdato) {
        apiCalls.push({
          name: 'datoInsert',
          method: 'dato.insert',
          params: { values },
        })
        ordenDato += 1
      }
    }
    return apiCalls
  }
  async batchCallUpdateDeFormData (identificador, formDataDatos) {
    let apiCalls = []
    for (const fieldname in formDataDatos) {
      const values = await this._valuesForBatchCall(formDataDatos, fieldname, identificador, 'iddato')
      if (values.iddato) {
        apiCalls.push({
          name: 'datoUpdate',
          method: 'dato.update',
          params: { values },
        })
      }
    }
    return apiCalls
  }
  async batchCallSelect (id, idtabla) {
    const apiFilter = new APIFilter()
      .addExact('id', id)
      .addExact('idtabla', idtabla)
      .addGTE('estado', 1)
    return { name: 'selectDato', method: 'dato.select', params: { filter: apiFilter } }
  }
}
