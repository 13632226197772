import filters from '@/utils/filters'

export default {
  install (Vue) {
    Vue.filter('decimal', filters.decimal)
    Vue.filter('currency', filters.currency)
    Vue.filter('style', filters.style)
    Vue.filter('currency', filters.currency)
    Vue.filter('capitalize', filters.capitalize)
    Vue.filter('lowercase', filters.lowercase)
    Vue.filter('uppercase', filters.uppercase)
    Vue.filter('shortDate', filters.shortDate)
    Vue.filter('shortMonth', filters.shortMonth)
    Vue.filter('shortTime', filters.shortTime)
    Vue.filter('shortDateTime', filters.shortDateTime)
    Vue.filter('linebreaksBr', filters.linebreaksBr)
    Vue.filter('addBase64ImagePrefix', filters.addBase64ImagePrefix)
    Vue.filter('humanizeDuration', filters.humanizeDuration)
    Vue.filter('humanizeBoolean', filters.humanizeBoolean)
    Vue.filter('pluralize', filters.pluralize)
    Vue.filter('initials', filters.initials)
    Vue.filter('hideIBAN', filters.hideIBAN)
    Vue.filter('percent', filters.percent)
    Vue.filter('round', filters.round)
  }
}
