import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fnovedad'
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fnovedad'
      },
      parteNovedad: {
        label: 'Parte de novedad',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idparte_novedad',
        selectItemValue: 'idparte_novedad',
      },
      puestoServicio: {
        label: 'Puesto',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idpuesto_servicio',
        selectItemValue: 'idpuesto_servicio',
        selectItemText: 'descripcion',
      },
      puestoServicioServicio: {
        label: 'Servicio',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idpuesto_servicio_servicio',
        selectItemValue: 'idpuesto_servicio_servicio',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
