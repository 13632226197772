import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class FicheroOnlineResource extends BaseOnlineResource {
  buildApiFilterVisualizacion (id, idtabla, esTecnico, esComercial, esVigilante, addIn=false) {
    const apiFicheroFilter = new APIFilter()
    if (addIn) {
      apiFicheroFilter
        .addIn('id', id)
    } else {
      apiFicheroFilter
        .addExact('id', id)
    }
    apiFicheroFilter
      .addExact('idtabla', idtabla)
      .addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    if (esTecnico || esComercial || esVigilante) {
      apiFilterVisualizacion
        .setOperator(API_FILTER_OPERATORS.OR)
      if (esTecnico) {
        apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
      }
      if (esComercial) {
        apiFilterVisualizacion.addExact('visible_movilidad_comercial', esComercial)
      }
      if (esVigilante) {
        apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esVigilante)
      }
      apiFicheroFilter.addNestedFilter(apiFilterVisualizacion)
    }
    return apiFicheroFilter
  }
  ApiCallSelect (id, idtabla, esTecnico, esComercial, esVigilante) {
    const apiFicheroFilter = this.buildApiFilterVisualizacion(id, idtabla, esTecnico, esComercial, esVigilante)
    return { name: 'selectFichero', method: 'fichero.select', params: { filter: apiFicheroFilter } }
  }
  ApiCallSelectCount (id, idtabla, esTecnico, esComercial, esVigilante) {
    let toReturn = this.ApiCallSelect(id, idtabla, esTecnico, esComercial, esVigilante)
    toReturn.params['wrapper'] = 'count'
    toReturn.name = 'selectFicheroCount'
    return toReturn
  }
}
