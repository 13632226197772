import colors from 'vuetify/lib/util/colors'
import vuetify from '@/plugins/vuetify'
import { permissions } from '@/utils/permissions'

export class ItemNotFoundError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ItemNotFoundError'
  }
}

export class SyncError extends Error {
  constructor(message) {
    super(message)
    this.name = 'SyncError'
  }
}

export class DatabaseVersionError extends Error {
  constructor(message) {
    super(message)
    this.name = 'DatabaseVersionError'
  }
}

export const BASE_COLORS = {
  selected: colors.grey.lighten2
}

export const CHECKLIST = {
  colores: {
    conAnomalias: colors.red.base,
    ok: colors.green.base,
    sinContestar: colors.grey.base,
    pendiente: colors.red.base,
    finalizado: colors.green.base,
    enCurso: colors.orange.base,
  },
  estados: {
    pendiente: 1,
    finalizado: 2
  },
}

export const GRUPO_CHECKLIST = {
  clasificacion: {
    generales: 1,
    subsistemas: 3,
    materiales: 2,
  },
  resultado: {
    conAnomalias: 0,
    ok: 1,
    sinContestar: null,
  },
  resultadoDesc: {
    conAnomalias: 'Con anomalías',
    ok: 'Todo correcto',
    sinContestar: 'Falta contestar',
  },
  colorResultado: {
    conAnomalias: colors.red.base,
    ok: colors.green.base,
    sinContestar: colors.grey.base,
  },
  colorGrupo: {
    generales: colors.cyan.base,
    subsistemas: colors.teal.base,
    materiales: colors.teal.darken4,
  },
}

export const PREGUNTA_CHECKLIST = {
  resultado: {
    conAnomalias: 0,
    ok: 1,
    noProcede: 2
  },
  colorResultado: {
    conAnomalias: colors.red.base,
    ok: colors.green.base,
    noProcede: colors.lightBlue.base
  },
  clasificacionValor: {
    booleano: 1,
    texto: 2,
    textoMultilinea: 3,
    entero: 4,
    decimal: 5,
    fecha: 6,
    sinValor: 7,
  },
}

export const GRADO_ANOMALIA = {
  clasificaciones: {
    correcto: 1,
    incorrecto: 2,
    noProcede: 3,
    desconocido: 4,
  },
  colores: {
    1: colors.lightGreen.base,
    2: colors.red.base,
    3: colors.grey.base,
    4: colors.grey.lighten2,
  }
}

export const PS_CHECK = {
  resultado: {
    mal: 0,
    ok: 1,
    noProcede: 2,
  },
  colorResultado: {
    mal: colors.red.base,
    ok: colors.green.base,
    noProcede: colors.lightBlue.base,
  },
}

export const PARTE_TRABAJO = {
  colores: {
    descargado: colors.lightGreen.lighten2,
    conAccionesPendientes: colors.amber.base,
    conAccionesTodasRealizadas: colors.green.base,
    noEncontrado: colors.red.lighten2,
    pendiente: colors.red.base,
    finalizado: colors.green.base,
    enCurso: colors.orange.base,
  },
  estados: {
    pendiente: 1,
    finalizado: 2
  },
  clasificaciones: {
    normal: 1,
    taller: 2,
    devolucion: 3,
  },
  descripcionesClasificacion: {
    1: 'Normal',
    2: 'Taller',
    3: 'Devolución',
  },
}

export const PARTE_TRABAJO_TECNICO = {
  colores: {
    principal: colors.green.base
  },
}

export const PARTE_NOVEDAD = {
  estados: {
    anulado: 0,
    pendiente: 1,
    finalizado: 2,
  },
  colores: {
    anulado: colors.grey.grey,
    pendiente: colors.red.base,
    finalizado: colors.green.base,
  },
}

export const LPARTE_NOVEDAD = {
  estados: {
    anulado: 0,
    pendiente: 1,
    finalizado: 2,
  },
  colores: {
    anulado: colors.grey.grey,
    pendiente: colors.red.base,
    finalizado: colors.green.base,
  },
  descripcionEstado: {
    0: 'Anulado',
    1: 'Pendiente',
    2: 'Finalizado',
  },
  coloresInteger: {
    0: colors.grey.grey,
    1: colors.red.base,
    2: colors.green.base,
  },
}

export const TDATO_NOVEDAD = {
  tipos: {
    numero: 1,
    texto: 2,
    fecha: 3,
  }
}

export const PEDIDO_CLIENTE = {
  estados: {
    anulado: 0,
    pendiente: 1,
    sirviendo: 2,
    servido: 3,
    finalizado: 4
  },
  colores: {
    pendiente: colors.red.base,
    sirviendo: colors.orange.base,
    servido: colors.green.base,
    finalizado: colors.green.base,
  },
  descripciones: {
    pendiente: 'Pendiente',
    sirviendo: 'Sirviendo',
    servido: 'Servido',
    finalizado: 'Finalizado'
  },
  alias: {
    pendiente: 'P',
    sirviendo: 'A',
    servido: 'S',
    finalizado: 'F'
  }
}

export const TABLA = {
  // el campo pk se utiliza para `OperacioncrmList`
  accion_correctora_ot: {
    name: 'accion_correctora_ot',
    pk: 'idaccion_correctora_ot',
    titleSingular: 'acción correctora',
    titlePlural: 'acciones correctoras',
    idtabla: 1219,
  },
  cliente: {
    name: 'cliente',
    pk: 'idcliente',
    titleSingular: 'cliente',
    titlePlural: 'clientes',
    idtabla: 9,
    icono: vuetify.userPreset.icons.values.cliente,
  },
  cliente_potencial: {
    name: 'cliente_potencial',
    pk: 'idcliente_potencial',
    titleSingular: 'cliente potencial',
    titlePlural: 'clientes potenciales',
    idtabla: 445,
    icono: vuetify.userPreset.icons.values.clientePotencial,
  },
  contrato: {
    name: 'contrato',
    pk: 'idcontrato',
    titleSingular: 'contrato',
    titlePlural: 'contratos',
    idtabla: 233,
  },
  lparte_novedad: {
    name: 'lparte_novedad',
    pk: 'idlparte_novedad',
    titleSingular: 'novedad',
    titlePlural: 'novedades',
    idtabla: 373,
    icono: vuetify.userPreset.icons.values.parteNovedad,
  },
  operacioncrm: {
    name: 'operacioncrm',
    pk: 'idoperacioncrm',
    titleSingular: 'operación',
    titlePlural: 'operaciones',
    idtabla: 777,
    icono: vuetify.userPreset.icons.values.operacionCRM,
  },
  orden_trabajo: {
    name: 'orden_trabajo',
    pk: 'idorden_trabajo',
    titleSingular: 'orden de trabajo',
    titlePlural: 'órdenes de trabajo',
    idtabla: 267,
    icono: vuetify.userPreset.icons.values.orden,
  },
  parte_novedad: {
    name: 'parte_novedad',
    pk: 'idparte_novedad',
    titleSingular: 'parte de novedad',
    titlePlural: 'partes de novedad',
    idtabla: 377,
  },
  parte_trabajo: {
    name: 'parte_trabajo',
    pk: 'idparte_trabajo',
    titleSingular: 'parte de trabajo',
    titlePlural: 'partes de trabajo',
    idtabla: 269,
  },
  presupuestocli: {
    name: 'presupuestocli',
    pk: 'idpresupuestocli',
    titleSingular: 'presupuesto',
    titlePlural: 'presupuestos',
    idtabla: 106,
    icono: vuetify.userPreset.icons.values.presupuestos,
  },
  puesto_servicio: {
    name: 'puesto_servicio',
    pk: 'idpuesto_servicio',
    titleSingular: 'puesto de servicio',
    titlePlural: 'puestos de servicio',
    idtabla: 379,
  },
  ps_check: {
    name: 'ps_check',
    pk: 'idps_check',
    titleSingular: 'punto de ronda',
    titlePlural: 'puntos de ronda',
    idtabla: 1187,
  },
  ronda: {
    name: 'ronda',
    pk: 'idronda',
    titleSingular: 'ronda',
    titlePlural: 'rondas',
    idtabla: 1003,
  },
  sistema: {
    name: 'sistema',
    pk: 'idsistema',
    titleSingular: 'sistema',
    titlePlural: 'sistemas',
    idtabla: 96,
    icono: vuetify.userPreset.icons.values.sistema,
  },
  tanomalia_checklist_ot: {
    name: 'tanomalia_checklist_ot',
    pk: 'idtanomalia_checklist_ot',
    titleSingular: 'anomalía',
    titlePlural: 'anomalías',
    idtabla: 1227,
  },
  tecnico: {
    name: 'tecnico',
    pk: 'idtecnico',
    titleSingular: 'técnico',
    titlePlural: 'técnicos',
    idtabla: 261,
  },
  facturacli: {
    name: 'facturacli',
    pk: 'idfacturacli',
    titleSingular: 'factura de cliente',
    titlePlural: 'facturas de cliente',
    idtabla: 86,
    icono: vuetify.userPreset.icons.values.facturas,
  },
  deudacli: {
    name: 'deudacli',
    pk: 'iddeudacli',
    titleSingular: 'deuda de cliente',
    titlePlural: 'deudas de cliente',
    idtabla: 145,
    icono: vuetify.userPreset.icons.values.facturacion,
  },
  sistema_tviacomunicacion: {
    name: 'sistema_tviacomunicacion',
    pk: 'idsistema_tviacomunicacion',
    titleSingular: 'vía de comunicación',
    titlePlural: 'vías de comunicación',
    idtabla: 743,
    icono: vuetify.userPreset.icons.values.viaComunicacion,
  },
  agente_comercial: {
    name: 'agente_comercial',
    pk: 'idagente_comercial',
    titleSingular: 'agente comercial',
    titlePlural: 'agentes comerciales',
    idtabla: 5,
    icono: vuetify.userPreset.icons.values.agenteComercial,
  },
  proveedor: {
    name: 'proveedor',
    pk: 'idproveedor',
    titleSingular: 'proveedor',
    titlePlural: 'proveedores',
    idtabla: 108,
    icono: vuetify.userPreset.icons.values.proveedor,
  },
  pedidopro: {
    name: 'pedidopro',
    pk: 'idpedidopro',
    titleSingular: 'pedido de proveedor',
    titlePlural: 'pedidos de proveedor',
    idtabla: 104,
    icono: vuetify.userPreset.icons.values.pedidoProveedor,
  },
  facturapro: {
    name: 'facturapro',
    pk: 'idfacturapro',
    titleSingular: 'factura de proveedor',
    titlePlural: 'facturas de proveedor',
    idtabla: 87,
    icono: vuetify.userPreset.icons.values.facturas,
  },
  vehiculo: {
    name: 'vehiculo',
    pk: 'idvehiculo',
    titleSingular: 'vehículo',
    titlePlural: 'vehículos',
    idtabla: 396,
    icono: vuetify.userPreset.icons.values.vehiculo,
  },
  proyecto_contable: {
    name: 'proyecto_contable',
    pk: 'idproyecto_contable',
    titleSingular: 'proyecto contable',
    titlePlural: 'proyectos contable',
    idtabla: 109,
    icono: vuetify.userPreset.icons.values.proyecto,
  },
  material_sistema: {
    name: 'material_sistema',
    pk: 'idmaterial_sistema',
    titleSingular: 'material de sistema',
    titlePlural: 'materiales de sistema',
    idtabla: 260,
    icono: vuetify.userPreset.icons.values.materialInstalar,
  },
  empleado: {
    name: 'empleado',
    pk: 'idempleado',
    titleSingular: 'empleado',
    titlePlural: 'empleados',
    idtabla: 59,
    icono: vuetify.userPreset.icons.values.empleado,
  },
  movcaja: {
    name: 'movcaja',
    pk: 'idmovcaja',
    titleSingular: 'movimiento de caja',
    titlePlural: 'movimientos de caja',
    idtabla: 152,
  },
}

export const getTablaById = (idtabla) => {
  for (const tabla in TABLA) {
    if (TABLA[tabla].idtabla === idtabla) {
      return TABLA[tabla]
    }
  }
}

export const TFICHERO = {
  clasificacion: {
    planos: 1,
    otros: 2,
    adjuntoAnomalia: 3,
    adjuntoAccionCorrectora: 4,
    adjuntoEnviadoPorElCliente: 5
  }
}

export const ACCION_EJEC = {
  estados: {
    pendiente: 1,
    realizada: 2,
    no_realizada: 3
  },
  descripcion: [
    {
      idest_accion_ejec: 1, descripcion: 'Pendiente'
    },
    {
      idest_accion_ejec: 2, descripcion: 'Realizada'
    }
    // De momento no se tiene en cuenta
    // {
    //   idest_accion_ejec: 1, descripcion: 'No Realizada'
    // }
  ],
  colores: {
    1: colors.red.base,
    2: colors.green.base,
  }
}

export const MACCION = {
  clasificacion: {
    revision_anual: 1,
    recarga: 2,
    retimbrado: 3,
    sustitucion: 4,
    retirada_temporal: 5,
    baja_definitiva: 6,
    devolucion: 7,
    revision_trimestral: 8,
    revision_semestral: 9,
    presurizacion: 10,
    otros: 11,
    revision_mensual: 12,
    recuperacion: 13,
  },
  getIcon (clasificacion) {
    if (this.clasificacion.revision_anual === clasificacion) {
      return 'revision'
    } else if (this.clasificacion.recarga === clasificacion) {
      return 'recarga'
    } else if (this.clasificacion.retimbrado === clasificacion) {
      return 'retimbrado'
    } else if (this.clasificacion.sustitucion === clasificacion) {
      return 'sustitucion'
    } else if (this.clasificacion.retirada_temporal === clasificacion) {
      return 'retiradaTemporal'
    } else if (this.clasificacion.baja_definitiva === clasificacion) {
      return 'bajaDefinitiva'
    } else if (this.clasificacion.devolucion === clasificacion) {
      return 'devolucionMaterial'
    } else if (this.clasificacion.revision_trimestral === clasificacion) {
      return 'revision'
    } else if (this.clasificacion.revision_semestral === clasificacion) {
      return 'revision'
    } else if (this.clasificacion.presurizacion === clasificacion) {
      return 'presurizacion'
    } else if (this.clasificacion.otros === clasificacion) {
      return 'accionMaterial'
    } else if (this.clasificacion.revision_mensual === clasificacion) {
      return 'revision'
    } else if (this.clasificacion.recuperacion === clasificacion) {
      return 'recuperacion'
    }
  },
  esRevision (clasificacion) {
    return [
      this.clasificacion.revision_anual,
      this.clasificacion.revision_semestral,
      this.clasificacion.revision_trimestral,
      this.clasificacion.revision_mensual,
    ].includes(clasificacion)
  },
  // Esto era por lo que se filtraba en el select antes de GLP-25391
  NoRealizableMasivamente () {
    return [
      this.clasificacion.sustitucion,
      // this.clasificacion.retirada_temporal,
      // this.clasificacion.baja_definitiva,
      // this.clasificacion.devolucion,
      this.clasificacion.recuperacion,
    ]
  },
}

export const TDATO_ARTICULO = {
  tipos: {
    numero: 1,
    texto: 2,
    fecha: 3,
    seleccion: 4
  }
}

export const ORDEN_TRABAJO = {
  colores: {
    conAccionesPendientes: colors.amber.base,
    conAccionesTodasRealizadas: colors.green.base,
    pendiente: colors.red.base,
    finalizada: colors.green.base,
    asignada: colors.orange.base,
  },
}

export const CLASIFICACION_ESTADO_ORDEN_TRABAJO = {
  clasificacion: {
    anulada: 0,
    pendiente: 1,
    finalizada: 2,
    bloqueada: 3,
  },
  colores: {
    pendiente: colors.red.base,
    finalizada: colors.green.base,
    bloqueada: colors.orange.base,
  },
  descripcion: {
    0: 'Anulada',
    1: 'Pendiente',
    2: 'Finalizada',
    3: 'Bloqueada',
  }
}

export const LORDEN_TRABAJO = {
  estados: {
    anulado: 0,
    pendiente_compra: 1,
    pendiente_recibir: 2,
    pendiente_instalar: 3,
    en_instalacion: 4,
    instalado: 5,
    pendiente_gestion: 6
  },
  descripcionEstados: {
    0: 'anulado',
    1: 'pdte. de compra',
    2: 'pdte. de recibir',
    3: 'pdte. de instalar',
    4: 'En instalación',
    5: 'Instalado',
    6: 'pdte. de gestión',
  }
}

export const TIEMPO_TRABAJADO = {
  colores: {
    manoDeObra: colors.blue.base,
    desplazamiento: colors.brown.base,
  },
}

export const RONDA = {
  estados: {
    anulada: 0,
    enCurso: 1,
    finalizada: 2
  },
  descripcionEstado: {
    anulada: 'Anulada',
    enCurso: 'En curso',
    finalizada: 'Finalizada',
  },
  colores: {
    anulada: colors.grey.base,
    enCurso: colors.orange.base,
    finalizada: colors.green.base,
  },
}

export const RONDA_PUNTO = {
  estados: {
    anulada: 0,
    enCurso: 1,
    finalizada: 2
  },
  descripcionEstado: {
    anulada: 'Anulada',
    enCurso: 'En curso',
    finalizada: 'Finalizada',
  },
}

export const SYNC_UPLOAD = {
  results: {
    error: 0,
    en_cola: 1,
    correcto: 2
  }
}

export const TCLASIFICACION_SERVICIO_CONTRATADO = {
  clasificacion: {
    instalacion: 1,
    conexion: 2,
    mantenimiento: 3,
    alquiler: 4,
    serviciosDePersonal: 5,
  }
}

export const PARAMETRO = {
  parametros: {
    EMPRESA_DEFECTO: 80002,
    VERSION_BASE_DATOS: 50807,
    GOOGLE_MAPS_API_KEY: 82029,
    MOSTRAR_VALORACION_INTERVENCION: 82030,
    COPIAR_COMENTARIOS_A_ALBARAN: 80517,
    TMONTAJE_DEFECTO: 80500,
    TRAMOTIEMPO_SIG_FACT: 80502,
    TRAMOTIEMPO_1_FACT: 80503,
    COSTE_KILOMETRO: 80516,
    IDTTIEMPO_TRA_DEFECTO: 80519,
    IDARTICULO_DESPLAZAMIENTO: 80012,
    IDIOMA_DEFECTO: 80103,
    MANODEOBRA_DESPLAZAMIENTO_FACTURABLE_DEFECTO: 82049,
    MOSTRAR_TIEMPO_DESPLAZAMIENTO: 82050,
    PRESENCIA_PUNTO_ACCESO_OBLIGATORIO: 82045,
    TIPO_INCLUSION_DEFECTO: 82054,
    SUSTITUCION_NO_FACTURABLE_EN_GARANTIA: 82056,
    TIPO_TELEFONO_DEFECTO: 80340,
    INICIAR_DETENER_CRONOMETRO_TODOS: 82016,
    COMPORTAMIENTO_ACCION_REVISION: 82047,
    ALBARANAR_MATERIAL_PENDIENTE_INSTALACION: 80014,
    FILTRAR_ARTICULOS_POR_STOCK_TECNICO: 82063,
    PAIS_POR_DEFECTO: 80102,
    TIPO_CLIENTE_POR_DEFECTO: 80101,
    TIPO_SECTOR_POR_DEFECTO: 80117,
    TIPO_CANAL_POR_DEFECTO: 81200,
    ZONA_POR_DEFECTO: 80105,
    TIPO_DIRECCION_POR_DEFECTO: 80118,
    TIPO_VIA_POR_DEFECTO: 80108,
    TIPO_TARIFA_POR_DEFECTO: 80100,
    IDFORMA_PAGO_POR_DEFECTO: 80104,
    IDCONDICION_PAGO_POR_DEFECTO: 80112,
    IDAGENTE_COMERCIAL_POR_DEFECTO: 80308,
    IDPERIODO_CUOTA_POR_DEFECTO: 80317,
    IDEMPLEADO_REPRESENTANTE_EMPRESA_CONTRATOS: 80337,
    IDPRIORIDAD_DEFECTO_CRM: 80460,
    TAMANIO_MAX_ADJUNTOS_MB: 82067,
    AGREGAR_ANOMALIA_A_DEFINICION_CHECKLIST: 81152,
    SERIE_OPERACION_CRM_DEFECTO: 80450,
    TIEMPO_INACTIVIDAD_MINUTOS: 82072,
  }
}

export const COMPORTAMIENTO_ACCION_REVISION = {
  tipos: {
    marcarPreguntasCorrectas: 1,
    noPermitirRevisionSinContestarPreguntas: 2,
  }
}

export const APPS = {
  comerciales: 'comerciales',
  tecnicos: 'tecnicos',
  vigilantes: 'vigilantes',
  custodiaLlaves: 'custodiaLlaves',
  almacen: 'almacen',
  presencia: 'presencia',
  moduloApp: {
    comerciales: 5,
    tecnicos: 6,
    vigilantes: 7,
    custodiaLlaves: 8,
    almacen: 9,
    presencia: 12,
  },
  idmoduloApp: {
    5: 'comerciales',
    6: 'tecnicos',
    7: 'vigilantes',
    8: 'custodiaLlaves',
    9: 'almacen',
    12: 'presencia',
  },
  setApps: {
    comerciales: 'SET_APP_COMERCIALES',
    tecnicos: 'SET_APP_TECNICOS',
    vigilantes: 'SET_APP_VIGILANTES',
    custodiaLlaves: 'SET_APP_CUSTODIA_LLAVES',
    almacen: 'SET_APP_ALMACEN',
    presencia: 'SET_APP_PRESENCIA',
  }
}

export const API = {
  puerto: 9443
}

export const VIGILANTE = {
  tipos_relacion: [
    { id: 1, descripcion: 'Habitual' },
    { id: 2, descripcion: 'Cubre puesto' },
    { id: 3, descripcion: 'Conocedor' },
    { id: 4, descripcion: 'Excluído' }
  ],
  idtrelacion: {
    habitual: 1,
    cubre_puesto: 2,
    conocedor: 3,
    excluido: 4
  }
}

export const TCUADRANTE_NOLABORAL = {
  tipos: {
    libra: 1
  }
}

export const TACTUACION = {
  tipos: {
    instalacion: 1,
    revisionAnual: 2,
    averia: 3,
    revisionSemestral: 4,
    revisionTrimestral: 5,
  },
  descripciones: {
    1: 'Instalación',
    2: 'Revisión anual',
    3: 'Avería',
    4: 'Revisión semestral',
    5: 'Revisión trimestral',
  },
}

export const TDIA = {
  tipos_dia: [
    { id: 1, alias: 'Lun', descripcion: 'Lunes', color: colors.green.base },
    { id: 2, alias: 'Mar', descripcion: 'Martes', color: colors.green.base },
    { id: 3, alias: 'Mié', descripcion: 'Miércoles', color: colors.green.base },
    { id: 4, alias: 'Jue', descripcion: 'Jueves', color: colors.green.base },
    { id: 5, alias: 'Vie', descripcion: 'Viernes', color: colors.green.base },
    { id: 6, alias: 'Sáb', descripcion: 'Sábado', color: colors.red.base },
    { id: 7, alias: 'Dom', descripcion: 'Domingo', color: colors.red.base },
    { id: 8, alias: 'Fes', descripcion: 'Festivo', color: colors.red.base },
  ],
}

export const ORA = {
  errors: {
    ORA_APPLICATION_ERROR: 20001,
    ORA_INTEGRITY_CONSTRAINT: 2292,
    ORA_UNIQUE_CONSTRAINT: 1,
  }
}

export const CRONOMETRO = {
  para: {
    soloParaMi: 1,
    paraTodos: 2
  }
}

export const TFACTURACION_KILOMETROS = {
  tipos: [
    { id: 1, descripcion: 'No facturable' },
    { id: 2, descripcion: 'Facturar por kilómetros del técnico' },
    { id: 3, descripcion: 'Facturar por kilómetros de la zona' },
    { id: 4, descripcion: 'Facturar por artículos de la zona' }
  ],
  ids: {
    noFacturable: 1,
    kilometrosTecnico: 2,
    kilometrosZona: 3,
    articulosZona: 4
  }
}

export const SISTEMA = {
  idestados: {
    0: 'anulado',
    1: 'activo',
    2: 'baja'
  },
  estados: {
    anulado: 0,
    activo: 1,
    baja: 2,
  },
  colores: {
    anulado: colors.red.base,
    baja: colors.grey.base,
  },
  descripcionEstado: {
    anulado: 'anulado',
    activo: 'activo',
    baja: 'de baja',
  }
}

export const MATERIAL_SISTEMA = {
  estados: {
    sistema: 1,
    taller: 2
  },
  colores: {
    desinstalado: colors.orange.base,
  },
  plano: {
    texto: {
      fillStyle: 'black',
      font: '16px Roboto',
      textAlign: 'center',
      textBaseline: 'middle',
    }
  }
}

export const LLAVERO = {
  estados: {
    enTablero: 1,
    retirado: 2
  }
}

export const TALMACEN = {
  clasificaciones: {
    almacen: 1,
    taller: 2,
    vehiculo: 3,
    obra: 4,
  }
}

export const TINCLUSION = {
  instalacion: 1,
  suministro: 2,
  preguntar: 3
}

export const ARTICULO_ESCANDALLO = {
  tinclusion: {
    soloInstalacion: 1,
    soloSuministro: 2,
    instalacionYSuministro: 3,
    fabricacion: 4
  }
}

export const ARTICULO = {
  tipos: {
    simple: 0,
    ampliado: 1,
    plantilla: 2
  },
  estados: {
    anulado: 0,
    activo: 1,
    obsoleto: 2
  },
  colores: {
    manoDeObra: colors.blue.base,
  },
}

export const TIPO_CIERRE_SUBIR_PARTE = {
  tipos: {
    finalizarOT: 1,
    reasignarParte: 2,
    reasignarParteOtroTecnico: 4,
    mantenerOTPendiente: 3,
  },
  descripciones: {
    1: 'Finalizar orden de trabajo',
    2: 'Reasignarme orden de trabajo',
    4: 'Reasignar orden de trabajo a otro técnico',
    3: 'Mantener orden de trabajo pendiente',
  },
  permisos: {
    1: permissions.partes.finalizarParteYOt,
    2: permissions.ordenes.asignarAMi,
    4: permissions.ordenes.asignarAOtros,
    3: null,
  },
}

export const TIPO_QUITAR_MATERIAL_AFECTADO_PARTE = {
  quitarParte: {
    id: 1,
    descripcion: 'Solo del parte',
  },
  quitarParteOT: {
    id: 2,
    descripcion: 'Del parte y de la orden de trabajo',
  },
  quitarParteOTSistema: {
    id: 3,
    descripcion: 'Del parte, de la orden de trabajo y del sistema',
  },
}

export const DEUDA_CLIENTE = {
  idestados: {
    1: 'pendiente',
    2: 'cobrada',
    3: 'dudosoCobro',
    4: 'incobrable',
    5: 'parcialmenteCobrada',
  },
  estados: {
    pendiente: 1,
    cobrada: 2,
    dudosoCobro: 3,
    incobrable: 4,
    parcialmenteCobrada: 5,
  },
  descripciones: {
    pendiente: 'Pendiente',
    cobrada: 'Cobrada',
    dudosoCobro: 'Dudoso cobro',
    incobrable: 'Incobrable',
    parcialmenteCobrada: 'Parcialmente cobrada',
  },
  // se corresponden con los colores de la aplicación de escritorio
  colores: {
    pendiente: colors.blue.base,
    cobrada: colors.green.base,
    dudosoCobro: colors.amber.darken1,
    incobrable: colors.red.base,
    parcialmenteCobrada: colors.amber.lighten1,
  },
}

export const OBJETOS = {
  TFQRMDParteMontaje: {
    name: 'TFQRMDParteMontaje',
    idobjeto: 452346469,
  },
}

export const CLIENTE_CUENTA = {
  colores: {
    principal: colors.green.base
  }
}

export const ENTRADASALIDA = {
  caso: {
    enServicio: 1,
    otro: 2
  }
}

export const REPRESENTANTE = {
  colores: {
    principal: colors.green.base
  }
}

export const CLIENTE = {
  idestados: {
    0: 'anulado',
    1: 'activo',
    2: 'bloqueado',
  },
  estados: {
    anulado: 0,
    activo: 1,
    bloqueado: 2,
  },
  colores: {
    anulado: colors.red.darken2,
    bloqueado: colors.red.base,
  },
  descripcionEstado: {
    anulado: 'Anulado',
    activo: 'Activo',
    bloqueado: 'Bloqueado'
  }
}

export const CLIENTE_DIRECCION = {
  colores: {
    postal: colors.blue.base,
    fiscal: colors.blue.base,
    servicio: colors.blue.base,
  }
}

export const PRESUPUESTOCLI = {
  // Los estados en realidad son clasificacion de estados, ver v_est_presupuestocli_clasif
  clasificacionEstados: {
    anulado: 0,
    enElaboracion: 1,
    pendienteCliente: 2,
    aceptado: 3,
    firmado: 4,
    rechazado: 5,
  },
  clasificacionEstadosAceptados: [3, 4],
  clasificacionEstadosPendientes: [1, 2],
  clasificacionEstadosHabilitadosParaEstudioTecnico: [1],
  colores: {
    pendiente: colors.orange.base,
    aceptado: colors.green.base,
    rechazado: colors.red.base,
  },
  descripciones: {
    anulado: 'Anulado',
    pendienteEstudioTecnico: 'Pdte. estudio técnico',
    enEstudioTecnico: 'En estudio técnico',
    pendienteRevisionComercial: 'Pdte. revisión comercial',
    pendienteAceptacion: 'Pdte. aceptación',
    aceptado: 'Aceptado',
    rechazado: 'Rechazado',
  },
  estadosFinancieros: {
    noNecesitaAprobacion: 0,
    pendienteAprobacion: 1,
    aprobado: 2,
    rechazado: 3,
  },
  descripcionesEstadosFinancieros: {
    0: 'No necesita aprobación',
    1: 'Pendiente de aprobación',
    2: 'Aprobado',
    3: 'Rechazado',
  },
  coloresEstadosFinancieros: {
    0: colors.green.base,
    1: colors.orange.base,
    2: colors.green.base,
    3: colors.red.base,
  },
}

export const ESTUDIO_FINANCIERO = {
  resultado: {
    favorable: 1,
    noFavorable: 2,
  },
  descripciones: {
    1: 'Favorable',
    2: 'No favorable'
  },
  colores: {
    1: colors.green.base,
    2: colors.red.base,
  },
}

export const TDATO_TIPO_DATO = {
  tipos: {
    texto: 1,
    entero: 2,
    decimal: 3,
    fecha: 5,
    hora: 6,
    fechaHora: 7,
    listaValores: 8,
    firma: 9.
  },
  tiposNumber: [2, 3],
  tiposDate: [5, 6, 7],
  tiposString: [1, 8],
  formFieldNames: {
    tiposNumber: 'valor_numerico_',
    fechaHora: 'valor_fecha_hora_',
    fecha: 'valor_fecha_',
    hora: 'valor_hora_',
    tiposString: 'valor_',
    firma: 'valor_firma_',
  },
  // TODO: esto debería obtenerlo directamente de la base de datos.
  maxLength: {
    tiposNumber: 18.8,
    tiposString: 4000,
  }
}

export const CONTRATO = {
  colores: {
    firmado: colors.green.base,
    sinFirmar: colors.red.base,
  },
}

export const DOCUMENTO = {
  colores: {
    sinFicheroGenerado: colors.red.base,
    conFicheroGenerado: colors.green.base,
  },
  descripciones: {
    sinFicheroGenerado: 'Sin generar',
    conFicheroGenerado: 'Generado',
  },
}

export const SOBRE = {
  estados: {
    anulado: 0,
    abierto: 1,
    cerrado: 2,
    entregado: 3,

  },
  descripciones: {
    0: 'Anulado',
    1: 'Abierto',
    2: 'Cerrado',
    3: 'Entregado',
  },
  colores: {
    0: colors.green.darken1,
    1: colors.red.base,
    2: colors.yellow.darken2,
    3: colors.green.base,
  },
}

export const SOBRE_DESTINATARIO = {
  clasificacion: {
    normal: 1,
    cc: 2,
    cco: 3,
  },
  descripcion : {
    1:'Normal' ,
    2:'CC' ,
    3:'CCO' ,
  }
}

export const TINTEGRACION = {
  tipos: {
    firma: 1,
    email: 2,
    cra: 3,
  },
}

export const STOCK = {
  // (0: Controla stock, 1: No controla stock, 2: controla stock por nserie)
  valor: {
    controla: 0,
    noControla: 1,
    porNserie: 2,
  },
  descripciones: {
    0: 'Si',
    1: 'No',
    2: 'Por número de serie'
  },
  abreviaturas: {
    0: 'CS',
    1: 'NCS',
    2: 'SNS'
  },
  descripcionLarga: {
    0: 'Controla stock',
    1: 'No Controla stock',
    2: 'Controla stock por número de serie'
  },
}

export const OPERACIONCRM = {
  // (1:Baja, 2:Normal, 3:Alta, 4: Crítico)
  prioridad: {
    baja: 1,
    normal: 2,
    alta: 3,
    crítico: 4,
  },
  descripcionesPrioridad: {
    1: 'Baja',
    2: 'Normal',
    3: 'Alta',
    4: 'Crítico',
  },
  coloresPrioridad: {
    1: colors.grey.darken2,
    2: colors.green.darken2,
    3: colors.orange.base,
    4: colors.red.base,
  },
  colorEstado: {
    abierta: colors.green.base,
    cerrada: colors.red.base,
  },
}

export const CITA = {
  tipo: {
    crm: 1,
    otros: null,
  },
  estado: {
    anulado: 0,
    pendiente: 1,
    realizado: 2,
  },
  coloresEstado: {
    0: colors.red.lighten2,
    1: colors.grey.base,
    2: colors.green.base,
  },
  descripcionesEstado: {
    0: 'Anulada',
    1: 'Pendiente',
    2: 'Realizada',
  },
}

export const AGENDA = {
  tipo: {
    diario: 'day',
    semanal: 'week',
    mensual: 'month',
  },
  // Esto es para cuando un evento (ej un parte) no tiene fecha de fin, para que se muestre bien se le añaden estos segundos
  segundosExtra: 180,
}

export const DASHBOARD = {
  cantidadElementos: 3,
}

export const ACCIONES_A_REALIZAR = {
  values: {
    no_incluir: 0,
    incluir: 1,
    incluir_y_realizar: 2,
  },
  descripciones: {
    0: 'No',
    1: 'Si',
    2: 'Si y realizar',
  },
  fieldname: 'accion_a_realizar_idaccion_correctora_'
}
export const CLIENTE_TELEFONO = {
  colores: {
    contacto_sistema: colors.blue.base,
  }
}

export const VALOR_MOSTRAR_VALORACION_INTERVENCION = {
  no: 0,
  si: 1,
  // Si la forma de pago está disponible para movilidad entonces muestro la valoración
  forma_pago_movilidad: 2,
}

export const USUARIO = {
  minPasswordLength: 6,
}

export const TDATO_TDATO_SISTEMA = {
  tipos: {
    entero: 1,
    texto: 2,
    fecha: 3,
    decimal: 4,
    texto_largo: 5,
    texto_fijo: 6,
    booleano: 7,
  },
  tiposNumber: [1, 4],
  tiposDate: [3],
  tiposString: [2, 5, 6],
  tiposBoolean: [7],
  selectBoolean: ['Si', 'No'],
}

export const SOLICITUD_MATERIAL = {
  estados: {
    anulada: 0,
    pendienteValidacion: 1,
    pendienteAlmacen: 2,
    pendientePresupuestar: 3,
    cerrada: 4,
  },
  descripcionEstados: {
    0: 'Anulada',
    1: 'Pendiente de validación',
    2: 'Pendiente de almacén',
    3: 'Pendiente de presupuestar',
    4: 'Cerrada/Finalizada',
  },
  coloresEstado: {
    0: colors.red.base,
    1: colors.green.darken1,
    2: colors.green.darken2,
    3: colors.green.darken3,
    4: colors.grey.base,
  }
}

export const ALMACENES_PARTE = {
  colores: {
    sinStockDescargado: colors.red.lighten2
  }
}

export const FLUJO_CLASIFICACION = {
  ordenTrabajo: 2
}

export const TIPOS_MOVIMIENTO_CAJA = {
  // Esto viene de la tabla tmovcaja
  descripciones: {
    5: 'Ingreso manual',
    6: 'Gasto manual'
  },
  colores: {
    5: colors.green.base,
    6: colors.red.base,
  },
  tipos: {
    ingresoManual: 5,
    gastoManual: 6,
  },
  por_defecto: 6,
  // tmovcaja.tipo: 1: Sistema, 2: Manual de usuario, 3: Traspaso entre cuentas
  tipo_manual_usuario: 2,
}

export const GEOLOCALIZACION = {
  capturar: {
    no: 1,
    siEstaDisponible: 2,
    obligatoriamente: 3,
  }
}

// esto es de v_dato_integracion_clasif
export const INTEGRACION = {
  datos: {
    remitente: 15,
  },
}

export const BANCO_PRECIO = {
  prioridadDescuento: {
    linea: 1,
    grupo: 2,
  }
}

export const TYPE_EXTENSIONS = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv"
]
