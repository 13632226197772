import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class EmpleadoOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.nombre_y_apellido
  }
  subtitle (row) {
    let toReturn = row.email
    if (row.telefono1) {
      if (toReturn) {
        toReturn += ` - ${row.telefono1}`
      } else {
        toReturn = row.telefono1
      }
    }
    return toReturn
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addIContainsWords('nombre', value)
      .addIContainsWords('apellido1', value)
      .addIContainsWords('apellido2', value)
      .addIContainsWords('nombre_y_apellido', value)
      .addIContainsWords('email', value)
      .addIContainsWords('telefono1', value)
  }
  async row (idempleado) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
      .addExact('idempleado', idempleado)
    const resp = await this.Vue.$api.call('empleado.select', { filter: apiFilter })
    return resp.data.result.dataset
  }
}
