import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'

export default class SistemaOfflineResource extends BaseOfflineResource {
  async selectTsubsis (idsistema) {
    const tables = this.db.tables
    const sistema = await this.Vue.$offline.sistema.row(idsistema)
    return await this.Vue.$offline.db
      .select(
        ...this.Vue.$offline.tsubsis.columns,
      )
      .from(tables.tsubsis)
      .innerJoin(
        tables.tsistema_tsubsis,
        tables.tsubsis.idtsubsis.eq(tables.tsistema_tsubsis.idtsubsis))
      .where(
        this.Vue.$offline.db.op.and(
          tables.tsubsis.estado.gt(0),
          tables.tsistema_tsubsis.estado.gt(0),
          tables.tsistema_tsubsis.idtsistema.eq(sistema.sistema.idtsistema),
        )
      )
      .groupBy(
        ...this.Vue.$offline.tsubsis.columns,
      )
      .orderBy(tables.tsubsis.descripcion)
      .exec()
  }
  async selectCodigosSubsis(idsistema) {
    const tables = this.Vue.$offline.db.tables
    const subsis = await this.Vue.$offline.db
      .select()
      .from(tables.subsis)
      .where(
        this.Vue.$offline.db.op.and(
          tables.subsis.estado.gt(0),
          tables.subsis.idsistema.eq(idsistema),
        )
      )
      .exec()
    return _.map(subsis, 'codigo')
  }
}
