import {
  // format/parse
  format as fnsFormat,
  formatISO as fnsFormatISO,
  // dias
  endOfDay as fnsEndOfDay,
  startOfDay as fnsStartOfDay,
  setDay as fnsSetDay, // dayNames
  // meses
  startOfMonth as fnsStartOfMonth,
  lastDayOfMonth as fnsLastDayOfMonth,
  getDaysInMonth as fnsGetDaysInMonth,
  // partes de una fecha
  getDate as fnsGetDate, // Get the day of the month of the given date.
  getDay as fnsGetDay, // Get the day of the week of the given date.
  getMonth as fnsGetMonth,
  getYear as fnsGetYear,
  // operaciones
  addDays as fnsAddDays,
  addMonths as fnsAddMonths,
  differenceInMinutes as fnsDifferenceInMinutes,
  // comprobaciones
  isAfter as fnsIsAfter,
  isWeekend as fnsIsWeekend,
  isEqual as fnsIsEqual,
  isSameDay as fnsIsSameDay,
  addSeconds as fnsAddSeconds,
} from 'date-fns'
import esLocale from 'date-fns/locale/es'
import { pad } from '@/utils/format'

export const encodeDate = (year, month = 1, day = 1) => {
  if (day) {
    return new Date(year, month, day)
  } else if (month) {
    return new Date(year, month)
  } else {
    return new Date(year)
  }
}

export const currentDate = () => {
  return new Date(new Date().toDateString())
}

export const currentDateTime = () => {
  return new Date()
}

// format/parse
export const toDate = (date) => {
  let toReturn = date
  if (typeof date === 'string') {
    toReturn = new Date(date)
  }
  return new Date(toReturn.toDateString())
}

export const toDateTime = (date) => {
  if (typeof date === 'string') {
    return new Date(date)
  } else {
    return date
  }
}

export const format = (date, formatStr) => {
  return fnsFormat(date, formatStr, { locale: esLocale })
}

export const formatISO = (date) => {
  return fnsFormatISO(date)
}

export const parseHHmm = (timeString, backupDate) => {
  if (timeString && backupDate) {
    const hours = parseInt(timeString.substring(0, 2))
    const minutes = parseInt(timeString.substring(3, 5))
    return new Date(backupDate.setHours(hours, minutes, 0, 0))
  } else {
    return null
  }
}

// dias
export const endOfDay = (date) => {
  return fnsEndOfDay(date)
}

export const startOfDay = (date) => {
  return fnsStartOfDay(date)
}

export const dayNames = (short = true) => {
  const SUNDAY = 0
  const FORMAT = short ? 'EEE' : 'EEEE'
  let currentDate = fnsSetDay(new Date(), SUNDAY)
  let days = []
  for (let i = 0; i < 7; i++) {
    days.push(fnsFormat(currentDate, FORMAT, { locale: esLocale }))
    currentDate = fnsAddDays(currentDate, 1)
  }
  return days
}

// meses
export const firstDayOfMonth = (date) => {
  return fnsStartOfMonth(date)
}

export const lastDayOfMonth = (date) => {
  return fnsLastDayOfMonth(date)
}

export const getDaysInMonth = (date) => {
  return fnsGetDaysInMonth(date)
}

// partes de una fecha
export const getDay = (date) => {
  return fnsGetDate(date)
}

export const getWeekDay = (date) => {
  return fnsGetDay(date)
}

export const getMonth = (date) => {
  return fnsGetMonth(date) + 1
}

export const getYear = (date) => {
  return fnsGetYear(date)
}

// operaciones
export const addDays = (date, amount) => {
  return fnsAddDays(date, amount)
}

export const addMonths = (date, amount) => {
  return fnsAddMonths(date, amount)
}

export const addSeconds = (date, seconds) => {
  return fnsAddSeconds(date, seconds)
}

export const differenceInMinutes = (date1, date2) => {
  return fnsDifferenceInMinutes(date1, date2)
}

export const diff = (date1, date2) => {
  return date1.getTime() - date2.getTime()
}

// comprobaciones
export const isAfter = (date, dateToCompare) => {
  return fnsIsAfter(date, dateToCompare)
}

export const isWeekend = (date) => {
  return fnsIsWeekend(date)
}

export const isEqual = (date1, date2) => {
  return fnsIsEqual(date1, date2)
}

export const isSameDay = (date1, date2) => {
  return fnsIsSameDay(date1, date2)
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

// utilidades
export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const msToHHmm = (ms) => {
  let seconds = Math.floor(ms / 1000)
  const hours = parseInt(Math.floor(seconds / 3600))
  seconds = seconds % 3600
  const minutes = parseInt(Math.floor(seconds / 60))
  return `${pad(hours, 2, '0')}:${pad(minutes, 2, '0')}`
}

export const combinarDateTime = (date, time) => {
  return new Date(date + ' ' + time)
}

export const currentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  // Formatea las partes de la hora para asegurar que siempre tengan dos dígitos
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  // Devuelve la hora en formato HH:MM:SS
  return `${formattedHours}:${formattedMinutes}`;
}