import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'
import { GRADO_ANOMALIA, TINCLUSION, PREGUNTA_CHECKLIST, CHECKLIST, ACCION_EJEC, MACCION, GRUPO_CHECKLIST } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'

export default class ChecklistOtOfflineResource extends BaseOfflineResource {
  async updateChecklistContestadoCorrecto (idparteTrabajo, idchecklistOt, idtecnico) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'checklistOt.updateSyncCorrecto',
      params:
        {
          idparte_trabajo: idparteTrabajo,
          idchecklist_ot: idchecklistOt,
          idtecnico: idtecnico
        }
    })
    // marcar preguntas correctas
    const inPreguntasGeneral = await this.Vue.$offline.preguntaChecklistOt.inPreguntasGeneral(idchecklistOt)
    const inPreguntasSubsistema = await this.Vue.$offline.preguntaChecklistOt.inPreguntasSubsistema(idparteTrabajo, idchecklistOt)
    const inPreguntasMaterial = await this.Vue.$offline.preguntaChecklistOt.inPreguntasMaterial(idparteTrabajo, idchecklistOt)
    const gradoOk = await this.Vue.$offline.gradoAnomalia.selectGradoAnomaliaCorrecto()
    const gradoAnomaliaOk = gradoOk[0]
    // marca solo en local cada pregunta porque en remoto marca el checklist completo
    await this.db
      .update(tables.pregunta_checklist_ot)
      .set(tables.pregunta_checklist_ot.idgrado_anomalia, gradoAnomaliaOk.idgrado_anomalia)
      .set(tables.pregunta_checklist_ot.idtecnico, idtecnico)
      .set(tables.pregunta_checklist_ot.resultado, PREGUNTA_CHECKLIST.resultado.ok)
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idgrado_anomalia.isNull(),
          this.db.op.or(
            tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(inPreguntasGeneral),
            tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(inPreguntasSubsistema),
            tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(inPreguntasMaterial)
          )
        )
      )
      .exec()
    // marcar acciones de revisión pendientes -> realizadas
    const clasificacionesRevision = [
      MACCION.clasificacion.revision_anual,
      MACCION.clasificacion.revision_semestral,
      MACCION.clasificacion.revision_trimestral,
      MACCION.clasificacion.revision_mensual,
    ]
    const revisiones = await this.db
      .select(
        tables.accion_ejec.idaccion_ejec,
        tables.accion_ejec.idmaccion,
        tables.accion_ejec.facturable,
        tables.accion_ejec.idest_accion_ejec,
        tables.maccion.clasificacion,
        tables.maccion.orden,
        this.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('dummy')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        this.db.op.and(
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
          tables.maccion.clasificacion.in(clasificacionesRevision),
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .groupBy(
        tables.accion_ejec.idaccion_ejec,
        tables.accion_ejec.idmaccion,
        tables.accion_ejec.facturable,
        tables.accion_ejec.idest_accion_ejec,
        tables.maccion.clasificacion,
        tables.maccion.orden
      )
      .orderBy(tables.maccion.orden)
      .exec()
    for (const revision of revisiones) {
      await this.Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
        revision.accion_ejec.idaccion_ejec,
        idchecklistOt,
        revision.accion_ejec.facturable,
        null,
        TINCLUSION.suministro,
        idtecnico,
        idparteTrabajo
      )
    }
    await this.Vue.$offline.checklistOt.updateTecnicoPreguntasArrastradas(idchecklistOt, idtecnico)
  }
  async updateTecnicoPreguntasArrastradas (idchecklistOt, idtecnico) {
    const tables = this.db.tables
    // preguntas arrastradas desde otro checklist
    // - sin técnico
    // - con anomalías
    const preguntasSinTecnico = await this.db
      .select(
        this.db.fn.distinct(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('idpregunta_checklist_ot')
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.pregunta_checklist_ot.idtecnico.isNull(),
        )
      )
      .exec()
    for (const preguntaSinTecnico of preguntasSinTecnico) {
      const pregunta = _.cloneDeep(await this.Vue.$offline.preguntaChecklistOt.row(preguntaSinTecnico.idpregunta_checklist_ot))
      pregunta.pregunta_checklist_ot.idtecnico = idtecnico
      await this.Vue.$offline.preguntaChecklistOt.updateSync(pregunta.pregunta_checklist_ot)
    }
  }
  async selectPartesRelacionados (idchecklistOt) {
    const tables = this.db.tables
    const partes = this.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.checklist_ot.idchecklist_ot.eq(idchecklistOt)
        )
      )
      .exec()
    return partes
  }
  selectAnomalias (idchecklistOt) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.estado.gt(0),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.tanomalia_checklist_ot.estado.gt(0),
        )
      )
      .exec()
  }
  selectDesconocidas (idchecklistOt) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grado_anomalia,
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.estado.gt(0),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.desconocido),
        )
      )
      .exec()
  }
  async finalizarChecklistOt (values) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'checklistOt.finalizarSync',
      params:
      {
        idchecklist_ot: values.idchecklist_ot,
        estado: values.estado,
        fchecklist: values.fchecklist,
        observaciones: values.observaciones
      }
    })
    this.db
      .update(tables.checklist_ot)
      .set(tables.checklist_ot.fchecklist, values.fchecklist)
      .set(tables.checklist_ot.observaciones, values.observaciones)
      .set(tables.checklist_ot.estado, values.estado)
      .where(tables.checklist_ot.idchecklist_ot.eq(values.idchecklist_ot))
      .exec()
  }
  async reabrirChecklistOt (idchecklistOt) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'checklistOt.reabrirSync',
      params:
      {
        idchecklist_ot: idchecklistOt,
        estado: CHECKLIST.estados.pendiente
      }
    })
    this.db
      .update(tables.checklist_ot)
      .set(tables.checklist_ot.fchecklist, null)
      .set(tables.checklist_ot.firmante, null)
      .set(tables.checklist_ot.firmante_dni, null)
      .set(tables.checklist_ot.firmante_cargo, null)
      .set(tables.checklist_ot.firma, null)
      .set(tables.checklist_ot.ffirma, null)
      .set(tables.checklist_ot.estado, CHECKLIST.estados.pendiente)
      .where(tables.checklist_ot.idchecklist_ot.eq(idchecklistOt))
      .exec()
  }
  async idchecklistOt (idordenTrabajo) {
    const tables = this.db.tables
    return (await this.db
      .select(this.db.fn.max(tables.checklist_ot.idchecklist_ot).as('idchecklist_ot'))
      .from(tables.orden_trabajo)
      .innerJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo),
          tables.checklist_ot.estado.gt(0)
        )
      )
      .exec()
    )[0].idchecklist_ot
  }
  async annadirPreguntasFaltan (idordenTrabajo) {
    const tables = this.db.tables
    const selectExisteGrupoChecklistOt = async (idchecklistOt, idgrupoChecklist, idchecklistOtSubsis) => {
      return await this.db
        .select(
          this.db.fn.max(tables.grupo_checklist_ot.idgrupo_checklist_ot).as('idgrupo_checklist_ot')
        )
        .from(tables.grupo_checklist_ot)
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
            tables.grupo_checklist_ot.idgrupo_checklist.eq(idgrupoChecklist),
            tables.grupo_checklist_ot.estado.gt(0),
            tables.grupo_checklist_ot.idchecklist_ot_subsis.eq(idchecklistOtSubsis)
          )
        )
        .exec()
    }
    const selectPreguntasChecklist = async (idgrupoChecklist) => {
      const preguntaChecklistRes = this.Vue.$offline.preguntaChecklist
      return await this.db
        .select()
        .from(tables.pregunta_checklist)
        .innerJoin(preguntaChecklistRes.dummyTable, preguntaChecklistRes.pk.eq(preguntaChecklistRes.dummyPk))
        .where(
          this.db.op.and(
            tables.pregunta_checklist.idgrupo_checklist.eq(idgrupoChecklist),
            tables.pregunta_checklist.estado.gt(0)
          )
        )
        .exec()
    }
    const selectGrupoChecklistOtCantPreguntasSubsis = async (idgrupoChecklistOt) => {
      return (await this.db
        .select(this.db.fn.count(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('cantidad'))
        .from(tables.pregunta_checklist_ot)
        .innerJoin(
          tables.grupo_checklist_ot,
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
        )
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.subsistemas),
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
            tables.pregunta_checklist_ot.idorden_trabajo_matsist.isNull(),
            tables.pregunta_checklist_ot.estado.gt(0)
          )
        )
        .exec()
      )[0].cantidad
    }
    const selectGrupoChecklistOtCantPreguntasMaterial = async (idgrupoChecklistOt, idordenTrabajoMatSist) => {
      return (await this.db
        .select(this.db.fn.count(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('cantidad'))
        .from(tables.pregunta_checklist_ot)
        .innerJoin(
          tables.grupo_checklist_ot,
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
        )
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.materiales),
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
            tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatSist),
            tables.pregunta_checklist_ot.estado.gt(0)
          )
        )
        .exec()
      )[0].cantidad
    }
    const insertGrupoChecklistOt = async (idchecklistOt, idgrupoChecklist, idchecklistOtSubsis) => {
      const grupoChecklist = await this.Vue.$offline.grupoChecklist.row(idgrupoChecklist)
      const idgrupoChecklistOt = (await this.Vue.$offline.grupoChecklistOt.insertSync({
        idgrupo_checklist_ot: uuidv4(),
        estado: 1,
        clasificacion: grupoChecklist.grupo_checklist.clasificacion,
        descripcion: grupoChecklist.grupo_checklist.descripcion,
        orden: grupoChecklist.grupo_checklist.orden,
        idchecklist_ot: idchecklistOt,
        idgrupo_checklist: idgrupoChecklist,
        idchecklist_ot_subsis: idchecklistOtSubsis,
        clasif_inventario: grupoChecklist.grupo_checklist.clasif_inventario,
        idlnormativa: grupoChecklist.grupo_checklist.idlnormativa,
      }))[0].idgrupo_checklist_ot
      return await this.Vue.$offline.grupoChecklistOt.row(idgrupoChecklistOt)
    }
    const insertValoresColumnaOt = async (idagrupacionPreguntaOt, idagrupacionPregunta, idpreguntaChecklistOt) => {
      const valorColumnaDummy = this.Vue.$offline.valorColumna
      const definicionValorColumna = await this.db
        .select()
        .from(tables.valor_columna)
        .innerJoin(valorColumnaDummy.dummyTable, valorColumnaDummy.pk.eq(valorColumnaDummy.dummyPk))
        .where(
          this.db.op.and(
            tables.valor_columna.idagrupacion_pregunta.eq(idagrupacionPregunta),
            tables.valor_columna.estado.gt(0)
          )
        )
        .orderBy(tables.valor_columna.orden)
        .exec()
      for (const valorColumna of definicionValorColumna) {
        await this.Vue.$offline.valorColumnaOt.insertSync({
          idvalor_columna_ot: uuidv4(),
          estado: 1,
          descripcion: valorColumna.valor_columna.descripcion,
          clasificacion: valorColumna.valor_columna.clasificacion,
          idagrupacion_pregunta_ot: idagrupacionPreguntaOt,
          idvalor_columna: valorColumna.valor_columna.idvalor_columna,
          orden: valorColumna.valor_columna.orden,
          valor: null,
          idpregunta_checklist_ot: idpreguntaChecklistOt,
        })
      }
    }
    const insertAgrupacionPreguntaOt = async (idgrupoChecklistOt, idagrupacionPregunta) => {
      // Busca y si no existe crea la agrupación de preguntas por grupo de checklist,
      // recordatorio: se da de alta una única vez la agrupación por grupo de preguntas
      let agrupacionPreguntaOt = await this.db
        .select()
        .from(tables.agrupacion_pregunta_ot)
        .where(
          this.db.op.and(
            tables.agrupacion_pregunta_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
            tables.agrupacion_pregunta_ot.idagrupacion_pregunta.eq(idagrupacionPregunta),
            tables.agrupacion_pregunta_ot.estado.gt(0),
          )
        )
        .exec()
      if (agrupacionPreguntaOt.length === 0) {
        const agrupacionPregunta = await this.Vue.$offline.agrupacionPregunta.row(idagrupacionPregunta)
        agrupacionPreguntaOt = await this.Vue.$offline.agrupacionPreguntaOt.insertSync({
          idagrupacion_pregunta_ot: uuidv4(),
          estado: 1,
          idgrupo_checklist_ot: idgrupoChecklistOt,
          idagrupacion_pregunta: agrupacionPregunta.agrupacion_pregunta.idagrupacion_pregunta,
          codigo: agrupacionPregunta.agrupacion_pregunta.codigo,
          descripcion: agrupacionPregunta.agrupacion_pregunta.descripcion,
          idtipo: agrupacionPregunta.agrupacion_pregunta.idtipo,
          orden: agrupacionPregunta.agrupacion_pregunta.orden,
        })
      }
      return (agrupacionPreguntaOt[0].idagrupacion_pregunta_ot)
    }
    const actualizarPreguntaChecklistOtAgrupacionPreguntaOt = async (idpreguntaChecklistOt, idagrupacionPreguntaOt) => {
      // Actualiza la pregunta de checklist con la agrupación de las preguntas, porque la preguntas se crea antes
      // recordatorio: se crea antes la pregunta porque hay que rellenar el campo valor_columna_ot.idpregunta_checklist_ot
      const pregunta = _.cloneDeep(await this.Vue.$offline.preguntaChecklistOt.row(idpreguntaChecklistOt))
      pregunta.pregunta_checklist_ot.idagrupacion_pregunta_ot = idagrupacionPreguntaOt
      await this.Vue.$offline.preguntaChecklistOt.updateSync(pregunta.pregunta_checklist_ot)
    }
    const insertPreguntaChecklistOt = async (idgrupoChecklistOt, idpreguntaChecklist, idordenTrabajoMatSist) => {
      const preguntaChecklist = await this.Vue.$offline.preguntaChecklist.row(idpreguntaChecklist)
      const idpreguntaChecklistOt = (await this.Vue.$offline.preguntaChecklistOt.insertSync({
        idpregunta_checklist_ot: uuidv4(),
        estado: 1,
        descripcion: preguntaChecklist.pregunta_checklist.descripcion,
        clasificacion: preguntaChecklist.pregunta_checklist.clasificacion,
        orden: preguntaChecklist.pregunta_checklist.orden,
        idgrupo_checklist_ot: idgrupoChecklistOt,
        requerido: preguntaChecklist.pregunta_checklist.requerido,
        idorden_trabajo_matsist: idordenTrabajoMatSist,
        alias: preguntaChecklist.pregunta_checklist.alias,
        determina_resultado_material: preguntaChecklist.pregunta_checklist.determina_resultado_material,
        idpregunta_checklist: idpreguntaChecklist,
        texto_incidencia: preguntaChecklist.pregunta_checklist.texto_incidencia,
        resultado: null,
        idgrado_anomalia: null,
      }))[0].idpregunta_checklist_ot
      if (preguntaChecklist.pregunta_checklist.idagrupacion_pregunta) {
        const idagrupacionPreguntaOt = await insertAgrupacionPreguntaOt(
          idgrupoChecklistOt,
          preguntaChecklist.pregunta_checklist.idagrupacion_pregunta
        )
        await insertValoresColumnaOt(
          idagrupacionPreguntaOt,
          preguntaChecklist.pregunta_checklist.idagrupacion_pregunta,
          idpreguntaChecklistOt
        )
        actualizarPreguntaChecklistOtAgrupacionPreguntaOt(idpreguntaChecklistOt, idagrupacionPreguntaOt)
      }
    }
    // obtener checklist activo de la OT
    const idchecklistOt = await this.Vue.$offline.checklistOt.idchecklistOt(idordenTrabajo)
    if (idchecklistOt) {
      const checklistOt = await this.Vue.$offline.checklistOt.row(idchecklistOt)
      // obtener subsistemas de la orden de trabajo
      const ordenTrabajoSubsis = await this.db
        .select()
        .from(tables.orden_trabajo_subsis)
        .innerJoin(
          tables.subsis,
          tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
        )
        .where(
          this.db.op.and(
            tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo),
            tables.orden_trabajo_subsis.estado.gt(0),
          )
        )
        .exec()
      for (const otSubsis of ordenTrabajoSubsis) {
        // comprobar si existe el subsistema correspondiente en checklist_ot_subsis
        const existeChecklistOtSubsis = await this.db
          .select(
            this.db.fn.max(tables.checklist_ot_subsis.idchecklist_ot_subsis).as('idchecklist_ot_subsis')
          )
          .from(tables.checklist_ot_subsis)
          .where(
            this.db.op.and(
              tables.checklist_ot_subsis.idchecklist_ot.eq(idchecklistOt),
              tables.checklist_ot_subsis.idsubsis.eq(otSubsis.orden_trabajo_subsis.idsubsis)
            )
          )
          .exec()
        let idchecklistOtSubsis = null
        if (existeChecklistOtSubsis[0].idchecklist_ot_subsis) {
          idchecklistOtSubsis = existeChecklistOtSubsis[0].idchecklist_ot_subsis
        } else {
          // insertar subsistema en checklist_ot_subsis
          idchecklistOtSubsis = (await this.Vue.$offline.checklistOtSubsis.insertSync({
            idchecklist_ot_subsis: uuidv4(),
            estado: 1,
            idchecklist_ot: idchecklistOt,
            idsubsis: otSubsis.orden_trabajo_subsis.idsubsis
          }))[0].idchecklist_ot_subsis
        }
        // obtener grupos preguntas de subsistemas en la definición del checklist
        const grupoChecklistRes = this.Vue.$offline.grupoChecklist
        const gruposChecklistSubsis = await this.db
          .select()
          .from(tables.grupo_checklist)
          .innerJoin(grupoChecklistRes.dummyTable, grupoChecklistRes.pk.eq(grupoChecklistRes.dummyPk))
          .where(
            this.db.op.and(
              tables.grupo_checklist.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.subsistemas),
              tables.grupo_checklist.idchecklist.eq(checklistOt.checklist_ot.idchecklist),
              tables.grupo_checklist.idtsubsis.eq(otSubsis.subsis.idtsubsis),
              tables.grupo_checklist.estado.gt(0)
            )
          )
          .exec()
        for (const grupoChecklistSubsis of gruposChecklistSubsis) {
          // comprobar si existe el grupo de preguntas correspondiente en grupo_checklist_ot
          const existeGrupoSubsisChecklistOt = await selectExisteGrupoChecklistOt(
            idchecklistOt, grupoChecklistSubsis.grupo_checklist.idgrupo_checklist, idchecklistOtSubsis
          )
          let idgrupoSubsisChecklistOt = null
          if (existeGrupoSubsisChecklistOt[0].idgrupo_checklist_ot) {
            idgrupoSubsisChecklistOt = existeGrupoSubsisChecklistOt[0].idgrupo_checklist_ot
          } else {
            // insertar grupo de preguntas de subsistema en grupo_checklist_ot
            idgrupoSubsisChecklistOt = (await insertGrupoChecklistOt(
              idchecklistOt, grupoChecklistSubsis.grupo_checklist.idgrupo_checklist, idchecklistOtSubsis
            )).grupo_checklist_ot.idgrupo_checklist_ot
          }
          const grupoChecklistOt = await this.Vue.$offline.grupoChecklistOt.row(idgrupoSubsisChecklistOt)
          // comprobar si el grupo tiene preguntas
          const grupoChecklistOtCantPreguntas = await selectGrupoChecklistOtCantPreguntasSubsis(idgrupoSubsisChecklistOt)
          if (grupoChecklistOtCantPreguntas === 0) {
            // insertar preguntas en pregunta_checklist_ot
            const preguntasChecklistSubsis = await selectPreguntasChecklist(
              grupoChecklistOt.grupo_checklist_ot.idgrupo_checklist
            )
            for (const preguntaChecklistSubsis of preguntasChecklistSubsis) {
              await insertPreguntaChecklistOt(
                idgrupoSubsisChecklistOt,
                preguntaChecklistSubsis.pregunta_checklist.idpregunta_checklist,
                null
              )
            }
          }
        }
        // obtener material del subsistema
        const materialesSubsis = await this.db
          .select()
          .from(tables.orden_trabajo_matsist)
          .innerJoin(
            tables.material_sistema,
            tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
          )
          .innerJoin(
            tables.subsis,
            tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
          )
          .innerJoin(
            tables.articulo,
            tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
          )
          .where(
            this.db.op.and(
              tables.orden_trabajo_matsist.idorden_trabajo.eq(idordenTrabajo),
              tables.material_sistema.idsubsis.eq(otSubsis.orden_trabajo_subsis.idsubsis),
              tables.orden_trabajo_matsist.estado.gt(0),
            )
          )
          .exec()
        for (const materialSubsis of materialesSubsis) {
          // obtener grupos preguntas de material en la definición del checklist
          const gruposChecklistMaterial = await this.db
            .select()
            .from(tables.grupo_checklist)
            .innerJoin(
              tables.grupo_checklist_subfamilia,
              tables.grupo_checklist.idgrupo_checklist.eq(tables.grupo_checklist_subfamilia.idgrupo_checklist)
            )
            .where(
              this.db.op.and(
                tables.grupo_checklist.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.materiales),
                tables.grupo_checklist.idchecklist.eq(checklistOt.checklist_ot.idchecklist),
                tables.grupo_checklist_subfamilia.idsubfamilia.eq(materialSubsis.articulo.idsubfamilia),
                tables.grupo_checklist.estado.gt(0),
                this.db.op.or(
                  tables.grupo_checklist.idtsubsis.isNull(),
                  tables.grupo_checklist.idtsubsis.eq(materialSubsis.subsis.idtsubsis),
                ),
              )
            )
            .exec()
          for (const grupoChecklistMaterial of gruposChecklistMaterial) {
            // comprobar si existe el grupo de preguntas correspondiente en grupo_checklist_ot
            const existeGrupoMaterialChecklistOt = await selectExisteGrupoChecklistOt(
              idchecklistOt, grupoChecklistMaterial.grupo_checklist.idgrupo_checklist, idchecklistOtSubsis
            )
            let idgrupoMaterialChecklistOt = null
            if (existeGrupoMaterialChecklistOt[0].idgrupo_checklist_ot) {
              idgrupoMaterialChecklistOt = existeGrupoMaterialChecklistOt[0].idgrupo_checklist_ot
            } else {
              // insertar grupo de preguntas de material en grupo_checklist_ot
              idgrupoMaterialChecklistOt = (await insertGrupoChecklistOt(
                idchecklistOt, grupoChecklistMaterial.grupo_checklist.idgrupo_checklist, idchecklistOtSubsis
              )).grupo_checklist_ot.idgrupo_checklist_ot
            }
            const grupoChecklistOt = await this.Vue.$offline.grupoChecklistOt.row(idgrupoMaterialChecklistOt)
            // comprobar si el grupo tiene preguntas
            const grupoChecklistOtCantPreguntas = await selectGrupoChecklistOtCantPreguntasMaterial(
              idgrupoMaterialChecklistOt, materialSubsis.orden_trabajo_matsist.idorden_trabajo_matsist
            )
            if (grupoChecklistOtCantPreguntas === 0) {
              // insertar preguntas en pregunta_checklist_ot
              const preguntasChecklistMaterial = await selectPreguntasChecklist(
                grupoChecklistOt.grupo_checklist_ot.idgrupo_checklist
              )
              for (const preguntaChecklistMaterial of preguntasChecklistMaterial) {
                await insertPreguntaChecklistOt(
                  idgrupoMaterialChecklistOt,
                  preguntaChecklistMaterial.pregunta_checklist.idpregunta_checklist,
                  materialSubsis.orden_trabajo_matsist.idorden_trabajo_matsist
                )
              }
            }
          }
        }
      }
      // borrar checklist_ot_subsis vacíos
      const inChecklistOtSubsisUsados = _.map(
        await this.db
          .select(
            this.db.fn.distinct(tables.grupo_checklist_ot.idchecklist_ot_subsis).as('idchecklist_ot_subsis')
          )
          .from(tables.grupo_checklist_ot)
          .where(
            this.db.op.and(
              tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
              tables.grupo_checklist_ot.estado.gt(0),
              tables.grupo_checklist_ot.idchecklist_ot_subsis.isNotNull(),
            )
          )
          .exec(),
        'idchecklist_ot_subsis'
      )
      const checklistOtSubsisVacios = await this.db
        .select()
        .from(tables.checklist_ot_subsis)
        .where(
          this.db.op.and(
            tables.checklist_ot_subsis.idchecklist_ot.eq(idchecklistOt),
            this.db.op.not(
              tables.checklist_ot_subsis.idchecklist_ot_subsis.in(inChecklistOtSubsisUsados)
            ),
          )
        )
        .exec()
      for (const checklistOtSubsisVacio of checklistOtSubsisVacios) {
        await this.Vue.$offline.checklistOtSubsis.deleteSync(checklistOtSubsisVacio.idchecklist_ot_subsis)
      }
    }
  }
  async borrarPreguntasSubsis (idordenTrabajo, idsubsis) {
    const tables = this.db.tables
    const idchecklistOt = await this.Vue.$offline.checklistOt.idchecklistOt(idordenTrabajo)
    const checklistOtSubsisRes = this.Vue.$offline.checklistOtSubsis
    const checklistOtSubsis = await this.db
      .select()
      .from(tables.checklist_ot_subsis)
      .innerJoin(checklistOtSubsisRes.dummyTable, checklistOtSubsisRes.pk.eq(checklistOtSubsisRes.dummyPk))
      .where(
        this.db.op.and(
          tables.checklist_ot_subsis.idchecklist_ot.eq(idchecklistOt),
          tables.checklist_ot_subsis.idsubsis.eq(idsubsis)
        )
      )
      .exec()
    if (checklistOtSubsis.length > 0) {
      const idchecklistOtSubsis = checklistOtSubsis[0].checklist_ot_subsis.idchecklist_ot_subsis
      const grupoChecklistOtRes = this.Vue.$offline.grupoChecklistOt
      const gruposChecklistSubsis = await this.db
        .select()
        .from(tables.grupo_checklist_ot)
        .innerJoin(grupoChecklistOtRes.dummyTable, grupoChecklistOtRes.pk.eq(grupoChecklistOtRes.dummyPk))
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
            tables.grupo_checklist_ot.estado.gt(0),
            tables.grupo_checklist_ot.idchecklist_ot_subsis.eq(idchecklistOtSubsis),
          )
        )
        .exec()
      for (const grupoChecklistSubsis of gruposChecklistSubsis) {
        const idgrupoChecklistOt = grupoChecklistSubsis.grupo_checklist_ot.idgrupo_checklist_ot
        const preguntaChecklistOtRes = this.Vue.$offline.preguntaChecklistOt
        const preguntasChecklistOt = await this.db
          .select()
          .from(tables.pregunta_checklist_ot)
          .innerJoin(preguntaChecklistOtRes.dummyTable, preguntaChecklistOtRes.pk.eq(preguntaChecklistOtRes.dummyPk))
          .where(
            this.db.op.and(
              tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
              tables.pregunta_checklist_ot.estado.gt(0),
            )
          )
          .exec()
        for (const preguntaChecklistOt of preguntasChecklistOt) {
          // borrar pregunta_checklist_ot
          this.Vue.$offline.preguntaChecklistOt.deleteSync(
            preguntaChecklistOt.pregunta_checklist_ot.idpregunta_checklist_ot
          )
        }
        // borrar grupo_checklist_ot
        this.Vue.$offline.grupoChecklistOt.deleteSync(idgrupoChecklistOt)
      }
      // borrar checklist_ot_subsis
      this.Vue.$offline.checklistOtSubsis.deleteSync(idchecklistOtSubsis)
    }
  }
  async resultado (idchecklistOt) {
    // alguna anomalía  -> GRUPO_CHECKLIST.resultado.conAnomalias
    const anomalias = await this.Vue.$offline.checklistOt.selectAnomalias(idchecklistOt)
    if (anomalias.length > 0) {
      return GRUPO_CHECKLIST.resultado.conAnomalias
    } else {
      // preguntas desconocidas implican incorrecto
      const desconocidas = await this.Vue.$offline.checklistOt.selectDesconocidas(idchecklistOt)
      if (desconocidas.length > 0) {
        return GRUPO_CHECKLIST.resultado.conAnomalias
      } else {
        // todas ok -> GRUPO_CHECKLIST.resultado.ok
        // preguntas sin contestar -> GRUPO_CHECKLIST.resultado.sinContestar
        // sino (combinación de ok/no procede) -> GRUPO_CHECKLIST.resultado.ok
        const tables = this.db.tables
        const preguntas = await this.db
          .select()
          .from(tables.pregunta_checklist_ot)
          .leftOuterJoin(
            tables.grado_anomalia,
            tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
          )
          .innerJoin(
            tables.grupo_checklist_ot,
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          )
          .where(
            this.db.op.and(
              tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
              tables.grupo_checklist_ot.estado.gt(0),
              tables.pregunta_checklist_ot.estado.gt(0),
            )
          )
          .exec()
        const preguntasOk = _.filter(preguntas, (value) => {
          return (value.pregunta_checklist_ot.resultado === PREGUNTA_CHECKLIST.resultado.ok)
        })
        if (preguntas.length === preguntasOk.length) {
          return GRUPO_CHECKLIST.resultado.ok
        } else {
          const preguntasSinContestar = _.filter(preguntas, (value) => {
            return (value.pregunta_checklist_ot.resultado === null)
          })
          if (preguntasSinContestar.length > 0) {
            return GRUPO_CHECKLIST.resultado.sinContestar
          } else {
            return GRUPO_CHECKLIST.resultado.ok
          }
        }
      }
    }
  }
  async selectChecklistOtDeIdOrdenTrabajo (idordenTrabajo) {
    const tables = this.db.tables
    return (await this.db
      .select()
      .from(tables.checklist_ot)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.db.op.and(
          tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo),
          tables.checklist_ot.estado.gt(0),
        )
      )
      .exec())[0]
  }
}
