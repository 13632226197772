import _ from '@/utils/lodash'
import { v4 as uuidv4 } from 'uuid'
import BaseOfflineResource from '@/offline/base'

export default class SubsisOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    const allowedRemoteParams = [
      'idsubsis',
      'idsistema',
      'idtsubsis',
      'codigo',
      'descripcion',
      'estado',
    ]
    const remoteValues = _.cloneDeep(values)
    const sistema = await this.Vue.$offline.sistema.row(values.idsistema)
    const tsubsis = await this.Vue.$offline.tsubsis.row(values.idtsubsis)
    if (!remoteValues.codigo) {
      remoteValues.codigo = `${sistema.sistema.codigo}.${tsubsis.tsubsis.desc_corta}`
    }
    if (!remoteValues.descripcion) {
      remoteValues.descripcion = tsubsis.tsubsis.descripcion
    }
    await this.Vue.$offline.sync.insert({
      method: 'subsis.insertSync',
      params: {
        values: _.pickBy(remoteValues, (value, key) => allowedRemoteParams.indexOf(key) >= 0),
      },
    })
    // se reordenan los subsistemas en el servidor
    const localValues = _.cloneDeep(remoteValues)
    localValues.orden = 1
    return await super.insert(localValues, options)
  }
  async selectSubsisTsubsis(idsistema) {
    const tables = this.db.tables
    const sistema = await this.Vue.$offline.sistema.row(idsistema)
    const subsisExistentes = await this.Vue.$offline.db
      .select()
      .from(tables.subsis)
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.subsis.idsistema.eq(idsistema),
          tables.subsis.estado.gt(0)
        )
      )
      .exec()
    const tsubsis = await this.Vue.$offline.sistema.selectTsubsis(idsistema)
    let tsubsisList = []
    if (tsubsis.length > 0) {
      tsubsisList = [
        {
          descripcion: 'Nuevo subsistema',
          value: {
            tipo: 'divider',
            color: 'warning',
          },
        },
        ..._.map(tsubsis, item => {
          return {
            descripcion: item.tsubsis.descripcion,
            value: {
              id: item.tsubsis.idtsubsis,
              idtsubsis: item.tsubsis.idtsubsis,
              descripcionPorDefecto: item.tsubsis.descripcion,
              codigoPorDefecto: `${sistema.sistema.codigo}.${item.tsubsis.desc_corta}`,
              tipo: 'tsubsis',
            },
          }
        }),
      ]
    }
    const toReturn = []
    if (subsisExistentes.length > 0) {
      toReturn.push({
        descripcion: 'Subsistema existente',
        value: {
          tipo: 'divider',
          color: 'success',
        },
      })
    }
    return [
      ...toReturn,
      ..._.map(subsisExistentes, item => {
        return {
          descripcion: item.subsis.descripcion,
          value: {
            // idsubsis string -> se puede insertar offline
            id: item.subsis.idsubsis.toString(),
            idtsubsis: item.subsis.idtsubsis,
            descripcionPorDefecto: item.subsis.descripcion,
            codigoPorDefecto: `${sistema.sistema.codigo}.${item.tsubsis.desc_corta}`,
            tipo: 'subsis',
          },
        }
      }),
      ...tsubsisList
    ]
  }
  async selectSubsisPorDefecto(subsisTsubsis, idarticulo=null) {
    const findSubsisIdtsubsis = async (idtsubsis) => {
      const indexSubsis = _.findIndex(
        subsisTsubsis, {
          value: {
            tipo: 'subsis',
            idtsubsis,
          }
        }
      )
      if (indexSubsis > -1) {
        return subsisTsubsis[indexSubsis].value
      }
    }
    const findTsubsisIdtsubsis = async (idtsubsis) => {
      const indexTsubsis = _.findIndex(
        subsisTsubsis, {
          value: {
            tipo: 'tsubsis',
            id: idtsubsis,
          }
        }
      )
      if (indexTsubsis > -1) {
        return subsisTsubsis[indexTsubsis].value
      }
    }
    // seleccionar como subsistema por defecto
    const tables = this.db.tables
    if (idarticulo) {
      const articulo = await this.db
        .select()
        .from(tables.articulo)
        .innerJoin(
          tables.subfamilia,
          tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
        )
        .innerJoin(
          tables.tsubfamilia,
          tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
        )
        .where(
          this.db.op.and(
            tables.articulo.idarticulo.eq(idarticulo),
          )
        )
        .exec()
      // 2) si el sistema tiene un subsistema que coincide con tsubfamilia.idtsubsis del artículo
      // const tsubfamilia = await this.$offline.tsubfamilia.row(this.idtsubfamilia)
      const subsis = await findSubsisIdtsubsis(articulo[0].tsubfamilia.idtsubsis)
      if (subsis) {
        return subsis
      } else {
        // 3) tipo de subsistema que coincide con tsubfamilia.idtsubsis del artículo
        const tsubsisComportamientoSubfam = await findTsubsisIdtsubsis(articulo[0].tsubfamilia.idtsubsis)
        if (tsubsisComportamientoSubfam) {
          return tsubsisComportamientoSubfam
        }
      }
    }
  }
  async selectSubsisDeIdsistema(idsistema) {
    const tables = this.db.tables
    return await this.Vue.$offline.db
      .select()
      .from(tables.subsis)
      .where(
        this.Vue.$offline.db.op.and(
          tables.subsis.idsistema.eq(idsistema),
          tables.subsis.estado.gt(0)
        )
      )
      .exec()
  }
  async creaSubsisDeForm(formData, idsistema) {
    // Si no existe el subsistema de ese tipo lo crea, si no, devuelve el idsubsis
    let idsubsis
    if (formData.idsubsis_idtsubsis.tipo === 'tsubsis') {
      idsubsis = uuidv4()
      await this.Vue.$offline.subsis.insertSync({
        idsubsis,
        idsistema: idsistema,
        idtsubsis: formData.idsubsis_idtsubsis.idtsubsis,
        descripcion: formData.subsis_descripcion,
        codigo: formData.subsis_codigo,
        estado: 1,
      })
    } else {
      idsubsis = formData.idsubsis_idtsubsis.id
    }
    return idsubsis
  }
}
