import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { OPERACIONCRM } from '@/utils/consts'

export default class OperacionCRMOnlineResource extends BaseOnlineResource {
  name (row) {
    return `${row.descripcion}`
  }
  title (row) {
    return `${row.toperacioncrm_desc} - ${row.est_operacioncrm_dec} (${row.serie_numero})`
  }
  subtitle (row) {
    let resumenOperacionCRM = []
    resumenOperacionCRM.push(`Próx. revisión: ${filters.shortDate(row.fproximarevision)}`)
    if (row.operacioncrm_desc) {
      resumenOperacionCRM.push(`${row.operacioncrm_desc}`)
    }
    if (row.observaciones) {
      resumenOperacionCRM.push(`Observaciones: ${filters.linebreaksBr(row.observaciones)}`)
    }
    return resumenOperacionCRM
  }
  viewSubtitle (row) {
    let resumenOperacionCRM = []
    resumenOperacionCRM.push(`<strong>Próx. revisión:</strong> ${filters.shortDate(row.fproximarevision)}`)
    if (row.operacioncrm_desc) {
      resumenOperacionCRM.push(`<strong>Relacionado con:</strong> ${row.operacioncrm_desc}`)
    }
    if (row.observaciones) {
      resumenOperacionCRM.push(`<strong>Observaciones:</strong> ${filters.linebreaksBr(row.observaciones)}`)
    }
    if (row.responsables) {
      resumenOperacionCRM.push(`<strong>Responables: </strong>${row.responsables}`)
    }
    return resumenOperacionCRM
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
      .addILike('est_operacioncrm_dec', value)
      .addILike('toperacioncrm_desc', value)
  }
  getIdentificador(tabla, routeParams) {
    return routeParams[tabla?.pk] || 0
  }
  async delete (idoperacioncrm) {
    await this.Vue.$api.call('operacioncrm.delete', { idoperacioncrm: idoperacioncrm })
  }
  formatListItem(item) {
    item.title = this.title(item)
    item.subtitle = this.subtitle(item).join(' - ')
    item.avatar = filters.initials(OPERACIONCRM.descripcionesPrioridad[item.idprioridad])
    item.avatarColor = OPERACIONCRM.coloresPrioridad[item.idprioridad]
    return item
  }

  buildApiFilterVisualizacion (esTecnico, esComercial, esVigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
    // TODO: esto se repite en fichero.js, nota.js y toperacioncrm.js, ver si se puede unificar
    const apiFilterVisualizacion = new APIFilter()
    if (esTecnico || esComercial || esVigilante) {
      apiFilterVisualizacion
        .setOperator(API_FILTER_OPERATORS.OR)
      if (esTecnico) {
        apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
      }
      if (esComercial) {
        apiFilterVisualizacion.addExact('visible_movilidad_comercial', esComercial)
      }
      if (esVigilante) {
        apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esVigilante)
      }
      apiFilter.addNestedFilter(apiFilterVisualizacion)
    }
    return apiFilter
  }
  async selectDeIdentificadorYTabla (identificador, tabla, esTecnico, esComercial, esVigilante) {
    const apiFilter = this.buildApiFilterVisualizacion(esTecnico, esComercial, esVigilante)
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact(tabla.pk, identificador)
      .addGT('estado', 0)
    return await this.Vue.$api.call('operacioncrm.select', {
      filter: apiFilter,
    })
  }
  async select (idoperacioncrm) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idoperacioncrm', idoperacioncrm)
      .addGT('estado', 0)
    return await this.Vue.$api.call('operacioncrm.selectList', { filter: apiFilter })
  }
}
