import { PARTE_TRABAJO } from '@/utils/consts'

import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      clienteIdcliente: {
        label: 'ID de cliente',
        type: 'string',
        value: null,
        field: 'cliente_idcliente'
      },
      sistemaCodigo: {
        label: 'Código de sistema',
        type: 'string',
        value: null,
        field: 'sistema_codigo'
      },
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fparte',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fparte',
      },
      zona: {
        label: 'Zona',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idzona',
        selectItemValue: 'idzona',
        selectItemText: 'descripcion',
      },
      sistemaDireccion: {
        label: 'Dirección (calle, localidad, C.P., etc)',
        type: 'string',
        value: null,
        field: 'sistema_direccion',
      },
      tactuacion: {
        label: 'Tipo de actuación',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtactuacion',
        selectItemValue: 'idtactuacion',
        selectItemText: 'descripcion',
      },
      tsistema: {
        label: 'Tipo de sistema',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtsistema',
        selectItemValue: 'idtsistema',
        selectItemText: 'descripcion',
      },
      tmotivoBloqueoOt: {
        label: 'Motivo de bloqueo',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtmotivo_bloqueo_ot',
        selectItemValue: 'idtmotivo_bloqueo_ot',
        selectItemText: 'descripcion',
      },
      tecnico: {
        label: 'Técnico asignado',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtecnico',
        selectItemValue: 'idtecnico',
        selectItemText: 'nombre_y_apellido',
      },
      clasificacion: {
        label: 'Clasificacion',
        type: 'multiple-select',
        value: null,
        items: [
          {
            clasificacion: PARTE_TRABAJO.clasificaciones.normal,
            descripcion: PARTE_TRABAJO.descripcionesClasificacion[PARTE_TRABAJO.clasificaciones.normal],
          },
          {
            clasificacion: PARTE_TRABAJO.clasificaciones.taller,
            descripcion: PARTE_TRABAJO.descripcionesClasificacion[PARTE_TRABAJO.clasificaciones.taller],
          },
          {
            clasificacion: PARTE_TRABAJO.clasificaciones.devolucion,
            descripcion: PARTE_TRABAJO.descripcionesClasificacion[PARTE_TRABAJO.clasificaciones.devolucion],
          },
        ],
        field: 'clasificacion',
        selectItemValue: 'clasificacion',
        selectItemText: 'descripcion',
      },
      conFfijada: {
        label: 'Con fecha fijada',
        type: 'check',
        value: null,
        field: 'con_ffijada',
      },
      ffijada: {
        label: 'Fecha fijada',
        type: 'date',
        value: null,
        field: 'ffijada',
      },
      ffinparte: {
        label: 'Fecha de finalización',
        type: 'date',
        value: null,
        field: 'ffinparte',
      },
      idparteTrabajo: {
        label: 'Nº de parte',
        type: 'number',
        value: null,
        field: 'idparte_trabajo'
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.zona.items = []
      state.filter.tactuacion.items = []
      state.filter.tsistema.items = []
      state.filter.tmotivoBloqueoOt.items = []
      state.filter.tecnico.items = []
      state.filter.clasificacion.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
