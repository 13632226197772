<template>
  <v-card flat>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="validationRules"
    >
      <p class="v-label theme--light">
        {{ label }}
      </p>
      <div
        class="signature-container editor"
        contenteditable="contenteditable"
      >
        <vue-signature-pad
          :ref="name"
          v-model="InternalValue"
          class="signature"
          :width="width"
          :height="height"
          :options="{ onEnd: saveFirma }"
        />
      </div>
      <span
        v-if="errors.length"
        class="error-text"
      >{{ errors[0] }}</span>
    </ValidationProvider>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="secondary"
        @click="clearFirma"
      >
        <v-icon left>
          {{ $vuetify.icons.values.erase }}
        </v-icon>
        Borrar firma
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatBase64 } from '@/utils/crypto'

export  default {
  prop: ['value'],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      width: "100%",
      height: "250px",
      InternalValue: null,
    }
  },
  mounted () {
    this.loadFirma()
  },
  methods: {
    saveFirma() {
      const signaturePad = this.$refs[this.name]
      if (signaturePad) {
        const { data } = signaturePad.saveSignature()
        const formattedData = formatBase64(data)
        this.$emit('input', formattedData)
        this.InternalValue = true
      }
    },
    clearFirma() {
      const signaturePad = this.$refs[this.name]
      if (signaturePad) {
        signaturePad.clearSignature()
      }
      this.InternalValue = null
      this.$emit('input', null)
    },
    loadFirma() {
      const signaturePad = this.$refs[this.name]
      if (signaturePad && this.$attrs.value) {
        const dataUrl = `data:image/jpeg;base64,${this.$attrs.value}`
        signaturePad.fromDataURL(dataUrl)
      }
    }
  },
}
</script>

<style scoped>
.signature-container {
  padding: 10px;
  border: 2px dotted var(--v-grey-base);
}
.error-text {
  color: red;
  font-size: 14px;
}
</style>
