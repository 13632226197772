import lovefield from 'lovefield'
import { doUpgrade } from './upgrades'

export const DB_VERSION = '63'

// TODO: Lovefield no soporta foreign keys nullables
export const initSchema = () => {
  let versionNro = (version) => {
    return Number.parseInt(version.replace(/[.]/g, ''))
  }
  let schemaBuilder = lovefield.schema.create('beta10', versionNro(DB_VERSION))
  schemaBuilder.createTable('parte_trabajo')
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idparte_trabajo'])
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_parte_trabajo__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('fparte', lovefield.Type.DATE_TIME)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('observacion', lovefield.Type.STRING)
    .addColumn('avisar_antes_ir', lovefield.Type.BOOLEAN)
    .addColumn('ffinparte', lovefield.Type.DATE_TIME)
    .addColumn('firma', lovefield.Type.STRING)
    .addColumn('ffirma', lovefield.Type.DATE_TIME)
    .addColumn('firmante', lovefield.Type.STRING)
    .addColumn('comentario_final_cliente', lovefield.Type.STRING)
    .addColumn('comentario_final_tecnico', lovefield.Type.STRING)
    .addColumn('manodeobrafacturable', lovefield.Type.BOOLEAN)
    .addColumn('tfacturacion_kilometros_desc', lovefield.Type.INTEGER)
    .addColumn('idtcierre_parte', lovefield.Type.INTEGER)
    // .addForeignKey('fk_parte_trabajo__tcierre_parte', {
    //   local: 'idtcierre_parte',
    //   ref: 'tcierre_parte.idtcierre_parte',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('dni_firmante', lovefield.Type.STRING)
    // sin FK (constantes)
    .addColumn('idaplicacion_origen_cierre', lovefield.Type.INTEGER)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    // .addForeignKey('fk_parte_trabajo__almacen', {
    //   local: 'idalmacen',
    //   ref: 'almacen.idalmacen',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idtparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_parte_trabajo__tparte_trabajo', {
      local: 'idtparte_trabajo',
      ref: 'tparte_trabajo.idtparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('copiar_comentario_aalbaran', lovefield.Type.BOOLEAN)
    .addColumn('fdescarga', lovefield.Type.DATE_TIME)
    .addColumn('idtfacturacion_kilometros', lovefield.Type.INTEGER)
    .addColumn('ffijada', lovefield.Type.DATE_TIME)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('fprevista_fin', lovefield.Type.DATE_TIME)
    .addNullable([
      'observacion', 'fparte', 'avisar_antes_ir', 'firma', 'idalmacen',
      'ffirma', 'firmante', 'comentario_final_cliente', 'idtcierre_parte',
      'comentario_final_tecnico', 'dni_firmante', 'idaplicacion_origen_cierre',
      'tfacturacion_kilometros_desc', 'ffinparte', 'copiar_comentario_aalbaran',
      'ffijada', 'fprevista_fin',
    ])
  schemaBuilder.createTable('orden_trabajo')
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idorden_trabajo'])
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('forden', lovefield.Type.DATE_TIME)
    .addColumn('orden_trabajo_serie_numero', lovefield)
    // sin FK (constantes)
    .addColumn('idest_orden_trabajo', lovefield.Type.INTEGER)
    .addColumn('fprevista', lovefield.Type.DATE_TIME)
    .addColumn('observacion', lovefield.Type.STRING)
    .addColumn('norden_trabajo', lovefield.Type.INTEGER)
    .addColumn('forma_pago_descripcion', lovefield.Type.STRING)
    .addColumn('manodeobrafacturable', lovefield.Type.BOOLEAN)
    .addColumn('ffinalizacion', lovefield.Type.DATE_TIME)
    .addColumn('ffijada', lovefield.Type.DATE_TIME)
    .addColumn('avisar_antes_ir', lovefield.Type.BOOLEAN)
    .addColumn('tfacturacion_kilometros_desc', lovefield.Type.INTEGER)
    .addColumn('comercial_nombre', lovefield.Type.INTEGER)
    .addColumn('agente_nombre', lovefield.Type.STRING)
    .addColumn('notas_internas', lovefield.Type.STRING)
    .addColumn('solicitante', lovefield.Type.STRING)
    .addColumn('responsable_propiedad', lovefield.Type.STRING)
    .addColumn('idtincidencia_ot', lovefield.Type.INTEGER)
    // .addForeignKey('fk_orden_trabajo__tincidencia_ot', {
    //   local: 'idtincidencia_ot',
    //   ref: 'tincidencia_ot.idtincidencia_ot',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idorden_trabajo_prioridad', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo__orden_trabajo_prioridad', {
      local: 'idorden_trabajo_prioridad',
      ref: 'orden_trabajo_prioridad.idorden_trabajo_prioridad',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtactuacion_tsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo__tactuacion_tsistema', {
      local: 'idtactuacion_tsistema',
      ref: 'tactuacion_tsistema.idtactuacion_tsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('npedido_cliente', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('estado_idclasificacion', lovefield.Type.INTEGER)
    .addColumn('cant_partes_pendientes', lovefield.Type.INTEGER)
    .addColumn('idnivel_satisfaccion', lovefield.Type.INTEGER)
    // .addForeignKey('fk_orden_trabajo__nivel_satisfaccion', {
    //   local: 'idnivel_satisfaccion',
    //   ref: 'nivel_satisfaccion.idnivel_satisfaccion',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('concepto_factura', lovefield.Type.STRING)
    .addColumn('idtmotivo_bloqueo_ot', lovefield.Type.INTEGER)
    .addColumn('pendiente_devolucion', lovefield.Type.BOOLEAN)
    .addColumn('idttarifa', lovefield.Type.INTEGER)
    // .addForeignKey('fk_orden_trabajo__ttarifa', {
    //   local: 'idttarifa',
    //   ref: 'ttarifa.idttarifa',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idbanco_precio', lovefield.Type.INTEGER)
    // .addForeignKey('fk_orden_trabajo__banco_precio', {
    //   local: 'idbanco_precio',
    //   ref: 'banco_precio.idbanco_precio',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idtoperacion_impuesto', lovefield.Type.INTEGER)
    // .addForeignKey('fk_orden_trabajo__toperacion_impuesto', {
    //   local: 'idtoperacion_impuesto',
    //   ref: 'toperacion_impuesto.idtoperacion_impuesto',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idforma_pago', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo__forma_pago', {
      local: 'idforma_pago',
      ref: 'forma_pago.idforma_pago',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idflujo', lovefield.Type.INTEGER)
    .addColumn('idproyecto_contable', lovefield.Type.INTEGER)
    .addNullable([
      'fprevista', 'observacion', 'ffinalizacion', 'ffijada', 'avisar_antes_ir',
      'tfacturacion_kilometros_desc', 'comercial_nombre', 'agente_nombre',
      'notas_internas', 'solicitante', 'responsable_propiedad', 'idtincidencia_ot',
      'npedido_cliente', 'cant_partes_pendientes', 'idnivel_satisfaccion', 'concepto_factura',
      'idtmotivo_bloqueo_ot', 'idbanco_precio', 'idtoperacion_impuesto', 'idproyecto_contable',
    ])
  schemaBuilder.createTable('est_orden_trabajo')
    .addColumn('idest_orden_trabajo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idest_orden_trabajo'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idclasificacion', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('alias', lovefield.Type.STRING)
    .addNullable(['alias', ])
  schemaBuilder.createTable('sistema')
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsistema'])
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema__cliente', {
      local: 'idcliente',
      ref: 'cliente.idcliente',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('nabonado1', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('fconexion', lovefield.Type.DATE_TIME)
    .addColumn('finstalacion', lovefield.Type.DATE_TIME)
    .addColumn('estado', lovefield.Type.INTEGER)
    // sin FK (circular)
    .addColumn('idcliente_direccion_sist', lovefield.Type.INTEGER)
    // sin FK (circular)
    .addColumn('idcliente_direccion_postal', lovefield.Type.INTEGER)
    .addColumn('manodeobrafacturable', lovefield.Type.BOOLEAN)
    .addColumn('tfacturacion_kilometros_desc', lovefield.Type.INTEGER)
    .addColumn('grado_seguridad_descripcion', lovefield.Type.STRING)
    .addColumn('central_alarma_nombre', lovefield.Type.STRING)
    .addColumn('agente_nombre', lovefield.Type.STRING)
    .addColumn('comercial_nombre', lovefield.Type.STRING)
    .addColumn('horario', lovefield.Type.STRING)
    .addColumn('tactividad_descripcion', lovefield.Type.STRING)
    .addColumn('idtsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema__tsistema', {
      local: 'idtsistema',
      ref: 'tsistema.idtsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idforma_pago', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema__forma_pago', {
      local: 'idforma_pago',
      ref: 'forma_pago.idforma_pago',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addNullable([
      'nabonado1', 'observaciones', 'fconexion',
      'idcliente_direccion_sist', 'idcliente_direccion_postal', 'central_alarma_nombre',
      'grado_seguridad_descripcion', 'agente_nombre', 'comercial_nombre', 'horario', 'tactividad_descripcion',
      'finstalacion'
    ])
  schemaBuilder.createTable('cliente')
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addPrimaryKey(['idcliente'])
    .addColumn('nombre', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('cif', lovefield.Type.STRING)
    .addColumn('razon_social', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    // sin FK (circular)
    .addColumn('idclidir_postal', lovefield.Type.INTEGER)
    // sin FK (circular)
    .addColumn('idclidir_fiscal', lovefield.Type.INTEGER)
    // sin FK (circular)
    .addColumn('idclidir_sistema', lovefield.Type.INTEGER)
    .addColumn('agente_nombre', lovefield.Type.STRING)
    .addColumn('tactividad_descripcion', lovefield.Type.STRING)
    .addColumn('comercial_nombre', lovefield.Type.STRING)
    .addColumn('idregimen_fiscal', lovefield.Type.INTEGER)
    .addForeignKey('fk_cliente__regimen_fiscal', {
      local: 'idregimen_fiscal',
      ref: 'regimen_fiscal.idregimen_fiscal',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idttarifa', lovefield.Type.INTEGER)
    // .addForeignKey('fk_cliente__ttarifa', {
    //   local: 'idttarifa',
    //   ref: 'ttarifa.idttarifa',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('ididioma', lovefield.Type.INTEGER)
    .addForeignKey('fk_cliente__idioma', {
      local: 'ididioma',
      ref: 'idioma.ididioma',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addNullable([
      'cif', 'razon_social', 'observaciones', 'idclidir_postal',
      'idclidir_fiscal', 'idclidir_sistema', 'agente_nombre',
      'tactividad_descripcion', 'comercial_nombre',
    ])
  schemaBuilder.createTable('cliente_aviso')
    .addColumn('idcliente_aviso', lovefield.Type.INTEGER)
    .addPrimaryKey(['idcliente_aviso'])
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addForeignKey('fk_cliente_aviso__cliente', {
      local: 'idcliente',
      ref: 'cliente.idcliente',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idsistema', lovefield.Type.INTEGER)
    // .addForeignKey('fk_cliente_aviso__sistema', {
    //   local: 'idsistema',
    //   ref: 'sistema.idsistema',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('aviso', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('finicio', lovefield.Type.DATE_TIME)
    .addColumn('ffin', lovefield.Type.DATE_TIME)
    .addColumn('imprime_comercial', lovefield.Type.BOOLEAN)
    .addColumn('imprime_sat', lovefield.Type.BOOLEAN)
    .addColumn('imprime_admon', lovefield.Type.BOOLEAN)
    .addNullable([
      'idsistema', 'finicio', 'ffin',
    ])

  schemaBuilder.createTable('cliente_direccion')
    .addColumn('idcliente_direccion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idcliente_direccion'])
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addForeignKey('fk_cliente_direccion__cliente', {
      local: 'idcliente',
      ref: 'cliente.idcliente',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('calle', lovefield.Type.STRING)
    .addColumn('num', lovefield.Type.STRING)
    .addColumn('cp', lovefield.Type.STRING)
    .addColumn('localidad', lovefield.Type.STRING)
    .addColumn('provincia', lovefield.Type.STRING)
    .addColumn('observacion', lovefield.Type.STRING)
    .addColumn('otros_datos', lovefield.Type.STRING)
    .addColumn('latitud', lovefield.Type.NUMBER)
    .addColumn('longitud', lovefield.Type.NUMBER)
    .addColumn('tdireccion_descripcion', lovefield.Type.STRING)
    .addColumn('pais_descripcion', lovefield.Type.STRING)
    .addColumn('tvia_descripcion', lovefield.Type.STRING)
    .addColumn('idzona_comercial', lovefield.Type.INTEGER)
    // .addForeignKey('fk_cliente_direccion__zona_comercial', {
    //   local: 'idzona_comercial',
    //   ref: 'zona.idzona',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idzona_tecnico', lovefield.Type.INTEGER)
    // .addForeignKey('fk_cliente_direccion__zona_tecnico', {
    //   local: 'idzona_tecnico',
    //   ref: 'zona.idzona',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idzona_vigilancia', lovefield.Type.INTEGER)
    // .addForeignKey('fk_cliente_direccion__zona_vigilancia', {
    //   local: 'idzona_vigilancia',
    //   ref: 'zona.idzona',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addNullable([
      'idcliente', 'calle', 'num', 'cp', 'localidad', 'provincia',
      'observacion', 'otros_datos', 'latitud', 'longitud', 'idzona_comercial',
      'idzona_tecnico', 'idzona_vigilancia'
    ])
  schemaBuilder.createTable('cliente_telefono')
    .addColumn('idcliente_telefono', lovefield.Type.STRING)
    .addPrimaryKey(['idcliente_telefono'])
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addForeignKey('fk_cliente_telefono__cliente', {
      local: 'idcliente',
      ref: 'cliente.idcliente',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('email', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('email_notifica_sat', lovefield.Type.BOOLEAN)
    .addColumn('nombre', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('email_notifica_admon', lovefield.Type.BOOLEAN)
    .addColumn('email_notifica_comercial', lovefield.Type.BOOLEAN)
    .addColumn('horario', lovefield.Type.STRING)
    .addColumn('idttelefono', lovefield.Type.INTEGER)
    .addColumn('para_todos_los_sistemas', lovefield.Type.BOOLEAN)
    // .addForeignKey('fk_cliente_telefono__idttelefono', {
    //   local: 'idttelefono',
    //   ref: 'cliente_telefono.idttelefono',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('telefono', lovefield.Type.STRING)
    .addNullable([
      'email', 'idttelefono', 'horario', 'observaciones', 'idttelefono',
      'nombre', 'email_notifica_admon', 'telefono'
    ])
  schemaBuilder.createTable('tsistema')
    .addColumn('idtsistema', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtsistema'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('desc_corta', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('ver_mat_nserie', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_nelemento', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ubicacion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_netiqueta', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_num_zona', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_evento', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_funcion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_particion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idfabricante', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ffabricacion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idagente_extintor', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idagente_propelente', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_volumen', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_peso_total', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_peso_agente_extintor', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_eficacia', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_presion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fult_revision', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fprox_revision', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fult_retimbrado', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fprox_retimbrado', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fcaducidad', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ffin_garantia', lovefield.Type.BOOLEAN)
    .addNullable(['orden', 'descripcion'])
  schemaBuilder.createTable('tcaja')
    .addColumn('idtcaja', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtcaja'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('empresa')
    .addColumn('idempresa', lovefield.Type.INTEGER)
    .addPrimaryKey(['idempresa'])
    .addColumn('nombre', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('caja')
    .addColumn('idcaja', lovefield.Type.INTEGER)
    .addPrimaryKey(['idcaja'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idtcaja', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tforma_pago__tcaja', {
    //   local: 'idtcaja',
    //   ref: 'tcaja.idtcaja',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idempresa', lovefield.Type.INTEGER)
    .addForeignKey('fk_caja__empresa', {
      local: 'idempresa',
      ref: 'empresa.idempresa',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addNullable(['idtcaja'])
  schemaBuilder.createTable('tforma_pago')
    .addColumn('idtforma_pago', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtforma_pago'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idtcaja', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tforma_pago__tcaja', {
    //   local: 'idtcaja',
    //   ref: 'tcaja.idtcaja',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable(['idtcaja'])
  schemaBuilder.createTable('forma_pago')
    .addColumn('idforma_pago', lovefield.Type.INTEGER)
    .addPrimaryKey(['idforma_pago'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('movilidad', lovefield.Type.BOOLEAN)
    .addColumn('idtforma_pago', lovefield.Type.INTEGER)
    .addForeignKey('fk_forma_pago__tforma_pago', {
      local: 'idtforma_pago',
      ref: 'tforma_pago.idtforma_pago',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
  schemaBuilder.createTable('tactuacion_tsistema')
    .addColumn('idtactuacion_tsistema', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtactuacion_tsistema'])
    .addColumn('idtactuacion', lovefield.Type.INTEGER)
    .addForeignKey('fk_tactuacion_tsistema__tactuacion', {
      local: 'idtactuacion',
      ref: 'tactuacion.idtactuacion',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idtsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_tactuacion_tsistema__tsistema', {
      local: 'idtsistema',
      ref: 'tsistema.idtsistema',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idchecklist', lovefield.Type.INTEGER)
    .addColumn('incluir_material_al_parte', lovefield.Type.BOOLEAN)
    // .addForeignKey('fk_tactuacion_tsistema__checklist', {
    //   local: 'idchecklist',
    //   ref: 'checklist.idchecklist',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable(['idchecklist'])
  schemaBuilder.createTable('tactuacion')
    .addColumn('idtactuacion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtactuacion'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('tipo', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('obliga_tcierre_parte', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addNullable(['orden'])
  schemaBuilder.createTable('tincidencia_ot')
    .addColumn('idtincidencia_ot', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtincidencia_ot'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('orden_trabajo_prioridad')
    .addColumn('idorden_trabajo_prioridad', lovefield.Type.INTEGER)
    .addPrimaryKey(['idorden_trabajo_prioridad'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('color_fondo', lovefield.Type.STRING)
    .addColumn('color_font', lovefield.Type.STRING)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('tparte_trabajo')
    .addColumn('idtparte_trabajo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtparte_trabajo'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('almacen')
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    .addPrimaryKey(['idalmacen'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('idtalmacen', lovefield.Type.INTEGER)
    .addNullable(['idtalmacen'])
  schemaBuilder.createTable('tcierre_parte')
    .addColumn('idtcierre_parte', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtcierre_parte'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('zona')
    .addColumn('idzona', lovefield.Type.INTEGER)
    .addPrimaryKey(['idzona'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('para_comercial', lovefield.Type.INTEGER)
    .addColumn('para_tecnico', lovefield.Type.INTEGER)
    .addColumn('para_vigilancia', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('kilometros', lovefield.Type.NUMBER)
  schemaBuilder.createTable('agente_extintor')
    .addColumn('idagente_extintor', lovefield.Type.INTEGER)
    .addPrimaryKey(['idagente_extintor'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('agente_propelente')
    .addColumn('idagente_propelente', lovefield.Type.INTEGER)
    .addPrimaryKey(['idagente_propelente'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('timportancia_material')
    .addColumn('idtimportancia_material', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtimportancia_material'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('color', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['orden', 'color'])
  schemaBuilder.createTable('articulo')
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idarticulo'])
    .addColumn('idsubfamilia', lovefield.Type.INTEGER)
    .addForeignKey('fk_articulo__subfamilia', {
      local: 'idsubfamilia',
      ref: 'subfamilia.idsubfamilia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idtimpuesto', lovefield.Type.INTEGER)
    .addForeignKey('fk_articulo__timpuesto', {
      local: 'idtimpuesto',
      ref: 'timpuesto.idtimpuesto',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('codbarras', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('esmanodeobra', lovefield.Type.BOOLEAN)
    .addColumn('frecuente', lovefield.Type.BOOLEAN)
    .addColumn('nserie_obligado', lovefield.Type.BOOLEAN)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idficha_tecnica', lovefield.Type.INTEGER)
    .addColumn('codpro', lovefield.Type.STRING)
    // .addForeignKey('fk_articulo__ficha_tecnica', {
    //   local: 'idficha_tecnica',
    //   ref: 'ficha_tecnica.idficha_tecnica',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('material_con_revision', lovefield.Type.BOOLEAN)
    .addColumn('tipo', lovefield.Type.INTEGER)
    .addNullable(['codbarras', 'clasificacion', 'idficha_tecnica', 'codpro'])
    .addColumn('visible_movilidad', lovefield.Type.BOOLEAN)
    .addColumn('desglosar_ubicacion', lovefield.Type.BOOLEAN)
    .addColumn('modificar_desc_movilidad', lovefield.Type.BOOLEAN)
    .addColumn('idfabricante', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__fabricante', {
    //   local: 'idfabricante',
    //   ref: 'fabricante.idfabricante',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagente_extintor', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__agente_extintor', {
    //   local: 'idagente_extintor',
    //   ref: 'agente_extintor.idagente_extintor',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagente_propelente', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__agente_propelente', {
    //   local: 'idagente_propelente',
    //   ref: 'agente_propelente.idagente_propelente',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('volumen', lovefield.Type.NUMBER)
    .addColumn('peso_total', lovefield.Type.NUMBER)
    .addColumn('peso_agente_extintor', lovefield.Type.NUMBER)
    .addColumn('presion', lovefield.Type.NUMBER)
    .addColumn('eficacia', lovefield.Type.STRING)
    .addColumn('meses_garantia', lovefield.Type.INTEGER)
    .addColumn('idtimportancia_material', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__timportancia_material', {
    //   local: 'idtimportancia_material',
    //   ref: 'timportancia_material.idtimportancia_material',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idmarca', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__marca', {
    //   local: 'idmarca',
    //   ref: 'marca.idmarca',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idgrupo_descuento', lovefield.Type.INTEGER)
    // .addForeignKey('fk_articulo__grupo_descuento', {
    //   local: 'idgrupo_descuento',
    //   ref: 'grupo_descuento.idgrupo_descuento',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('sin_stock', lovefield.Type.NUMBER)
    .addNullable([
      'idfabricante',
      'idagente_extintor',
      'idagente_propelente',
      'volumen',
      'peso_total',
      'peso_agente_extintor',
      'presion',
      'eficacia',
      'meses_garantia',
      'idtimportancia_material',
      'idmarca',
      'idgrupo_descuento',
    ])
  schemaBuilder.createTable('dato_articulo')
    .addColumn('iddato_articulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['iddato_articulo'])
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addColumn('idtdato_articulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_dato_articulo__tdato_articulo', {
      local: 'idtdato_articulo',
      ref: 'tdato_articulo.idtdato_articulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['idarticulo', 'valor'])
  schemaBuilder.createTable('tdato_articulo')
    .addColumn('idtdato_articulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtdato_articulo'])
    .addColumn('tipo_valor', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('para_articulo', lovefield.Type.BOOLEAN)
    .addColumn('para_material_sis', lovefield.Type.BOOLEAN)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idficha_tecnica', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tdato_articulo__ficha_tecnica', {
    //   local: 'idficha_tecnica',
    //   ref: 'ficha_tecnica.idficha_tecnica',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idgrupo_ficha_tecnica', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tdato_articulo__grupo_ficha_tecnica', {
    //   local: 'idgrupo_ficha_tecnica',
    //   ref: 'grupo_ficha_tecnica.idgrupo_ficha_tecnica',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable([
      'para_articulo', 'para_material_sis', 'orden',
      'idgrupo_ficha_tecnica', 'idficha_tecnica'
    ])
  schemaBuilder.createTable('tdato_articulo_valor')
    .addColumn('idtdato_articulo_valor', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtdato_articulo_valor'])
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idtdato_articulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_tdato_articulo_valor__tdato_articulo', {
      local: 'idtdato_articulo',
      ref: 'tdato_articulo.idtdato_articulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
  schemaBuilder.createTable('ficha_tecnica')
    .addColumn('idficha_tecnica', lovefield.Type.INTEGER)
    .addPrimaryKey(['idficha_tecnica'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('grupo_ficha_tecnica')
    .addColumn('idgrupo_ficha_tecnica', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrupo_ficha_tecnica'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idficha_tecnica', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_ficha_tecnica__ficha_tecnica', {
      local: 'idficha_tecnica',
      ref: 'ficha_tecnica.idficha_tecnica',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addNullable(['idficha_tecnica'])
  schemaBuilder.createTable('fabricante')
    .addColumn('idfabricante', lovefield.Type.INTEGER)
    .addPrimaryKey(['idfabricante'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('familia')
    .addColumn('idfamilia', lovefield.Type.INTEGER)
    .addPrimaryKey(['idfamilia'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('maccion')
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idmaccion'])
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('descripcion_corta', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('facturable', lovefield.Type.BOOLEAN)
  schemaBuilder.createTable('maccion_tsubfam')
    .addColumn('idmaccion_tsubfam', lovefield.Type.INTEGER)
    .addPrimaryKey(['idmaccion_tsubfam'])
    .addColumn('idtsubfamilia', lovefield.Type.INTEGER)
    .addForeignKey('fk_maccion_tsubfam__tsubfamilia', {
      local: 'idtsubfamilia',
      ref: 'tsubfamilia.idtsubfamilia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    .addForeignKey('fk_maccion_tsubfam__maccion', {
      local: 'idmaccion',
      ref: 'maccion.idmaccion',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('periodo_revision')
    .addColumn('idperiodo_revision', lovefield.Type.INTEGER)
    .addPrimaryKey(['idperiodo_revision'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('dias', lovefield.Type.INTEGER)
    .addColumn('meses', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('subfamilia')
    .addColumn('idsubfamilia', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsubfamilia'])
    .addColumn('idfamilia', lovefield.Type.INTEGER)
    .addForeignKey('fk_subfamilia__familia', {
      local: 'idfamilia',
      ref: 'familia.idfamilia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idtsubfamilia', lovefield.Type.INTEGER)
    .addForeignKey('fk_subfamilia__tsubfamilia', {
      local: 'idtsubfamilia',
      ref: 'tsubfamilia.idtsubfamilia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('tsubfamilia')
    .addColumn('idtsubfamilia', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtsubfamilia'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('permite_retirar', lovefield.Type.BOOLEAN)
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tsubfamilia__tsubsis', {
    //   local: 'idtsubsis',
    //   ref: 'tsubsis.idtsubsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('factura_junto_revi_retim', lovefield.Type.BOOLEAN)
    .addColumn('meses_caducidad', lovefield.Type.INTEGER)
    .addNullable(['idtsubsis', 'meses_caducidad'])
  schemaBuilder.createTable('tsubsis')
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtsubsis'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('desc_corta', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('ver_mat_nserie', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_nelemento', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ubicacion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_netiqueta', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_num_zona', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_evento', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_funcion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_particion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idfabricante', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ffabricacion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idagente_extintor', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_idagente_propelente', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_volumen', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_peso_total', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_peso_agente_extintor', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_eficacia', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_presion', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fult_revision', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fprox_revision', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fult_retimbrado', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fprox_retimbrado', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_fcaducidad', lovefield.Type.BOOLEAN)
    .addColumn('ver_mat_ffin_garantia', lovefield.Type.BOOLEAN)
    .addNullable(['desc_corta'])
  schemaBuilder.createTable('timpuesto')
    .addColumn('idtimpuesto', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtimpuesto'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('valoriva', lovefield.Type.NUMBER)
    .addColumn('valorrecargo', lovefield.Type.NUMBER)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('material_sistema')
    .addColumn('idmaterial_sistema', lovefield.Type.STRING)
    .addPrimaryKey(['idmaterial_sistema'])
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_material_sistema__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('fsistema', lovefield.Type.DATE_TIME)
    .addColumn('num_zona', lovefield.Type.STRING)
    .addColumn('evento', lovefield.Type.STRING)
    .addColumn('ubicacion', lovefield.Type.STRING)
    .addColumn('nserie', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('ndgp', lovefield.Type.STRING)
    .addColumn('funcion', lovefield.Type.STRING)
    .addColumn('ffabricacion', lovefield.Type.DATE_TIME)
    .addColumn('idfabricante', lovefield.Type.INTEGER)
    // .addForeignKey('fk_material_sistema__fabricante', {
    //   local: 'idfabricante',
    //   ref: 'fabricante.idfabricante',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('fult_retimbrado', lovefield.Type.DATE_TIME)
    .addColumn('n_orden', lovefield.Type.INTEGER)
    .addColumn('fdesinstalacion', lovefield.Type.DATE_TIME)
    .addColumn('nelemento', lovefield.Type.STRING)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('idagente_propelente', lovefield.Type.INTEGER)
    // .addForeignKey('fk_material_sistema__agente_propelente', {
    //   local: 'idagente_propelente',
    //   ref: 'agente_propelente.idagente_propelente',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagente_extintor', lovefield.Type.INTEGER)
    // .addForeignKey('fk_material_sistema__agente_extintor', {
    //   local: 'idagente_extintor',
    //   ref: 'agente_extintor.idagente_extintor',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('volumen', lovefield.Type.NUMBER)
    .addColumn('eficacia', lovefield.Type.STRING)
    .addColumn('presion', lovefield.Type.NUMBER)
    .addColumn('peso_total', lovefield.Type.NUMBER)
    .addColumn('peso_agente_extintor', lovefield.Type.NUMBER)
    .addColumn('fcaducidad', lovefield.Type.DATE_TIME)
    .addColumn('idsubsis', lovefield.Type.STRING)
    .addForeignKey('fk_material_sistema__subsis', {
      local: 'idsubsis',
      ref: 'subsis.idsubsis',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('material_con_revision', lovefield.Type.INTEGER)
    .addColumn('netiqueta', lovefield.Type.STRING)
    // sin FK (constantes)
    .addColumn('idest_material_sistema', lovefield.Type.INTEGER)
    .addColumn('observaciones_internas', lovefield.Type.STRING)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    // .addForeignKey('fk_material_sistema__almacen', {
    //   local: 'idalmacen',
    //   ref: 'almacen.idalmacen',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('permite_retirar', lovefield.Type.BOOLEAN)
    .addColumn('fultrevision', lovefield.Type.DATE_TIME)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('ffin_garantia', lovefield.Type.DATE_TIME)
    .addColumn('particion', lovefield.Type.STRING)
    .addColumn('idtimportancia_material', lovefield.Type.INTEGER)
    // .addForeignKey('fk_material_sistema__timportancia_material', {
    //   local: 'idtimportancia_material',
    //   ref: 'timportancia_material.idtimportancia_material',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable([
      'num_zona', 'evento', 'ubicacion', 'nserie', 'observaciones',
      'ndgp', 'funcion', 'ffabricacion', 'idfabricante', 'fult_retimbrado',
      'fdesinstalacion', 'nelemento', 'codigo', 'idagente_propelente',
      'idagente_extintor', 'volumen', 'eficacia', 'presion', 'peso_total',
      'peso_agente_extintor', 'fcaducidad', 'netiqueta', 'observaciones_internas',
      'idalmacen', 'permite_retirar', 'fultrevision', 'ffin_garantia', 'particion',
      'idtimportancia_material',
    ])
  schemaBuilder.createTable('dato_material_sistema')
    .addColumn('iddato_material_sistema', lovefield.Type.STRING)
    .addPrimaryKey(['iddato_material_sistema'])
    .addColumn('idmaterial_sistema', lovefield.Type.STRING)
    .addForeignKey('fk_dato_material_sistema__material_sistema', {
      local: 'idmaterial_sistema',
      ref: 'material_sistema.idmaterial_sistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtdato_articulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_dato_material_sistema__tdato_articulo', {
      local: 'idtdato_articulo',
      ref: 'tdato_articulo.idtdato_articulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('valor', lovefield.Type.STRING)
    .addNullable(['valor'])
  schemaBuilder.createTable('orden_trabajo_matsist')
    .addColumn('idorden_trabajo_matsist', lovefield.Type.STRING)
    .addPrimaryKey(['idorden_trabajo_matsist'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idmaterial_sistema', lovefield.Type.STRING)
    // .addForeignKey('fk_orden_trabajo_matsist__material_sistema', {
    //   local: 'idmaterial_sistema',
    //   ref: 'material_sistema.idmaterial_sistema',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo_matsist__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('observaciones_ot', lovefield.Type.STRING)
    .addColumn('fproxrevision', lovefield.Type.DATE_TIME)
    .addColumn('fult_retimbrado', lovefield.Type.DATE_TIME)
    .addColumn('fprox_revision', lovefield.Type.DATE_TIME)
    .addColumn('fprox_retimbrado', lovefield.Type.DATE_TIME)
    .addColumn('mostrar_informe', lovefield.Type.BOOLEAN)
    .addNullable([
      'idmaterial_sistema',
      'observaciones_ot',
      'fproxrevision',
      'fult_retimbrado',
      'fprox_revision',
      'fprox_retimbrado',
      'mostrar_informe',
    ])
    .addNullable(['idmaterial_sistema', 'observaciones_ot'])
  schemaBuilder.createTable('sistema_mant')
    .addColumn('idsistema_mant', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsistema_mant'])
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_mant__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtactuacion', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_mant__tactuacion', {
      local: 'idtactuacion',
      ref: 'tactuacion.idtactuacion',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('fultrevision', lovefield.Type.DATE_TIME)
    .addColumn('activar', lovefield.Type.BOOLEAN)
    .addColumn('manodeobrafacturable', lovefield.Type.BOOLEAN)
    .addColumn('tfacturacion_kilometros_desc', lovefield.Type.STRING)
    .addColumn('subsistemas', lovefield.Type.STRING)
    .addColumn('idperiodo_revision', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_mant__periodo_revision', {
      local: 'idperiodo_revision',
      ref: 'periodo_revision.idperiodo_revision',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('fproxrevision', lovefield.Type.DATE_TIME)
    .addColumn('fplanificacion', lovefield.Type.DATE_TIME)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addNullable(['fultrevision', 'fproxrevision', 'fplanificacion', 'subsistemas'])
  schemaBuilder.createTable('subsis')
    .addColumn('idsubsis', lovefield.Type.STRING)
    .addPrimaryKey(['idsubsis'])
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_subsis__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    .addForeignKey('fk_subsis__tsubsis', {
      local: 'idtsubsis',
      ref: 'tsubsis.idtsubsis',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('checklist')
    .addColumn('idchecklist', lovefield.Type.INTEGER)
    .addPrimaryKey(['idchecklist'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addNullable(['observaciones'])
  schemaBuilder.createTable('grupo_checklist')
    .addColumn('idgrupo_checklist', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrupo_checklist'])
    .addColumn('idchecklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_checklist__checklist', {
      local: 'idchecklist',
      ref: 'checklist.idchecklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    // .addForeignKey('fk_grupo_checklist__tsubsis', {
    //   local: 'idtsubsis',
    //   ref: 'tsubsis.idtsubsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('clasif_inventario', lovefield.Type.INTEGER)
    .addColumn('idlnormativa', lovefield.Type.INTEGER)
    .addNullable(['orden', 'idtsubsis', 'clasif_inventario', 'idlnormativa'])
  schemaBuilder.createTable('grupo_checklist_subfamilia')
    .addColumn('idgrupo_checklist_subfamilia', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrupo_checklist_subfamilia'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idsubfamilia', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_checklist_subfamilia__subfamilia', {
      local: 'idsubfamilia',
      ref: 'subfamilia.idsubfamilia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idgrupo_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_checklist_subfamilia__grupo_checklist', {
      local: 'idgrupo_checklist',
      ref: 'grupo_checklist.idgrupo_checklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
  schemaBuilder.createTable('agrupacion_pregunta')
    .addColumn('idagrupacion_pregunta', lovefield.Type.INTEGER)
    .addPrimaryKey(['idagrupacion_pregunta'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    // sin FK (constantes)
    .addColumn('idtipo', lovefield.Type.INTEGER)
    .addColumn('idgrupo_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_agrupacion_pregunta__grupo_checklist', {
      local: 'idgrupo_checklist',
      ref: 'grupo_checklist.idgrupo_checklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
  schemaBuilder.createTable('tequipo_medicion')
    .addColumn('idtequipo_medicion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtequipo_medicion'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('equipo_medicion')
    .addColumn('idequipo_medicion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idequipo_medicion'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idtequipo_medicion', lovefield.Type.INTEGER)
    .addForeignKey('fk_equipo_medicion__tequipo_medicion', {
      local: 'idtequipo_medicion',
      ref: 'tequipo_medicion.idtequipo_medicion',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('nserie', lovefield.Type.STRING)
    .addNullable(['nserie'])
  schemaBuilder.createTable('grado_anomalia')
    .addColumn('idgrado_anomalia', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrado_anomalia'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('correcto', lovefield.Type.BOOLEAN)
    .addColumn('no_procede', lovefield.Type.BOOLEAN)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
  schemaBuilder.createTable('pregunta_checklist')
    .addColumn('idpregunta_checklist', lovefield.Type.INTEGER)
    .addPrimaryKey(['idpregunta_checklist'])
    .addColumn('idgrupo_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_pregunta_checklist__grupo_checklist', {
      local: 'idgrupo_checklist',
      ref: 'grupo_checklist.idgrupo_checklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('requerido', lovefield.Type.BOOLEAN)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('determina_resultado_material', lovefield.Type.INTEGER)
    .addColumn('texto_incidencia', lovefield.Type.STRING)
    .addColumn('idtequipo_medicion', lovefield.Type.INTEGER)
    // .addForeignKey('fk_pregunta_checklist__tequipo_medicion', {
    //   local: 'idtequipo_medicion',
    //   ref: 'tequipo_medicion.idtequipo_medicion',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagrupacion_pregunta', lovefield.Type.INTEGER)
    // .addForeignKey('fk_pregunta_checklist__agrupacion_pregunta', {
    //   local: 'idagrupacion_pregunta',
    //   ref: 'agrupacion_pregunta.idagrupacion_pregunta',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('clasificacion_valor', lovefield.Type.INTEGER)
    .addColumn('observaciones_internas', lovefield.Type.STRING)
    .addNullable(['texto_incidencia', 'idtequipo_medicion', 'idagrupacion_pregunta', 'clasificacion_valor', 'observaciones_internas'])
  schemaBuilder.createTable('valor_columna')
    .addColumn('idvalor_columna', lovefield.Type.INTEGER)
    .addPrimaryKey(['idvalor_columna'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('idagrupacion_pregunta', lovefield.Type.INTEGER)
    .addForeignKey('fk_valor_columna__agrupacion_pregunta', {
      local: 'idagrupacion_pregunta',
      ref: 'agrupacion_pregunta.idagrupacion_pregunta',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['orden'])
  schemaBuilder.createTable('tanomalia_checklist')
    .addColumn('idtanomalia_checklist', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtanomalia_checklist'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idpregunta_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_tanomalia_checklist__pregunta_checklist', {
      local: 'idpregunta_checklist',
      ref: 'pregunta_checklist.idpregunta_checklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idgrado_anomalia', lovefield.Type.INTEGER)
    .addForeignKey('fk_tanomalia_checklist__grado_anomalia', {
      local: 'idgrado_anomalia',
      ref: 'grado_anomalia.idgrado_anomalia',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('mejora', lovefield.Type.BOOLEAN)
  schemaBuilder.createTable('accion_correctora')
    .addColumn('idaccion_correctora', lovefield.Type.INTEGER)
    .addPrimaryKey(['idaccion_correctora'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idtanomalia_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_accion_correctora__tanomalia_checklist', {
      local: 'idtanomalia_checklist',
      ref: 'tanomalia_checklist.idtanomalia_checklist',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_correctora__articulo', {
    //   local: 'idarticulo',
    //   ref: 'articulo.idarticulo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_correctora__maccion', {
    //   local: 'idmaccion',
    //   ref: 'maccion.idmaccion',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addNullable(['idarticulo', 'idmaccion', 'unidades'])
  schemaBuilder.createTable('checklist_ot')
    .addColumn('idchecklist_ot', lovefield.Type.INTEGER)
    .addPrimaryKey(['idchecklist_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('fchecklist', lovefield.Type.DATE_TIME)
    .addColumn('resultado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_checklist_ot__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('idchecklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_checklist_ot__checklist', {
      local: 'idchecklist',
      ref: 'checklist.idchecklist',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('firma', lovefield.Type.STRING)
    .addColumn('ffirma', lovefield.Type.DATE_TIME)
    .addColumn('firmante', lovefield.Type.STRING)
    .addColumn('firmante_dni', lovefield.Type.STRING)
    .addColumn('firmante_cargo', lovefield.Type.STRING)
    .addNullable([
      'fchecklist', 'resultado', 'idorden_trabajo', 'observaciones',
      'firma', 'ffirma', 'firmante', 'firmante_dni', 'firmante_cargo',
    ])
  schemaBuilder.createTable('checklist_ot_subsis')
    .addColumn('idchecklist_ot_subsis', lovefield.Type.STRING)
    .addPrimaryKey(['idchecklist_ot_subsis'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idchecklist_ot', lovefield.Type.INTEGER)
    .addForeignKey('fk_checklist_ot_subsis__checklist_ot', {
      local: 'idchecklist_ot',
      ref: 'checklist_ot.idchecklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idsubsis', lovefield.Type.STRING)
    // .addForeignKey('fk_checklist_ot_subsis__subsis', {
    //   local: 'idsubsis',
    //   ref: 'subsis.idsubsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable(['idchecklist_ot', 'idsubsis'])
  schemaBuilder.createTable('grupo_checklist_ot')
    .addColumn('idgrupo_checklist_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idgrupo_checklist_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idchecklist_ot', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_checklist_ot__checklist_ot', {
      local: 'idchecklist_ot',
      ref: 'checklist_ot.idchecklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idgrupo_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_grupo_checklist_ot__grupo_checklist', {
      local: 'idgrupo_checklist',
      ref: 'grupo_checklist.idgrupo_checklist',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idchecklist_ot_subsis', lovefield.Type.STRING)
    // .addForeignKey('fk_grupo_checklist_ot__checklist_ot_subsis', {
    //   local: 'idchecklist_ot_subsis',
    //   ref: 'checklist_ot_subsis.idchecklist_ot_subsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('clasif_inventario', lovefield.Type.INTEGER)
    .addColumn('idlnormativa', lovefield.Type.INTEGER)
    .addNullable(['idchecklist_ot_subsis', 'clasif_inventario', 'idlnormativa'])
  schemaBuilder.createTable('agrupacion_pregunta_ot')
    .addColumn('idagrupacion_pregunta_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idagrupacion_pregunta_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    // sin FK (constantes)
    .addColumn('idtipo', lovefield.Type.INTEGER)
    .addColumn('idagrupacion_pregunta', lovefield.Type.INTEGER)
    .addForeignKey('fk_agrupacion_pregunta_ot__agrupacion_pregunta', {
      local: 'idagrupacion_pregunta',
      ref: 'agrupacion_pregunta.idagrupacion_pregunta',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idgrupo_checklist_ot', lovefield.Type.STRING)
    .addForeignKey('fk_agrupacion_pregunta_ot__grupo_checklist_ot', {
      local: 'idgrupo_checklist_ot',
      ref: 'grupo_checklist_ot.idgrupo_checklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
  schemaBuilder.createTable('pregunta_checklist_ot')
    .addColumn('idpregunta_checklist_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idpregunta_checklist_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('resultado', lovefield.Type.INTEGER)
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('idgrupo_checklist_ot', lovefield.Type.STRING)
    .addForeignKey('fk_pregunta_checklist_ot__grupo_checklist_ot', {
      local: 'idgrupo_checklist_ot',
      ref: 'grupo_checklist_ot.idgrupo_checklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idorden_trabajo_matsist', lovefield.Type.STRING)
    // .addForeignKey('fk_pregunta_checklist_ot__orden_trabajo_matsist', {
    //   local: 'idorden_trabajo_matsist',
    //   ref: 'orden_trabajo_matsist.idorden_trabajo_matsist',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('requerido', lovefield.Type.BOOLEAN)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('alias', lovefield.Type.STRING)
    .addColumn('determina_resultado_material', lovefield.Type.BOOLEAN)
    .addColumn('idpregunta_checklist', lovefield.Type.INTEGER)
    .addForeignKey('fk_pregunta_checklist_ot__pregunta_checklist', {
      local: 'idpregunta_checklist',
      ref: 'pregunta_checklist.idpregunta_checklist',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('texto_incidencia', lovefield.Type.STRING)
    .addColumn('idequipo_medicion', lovefield.Type.INTEGER)
    // .addForeignKey('fk_pregunta_checklist_ot__equipo_medicion', {
    //   local: 'idequipo_medicion',
    //   ref: 'equipo_medicion.idequipo_medicion',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagrupacion_pregunta_ot', lovefield.Type.STRING)
    // .addForeignKey('fk_pregunta_checklist_ot__agrupacion_pregunta_ot', {
    //   local: 'idagrupacion_pregunta_ot',
    //   ref: 'agrupacion_pregunta_ot.idagrupacion_pregunta_ot',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idtecnico', lovefield.Type.INTEGER)
    // .addForeignKey('fk_pregunta_checklist_ot__tecnico', {
    //   local: 'idtecnico',
    //   ref: 'tecnico.idtecnico',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('clasificacion_valor', lovefield.Type.INTEGER)
    .addColumn('idgrado_anomalia', lovefield.Type.INTEGER)
    // .addForeignKey('fk_pregunta_checklist_ot__grado_anomalia', {
    //   local: 'idgrado_anomalia',
    //   ref: 'grado_anomalia.idgrado_anomalia',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('notas_internas', lovefield.Type.STRING)
    .addNullable([
      'estado', 'resultado', 'valor', 'idorden_trabajo_matsist', 'observaciones',
      'idpregunta_checklist', 'idequipo_medicion', 'texto_incidencia', 'idagrupacion_pregunta_ot',
      'idtecnico', 'clasificacion_valor', 'idgrado_anomalia', 'notas_internas',
    ])
  schemaBuilder.createTable('valor_columna_ot')
    .addColumn('idvalor_columna_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idvalor_columna_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
    .addColumn('idagrupacion_pregunta_ot', lovefield.Type.STRING)
    .addForeignKey('fk_valor_columna_ot__agrupacion_pregunta_ot', {
      local: 'idagrupacion_pregunta_ot',
      ref: 'agrupacion_pregunta_ot.idagrupacion_pregunta_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idvalor_columna', lovefield.Type.INTEGER)
    .addForeignKey('fk_valor_columna_ot__valor_columna', {
      local: 'idvalor_columna',
      ref: 'valor_columna.idvalor_columna',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('idpregunta_checklist_ot', lovefield.Type.STRING)
    .addForeignKey('fk_valor_columna_ot__pregunta_checklist_ot', {
      local: 'idpregunta_checklist_ot',
      ref: 'pregunta_checklist_ot.idpregunta_checklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addNullable(['orden', 'valor'])
  schemaBuilder.createTable('tanomalia_checklist_ot')
    .addColumn('idtanomalia_checklist_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idtanomalia_checklist_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idpregunta_checklist_ot', lovefield.Type.STRING)
    .addForeignKey('fk_tanomalia_checklist_ot__pregunta_checklist_ot', {
      local: 'idpregunta_checklist_ot',
      ref: 'pregunta_checklist_ot.idpregunta_checklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtanomalia_checklist', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tanomalia_checklist_ot__tanomalia_checklist', {
    //   local: 'idtanomalia_checklist',
    //   ref: 'tanomalia_checklist.idtanomalia_checklist',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idgrado_anomalia', lovefield.Type.INTEGER)
    .addForeignKey('fk_tanomalia_checklist_ot__grado_anomalia', {
      local: 'idgrado_anomalia',
      ref: 'grado_anomalia.idgrado_anomalia',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('descripcion_ampliada', lovefield.Type.STRING)
    .addColumn('subsanado', lovefield.Type.BOOLEAN)
    .addColumn('mejora', lovefield.Type.BOOLEAN)
    .addColumn('notas_internas', lovefield.Type.STRING)
    .addColumn('agregaradefinicioncheck', lovefield.Type.INTEGER)
    .addNullable(['idtanomalia_checklist', 'descripcion_ampliada', 'notas_internas', 'agregaradefinicioncheck'])
  schemaBuilder.createTable('accion_correctora_ot')
    .addColumn('idaccion_correctora_ot', lovefield.Type.STRING)
    .addPrimaryKey(['idaccion_correctora_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idaccion_correctora', lovefield.Type.INTEGER)
    .addForeignKey('fk_accion_correctora_ot__accion_correctora', {
      local: 'idaccion_correctora',
      ref: 'accion_correctora.idaccion_correctora',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtanomalia_checklist_ot', lovefield.Type.STRING)
    .addForeignKey('fk_accion_correctora_ot__tanomalia_checklist_ot', {
      local: 'idtanomalia_checklist_ot',
      ref: 'tanomalia_checklist_ot.idtanomalia_checklist_ot',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('realizar', lovefield.Type.BOOLEAN)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_correctora_ot__articulo', {
    //   local: 'idarticulo',
    //   ref: 'articulo.idarticulo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_correctora_ot__maccion', {
    //   local: 'idmaccion',
    //   ref: 'maccion.idmaccion',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('descripcion_ampliada', lovefield.Type.STRING)
    .addNullable(['idaccion_correctora', 'observaciones', 'idarticulo', 'idmaccion', 'unidades', 'descripcion_ampliada'])
  schemaBuilder.createTable('accion_ejec')
    .addColumn('idaccion_ejec', lovefield.Type.STRING)
    .addPrimaryKey(['idaccion_ejec'])
    .addColumn('idorden_trabajo_matsist', lovefield.Type.STRING)
    .addForeignKey('fk_accion_ejec__orden_trabajo_matsist', {
      local: 'idorden_trabajo_matsist',
      ref: 'orden_trabajo_matsist.idorden_trabajo_matsist',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    .addForeignKey('fk_accion_ejec__maccion', {
      local: 'idmaccion',
      ref: 'maccion.idmaccion',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('observaciones', lovefield.Type.STRING)
    // sin FK (constantes)
    .addColumn('idest_accion_ejec', lovefield.Type.INTEGER)
    .addColumn('facturable', lovefield.Type.BOOLEAN)
    .addColumn('fplanificacion', lovefield.Type.DATE_TIME)
    .addColumn('fplanificacion_ant', lovefield.Type.DATE_TIME)
    .addColumn('fproxejecucion_ant', lovefield.Type.DATE_TIME)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_ejec__almacen', {
    //   local: 'idalmacen',
    //   ref: 'almacen.idalmacen',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_accion_ejec__parte_trabajo', {
    //   local: 'idparte_trabajo',
    //   ref: 'parte_trabajo.idparte_trabajo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable([
      'observaciones', 'fplanificacion', 'fplanificacion_ant',
      'fproxejecucion_ant', 'idalmacen', 'idparte_trabajo',
    ])
  schemaBuilder.createTable('parte_trabajo_matsist')
    .addColumn('idparte_trabajo_matsist', lovefield.Type.STRING)
    .addPrimaryKey(['idparte_trabajo_matsist'])
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_parte_trabajo_matsist__parte_trabajo', {
      local: 'idparte_trabajo',
      ref: 'parte_trabajo.idparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idorden_trabajo_matsist', lovefield.Type.STRING)
    .addForeignKey('fk_parte_trabajo_matsist__orden_trabajo_matsist', {
      local: 'idorden_trabajo_matsist',
      ref: 'orden_trabajo_matsist.idorden_trabajo_matsist',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('orden_trabajo_mant')
    .addColumn('idorden_trabajo_mant', lovefield.Type.INTEGER)
    .addPrimaryKey(['idorden_trabajo_mant'])
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo_mant__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idsistema_mant', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo_mant__sistema_mant', {
      local: 'idsistema_mant',
      ref: 'sistema_mant.idsistema_mant',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('fplanificacion_ant', lovefield.Type.DATE_TIME)
    .addColumn('fproxejecucion_ant', lovefield.Type.DATE_TIME)
    .addNullable(['fplanificacion_ant', 'fproxejecucion_ant'])
  schemaBuilder.createTable('tecnico')
    .addColumn('idtecnico', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtecnico'])
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addForeignKey('fk_tecnico__empleado', {
      local: 'idempleado',
      ref: 'empleado.idempleado',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    .addNullable(['idalmacen'])
  schemaBuilder.createTable('empleado')
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addPrimaryKey(['idempleado'])
    .addColumn('nombre_y_apellido', lovefield.Type.STRING)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.STRING)
    .addNullable(['nombre_y_apellido'])
  schemaBuilder.createTable('tfichero')
    .addColumn('idtfichero', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtfichero'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idclasificacion_tfichero', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('visible_movilidad_sat', lovefield.Type.BOOLEAN)
    .addColumn('visible_movilidad_comercial', lovefield.Type.BOOLEAN)
    .addColumn('visible_movilidad_vigilancia', lovefield.Type.BOOLEAN)
  schemaBuilder.createTable('fichero')
    .addColumn('idfichero', lovefield.Type.STRING)
    .addPrimaryKey(['idfichero'])
    .addColumn('idtfichero', lovefield.Type.INTEGER)
    .addColumn('fcreacion', lovefield.Type.DATE_TIME)
    .addForeignKey('fk_fichero__tfichero', {
      local: 'idtfichero',
      ref: 'tfichero.idtfichero',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtabla', lovefield.Type.INTEGER)
    .addColumn('id', lovefield.Type.STRING)
    .addColumn('nombre_fichero', lovefield.Type.STRING)
    .addColumn('url', lovefield.Type.STRING)
    .addColumn('type', lovefield.Type.STRING)
    .addColumn('data', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('visible_movilidad_sat', lovefield.Type.BOOLEAN)
    .addColumn('visible_movilidad_comercial', lovefield.Type.BOOLEAN)
    .addColumn('visible_movilidad_vigilancia', lovefield.Type.BOOLEAN)
    .addColumn('carpeta', lovefield.Type.STRING)
    .addNullable(['url', 'type', 'data', 'observaciones', 'carpeta'])
  schemaBuilder.createTable('parte_trabajo_tecnico')
    .addColumn('idparte_trabajo_tecnico', lovefield.Type.STRING)
    .addPrimaryKey(['idparte_trabajo_tecnico'])
    .addColumn('idtecnico', lovefield.Type.INTEGER)
    .addForeignKey('fk_parte_trabajo_tecnico__tecnico', {
      local: 'idtecnico',
      ref: 'tecnico.idtecnico',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_parte_trabajo_tecnico__parte_trabajo', {
      local: 'idparte_trabajo',
      ref: 'parte_trabajo.idparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('principal', lovefield.Type.BOOLEAN)
    .addColumn('fdescarga', lovefield.Type.DATE_TIME)
    .addColumn('finicio', lovefield.Type.DATE_TIME)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addColumn('latitud', lovefield.Type.INTEGER)
    .addColumn('longitud', lovefield.Type.INTEGER)
    .addColumn('es_desplazamiento', lovefield.Type.BOOLEAN)
    .addNullable(['fdescarga', 'finicio', 'idarticulo', 'latitud', 'longitud'])
  schemaBuilder.createTable('articulo_escandallo')
    .addColumn('idarticulo_escandallo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idarticulo_escandallo'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_articulo_escandallo__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idescandallo', lovefield.Type.INTEGER)
    .addForeignKey('fk_articulo_escandallo__escandallo', {
      local: 'idescandallo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('obligatorio', lovefield.Type.BOOLEAN)
    .addColumn('idtinclusion', lovefield.Type.INTEGER)
    .addColumn('bloquear', lovefield.Type.BOOLEAN)
    .addColumn('costo', lovefield.Type.INTEGER)
    .addNullable(['observaciones', 'costo'])
  schemaBuilder.createTable('tmotivo_nofacturable')
    .addColumn('idtmotivo_nofacturable', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtmotivo_nofacturable'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('maccion_fact')
    .addColumn('idmaccion_fact', lovefield.Type.INTEGER)
    .addPrimaryKey(['idmaccion_fact'])
    .addColumn('idmaccion', lovefield.Type.INTEGER)
    .addForeignKey('fk_maccion_fact__maccion', {
      local: 'idmaccion',
      ref: 'maccion.idmaccion',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idarticulo_afectado', lovefield.Type.INTEGER)
    .addForeignKey('fk_maccion_fact__articulo_afectado', {
      local: 'idarticulo_afectado',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idarticulo_facturar', lovefield.Type.INTEGER)
    .addForeignKey('fk_maccion_fact__articulo_facturar', {
      local: 'idarticulo_facturar',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('regimen_fiscal')
    .addColumn('idregimen_fiscal', lovefield.Type.INTEGER)
    .addPrimaryKey(['idregimen_fiscal'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('con_iva', lovefield.Type.BOOLEAN)
    .addColumn('con_recargo', lovefield.Type.BOOLEAN)
  schemaBuilder.createTable('lparte_trabajo')
    .addColumn('idlparte_trabajo', lovefield.Type.STRING)
    .addPrimaryKey(['idlparte_trabajo'])
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lparte_trabajo__parte_trabajo', {
      local: 'idparte_trabajo',
      ref: 'parte_trabajo.idparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idlorden_trabajo', lovefield.Type.STRING)
    .addForeignKey('fk_lparte_trabajo__lorden_trabajo', {
      local: 'idlorden_trabajo',
      ref: 'lorden_trabajo.idlorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('unidades_orig', lovefield.Type.NUMBER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lparte_trabajo__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('ubicacion', lovefield.Type.STRING)
    .addColumn('numzona', lovefield.Type.STRING)
    .addColumn('evento', lovefield.Type.STRING)
    .addColumn('nserie', lovefield.Type.STRING)
    .addColumn('kilometraje', lovefield.Type.BOOLEAN)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idaccion_ejec', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabajo__accion_ejec', {
    //   local: 'idaccion_ejec',
    //   ref: 'accion_ejec.idaccion_ejec',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('nelemento', lovefield.Type.STRING)
    .addColumn('idfabricante', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabajo__fabricante', {
    //   local: 'idfabricante',
    //   ref: 'fabricante.idfabricante',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('ffabricacion', lovefield.Type.DATE_TIME)
    .addColumn('funcion', lovefield.Type.STRING)
    .addColumn('idagente_extintor', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabajo__agente_extintor', {
    //   local: 'idagente_extintor',
    //   ref: 'agente_extintor.idagente_extintor',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagente_propelente', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabajo__agente_propelente', {
    //   local: 'idagente_propelente',
    //   ref: 'agente_propelente.idagente_propelente',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('volumen', lovefield.Type.NUMBER)
    .addColumn('eficacia', lovefield.Type.STRING)
    .addColumn('presion', lovefield.Type.NUMBER)
    .addColumn('peso_total', lovefield.Type.NUMBER)
    .addColumn('peso_agente_extintor', lovefield.Type.NUMBER)
    .addColumn('idsubsis', lovefield.Type.STRING)
    .addForeignKey('fk_lparte_trabajo__subsis', {
      local: 'idsubsis',
      ref: 'subsis.idsubsis',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('facturar', lovefield.Type.BOOLEAN)
    .addColumn('idtmotivo_nofacturable', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabajo__tmotivo_nofacturable', {
    //   local: 'idtmotivo_nofacturable',
    //   ref: 'tmotivo_nofacturable.idtmotivo_nofacturable',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('netiqueta', lovefield.Type.STRING)
    .addColumn('fult_retimbrado', lovefield.Type.DATE_TIME)
    .addColumn('particion', lovefield.Type.STRING)
    .addColumn('idaccion_correctora_ot', lovefield.Type.STRING)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lparte_trabaajo__almacen', {
    //   local: 'idalmacen',
    //   ref: 'almacen.idalmacen',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('imputa_al_proyecto', lovefield.Type.BOOLEAN)
    .addNullable([
      'idlorden_trabajo', 'observaciones', 'unidades_orig', 'codigo',
      'ubicacion', 'numzona', 'evento', 'nserie', 'kilometraje',
      'orden', 'idaccion_ejec', 'nelemento', 'idfabricante', 'ffabricacion',
      'funcion', 'idagente_propelente', 'idagente_extintor', 'volumen',
      'eficacia', 'presion', 'peso_total', 'peso_agente_extintor', 'idtmotivo_nofacturable',
      'netiqueta', 'fult_retimbrado', 'particion', 'idaccion_correctora_ot'
    ])
  schemaBuilder.createTable('lorden_trabajo')
    .addColumn('idlorden_trabajo', lovefield.Type.STRING)
    .addPrimaryKey(['idlorden_trabajo'])
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lorden_trabajo__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lorden_trabajo__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idlest_orden_trabajo', lovefield.Type.INTEGER)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('iva', lovefield.Type.NUMBER)
    .addColumn('recargo', lovefield.Type.NUMBER)
    .addColumn('dto', lovefield.Type.NUMBER)
    .addColumn('observacion', lovefield.Type.STRING)
    .addColumn('ubicacion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('facturar', lovefield.Type.BOOLEAN)
    .addColumn('numzona', lovefield.Type.STRING)
    .addColumn('evento', lovefield.Type.STRING)
    .addColumn('unidades_orig', lovefield.Type.NUMBER)
    .addColumn('nserie', lovefield.Type.STRING)
    .addColumn('precio', lovefield.Type.NUMBER)
    .addColumn('nelemento', lovefield.Type.STRING)
    .addColumn('idfabricante', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lorden_trabajo__fabricante', {
    //   local: 'idfabricante',
    //   ref: 'fabricante.idfabricante',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('ffabricacion', lovefield.Type.DATE_TIME)
    .addColumn('funcion', lovefield.Type.STRING)
    .addColumn('idagente_extintor', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lorden_trabajo__agente_extintor', {
    //   local: 'idagente_extintor',
    //   ref: 'agente_extintor.idagente_extintor',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idagente_propelente', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lorden_trabajo__agente_propelente', {
    //   local: 'idagente_propelente',
    //   ref: 'agente_propelente.idagente_propelente',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('volumen', lovefield.Type.NUMBER)
    .addColumn('eficacia', lovefield.Type.STRING)
    .addColumn('presion', lovefield.Type.NUMBER)
    .addColumn('peso_total', lovefield.Type.NUMBER)
    .addColumn('peso_agente_extintor', lovefield.Type.NUMBER)
    .addColumn('idsubsis', lovefield.Type.STRING)
    .addForeignKey('fk_lorden_trabajo__subsis', {
      local: 'idsubsis',
      ref: 'subsis.idsubsis',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtmotivo_nofacturable', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lorden_trabajo__tmotivo_nofacturable', {
    //   local: 'idtmotivo_nofacturable',
    //   ref: 'tmotivo_nofacturable.idtmotivo_nofacturable',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('kilometraje', lovefield.Type.BOOLEAN)
    .addColumn('costo', lovefield.Type.INTEGER)
    .addColumn('unidades_pend_instalar', lovefield.Type.NUMBER)
    .addColumn('netiqueta', lovefield.Type.STRING)
    .addColumn('particion', lovefield.Type.STRING)
    .addColumn('imputa_al_proyecto', lovefield.Type.BOOLEAN)
    .addNullable([
      'observacion', 'ubicacion', 'numzona', 'evento', 'unidades_orig',
      'nserie', 'precio', 'nelemento', 'idfabricante', 'ffabricacion',
      'funcion', 'idagente_propelente', 'idagente_extintor', 'volumen',
      'presion', 'peso_total', 'peso_agente_extintor', 'idtmotivo_nofacturable',
      'eficacia', 'costo', 'kilometraje', 'netiqueta', 'particion',
      'imputa_al_proyecto',
    ])
  schemaBuilder.createTable('lorden_trabajo_detalle')
    .addColumn('idlorden_trabajo_detalle', lovefield.Type.STRING)
    .addPrimaryKey(['idlorden_trabajo_detalle'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lorden_trabajo__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('idlorden_trabajo', lovefield.Type.STRING)
    .addForeignKey('fk_lorden_trabajo_detalle__lorden_trabajo', {
      local: 'idlorden_trabajo',
      ref: 'lorden_trabajo.idlorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('observacion', lovefield.Type.STRING)
    .addColumn('idlest_orden_trabajo', lovefield.Type.INTEGER)
    .addColumn('costo', lovefield.Type.INTEGER)
    .addNullable(['observacion', 'costo'])
  schemaBuilder.createTable('nivel_satisfaccion')
    .addColumn('idnivel_satisfaccion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idnivel_satisfaccion'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('icono', lovefield.Type.STRING)
  schemaBuilder.createTable('parametro')
    .addColumn('idparametro', lovefield.Type.INTEGER)
    .addPrimaryKey(['idparametro'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('tipo', lovefield.Type.INTEGER)
    .addNullable(['valor', 'tipo'])
  schemaBuilder.createTable('tiempo_trabajado')
    .addColumn('idtiempo_trabajado', lovefield.Type.STRING)
    .addPrimaryKey(['idtiempo_trabajado'])
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_tiempo_trabajado__parte_trabajo', {
      local: 'idparte_trabajo',
      ref: 'parte_trabajo.idparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('finicio', lovefield.Type.DATE_TIME)
    .addColumn('ffin', lovefield.Type.DATE_TIME)
    .addColumn('idttarea', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tiempo_trabajado__ttarea', {
    //   local: 'idttarea',
    //   ref: 'ttarea.idttarea',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addForeignKey('fk_tiempo_trabajado__empleado', {
      local: 'idempleado',
      ref: 'empleado.idempleado',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('manodeobrafacturable', lovefield.Type.BOOLEAN)
    .addColumn('kilometros_facturables', lovefield.Type.BOOLEAN)
    .addColumn('kilometros', lovefield.Type.INTEGER)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('latitud_inicio', lovefield.Type.INTEGER)
    .addColumn('longitud_inicio', lovefield.Type.INTEGER)
    .addColumn('latitud_fin', lovefield.Type.INTEGER)
    .addColumn('longitud_fin', lovefield.Type.INTEGER)
    .addColumn('coste_hora', lovefield.Type.INTEGER)
    .addColumn('coste_kilometro', lovefield.Type.INTEGER)
    .addColumn('idsubsis', lovefield.Type.STRING)
    .addColumn('idttiempo_trabajado', lovefield.Type.INTEGER)
    .addColumn('idlparte_trabajo_kilometros', lovefield.Type.INTEGER)
    .addColumn('idlparte_trabajo', lovefield.Type.STRING)
    // .addForeignKey('fk_tiempo_trabajado__lparte_trabajo', {
    //   local: 'idlparte_trabajo',
    //   ref: 'lparte_trabajo.idlparte_trabajo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('es_desplazamiento', lovefield.Type.BOOLEAN)
    .addColumn('idvehiculo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tiempo_trabajado__idvehiculo', {
    //   local: 'idvehiculo',
    //   ref: 'vehiculo.idvehiculo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable([
      'idparte_trabajo', 'idempleado', 'observaciones',
      'latitud_inicio', 'longitud_inicio', 'latitud_fin',
      'longitud_fin', 'idttarea', 'coste_hora', 'coste_kilometro',
      'idsubsis', 'idttiempo_trabajado', 'idlparte_trabajo_kilometros',
      'idlparte_trabajo', 'idvehiculo'
    ])
  schemaBuilder.createTable('vehiculo')
    .addColumn('idvehiculo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idvehiculo'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idempleado', lovefield.Type.INTEGER)
    // .addForeignKey('fk_vehiculo__empleado', {
    //   local: 'idempleado',
    //   ref: 'empleado.idempleado',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('matricula', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addNullable([
      'observaciones', 'idempleado'
    ])
  schemaBuilder.createTable('ttarea')
    .addColumn('idttarea', lovefield.Type.INTEGER)
    .addPrimaryKey(['idttarea'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('tecnico_ttarea')
    .addColumn('idtecnico_ttarea', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtecnico_ttarea'])
    .addColumn('idttarea', lovefield.Type.INTEGER)
    .addForeignKey('fk_tecnico_ttarea__ttarea', {
      local: 'idttarea',
      ref: 'ttarea.idttarea',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idtecnico', lovefield.Type.INTEGER)
    .addForeignKey('fk_tecnico_ttarea__tecnico', {
      local: 'idtecnico',
      ref: 'tecnico.idtecnico',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('por_defecto', lovefield.Type.BOOLEAN)
    .addColumn('coste_hora', lovefield.Type.INTEGER)
    .addNullable(['por_defecto', 'coste_hora'])
  schemaBuilder.createTable('orden_trabajo_subsis')
    .addColumn('idorden_trabajo_subsis', lovefield.Type.STRING)
    .addPrimaryKey(['idorden_trabajo_subsis'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_orden_trabajo_subsis__orden_trabajo', {
      local: 'idorden_trabajo',
      ref: 'orden_trabajo.idorden_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idsubsis', lovefield.Type.STRING)
    // .addForeignKey('fk_orden_trabajo_subsis__subsis', {
    //   local: 'idsubsis',
    //   ref: 'subsis.idsubsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable(['idsubsis'])
  schemaBuilder.createTable('tarifa')
    .addColumn('idtarifa', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtarifa'])
    .addColumn('idttarifa', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_tarifa__articulo', {
    //   local: 'idarticulo',
    //   ref: 'articulo.idarticulo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('dto', lovefield.Type.INTEGER)
    .addColumn('precio', lovefield.Type.INTEGER)
    .addColumn('precio_ins', lovefield.Type.INTEGER)
    .addNullable(['precio', 'precio_ins', 'idarticulo'])
  schemaBuilder.createTable('idioma')
    .addColumn('ididioma', lovefield.Type.INTEGER)
    .addPrimaryKey(['ididioma'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addNullable(['descripcion'])
  schemaBuilder.createTable('traduccion')
    .addColumn('idtraduccion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtraduccion'])
    .addColumn('idfila', lovefield.Type.INTEGER)
    .addColumn('tabla', lovefield.Type.STRING)
    .addColumn('campo', lovefield.Type.STRING)
    .addColumn('ididioma', lovefield.Type.INTEGER)
    .addForeignKey('fk_traduccion__idioma', {
      local: 'ididioma',
      ref: 'idioma.ididioma',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('traduccion_campo', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('sistema_telefono')
    .addColumn('idsistema_telefono', lovefield.Type.STRING)
    .addPrimaryKey(['idsistema_telefono'])
    .addColumn('idcliente_telefono', lovefield.Type.STRING)
    .addForeignKey('fk_sistema_telefono__cliente_telefono', {
      local: 'idcliente_telefono',
      ref: 'cliente_telefono.idcliente_telefono',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_telefono__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('es_responsable_propiedad', lovefield.Type.BOOLEAN)
    .addColumn('orden_cra', lovefield.Type.STRING)
    .addColumn('codigo_cra', lovefield.Type.STRING)
    .addColumn('codigo_coaccion_cra', lovefield.Type.STRING)
    .addNullable(['orden_cra', 'codigo_cra', 'codigo_coaccion_cra'])
  schemaBuilder.createTable('ttelefono')
    .addColumn('idttelefono', lovefield.Type.INTEGER)
    .addPrimaryKey(['idttelefono'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('talmacen')
    .addColumn('idtalmacen', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtalmacen'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('clasificacion', lovefield.Type.INTEGER)
  schemaBuilder.createTable('tmotivo_bloqueo_ot')
    .addColumn('idtmotivo_bloqueo_ot', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtmotivo_bloqueo_ot'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('ocultar_parte_movilidad', lovefield.Type.BOOLEAN)
    .addNullable(['ocultar_parte_movilidad'])
  schemaBuilder.createTable('sync_upload')
    .addColumn('idsync_upload', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsync_upload'])
    .addColumn('timestamp', lovefield.Type.DATE_TIME)
    .addColumn('count_ok', lovefield.Type.INTEGER)
    .addColumn('count_errors', lovefield.Type.INTEGER)
    .addColumn('count_total', lovefield.Type.INTEGER)
    .addColumn('errors', lovefield.Type.STRING)
    .addColumn('synced', lovefield.Type.BOOLEAN)
    .addNullable(['count_ok', 'count_errors', 'errors', 'count_total'])
  schemaBuilder.createTable('sync')
    .addColumn('idsync', lovefield.Type.STRING)
    .addPrimaryKey(['idsync'])
    .addColumn('idsync_upload', lovefield.Type.INTEGER)
    // .addForeignKey('fk_sync__sync_upload', {
    //   local: 'idsync_upload',
    //   ref: 'sync_upload.idsync_upload',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('timestamp', lovefield.Type.DATE_TIME)
    .addColumn('pending', lovefield.Type.BOOLEAN)
    .addColumn('result', lovefield.Type.INTEGER)
    .addColumn('error_message', lovefield.Type.STRING)
    .addColumn('method', lovefield.Type.STRING)
    .addColumn('params', lovefield.Type.OBJECT)
    // idusuario -> no debería ser nullable pero el upgrade de la BD se podría ejecutar
    // sin un usuario logado
    .addColumn('idusuario', lovefield.Type.INTEGER)
    .addNullable(['idsync_upload', 'result', 'error_message', 'params', 'idusuario'])
  schemaBuilder.createTable('tservicio_contratado')
    .addColumn('idtservicio_contratado', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtservicio_contratado'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idtclasificacion_servicio', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['idtclasificacion_servicio', 'orden'])
  schemaBuilder.createTable('concepto_cuota')
    .addColumn('idconcepto_cuota', lovefield.Type.INTEGER)
    .addPrimaryKey(['idconcepto_cuota'])
    .addColumn('descripcion_cuota', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('concepto_cuota_tservicio')
    .addColumn('idconcepto_cuota_tservicio', lovefield.Type.INTEGER)
    .addPrimaryKey(['idconcepto_cuota_tservicio'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idconcepto_cuota', lovefield.Type.INTEGER)
    .addForeignKey('fk_concepto_cuota_tservicio__concepto_cuota', {
      local: 'idconcepto_cuota',
      ref: 'concepto_cuota.idconcepto_cuota',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idtservicio_contratado', lovefield.Type.INTEGER)
    .addForeignKey('fk_concepto_cuota_tservicio__tservicio_contratado', {
      local: 'idtservicio_contratado',
      ref: 'tservicio_contratado.idtservicio_contratado',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
  schemaBuilder.createTable('sistema_cuota')
    .addColumn('idsistema_cuota', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsistema_cuota'])
    .addColumn('idconcepto_cuota', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_cuota__concepto_cuota', {
      local: 'idconcepto_cuota',
      ref: 'concepto_cuota.idconcepto_cuota',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_cuota__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('fcontratacion', lovefield.Type.DATE_TIME)
    .addColumn('ffin_cuota', lovefield.Type.DATE_TIME)
    .addColumn('ffin_servicio', lovefield.Type.DATE_TIME)
    .addColumn('fproxima_cuota', lovefield.Type.DATE_TIME)
    .addNullable(['ffin_cuota', 'ffin_servicio', 'fproxima_cuota'])
  schemaBuilder.createTable('tstock')
    .addColumn('idtstock', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtstock'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('ver_informes', lovefield.Type.BOOLEAN)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('stock')
    .addColumn('idstock', lovefield.Type.INTEGER)
    .addPrimaryKey(['idstock'])
    .addColumn('idtstock', lovefield.Type.INTEGER)
    .addForeignKey('fk_stock__tstock', {
      local: 'idtstock',
      ref: 'tstock.idtstock',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    .addForeignKey('fk_stock__almacen', {
      local: 'idalmacen',
      ref: 'almacen.idalmacen',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_stock__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('estado', lovefield.Type.INTEGER)
  schemaBuilder.createTable('lstock')
    .addColumn('idlstock', lovefield.Type.INTEGER)
    .addPrimaryKey(['idlstock'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idstock', lovefield.Type.INTEGER)
    .addForeignKey('fk_lstock__stock', {
      local: 'idstock',
      ref: 'stock.idstock',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('nserie', lovefield.Type.STRING)
  schemaBuilder.createTable('empleado_caja')
    .addColumn('idempleado_caja', lovefield.Type.INTEGER)
    .addPrimaryKey(['idempleado_caja'])
    .addColumn('estado', lovefield.Type.STRING)
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addForeignKey('fk_empleado_caja__empleado', {
      local: 'idempleado',
      ref: 'empleado.idempleado',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idcaja', lovefield.Type.INTEGER)
    .addForeignKey('fk_empleado_caja__caja', {
      local: 'idcaja',
      ref: 'caja.idcaja',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
  schemaBuilder.createTable('anticipocli')
    .addColumn('idanticipocli', lovefield.Type.STRING)
    .addPrimaryKey(['idanticipocli'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idcliente', lovefield.Type.INTEGER)
    // .addForeignKey('fk_anticipocli__cliente', {
    //   local: 'idcliente',
    //   ref: 'cliente.idcliente',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idforma_pago', lovefield.Type.INTEGER)
    .addForeignKey('fk_anticipocli__forma_pago', {
      local: 'idforma_pago',
      ref: 'forma_pago.idforma_pago',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idcondicion_pago', lovefield.Type.INTEGER)
    // .addForeignKey('fk_anticipocli__condicion_pago', {
    //   local: 'idcondicion_pago',
    //   ref: 'condicion_pago.idcondicion_pago',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idcaja', lovefield.Type.INTEGER)
    .addForeignKey('fk_anticipocli__caja', {
      local: 'idcaja',
      ref: 'caja.idcaja',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idempresa', lovefield.Type.INTEGER)
    .addForeignKey('fk_anticipocli__empresa', {
      local: 'idempresa',
      ref: 'empresa.idempresa',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_anticipocli__orden_trabajo', {
    //   local: 'idorden_trabajo',
    //   ref: 'orden_trabajo.idorden_trabajo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('fanticipo', lovefield.Type.DATE_TIME)
    .addColumn('importe', lovefield.Type.NUMBER)
    .addColumn('consumido', lovefield.Type.BOOLEAN)
    .addColumn('comentario', lovefield.Type.STRING)
    .addNullable(['idcliente', 'idcondicion_pago', 'comentario', 'idorden_trabajo'])
  schemaBuilder.createTable('tsistema_tsubsis')
    .addColumn('idtsistema_tsubsis', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtsistema_tsubsis'])
    .addColumn('idtsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_tsistema_tsubsis__tsistema', {
      local: 'idtsistema',
      ref: 'tsistema.idtsistema',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    .addForeignKey('fk_tsistema_tsubsis__tsubsis', {
      local: 'idtsubsis',
      ref: 'tsubsis.idtsubsis',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
  schemaBuilder.createTable('plantilla_articulo')
    .addColumn('idplantilla_articulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idplantilla_articulo'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('plantilla_grupo_articulo')
    .addColumn('idplantilla_grupo_articulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idplantilla_grupo_articulo'])
    .addColumn('idplantilla_articulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_plantilla_grupo_articulo__plantilla_articulo', {
      local: 'idplantilla_articulo',
      ref: 'plantilla_articulo.idplantilla_articulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idtsubsis', lovefield.Type.INTEGER)
    // .addForeignKey('fk_plantilla_grupo_articulo__tsubsis', {
    //   local: 'idtsubsis',
    //   ref: 'tsubsis.idtsubsis',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable(['idtsubsis'])
  schemaBuilder.createTable('lplantilla_articulo')
    .addColumn('idlplantilla_articulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idlplantilla_articulo'])
    .addColumn('idplantilla_grupo_articulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lplantilla_articulo__plantilla_grupo_articulo', {
      local: 'idplantilla_grupo_articulo',
      ref: 'plantilla_grupo_articulo.idplantilla_grupo_articulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lplantilla_articulo__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('unidades', lovefield.Type.NUMBER)
  schemaBuilder.createTable('marca')
    .addColumn('idmarca', lovefield.Type.INTEGER)
    .addPrimaryKey(['idmarca'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('grupo_descuento')
    .addColumn('idgrupo_descuento', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrupo_descuento'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('banco_precio')
    .addColumn('idbanco_precio', lovefield.Type.INTEGER)
    .addPrimaryKey(['idbanco_precio'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('definir_escandallo', lovefield.Type.BOOLEAN)
    .addColumn('prioridad_dto', lovefield.Type.INTEGER)
  schemaBuilder.createTable('banco_precio_capitulo')
    .addColumn('idbanco_precio_capitulo', lovefield.Type.INTEGER)
    .addPrimaryKey(['idbanco_precio_capitulo'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idbanco_precio', lovefield.Type.INTEGER)
    .addForeignKey('fk_banco_precio_capitulo__banco_precio', {
      local: 'idbanco_precio',
      ref: 'banco_precio.idbanco_precio',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idbanco_precio_capitulo_padre', lovefield.Type.INTEGER)
    // .addForeignKey('fk_banco_precio_capitulo__banco_precio_capitulo', {
    //   local: 'idbanco_precio_capitulo_padre',
    //   ref: 'banco_precio_capitulo.idbanco_precio_capitulo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['idbanco_precio_capitulo_padre'])
  schemaBuilder.createTable('lbanco_precio')
    .addColumn('idlbanco_precio', lovefield.Type.INTEGER)
    .addPrimaryKey(['idlbanco_precio'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idbanco_precio', lovefield.Type.INTEGER)
    .addForeignKey('fk_lbanco_precio__banco_precio', {
      local: 'idbanco_precio',
      ref: 'banco_precio.idbanco_precio',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idbanco_precio_capitulo', lovefield.Type.INTEGER)
    // .addForeignKey('fk_lbanco_precio__banco_precio_capitulo', {
    //   local: 'idbanco_precio_capitulo',
    //   ref: 'banco_precio_capitulo.idbanco_precio_capitulo',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lbanco_precio__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('precio', lovefield.Type.NUMBER)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('dto', lovefield.Type.NUMBER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('coste', lovefield.Type.NUMBER)
    .addNullable(['idbanco_precio_capitulo'])
  schemaBuilder.createTable('lbanco_precio_detalle')
    .addColumn('idlbanco_precio_detalle', lovefield.Type.INTEGER)
    .addPrimaryKey(['idlbanco_precio_detalle'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idlbanco_precio', lovefield.Type.INTEGER)
    .addForeignKey('fk_lbanco_precio_detalle__lbanco_precio', {
      local: 'idlbanco_precio',
      ref: 'lbanco_precio.idlbanco_precio',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_lbanco_precio_detalle__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('precio', lovefield.Type.NUMBER)
    .addColumn('unidades', lovefield.Type.NUMBER)
    .addColumn('dto', lovefield.Type.NUMBER)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('coste', lovefield.Type.NUMBER)
  schemaBuilder.createTable('banco_precio_grupo_dto')
    .addColumn('idbanco_precio_grupo_dto', lovefield.Type.INTEGER)
    .addPrimaryKey(['idbanco_precio_grupo_dto'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idbanco_precio', lovefield.Type.INTEGER)
    .addForeignKey('fk_banco_precio_grupo_dto__banco_precio', {
      local: 'idbanco_precio',
      ref: 'banco_precio.idbanco_precio',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idgrupo_descuento', lovefield.Type.INTEGER)
    .addForeignKey('fk_banco_precio_grupo_dto__grupo_descuento', {
      local: 'idgrupo_descuento',
      ref: 'grupo_descuento.idgrupo_descuento',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('dto', lovefield.Type.NUMBER)
  schemaBuilder.createTable('toperacion_impuesto')
    .addColumn('idtoperacion_impuesto', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtoperacion_impuesto'])
    .addColumn('idtimpuesto', lovefield.Type.INTEGER)
    .addForeignKey('fk_toperacion_impuesto__timpuesto', {
      local: 'idtimpuesto',
      ref: 'timpuesto.idtimpuesto',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('tdato_sistema')
    .addColumn('idtdato_sistema', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtdato_sistema'])
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('tipo', lovefield.Type.INTEGER)
    .addColumn('opcional', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addNullable([])
  schemaBuilder.createTable('dato_sistema')
    .addColumn('iddato_sistema', lovefield.Type.INTEGER)
    .addPrimaryKey(['iddato_sistema'])
    .addColumn('idtdato_sistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_dato_sistema__tdato_sistema', {
      local: 'idtdato_sistema',
      ref: 'tdato_sistema.idtdato_sistema',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_dato_sistema__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('valor', lovefield.Type.STRING)
    .addColumn('orden', lovefield.Type.INTEGER)
    .addNullable(['valor'])
  schemaBuilder.createTable('tsistema_tdatosist')
    .addColumn('idtsistema_tdatosist', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtsistema_tdatosist'])
    .addColumn('idtsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_tsistema_tdatosist____tsistema', {
      local: 'idtsistema',
      ref: 'tsistema.idtsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtdato_sistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_tsistema_tdatosist__tdato_sistema', {
      local: 'idtdato_sistema',
      ref: 'tdato_sistema.idtdato_sistema',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
  schemaBuilder.createTable('tviacomunicacion')
    .addColumn('idtviacomunicacion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtviacomunicacion'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('ttransmisor')
    .addColumn('idttransmisor', lovefield.Type.INTEGER)
    .addPrimaryKey(['idttransmisor'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
  schemaBuilder.createTable('sistema_tviacomunicacion')
    .addColumn('idsistema_tviacomunicacion', lovefield.Type.INTEGER)
    .addPrimaryKey(['idsistema_tviacomunicacion'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_tviacomunicacion__sistema', {
      local: 'idsistema',
      ref: 'sistema.idsistema',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idtviacomunicacion', lovefield.Type.INTEGER)
    .addForeignKey('fk_sistema_tviacomunicacion__tviacomunicacion', {
      local: 'idtviacomunicacion',
      ref: 'tviacomunicacion.idtviacomunicacion',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('orden', lovefield.Type.INTEGER)
    .addColumn('n_serie', lovefield.Type.STRING)
    .addColumn('numero', lovefield.Type.STRING)
    .addColumn('falta_via', lovefield.Type.DATE_TIME)
    .addColumn('fbaja_via', lovefield.Type.DATE_TIME)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('idttransmisor', lovefield.Type.INTEGER)
    // .addForeignKey('fk_sistema_tviacomunicacion__ttransmisor', {
    //   local: 'idttransmisor',
    //   ref: 'ttransmisor.idttransmisor',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('idmaterial_sistema', lovefield.Type.INTEGER)
    // .addForeignKey('fk_sistema_tviacomunicacion__material_sistema', {
    //   local: 'idmaterial_sistema',
    //   ref: 'material_sistema.idmaterial_sistema',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('nabonado', lovefield.Type.STRING)
    .addColumn('fcomprobacion', lovefield.Type.DATE_TIME)
    .addNullable(['n_serie',
      'numero',
      'falta_via',
      'fbaja_via',
      'observaciones',
      'idttransmisor',
      'idmaterial_sistema',
      'nabonado',
      'fcomprobacion',
    ])
  // Solicitud de material
  schemaBuilder.createTable('solicitud_material')
    .addColumn('idsolicitud_material', lovefield.Type.STRING)
    .addPrimaryKey(['idsolicitud_material'])
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addColumn('iddelegacion', lovefield.Type.INTEGER)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addColumn('observaciones', lovefield.Type.STRING)
    .addColumn('atencion_de', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idest_solicitud_material', lovefield.Type.INTEGER)
    .addColumn('idserie_solicitud_mat', lovefield.Type.INTEGER)
    .addColumn('nsolicitud_mat', lovefield.Type.INTEGER)
    .addColumn('fsolicitud', lovefield.Type.DATE_TIME)
    .addColumn('fsolicitada_entrega', lovefield.Type.DATE_TIME)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idgrupo_compra', lovefield.Type.INTEGER)
    .addColumn('idorden_trabajo', lovefield.Type.INTEGER)
    .addColumn('idproyecto_contable', lovefield.Type.INTEGER)
    .addColumn('idgrupo_empleado', lovefield.Type.INTEGER)
    // .addForeignKey('fk_solicitud_material__grupo_empleado', {
    //   local: 'idgrupo_empleado',
    //   ref: 'grupo_empleado.idgrupo_empleado',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addNullable([
      'idparte_trabajo',
      'observaciones',
      'atencion_de',
      'fsolicitada_entrega',
      'descripcion',
      'idorden_trabajo',
      'idproyecto_contable',
      'idserie_solicitud_mat',
      'idgrupo_compra',
      'nsolicitud_mat',
      'iddelegacion',
      'idalmacen',
      'estado',
      'idgrupo_empleado',
    ])
  schemaBuilder.createTable('lsolicitud_material')
    .addColumn('idlsolicitud_material', lovefield.Type.STRING)
    .addPrimaryKey(['idlsolicitud_material'])
    .addColumn('idsolicitud_material', lovefield.Type.STRING)
    .addForeignKey('fk_lsolicitud_material__solicitud_material', {
      local: 'idsolicitud_material',
      ref: 'solicitud_material.idsolicitud_material',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idest_lsolicitud_material', lovefield.Type.INTEGER)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addColumn('unidades_reservadas', lovefield.Type.INTEGER)
    // .addNullable([])
  schemaBuilder.createTable('llsolicitud_material')
    .addColumn('idllsolicitud_material', lovefield.Type.STRING)
    .addPrimaryKey(['idllsolicitud_material'])
    .addColumn('idlsolicitud_material', lovefield.Type.STRING)
    .addForeignKey('fk_llsolicitud_material__lsolicitud_material', {
      local: 'idlsolicitud_material',
      ref: 'lsolicitud_material.idlsolicitud_material',
      action: lovefield.ConstraintAction.CASCADE,
      timing: lovefield.ConstraintTiming.IMMEDIATE
    })
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idest_llsolicitud_material', lovefield.Type.INTEGER)
    .addColumn('idlorden_trabajo_detalle', lovefield.Type.INTEGER)
    // .addForeignKey('fk_llsolicitud_material__lorden_trabajo_detalle', {
    //   local: 'idlorden_trabajo_detalle',
    //   ref: 'lorden_trabajo_detalle.idlorden_trabajo_detalle',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addColumn('unidades', lovefield.Type.INTEGER)
    .addNullable(['idlorden_trabajo_detalle', ])
  schemaBuilder.createTable('grupo_empleado')
    .addColumn('idgrupo_empleado', lovefield.Type.INTEGER)
    .addPrimaryKey(['idgrupo_empleado'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addNullable([])
  schemaBuilder.createTable('v_almacenes_parte')
    .addColumn('idv_almacenes_parte', lovefield.Type.INTEGER)
    .addPrimaryKey(['idv_almacenes_parte'])
    .addColumn('idparte_trabajo', lovefield.Type.INTEGER)
    .addForeignKey('fk_v_almacenes_parte__parte_trabajo', {
      local: 'idparte_trabajo',
      ref: 'parte_trabajo.idparte_trabajo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    // Puede que el almacén no lo tenga descargado
    // .addForeignKey('fk_v_almacenes_parte__almacen', {
    //   local: 'idalmacen',
    //   ref: 'almacen.idalmacen',
    //   action: lovefield.ConstraintAction.RESTRICT,
    //   timing: lovefield.ConstraintTiming.DEFERRABLE
    // })
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('talmacen_desc', lovefield.Type.STRING)
  schemaBuilder.createTable('tnota')
    .addColumn('idtnota', lovefield.Type.INTEGER)
    .addPrimaryKey(['idtnota'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('visible_movilidad_sat', lovefield.Type.INTEGER)
    .addColumn('visible_movilidad_comercial', lovefield.Type.INTEGER)
    .addColumn('visible_movilidad_vigilancia', lovefield.Type.INTEGER)
    .addNullable([])
  schemaBuilder.createTable('nota')
    .addColumn('idnota', lovefield.Type.INTEGER)
    .addPrimaryKey(['idnota'])
    .addColumn('idtabla', lovefield.Type.INTEGER)
    .addColumn('idtnota', lovefield.Type.INTEGER)
    .addForeignKey('fk_nota__tnota', {
      local: 'idtnota',
      ref: 'tnota.idtnota',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('visible_movilidad_sat', lovefield.Type.INTEGER)
    .addColumn('visible_movilidad_comercial', lovefield.Type.INTEGER)
    .addColumn('visible_movilidad_vigilancia', lovefield.Type.INTEGER)
    .addColumn('titulo', lovefield.Type.STRING)
    .addColumn('fnota', lovefield.Type.DATE_TIME)
    .addColumn('idempleado', lovefield.Type.INTEGER)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('identificador', lovefield.Type.INTEGER)
    // http://google.github.io/lovefield/error_lookup/src/error_lookup.html?c=546&p0=nota
    .addColumn('nota_texto', lovefield.Type.STRING)
    .addNullable(['nota_texto'])
  schemaBuilder.createTable('articulo_codbarras')
    .addColumn('idarticulo_codbarras', lovefield.Type.INTEGER)
    .addPrimaryKey(['idarticulo_codbarras'])
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idarticulo', lovefield.Type.INTEGER)
    .addForeignKey('fk_articulo_codbarras__articulo', {
      local: 'idarticulo',
      ref: 'articulo.idarticulo',
      action: lovefield.ConstraintAction.RESTRICT,
      timing: lovefield.ConstraintTiming.DEFERRABLE
    })
    .addColumn('codbarras', lovefield.Type.STRING)
    .addNullable([])
  schemaBuilder.createTable('proyecto_contable')
    .addColumn('idproyecto_contable', lovefield.Type.INTEGER)
    .addPrimaryKey(['idproyecto_contable'])
    .addColumn('fcierre', lovefield.Type.DATE_TIME)
    .addColumn('idestado_proyecto', lovefield.Type.INTEGER)
    .addColumn('idalmacen', lovefield.Type.INTEGER)
    .addColumn('descripcion', lovefield.Type.STRING)
    .addColumn('estado', lovefield.Type.INTEGER)
    .addColumn('idempresa', lovefield.Type.INTEGER)
    .addColumn('codigo', lovefield.Type.STRING)
    .addColumn('idcliente', lovefield.Type.INTEGER)
    .addColumn('idsistema', lovefield.Type.INTEGER)
    .addColumn('idtproyecto_contable', lovefield.Type.INTEGER)
    .addColumn('finicio', lovefield.Type.DATE_TIME)
    .addNullable(['fcierre', 'idestado_proyecto', 'idalmacen', 'idcliente', 'idsistema', ])
  return schemaBuilder
}

export const connect = (schema) => {
  return schema.connect({
    storeType: lovefield.schema.DataStoreType.INDEXED_DB,
    enableInspector: process.env.NODE_ENV === 'development',
    onUpgrade: (rawDb) => {
      doUpgrade(rawDb)
      return rawDb.dump()
    }
  })
}

export const getDatabaseVersion = (db) => {
  return db.getSchema().version()
}

export const getTables = (db) => {
  return db.getSchema().tables()
}

export const getTable = (db, name) => {
  for (let t of db.getSchema().tables()) {
    if (t.getName() === name) {
      return t
    }
  }
}

export const likeRegExp = (q) => {
  // Escape string for use in Javascript regex
  // https://stackoverflow.com/a/6969486
  let escapedQ = q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  escapedQ = escapedQ.replaceAll(' ', '.*')
  const likeExpr = '.*' + escapedQ + '.*'
  return new RegExp(likeExpr, 'i')
}

export const tablasGlobalesDescargadas = async (Vue) => {
  const parametrosCount = await Vue.$offline.parametro.count()
  return parametrosCount[0].count > 0
}