import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'
import { GRADO_ANOMALIA, PREGUNTA_CHECKLIST, GRUPO_CHECKLIST } from '@/utils/consts'

export default class PreguntaChecklistOtOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'preguntaChecklistOt.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    const allowedRemoteParams = [
      'idpregunta_checklist_ot',
      'idgrado_anomalia',
      'idtecnico',
      'valor',
      'resultado',
      'observaciones',
      'notas_internas',
    ]
    await this.Vue.$offline.sync.insert({
      method: 'preguntaChecklistOt.updateSync',
      params: {
        values: _.pickBy(values, (value, key) => allowedRemoteParams.indexOf(key) >= 0),
      },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idpreguntaChecklistOt) {
    const tables = this.db.tables
    // eliminar anomalias en cascada
    const anomalias = await this.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .where(
        this.db.op.and(
          tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt),
        )
      )
      .exec()
    for (const anomalia of anomalias) {
      await this.Vue.$offline.tanomaliaChecklistOt.deleteSync(anomalia.idtanomalia_checklist_ot)
    }
    await this.Vue.$offline.sync.insert({
      method: 'preguntaChecklistOt.deleteSync',
      params: {
        idpregunta_checklist_ot: idpreguntaChecklistOt
      }
    })
    await this.Vue.$offline.preguntaChecklistOt.delete({
      where: tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt)
    })
  }
  async deleteAnomaliasSync (idpreguntaChecklistOt, idtecnico) {
    const tables = this.db.tables
    const anomalias = await this.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .where(
        this.db.op.and(
          tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt),
        )
      )
      .exec()
    for (const anomalia of anomalias) {
      await this.Vue.$offline.tanomaliaChecklistOt.deleteSync(
        anomalia.idtanomalia_checklist_ot
      )
    }
    // actualizar el grado de anomalía de la pregunta
    // al máximo orden de los grados de anomalía de sus anomalías
    await this.Vue.$offline.preguntaChecklistOt.updateMaxGradoSync(idpreguntaChecklistOt, idtecnico)
  }
  async updateMaxGradoSync (idPreguntaChecklistOt, idtecnico) {
    const tables = this.db.tables
    const maxOrden = await this.db
      .select(this.db.fn.max(tables.grado_anomalia.orden).as('max_orden'))
      .from(tables.grado_anomalia)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.grado_anomalia.idgrado_anomalia.eq(tables.tanomalia_checklist_ot.idgrado_anomalia)
      )
      .where(
        this.db.op.and(
          tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(idPreguntaChecklistOt),
          tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.incorrecto),
        )
      )
      .exec()
    if (maxOrden[0].max_orden !== null) {
      // con anomalías
      const maxGradoAnomalia = await this.db
        .select(tables.grado_anomalia.idgrado_anomalia)
        .from(tables.grado_anomalia)
        .where(
          this.db.op.and(
            tables.grado_anomalia.orden.eq(maxOrden[0].max_orden),
            tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.incorrecto),
          )
        )
        .exec()
      await this.db
        .update(tables.pregunta_checklist_ot)
        .set(tables.pregunta_checklist_ot.idgrado_anomalia, maxGradoAnomalia[0].idgrado_anomalia)
        .set(tables.pregunta_checklist_ot.resultado, PREGUNTA_CHECKLIST.resultado.conAnomalias)
        .set(tables.pregunta_checklist_ot.idtecnico, idtecnico)
        .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idPreguntaChecklistOt))
        .exec()
      await this.Vue.$offline.sync.insert({
        method: 'preguntaChecklistOt.updateSync',
        params:
          {
            values: {
              idpregunta_checklist_ot: idPreguntaChecklistOt,
              idgrado_anomalia: maxGradoAnomalia[0].idgrado_anomalia,
              idtecnico: idtecnico,
              resultado: PREGUNTA_CHECKLIST.resultado.conAnomalias
            }
          }
      })
    } else {
      // sin anomalías, deja la pregunta sin contestar
      await this.db
        .update(tables.pregunta_checklist_ot)
        .set(tables.pregunta_checklist_ot.idgrado_anomalia, null)
        .set(tables.pregunta_checklist_ot.resultado, null)
        .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idPreguntaChecklistOt))
        .exec()
      await this.Vue.$offline.sync.insert({
        method: 'preguntaChecklistOt.updateSync',
        params:
          {
            values: {
              idpregunta_checklist_ot: idPreguntaChecklistOt,
              idgrado_anomalia: null,
              resultado: null
            }
          }
      })
    }
  }
  async inPreguntasGeneral (idchecklistOt) {
    const tables = this.db.tables
    return _.map(
      await this.db
        .select(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        )
        .from(tables.grupo_checklist_ot)
        .innerJoin(
          tables.pregunta_checklist_ot,
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
        )
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
            tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.generales)
          )
        )
        .exec(),
      'pregunta_checklist_ot.idpregunta_checklist_ot'
    )
  }
  async inPreguntasSubsistema (idparteTrabajo, idchecklistOt) {
    // no se filtra por subsistemas afectados porque no hay una tabla PARTE_MONTAJE_SUBSIS
    // tirando de PARTE_MONTAJE_MATSIS -> ORDEN_TRABAJO_MATSIST -> MATERIAL_SISTEMA -> SUBSIS
    // solo se contemplan las preguntas de subsistema sin hay material
    const tables = this.db.tables
    return _.map(
      await this.db
        .select(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        )
        .from(tables.grupo_checklist_ot)
        .innerJoin(
          tables.pregunta_checklist_ot,
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
        )
        .innerJoin(
          tables.checklist_ot_subsis,
          tables.grupo_checklist_ot.idchecklist_ot_subsis.eq(tables.checklist_ot_subsis.idchecklist_ot_subsis)
        )
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
            tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.subsistemas),
          )
        )
        .exec(),
      'pregunta_checklist_ot.idpregunta_checklist_ot'
    )
  }
  async inPreguntasMaterial (idparteTrabajo, idchecklistOt) {
    const tables = this.db.tables
    const inSubsisAfectados = _.map(
      await this.Vue.$offline.parteTrabajoMatsist.selectSubsisAfectados(
        idparteTrabajo
      ),
      'idsubsis'
    )
    return _.map(
      await this.db
        .select(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        )
        .from(tables.grupo_checklist_ot)
        .innerJoin(
          tables.pregunta_checklist_ot,
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
        )
        .innerJoin(
          tables.orden_trabajo_matsist,
          tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
        )
        .innerJoin(
          tables.material_sistema,
          tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
        )
        .innerJoin(
          tables.subsis,
          tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
        )
        .where(
          this.db.op.and(
            tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
            tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.materiales),
            tables.material_sistema.idsubsis.in(inSubsisAfectados)
          )
        )
        .exec(),
      'pregunta_checklist_ot.idpregunta_checklist_ot'
    )
  }
  async selectMaterialSistemaDePreguntaChecklistOt (idpreguntaChecklistOt) {
    const tables = this.db.tables
    return (await this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt),
          tables.pregunta_checklist_ot.estado.gt(0)
        )
      ).exec())[0]
  }
}
