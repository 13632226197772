import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'

export default class SistemaTelefonoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    const allowedRemoteParams = [
      'idsistema_telefono',
      'idcliente_telefono',
      'idsistema',
      'estado',
      'orden_cra',
      'codigo_cra',
      'codigo_coaccion_cra',
    ]
    await this.Vue.$offline.sync.insert({
      method: 'sistemaTelefono.insertSync',
      params: {
        values: _.pickBy(values, (value, key) => allowedRemoteParams.indexOf(key) >= 0),
      },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'sistemaTelefono.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idsistemaTelefono) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'sistemaTelefono.deleteSync',
      params: { idsistema_telefono: idsistemaTelefono }
    })
    await this.Vue.$offline.sistemaTelefono.delete({
      where: tables.sistema_telefono.idsistema_telefono.eq(idsistemaTelefono)
    })
  }
  async selectContactosDelSistema (idsistema) {
    // los contactos del sistema son: los de SISTEMA_TELEFONO + CLIENTE_TELEFONO con para_todos_los_sistemas = 1
    const tables = this.db.tables
    const sistemaTelefono = await this.Vue.$offline.db
      .select()
      .from(tables.sistema_telefono)
      .innerJoin(
        tables.cliente_telefono,
        tables.sistema_telefono.idcliente_telefono.eq(tables.cliente_telefono.idcliente_telefono)
      )
      .where(
        this.db.op.and(
          tables.cliente_telefono.estado.gt(0),
          tables.sistema_telefono.idsistema.eq(idsistema),
          tables.sistema_telefono.estado.gt(0),
        )
      )
      .exec()
    const clienteTelefonoRes = this.Vue.$offline.clienteTelefono
    const sistema = await this.Vue.$offline.sistema.row(idsistema)
    const clienteTelefono = await this.Vue.$offline.db
      .select()
      .from(tables.cliente_telefono)
      .innerJoin(clienteTelefonoRes.dummyTable, clienteTelefonoRes.pk.eq(clienteTelefonoRes.dummyPk))
      .where(
        this.db.op.and(
          tables.cliente_telefono.estado.gt(0),
          tables.cliente_telefono.idcliente.eq(sistema.sistema.idcliente),
          tables.cliente_telefono.para_todos_los_sistemas.eq(1)
        )
      )
      .exec()
    return _.uniqBy(_.concat(clienteTelefono, sistemaTelefono),'cliente_telefono.idcliente_telefono')
  }
  async selectConSAT (idsistema) {
    // los contactos del sistema son: los de SISTEMA_TELEFONO + CLIENTE_TELEFONO con para_todos_los_sistemas = 1
    // Esto hace lo mismo que selectContactosDelSistema, sólo que añade el filtro de email_notifica_sat
    const tables = this.db.tables
    const sistemaTelefono = await this.Vue.$offline.db
      .select()
      .from(tables.sistema_telefono)
      .innerJoin(
        tables.cliente_telefono,
        tables.sistema_telefono.idcliente_telefono.eq(tables.cliente_telefono.idcliente_telefono)
      )
      .where(
        this.db.op.and(
          tables.cliente_telefono.estado.gt(0),
          tables.sistema_telefono.idsistema.eq(idsistema),
          tables.sistema_telefono.estado.gt(0),
          tables.cliente_telefono.email_notifica_sat.eq(true),
        )
      )
      .exec()
    const clienteTelefonoRes = this.Vue.$offline.clienteTelefono
    const sistema = await this.Vue.$offline.sistema.row(idsistema)
    const clienteTelefono = await this.Vue.$offline.db
      .select()
      .from(tables.cliente_telefono)
      .innerJoin(clienteTelefonoRes.dummyTable, clienteTelefonoRes.pk.eq(clienteTelefonoRes.dummyPk))
      .where(
        this.db.op.and(
          tables.cliente_telefono.estado.gt(0),
          tables.cliente_telefono.idcliente.eq(sistema.sistema.idcliente),
          tables.cliente_telefono.para_todos_los_sistemas.eq(1),
          tables.cliente_telefono.email_notifica_sat.eq(true),
        )
      )
      .exec()
    return _.uniqBy(_.concat(clienteTelefono, sistemaTelefono),'cliente_telefono.idcliente_telefono')
  }
}
