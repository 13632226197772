import BaseOfflineResource from '@/offline/base'
import { TINCLUSION, ARTICULO_ESCANDALLO, PARAMETRO, BANCO_PRECIO } from '@/utils/consts'
import filters from '@/utils/filters'
import { likeRegExp } from '@/offline/database'
import _ from '@/utils/lodash'

export default class ArticuloOfflineResource extends BaseOfflineResource {
  async buscarArticulo (codigo, idttarifa, idbancoPrecio, ididioma, idtoperacionImpuesto, tinclusion) {
    const tables = this.db.tables
    const selectLbancoPrecio = async (codigo, idbancoPrecio) => {
      return await this.db
        .select()
        .from(tables.lbanco_precio)
        .innerJoin(
          tables.banco_precio,
          tables.lbanco_precio.idbanco_precio.eq(tables.banco_precio.idbanco_precio)
        )
        .where(
          this.db.op.and(
            tables.banco_precio.estado.gt(0),
            tables.lbanco_precio.estado.gt(0),
            tables.lbanco_precio.codigo.eq(codigo),
            tables.banco_precio.idbanco_precio.eq(idbancoPrecio)
          )
        )
        .exec()
    }
    const selectLbancoPrecioDetalle = async (codigo, idbancoPrecio) => {
      return await this.db
        .select()
        .from(tables.lbanco_precio_detalle)
        .innerJoin(
          tables.lbanco_precio,
          tables.lbanco_precio_detalle.idlbanco_precio.eq(tables.lbanco_precio.idlbanco_precio)
        )
        .innerJoin(
          tables.banco_precio,
          tables.lbanco_precio.idbanco_precio.eq(tables.banco_precio.idbanco_precio)
        )
        .where(
          this.db.op.and(
            tables.banco_precio.estado.gt(0),
            tables.lbanco_precio.estado.gt(0),
            tables.lbanco_precio_detalle.estado.gt(0),
            tables.lbanco_precio_detalle.codigo.eq(codigo),
            tables.banco_precio.idbanco_precio.eq(idbancoPrecio)
          )
        )
        .exec()
    }
    const selectArticulo = async (codigo) => {
      return await this.db
        .select()
        .from(tables.articulo)
        .innerJoin(
          tables.timpuesto,
          tables.articulo.idtimpuesto.eq(tables.timpuesto.idtimpuesto)
        )
        .where(
          this.db.op.and(
            tables.articulo.estado.gt(0),
            tables.articulo.codigo.eq(codigo)
          )
        )
        .exec()
    }
    const selectTarifa = async (idarticulo, idttarifa) => {
      return await this.db
        .select()
        .from(tables.tarifa)
        .innerJoin(
          tables.articulo,
          tables.tarifa.idarticulo.eq(tables.articulo.idarticulo)
        )
        .where(
          this.db.op.and(
            tables.tarifa.estado.gt(0),
            tables.articulo.estado.gt(0),
            tables.articulo.idarticulo.eq(idarticulo),
            tables.tarifa.idttarifa.eq(idttarifa)
          )
        )
        .exec()
    }
    const selectArticuloEscandallo = async (idarticulo, tinclusion) => {
      /*
      En escandallo         Se solicita  ¿Se debe
      esta definido         para          incluir?
      como
      ---------------------------------------------
      Solo instalación (1)  Instalación  Si
      Solo instalación (1)  Suministro   No
      Solo instalación (1)  Fabricación  No

      Solo suministro (2)   Instalación  No
      Solo suministro (2)   Suministro   Si
      Solo suministro (2)   Fabricación  No

      Ambos (3)             Instalación  Si
      Ambos (3)             Suministro   Si
      Ambos (3)             Fabricación  No

      Fabricación (4)       Instalación  No
      Fabricación (4)       Suministro   No
      Fabricación (4)       Fabricación  Si
      */
      let whereIdTInclusion = []
      if (tinclusion === TINCLUSION.instalacion) {
        // instalacion
        whereIdTInclusion.push(
          this.db.op.and(
            tables.articulo_escandallo.idtinclusion.in([
              ARTICULO_ESCANDALLO.tinclusion.soloInstalacion,
              ARTICULO_ESCANDALLO.tinclusion.instalacionYSuministro,
            ])
          )
        )
      } else {
        // suministro
        whereIdTInclusion.push(
          this.db.op.and(
            tables.articulo_escandallo.idtinclusion.in([
              ARTICULO_ESCANDALLO.tinclusion.soloSuministro,
              ARTICULO_ESCANDALLO.tinclusion.instalacionYSuministro,
            ])
          )
        )
      }
      return await this.db
        .select()
        .from(tables.articulo_escandallo)
        .innerJoin(
          tables.articulo,
          tables.articulo_escandallo.idescandallo.eq(tables.articulo.idarticulo)
        )
        .where(
          this.db.op.and(
            tables.articulo_escandallo.idarticulo.eq(idarticulo),
            ...whereIdTInclusion
          )
        )
        .exec()
    }
    const selectBancoPrecioGrupoDto = async (codigo, idbancoPrecio) => {
      return await this.db
        .select()
        .from(tables.banco_precio_grupo_dto)
        .innerJoin(
          tables.banco_precio,
          tables.banco_precio_grupo_dto.idbanco_precio.eq(tables.banco_precio.idbanco_precio)
        )
        .innerJoin(
          tables.grupo_descuento,
          tables.banco_precio_grupo_dto.idgrupo_descuento.eq(tables.grupo_descuento.idgrupo_descuento)
        )
        .innerJoin(
          tables.articulo,
          tables.grupo_descuento.idgrupo_descuento.eq(tables.articulo.idgrupo_descuento)
        )
        .where(
          this.db.op.and(
            tables.banco_precio.estado.gt(0),
            tables.banco_precio_grupo_dto.estado.gt(0),
            tables.articulo.codigo.eq(codigo),
            tables.banco_precio_grupo_dto.idbanco_precio.eq(idbancoPrecio)
          )
        )
        .exec()
    }
    const selectTipoDeImpuestoToperacion = async (idtoperacionImpuesto) => {
      return await this.db
        .select()
        .from(tables.toperacion_impuesto)
        .innerJoin(
          tables.timpuesto,
          tables.toperacion_impuesto.idtimpuesto.eq(tables.timpuesto.idtimpuesto)
        )
        .where(
          this.db.op.and(
            tables.toperacion_impuesto.idtoperacion_impuesto.eq(idtoperacionImpuesto)
          )
        )
        .exec()
    }
    const selectTipoDeImpuestoArticulo = async (idarticulo) => {
      return await this.db
        .select()
        .from(tables.timpuesto)
        .innerJoin(
          tables.articulo,
          tables.timpuesto.idtimpuesto.eq(tables.articulo.idtimpuesto)
        )
        .where(
          this.db.op.and(
            tables.articulo.idarticulo.eq(idarticulo)
          )
        )
        .exec()
    }
    let idiomaCalc = ididioma
    if (!idiomaCalc) {
      idiomaCalc = await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.IDIOMA_DEFECTO)
    }
    let tinclusionCalc = tinclusion
    if (!tinclusionCalc) {
      tinclusionCalc = TINCLUSION.instalacion
    }
    const asignarValoresDtoBancoPrecio = async (articulo, dto, tabla, idiomaCalc, toReturn) => {
      const tarifa = await selectTarifa(articulo[0].articulo.idarticulo, idttarifa)
      if (tarifa.length > 0) {
        toReturn.precio = tarifa[0].tarifa.precio;
        toReturn.precioIns = tarifa[0].tarifa.precio_ins;
      }
      toReturn.idarticulo = articulo[0].articulo.idarticulo;
      toReturn.descripcion = await this.Vue.$offline.idioma.traducir(tabla, 'descripcion', articulo[0].articulo.idarticulo, idiomaCalc);
      toReturn.dto = dto;
      return toReturn
    }
    const asignarValoresLineaBancoPrecio = async (lbancoPrecio, tabla, idarticulo, idiomaCalc, toReturn) => {
      toReturn.idarticulo = lbancoPrecio.idarticulo;
      toReturn.descripcion = await this.Vue.$offline.idioma.traducir(tabla, 'descripcion', idarticulo, idiomaCalc);
      toReturn.precio = lbancoPrecio.precio;
      toReturn.precioIns = lbancoPrecio.precio;
      toReturn.dto = lbancoPrecio.dto;
      toReturn.coste = lbancoPrecio.coste;

      // TODO: cargar escandallo si es necesario
      // escandalloCargado = true;

      return toReturn
    }
    const buscarLbancoPrecio = async (toReturn, codigo, idbancoPrecio, idiomaCalc) => {
      // 1º Intentar localizar el artículo en LBANCO_PRECIO
      const lbancoPrecio = await selectLbancoPrecio(codigo, idbancoPrecio);
      if (lbancoPrecio.length > 0) {
        return await asignarValoresLineaBancoPrecio(
          lbancoPrecio[0].lbanco_precio,
          'lbanco_precio',
          lbancoPrecio[0].lbanco_precio.idlbanco_precio,
          idiomaCalc,
          toReturn
        )
      } else {
        // 2º Intentar localizar el artículo en LBANCO_PRECIO_DETALLE
        const lbancoPrecioDetalle = await selectLbancoPrecioDetalle(codigo, idbancoPrecio);
        if (lbancoPrecioDetalle.length > 0) {
          return await this.asignarValoresLineaBancoPrecio(
            lbancoPrecioDetalle[0].lbanco_precio_detalle,
            'lbanco_precio_detalle',
            lbancoPrecioDetalle[0].lbanco_precio_detalle.idlbanco_precio_detalle,
            idiomaCalc,
            toReturn
          )
        }
      }
      return toReturn
    }
    const buscarGrupoDtoBancoPrecio = async (toReturn, codigo, idbancoPrecio, idiomaCalc) => {
      const articulo = await selectArticulo(codigo);
      const bancoPrecioGrupoDto = await selectBancoPrecioGrupoDto(codigo, idbancoPrecio);
      if (bancoPrecioGrupoDto.length > 0) {
        return await asignarValoresDtoBancoPrecio(
          articulo,
          bancoPrecioGrupoDto[0].banco_precio_grupo_dto.dto,
          'articulo',
          idiomaCalc,
          toReturn
        )
      }
      return toReturn
    }
    let toReturn = {
      idarticulo: 0,
      descripcion: '',
      precio: 0,
      precioIns: 0,
      dto: null,
      precioConDto: 0,
      precioInsConDto: 0,
      valorIVA: 0,
      valorRecargo: 0,
      coste: 0,
      escandallo: [],
      data: {},
    }
    let escandalloCargado = false
    if (idbancoPrecio) {
      const bancoPrecio = await this.Vue.$offline.bancoPrecio.row(idbancoPrecio)
      // Evaluar la prioridad
      // Se toma la prioridad de descuento del campo `banco_precio.prioridad_dto`
      if (bancoPrecio.banco_precio.prioridad_dto === BANCO_PRECIO.prioridadDescuento.grupo) {
        toReturn = await buscarGrupoDtoBancoPrecio(toReturn, codigo, idbancoPrecio, idiomaCalc)
      }
      if (bancoPrecio.banco_precio.prioridad_dto === BANCO_PRECIO.prioridadDescuento.linea || toReturn.dto === null) {
        toReturn = await buscarLbancoPrecio(toReturn, codigo, idbancoPrecio, idiomaCalc)
      }
      // Si aún no se ha encontrado un dto válido (en caso de que el dto de la línea sea null)
      if (toReturn.dto === null && bancoPrecio.banco_precio.prioridad_dto === BANCO_PRECIO.prioridadDescuento.linea) {
        toReturn = await buscarGrupoDtoBancoPrecio(toReturn, codigo, idbancoPrecio, idiomaCalc);
      }
    }
    // no se localizó el artículo en el banco de precios o no se pasó banco de precios por parámetro
    // intentar localizar el artículo en ARTICULO
    if (toReturn.idarticulo === 0 && toReturn.dto === null) {
      const articulo = await selectArticulo(codigo)
      if (articulo.length > 0) {
        toReturn.idarticulo = articulo[0].articulo.idarticulo
        toReturn.descripcion = await this.Vue.$offline.idioma.traducir(
          'articulo', 'descripcion', articulo[0].articulo.idarticulo, idiomaCalc
        )
        const selectEscandallo = await selectArticuloEscandallo(toReturn.idarticulo, tinclusionCalc)
        for (let escandallo of selectEscandallo) {
          toReturn.coste += escandallo.articulo_escandallo.costo * escandallo.articulo_escandallo.unidades
        }
      }
      const tarifa = await selectTarifa(toReturn.idarticulo, idttarifa)
      if (tarifa.length > 0) {
        toReturn.precio = tarifa[0].tarifa.precio
        toReturn.precioIns = tarifa[0].tarifa.precio_ins
        toReturn.dto = tarifa[0].tarifa.dto
      } else {
        toReturn.dto = 0
      }
    }
    // cargar escandallo si ya no se cargó:
    // - localizado en ARTICULO
    // - localizado en LBANCO_PRECIO con BANCO_PRECIO.DEFINIR_ESCANDALLO = 0
    if (toReturn.idarticulo > 0 && !escandalloCargado) {
      const selectEscandallo = await selectArticuloEscandallo(toReturn.idarticulo, tinclusionCalc)
      for (let escandallo of selectEscandallo) {
        toReturn.escandallo.push({
          idarticulo: escandallo.articulo_escandallo.idescandallo,
          unidades: escandallo.articulo_escandallo.unidades,
          idtinclusion: escandallo.articulo_escandallo.idtinclusion,
          coste: escandallo.articulo_escandallo.costo,
          codigo: escandallo.articulo.codigo,
          descripcion: await this.Vue.$offline.idioma.traducir(
            'articulo', 'descripcion', escandallo.articulo.idarticulo, idiomaCalc
          )
        })
      }
    }
    // impuestos que se deben aplicar
    if (idtoperacionImpuesto) {
      const tipoDeImpuestoToperacion = await selectTipoDeImpuestoToperacion(idtoperacionImpuesto)
      toReturn.valorIVA = tipoDeImpuestoToperacion[0].timpuesto.valoriva
      toReturn.valorRecargo = tipoDeImpuestoToperacion[0].timpuesto.valorrecargo
    } else if (toReturn.idarticulo > 0) {
      const tipoDeImpuestoArticulo = await selectTipoDeImpuestoArticulo(toReturn.idarticulo)
      toReturn.valorIVA = tipoDeImpuestoArticulo[0].timpuesto.valoriva
      toReturn.valorRecargo = tipoDeImpuestoArticulo[0].timpuesto.valorrecargo
    }
    toReturn.precioConDto = toReturn.precio * (1 - toReturn.dto / 100)
    toReturn.precioInsConDto = toReturn.precioIns * (1 - toReturn.dto / 100)
    toReturn.precioConDtoConIVA = toReturn.precioConDto * (1 + (toReturn.valorIVA / 100))
    toReturn.precioInsConDtoConIVA = toReturn.precioInsConDto * (1 + (toReturn.valorIVA / 100))
    // data
    if (toReturn.idarticulo > 0) {
      toReturn.data = (await selectArticulo(codigo))[0]
    }
    return toReturn
  }
  async tinclusionArticulo (codigo, idttarifa, idbancoPrecio, mostrarTarifas) {
    const articulo = await this.buscarArticulo(codigo, idttarifa, idbancoPrecio)
    if (articulo.precio !== articulo.precioIns && mostrarTarifas) {
      return [
        { id: TINCLUSION.instalacion, descripcion: `Instalación (${filters.currency(articulo.precioInsConDto)})` },
        { id: TINCLUSION.suministro, descripcion: `Suministro (${filters.currency(articulo.precioConDto)})` }
      ]
    } else {
      return [
        { id: TINCLUSION.instalacion, descripcion: 'Instalación' },
        { id: TINCLUSION.suministro, descripcion: 'Suministro' }
      ]
    }
  }
  async preguntarTinclusion (codigo, idttarifa, idbancoPrecio) {
    // algún artículo del escandallo tiene tipo de inclusión = sólo suministro ó
    // algún artículo del escandallo tiene tipo de inclusión = sólo instalación ó
    // precio de instalación <> precio de suministro --> el usuario debe indicar tipo de inclusión
    const articulo = await this.buscarArticulo(codigo, idttarifa, idbancoPrecio)
    let toReturn = {
      preguntar: false,
      tinclusion: null
    }
    let hayQueDistinguir = false
    for (let item of articulo.escandallo) {
      if (
        item.idtinclusion === ARTICULO_ESCANDALLO.tinclusion.soloInstalacion ||
        item.idtinclusion === ARTICULO_ESCANDALLO.tinclusion.soloSuministro ||
        articulo.precio !== articulo.precioIns
      ) {
        hayQueDistinguir = true
      }
    }
    if (hayQueDistinguir) {
      let tipoInclusionDefecto = await this.Vue.$offline.parametro.tipoInclusionDefecto()
      if (tipoInclusionDefecto === TINCLUSION.preguntar) {
        toReturn.preguntar = true
      } else {
        toReturn.tinclusion = tipoInclusionDefecto
      }
    } else {
      toReturn.tinclusion = TINCLUSION.instalacion
    }
    return toReturn
  }
  selectTDatoArticulo (idfichaTecnica) {
    const tables = this.db.tables
    return this.Vue.$offline.tdatoArticulo.select({
      where: this.Vue.$offline.db.op.and(
        tables.tdato_articulo.estado.gt(0),
        tables.tdato_articulo.para_material_sis.eq(true),
        tables.tdato_articulo.idficha_tecnica.eq(idfichaTecnica)
      )
    })
  }
  async searchFilter (value) {
    const tables = this.Vue.$offline.db.tables
    let whereSearch = []
    const reQ = likeRegExp(value)
    const articulosCodigosBarra = await this.Vue.$offline.db
      .select(
        this.Vue.$offline.db.fn.distinct(tables.articulo_codbarras.idarticulo).as('idarticulo')
      )
      .from(tables.articulo_codbarras)
      .where(tables.articulo_codbarras.codbarras.match(reQ))
      .exec()
    const idsArticulosCodigosBarra = _.map(articulosCodigosBarra, 'idarticulo')
    whereSearch.push(
      this.Vue.$offline.db.op.or(
        tables.articulo.descripcion.match(reQ),
        tables.articulo.codigo.match(reQ),
        tables.articulo.codbarras.match(reQ),
        tables.articulo.idarticulo.in(idsArticulosCodigosBarra),
      )
    )
    return whereSearch
  }
  async selectArticuloSearch (search=null) {
    const tables = this.Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    if (search) {
      whereSearch = await this.searchFilter(search)
    }
    where.push(
      this.Vue.$offline.db.op.and(
        tables.articulo.estado.gt(0),
        ...whereSearch,
      )
    )
    return (await this.Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.familia,
        tables.subfamilia.idfamilia.eq(tables.familia.idfamilia)
      )
      .where(...where)
      .exec())
  }
  async rows (ids) {
    // hace un select... where idarticulo in (ids), no tiene en cuenta el estado porque se usa en parteTrabajoView
    const tables = this.Vue.$offline.db.tables
    return await this.Vue.$offline.db
      .select()
      .from(tables.articulo)
      .where(
        this.Vue.$offline.db.op.and(
          // tables.articulo.estado.gt(0),
          tables.articulo.idarticulo.in(ids),
        )
      ).exec()
  }
}
