import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      clienteNombre: {
        label: 'Nombre',
        type: 'string',
        value: null,
        field: 'nombre'
      },
      razonSocial: {
        label: 'Razón social',
        type: 'string',
        value: null,
        field: 'razon_social'
      },
      cif: {
        label: 'NIF',
        type: 'string',
        value: null,
        field: 'cif'
      },
      calle: {
        label: 'Calle',
        type: 'string',
        value: null,
        field: 'calle'
      },
      cp: {
        label: 'Código postal',
        type: 'string',
        value: null,
        field: 'cp'
      },
      localidad: {
        label: 'Localidad',
        type: 'string',
        value: null,
        field: 'localidad'
      },
      zona: {
        label: 'Zona',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idzona',
        selectItemValue: 'idzona',
        selectItemText: 'descripcion',
      },
      tsector: {
        label: 'Actividad',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtsector',
        selectItemValue: 'idtsector',
        selectItemText: 'descripcion',
      },
      tcliente: {
        label: 'Tipo de cliente',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtcliente',
        selectItemValue: 'idtcliente',
        selectItemText: 'descripcion',
      },
      agenteComercial: {
        label: 'Agente',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idagente_comercial',
        selectItemValue: 'idagente_comercial',
        selectItemText: 'nombre',
      },
      prospecto: {
        label: 'Ver solo prospectos',
        type: 'check',
        value: null,
        field: 'prospecto',
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.tcliente.items = []
      state.filter.zona.items = []
      state.filter.tsector.items = []
      state.filter.tcliente.items = []
      state.filter.agenteComercial.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
