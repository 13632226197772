import BaseOfflineResource from '@/offline/base'
import { APIFilter } from '@/utils/api'
import _ from '@/utils/lodash'

export default class stockOfflineResource extends BaseOfflineResource {
  async actualizarStockAlmacen(idalmacen) {
    const tables = this.Vue.$offline.db.tables
    // descarga el stock online y lo inserta
    // Antes de llamarlo se debe controlar que esté o no online
    try {
      let apiCalls = []
      const apiFilter = new APIFilter()
        .addExact('idalmacen', idalmacen)
      apiCalls.push({ name: 'stock', method: 'stock.select', params: { filter: apiFilter } })
      apiCalls.push({ name: 'lstock', method: 'lstock.select', params: { filter: apiFilter } })
      apiCalls.push({ name: 'almacen', method: 'almacen.select', params: { filter: apiFilter } })
      const apiCall = await this.Vue.$api.batchCall(apiCalls)
      // Tengo que borrar el stock del almacén antes de sustituir para evitar errores
      const respStock = await this.Vue.$offline.db
        .select()
        .from(tables.stock)
        .innerJoin(
          tables.lstock,
          tables.lstock.idstock.eq(tables.stock.idstock)
        )
        .where(
          tables.stock.idalmacen.eq(idalmacen)
        )
        .exec()
      const LstockAlmacen = _.map(respStock, 'lstock.idlstock')
      await this.Vue.$offline.lstock.delete({
        where: tables.lstock.idlstock.in(LstockAlmacen)
      })
      const StockAlmacen = _.map(respStock, 'stock.idstock')
      await this.Vue.$offline.stock.delete({
        where: tables.stock.idstock.in(StockAlmacen)
      })
      await this.Vue.$offline.almacen.insert(apiCall.data.almacen.result.dataset, { replace: true})
      await this.Vue.$offline.stock.insert(apiCall.data.stock.result.dataset, { replace: true})
      await this.Vue.$offline.lstock.insert(apiCall.data.lstock.result.dataset, { replace: true})
    } catch (e) {
      throw new Error(`${e} (insertStock)`)
    }

  }
}
