import Vue from 'vue'
import colors from 'vuetify/lib/util/colors'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'
import es from 'vuetify/lib/locale/es'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify, {
  directives: {
    Touch,
  },
})

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
    defaultLocale: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#990200',
        secondary: '#004777',
        accent: '#990200',
        ...colors
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    iconfont: 'fa',
    values: {
      // generales
      account: 'fas fa-user-circle',
      add: 'fas fa-plus',
      attach: 'fas fa-paperclip',
      calendar: 'fas fa-calendar-alt',
      camera: 'fas fa-camera',
      cameraFlip: 'fas fa-sync',
      caretDown: 'fas fa-caret-down',
      caretLeft: 'fas fa-caret-left',
      caretRight: 'fas fa-caret-right',
      caretUp: 'fas fa-caret-up',
      chat: 'fas fa-comment',
      check: 'fas fa-check',
      checkAll: 'fas fa-check-double',
      database: 'fas fa-database',
      debug: 'fas fa-bug',
      delete: 'fas fa-times',
      down: 'fas fa-chevron-down',
      download: 'fas fa-cloud-download-alt',
      edit: 'fas fa-edit',
      email: 'fas fa-envelope',
      equals: 'fas fa-equals',
      erase: 'fas fa-eraser',
      exclamation: 'fas fa-exclamation',
      export: 'fas fa-file-export',
      filter: 'fas fa-filter',
      import: 'fas fa-download',
      info: 'fas fa-info-circle',
      hide: 'fas fa-eye-slash',
      locked: 'fas fa-lock',
      map: 'fas fa-map',
      mapLocation: 'fas fa-map-marker',
      minus: 'fas fa-minus',
      more: 'fas fa-ellipsis-v',
      offline: 'signal_cellular_off',
      phone: 'fas fa-phone',
      purge: 'fas fa-toilet',
      refresh: 'fas fa-sync',
      route: 'fas fa-route',
      search: 'fas fa-search',
      select: 'fas fa-object-group',
      show: 'fas fa-eye',
      shutter: 'fas fa-circle',
      send: 'fas fa-paper-plane',
      signature: 'fas fa-signature',
      sort: 'fas fa-sort-alpha-up',
      sync: 'fas fa-cloud-upload-alt',
      thumbsDown: 'fas fa-thumbs-down',
      thumbsUp: 'fas fa-thumbs-up',
      undo: 'fas fa-undo',
      unlocked: 'fas fa-unlock',
      up: 'fas fa-chevron-up',
      upload: 'fas fa-upload',
      user: 'fas fa-user',
      warning: 'fas fa-exclamation-triangle',
      home: 'fas fa-home',
      mark: 'fas fa-check-circle',
      unmark: 'fas fa-circle',
      // dominio
      almacen: 'fas fa-box-open',
      accionCorrectora: 'fas fa-band-aid',
      accionMaterial: 'fas fa-tools',
      agenda: 'fas fa-calendar-alt',
      agenteComercial: 'fa-solid fa-user-tie',
      anomaliaChecklist: 'fas fa-exclamation',
      articulo: 'fas fa-hdd',
      asignado: 'fas fa-hand-point-right',
      avisarAntesDeIr: 'fas fa-phone',
      bajaDefinitiva: 'fas fa-thumbs-down',
      citas: 'fas fa-calendar-alt',
      checklist: 'fas fa-tasks',
      cliente: 'fas fa-user',
      clienteAviso: 'fas fa-exclamation-triangle',
      clienteCuenta: 'fas fa-university',
      clientePotencial: 'fas fa-user-clock',
      cobrar: 'fas fa-euro-sign',
      conFechaFijada: 'fas fa-clock',
      contactos: 'fas fa-comments',
      contratos: 'fa-solid fa-file-contract',
      cronometro: 'fas fa-stopwatch',
      cronometroDetener: 'fas fa-stopwatch-20',
      cuadrante: 'fas fa-border-all',
      cuotas: 'fas fa-tasks',
      datoPersonalizado: 'fas fa-database',
      descargarVehiculo: 'fas fa-dolly',
      desplazamiento: 'fas fa-shipping-fast',
      devolucionMaterial: 'fas fa-shipping-fast',
      direcciones: 'fas fa-map-marker',
      documentacionEnviar: 'fas fa-file-alt',
      empleado: 'fa-solid fa-house-user',
      entrada: 'fas fa-sign-in-alt',
      escandallo: 'fas fa-sitemap',
      estudioFinanciero: 'fas fa-hand-holding-usd',
      facturacion: 'fas fa-money-bill',
      facturas: 'fa-solid fa-file-invoice',
      fichaTecnica: 'fas fa-book-open',
      finalizar: 'fas fa-flag-checkered',
      idioma: 'fas fa-solid fa-language',
      irAHoy: 'fa-solid fa-chevron-right',
      lpresupuestocli: 'fas fa-layer-group',
      llavero: 'fas fa-key',
      loperacioncrm: 'fas fa-share',
      manoDeObra: 'fas fa-user-clock',
      materialAfectado: 'fas fa-cubes',
      materialInstalar: 'fas fa-layer-group',
      motivoBloqueo: 'fas fa-lock',
      movimientoCaja: 'fas fa-wallet',
      nfc: 'fas fa-tag',
      novedad: 'fas fa-bell',
      nota: 'fas fa-clipboard', // Lo ideal sería fa-solid fa-note-sticky como en escritorio
      orden: 'fas fa-clipboard-list',
      observaciones: 'fas fa-align-left',
      operacionCRM: 'fas fa-address-card',
      parte: 'fas fa-clipboard',
      parteNovedad: 'fa-solid fa-receipt',
      password: 'fas fa-key',
      pedidoProveedor: 'fas fa-clipboard',
      personaContacto: 'fas fa-address-book',
      plano: 'fas fa-map',
      preguntaChecklist: 'fas fa-question',
      preguntaChecklistValores: 'fas fa-pencil-ruler',
      preguntaChecklistOk: 'fas fa-thumbs-up',
      preguntaChecklistMal: 'fas fa-thumbs-down',
      preguntaChecklistNoProcede: 'fas fa-ban',
      preguntaChecklistDesconocido: 'fas fa-question-circle',
      presencia: 'fas fa-hourglass',
      presenciaConSalida: 'fas fa-check',
      presenciaSinSalida: 'fas fa-exclamation',
      presupuestos: 'fas fa-euro-sign',
      presurizacion: 'fas fa-compress-arrows-alt',
      prioridad: 'fas fa-sort-numeric-up',
      proveedor: 'fas fa-dolly',
      proyecto: 'fas fa-folder',
      psCheckMal: 'fas fa-thumbs-down',
      psCheckOk: 'fas fa-thumbs-up',
      psCheckNoProcede: 'fas fa-ban',
      puntoControl: 'fas fa-dot-circle',
      qr: 'fas fa-qrcode',
      recarga: 'fas fa-charging-station',
      representantes: 'fa-solid fa-user-tie',
      retimbrado: 'fas fa-stamp',
      revision: 'fas fa-clipboard-check',
      retiradaTemporal: 'fas fa-people-carry',
      ronda: 'fas fa-wave-square',
      salida: 'fas fa-sign-out-alt',
      servicioVigilancia: 'fas fa-eye',
      servicioContratado: 'fas fa-hand-holding-usd',
      sistema: 'fas fa-building',
      sobre: 'fas fa-envelope',
      sobreAbierto: 'fa-solid fa-envelope-open-text',
      solicitudMaterial: 'fa-solid fa-dolly',
      subsistema: 'fas fa-server',
      sustitucion: 'fas fa-exchange-alt',
      tiempoTrabajado: 'fas fa-business-time',
      tipoActuacion: 'fas fa-tag',
      tecnicos: 'fas fa-users',
      turno: 'fas fa-calendar-alt',
      ubicacion: 'fas fa-crosshairs',
      vehiculo: 'fas fa-truck',
      verAgendaDia: 'fa-solid fa-calendar',
      verAgendaSemana: 'fa-solid fa-calendar',
      verAgendaMes: 'fa-solid fa-calendar',
      viaComunicacion: 'fas fa-broadcast-tower',
      vigilante: 'fas fa-user-shield',
      recuperacion: 'fas fa-people-carry',
      irA: 'fa-solid fa-chevron-right',
    }
  },
})
