import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      esTecnico: {
        label: 'Ver técnicos',
        type: 'check',
        value: null,
        field: 'es_tecnico',
      },
      esComercial: {
        label: 'Ver comerciales',
        type: 'check',
        value: null,
        field: 'es_comercial',
      },
      esVigilante: {
        label: 'Ver vigilantes',
        type: 'check',
        value: null,
        field: 'es_vigilante',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
