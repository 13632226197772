import { ListStoreBase, CreateStore } from '@/store/pages/base'
import { STOCK } from '@/utils/consts'

// TODO: generalizar esta funcuón para usarla también en, por ejemplo ESTUDIO_FINANCIERO
const generarItemsStock = () => {
  return Object.keys(STOCK.valor).map(key => {
    const valor = STOCK.valor[key]
    return {
      valor,
      descripcion: STOCK.descripciones[valor],
    }
  })
}

const pageStore = {
  state: {
    filter: {
      codigo: {
        label: 'Código',
        type: 'string',
        value: null,
        field: 'codigo',
      },
      codpro: {
        label: 'Código de proveedor',
        type: 'string',
        value: null,
        field: 'codpro',
      },
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion',
      },
      frecuente: {
        label: 'Ver solo frecuentes',
        type: 'check',
        value: null,
        field: 'frecuente',
      },
      almacen: {
        label: 'Almacén',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idalmacen',
        selectItemValue: 'almacen.idalmacen',
        selectItemText: 'almacen.descripcion',
      },
      sinStock: {
        label: 'Controla stock',
        type: 'multiple-select',
        value: null,
        items: generarItemsStock(),
        field: 'sin_stock',
        selectItemValue: 'valor',
        selectItemText: 'descripcion',
      },
      marca: {
        label: 'Marca',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idmarca',
        selectItemValue: 'marca.idmarca',
        selectItemText: 'marca.descripcion',
      },
      subfamilia: {
        label: 'Subfamilia',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idsubfamilia',
        selectItemValue: 'subfamilia.idsubfamilia',
        selectItemText: 'subfamilia.descripcion',
      },
    },
    mostrarConImpuestos: true,
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.marca.items = [],
      state.filter.subfamilia.items = []
      state.filter.almacen.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
