import { load } from '@/router/utils'
import { PARTE_TRABAJO } from '@/utils/consts'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: 'asignados',
    name: 'partes__parte-asignados-list',
    component: load('partes/parteAsignadosList/ParteAsignadosList'),
    props: {
      estado: PARTE_TRABAJO.estados.pendiente,
    },
  },
  {
    path: 'finalizados',
    name: 'partes__finalizados-parte-asignados-list',
    component: load('partes/parteAsignadosList/ParteAsignadosList'),
    props: {
      estado: PARTE_TRABAJO.estados.finalizado,
    },
  },
  {
    path: 'asignado-fecha-fijada',
    name: 'partes__parte-asignado-fecha-fijada',
    component: load('partes/parteAsignadoFechaFijada/ParteAsignadoFechaFijada'),
  },
  {
    path: 'acciones-fecha-fijada/:clasificacion/:ffijada',
    name: 'partes__accion-asignado-fecha-fijada-list',
    component: load('partes/accionAsignadoFechaFijadaList/AccionAsignadoFechaFijadaList'),
  },
  {
    path: 'add',
    name: 'partes__parte-trabajo-add',
    component: load('partes/parteTrabajoAdd/ParteTrabajoAdd'),
  },
  {
    path: ':idparte_trabajo/subir',
    name: 'partes__parte-subir',
    component: load('partes/parteSubir/ParteSubir'),
  },
  {
    path: ':idparte_trabajo',
    name: 'partes__parte-trabajo-view',
    component: load('partes/parteTrabajoView/ParteTrabajoView'),
  },
  {
    path: ':idparte_trabajo/tecnicos/add',
    name: 'partes__tecnicosasignados__parte-trabajo-tecnico-add',
    component: load('partes/tecnicosAsignados/parteTrabajoTecnicoAdd/ParteTrabajoTecnicoAdd'),
  },
  {
    path: ':idparte_trabajo/tecnicos/:idparte_trabajo_tecnico',
    name: 'partes__tecnicosasignados__parte-trabajo-tecnico-view',
    component: load('partes/tecnicosAsignados/parteTrabajoTecnicoView/ParteTrabajoTecnicoView'),
  },
  // Materiales
  {
    path: ':idparte_trabajo/material-afectado',
    name: 'partes__parte_trabajo_material_afectado-subsis-list',
    component: load('partes/parteTrabajoMatsistSubsisList/ParteTrabajoMatsistSubsisList'),
    props: {
      viewRoute: 'partes__parte_trabajo_material_afectado-list',
    },
  },
  {
    path: ':idparte_trabajo/material-afectado/:idsubsis',
    name: 'partes__parte_trabajo_material_afectado-list',
    component: load('partes/parteTrabajoMatsistList/ParteTrabajoMatsistList'),
  },
  // Notas
  {
    path: ':id/:idtabla/notas',
    name: 'partes__parte-nota-list',
    component: load('notas/notaList/NotaList'),
    props: {
      viewRoute: 'partes__parte-nota-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.nota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':id/:idtabla/notas/:idnota',
    name: 'partes__parte-nota-view',
    component: load('notas/notaView/NotaView'),
    meta: {
      permission: {
        idobjeto: permissions.nota.id,
        access: ACCESS.view,
      },
    },
  }
]
