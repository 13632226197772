import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      periodoRevision: {
        label: 'Período',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idperiodo_revision',
        selectItemValue: 'idperiodo_revision',
        selectItemText: 'descripcion',
      },
      tactuacion: {
        label: 'Tipo de actuación',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idtactuacion',
        selectItemValue: 'idtactuacion',
        selectItemText: 'descripcion',
      }
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.periodoRevision.items = []
      state.filter.tactuacion.items = []
    }
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
