import BaseOfflineResource from '@/offline/base'
import _ from '@/utils/lodash'
import { TINCLUSION, GRUPO_CHECKLIST, PREGUNTA_CHECKLIST, ACCION_EJEC, MACCION, GRADO_ANOMALIA } from '@/utils/consts'

export default class GrupoChecklistOtOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'grupoChecklistOt.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async deleteSync (idgrupoChecklistOt) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'grupoChecklistOt.deleteSync',
      params: {
        idgrupo_checklist_ot: idgrupoChecklistOt
      }
    })
    await this.Vue.$offline.grupoChecklistOt.delete({
      where: tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt)
    })
  }
  async updateGrupoContestadoCorrecto (idgrupoChecklistOt, idtecnico, idparteTrabajo) {
    const tables = this.db.tables
    // marcar preguntas correctas
    const preguntasSinContestar = await this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(this.db.op.and(
        tables.pregunta_checklist_ot.idgrado_anomalia.isNull(),
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt)
      ))
      .exec()
    const idsPregunta = _.map(preguntasSinContestar, 'pregunta_checklist_ot.idpregunta_checklist_ot')
    const gradoOk = await this.Vue.$offline.gradoAnomalia.selectGradoAnomaliaCorrecto()
    const gradoAnomaliaOk = gradoOk[0]
    await this.Vue.$offline.sync.insert({
      method: 'grupoChecklistOt.updateSyncCorrecto',
      params:
        {
          idgrupo_checklist_ot: idgrupoChecklistOt,
          idtecnico: idtecnico
        }
    })
    // marca solo en local cada pregunta porque en remoto marca el grupo completo
    await this.db
      .update(tables.pregunta_checklist_ot)
      .set(tables.pregunta_checklist_ot.idgrado_anomalia, gradoAnomaliaOk.idgrado_anomalia)
      .set(tables.pregunta_checklist_ot.idtecnico, idtecnico)
      .set(tables.pregunta_checklist_ot.resultado, PREGUNTA_CHECKLIST.resultado.ok)
      .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(idsPregunta))
      .exec()
    // marcar acciones de revisión pendientes -> realizadas
    const rowGrupoChecklistOt = this.Vue.$offline.grupoChecklistOt.row(idgrupoChecklistOt)
    const revisiones = await this.Vue.$offline.grupoChecklistOt.selectAccionesRevision(idgrupoChecklistOt)
    for (let revision of revisiones) {
      await this.Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
        revision.accion_ejec.idaccion_ejec,
        rowGrupoChecklistOt.idchecklist_ot,
        revision.accion_ejec.facturable,
        null,
        TINCLUSION.suministro,
        idtecnico,
        idparteTrabajo
      )
    }
    // asignar técnico a preguntas con anomalías arrastradas desde otro checklist
    await this.Vue.$offline.grupoChecklistOt.updateTecnicoPreguntasArrastradas(idgrupoChecklistOt, idtecnico)
  }
  async updateTecnicoPreguntasArrastradas (idgrupoChecklistOt, idtecnico) {
    const tables = this.db.tables
    // preguntas arrastradas desde otro checklist
    // - sin técnico
    // - con anomalías
    const preguntasSinTecnico = await this.db
      .select(
        this.db.fn.distinct(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('idpregunta_checklist_ot')
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          tables.pregunta_checklist_ot.idtecnico.isNull(),
        )
      )
      .exec()
    for (const preguntaSinTecnico of preguntasSinTecnico) {
      const pregunta = _.cloneDeep(await this.Vue.$offline.preguntaChecklistOt.row(preguntaSinTecnico.idpregunta_checklist_ot))
      pregunta.pregunta_checklist_ot.idtecnico = idtecnico
      await this.Vue.$offline.preguntaChecklistOt.updateSync(pregunta.pregunta_checklist_ot)
    }
  }
  async resultadoGrupo (idgrupoChecklistOt) {
    const tables = this.db.tables
    // alguna anomalía  -> GRUPO_CHECKLIST.resultado.conAnomalias
    const anomalias = await this.Vue.$offline.grupoChecklistOt.selectAnomalias(idgrupoChecklistOt)
    if (anomalias.length > 0) {
      return GRUPO_CHECKLIST.resultado.conAnomalias
    } else {
      // preguntas desconocidas implican incorrecto
      const desconocidas = await this.Vue.$offline.grupoChecklistOt.selectDesconocidas(idgrupoChecklistOt)
      if (desconocidas.length > 0) {
        return GRUPO_CHECKLIST.resultado.conAnomalias
      } else {
        // todas ok -> GRUPO_CHECKLIST.resultado.ok
        // preguntas sin contestar -> GRUPO_CHECKLIST.resultado.sinContestar
        // sino (combinación de ok/no procede) -> GRUPO_CHECKLIST.resultado.ok
        const preguntas = await this.db
          .select()
          .from(tables.pregunta_checklist_ot)
          .leftOuterJoin(
            tables.grado_anomalia,
            tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
          )
          .where(
            this.db.op.and(
              tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
              tables.pregunta_checklist_ot.estado.gt(0),
            )
          )
          .exec()
        const preguntasOk = _.filter(preguntas, (value) => {
          return (value.pregunta_checklist_ot.resultado === PREGUNTA_CHECKLIST.resultado.ok)
        })
        if (preguntas.length === preguntasOk.length) {
          return GRUPO_CHECKLIST.resultado.ok
        } else {
          const preguntasSinContestar = _.filter(preguntas, (value) => {
            return (value.pregunta_checklist_ot.resultado === null)
          })
          if (preguntasSinContestar.length > 0) {
            return GRUPO_CHECKLIST.resultado.sinContestar
          } else {
            return GRUPO_CHECKLIST.resultado.ok
          }
        }
      }
    }
  }
  selectAnomalias (idgrupoChecklistOt) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.tanomalia_checklist_ot.estado.gt(0),
        )
      )
      .exec()
  }
  selectDesconocidas (idgrupoChecklistOt) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grado_anomalia,
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .where(
        this.db.op.and(
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          tables.pregunta_checklist_ot.estado.gt(0),
          tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.desconocido),
        )
      )
      .exec()
  }
  selectAccionesRevision (idgrupoChecklistOt) {
    const tables = this.db.tables
    const clasificaciones = [
      MACCION.clasificacion.revision_anual,
      MACCION.clasificacion.revision_semestral,
      MACCION.clasificacion.revision_trimestral,
      MACCION.clasificacion.revision_mensual,
    ]
    return this.db
      .select(
        tables.accion_ejec.idaccion_ejec,
        tables.accion_ejec.idmaccion,
        tables.accion_ejec.facturable,
        tables.accion_ejec.idest_accion_ejec,
        tables.maccion.clasificacion,
        tables.maccion.orden,
        this.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('dummy')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        this.db.op.and(
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
          tables.maccion.clasificacion.in(clasificaciones),
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt)
        )
      )
      .groupBy(
        tables.maccion.clasificacion,
        tables.accion_ejec.facturable,
        tables.accion_ejec.idest_accion_ejec,
        tables.accion_ejec.idaccion_ejec,
        tables.maccion.orden
      )
      .orderBy(tables.maccion.orden)
      .exec()
  }
}
