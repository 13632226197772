export default {
  install: (Vue) => {
    Vue.$alert = Vue.prototype.$alert = {
      dialogPlugin: null,
      showingPersistent: [],
      init (dialogPlugin) {
        this.dialogPlugin = dialogPlugin
      },
      showDialogInfo (text, title = 'Información') {
        if (this.dialogPlugin) {
          const wrappedText = `<span style="overflow-wrap: break-word">${text}</span>`
          this.dialogPlugin.info({ text: wrappedText, title })
        } else {
          alert(text)
        }
      },
      showDialogError (text, title = 'Error', cause = null) {
        if (this.dialogPlugin) {
          let wrappedText = `<span style="overflow-wrap: break-word">${text}</span>`
          if (cause) {
            wrappedText = `${wrappedText} <h4 class="mb-0">Motivo:</h4> ${cause}`
          }
          this.dialogPlugin.error({ text: wrappedText, title })
        } else {
          alert(text)
        }
      },
      showDialogWarning (text, title = 'Advertencia') {
        if (this.dialogPlugin) {
          const wrappedText = `<span style="overflow-wrap: break-word">${text}</span>`
          this.dialogPlugin.warning({ text: wrappedText, title })
        } else {
          alert(text)
        }
      },
      async showSnackbar (text, type, options = {}) {
        if (this.dialogPlugin) {
          const defaults = {
            persistent: false,
            actions: [],
          }
          let finalOptions = Object.assign({}, defaults, options)
          const dialogOptions = {
            'multi-line': true,
            border: 'top',
            position: 'bottom-left',
            actions: finalOptions.actions,
          }
          if (finalOptions.persistent) {
            dialogOptions.timeout = 0
            dialogOptions.waitForResult = false
            this.showingPersistent.push(await this.dialogPlugin.notify[type](text, dialogOptions))
          } else {
            dialogOptions.timeout = 3500
            this.dialogPlugin.notify[type](text, dialogOptions)
          }
        } else {
          alert(text)
        }
      },
      showSnackbarError (text, options = {}) {
        this.showSnackbar(text, 'error', options)
      },
      showSnackbarWarning (text, options = {}) {
        this.showSnackbar(text, 'warning', options)
      },
      showSnackbarInfo (text, options = {}) {
        this.showSnackbar(text, 'info', options)
      },
      showSnackbarSuccess (text, options = {}) {
        this.showSnackbar(text, 'success', options)
      },
      showConfirm (
        text,
        title = 'Confirmación',
        actions = { false: 'Cancelar', true: { text: 'Aceptar', color: 'primary', flat: false } }
      ) {
        return this.dialogPlugin.confirm({ text, title, actions })
      },
      showPrompt (
        title,
        text,
        actions = {
          false: 'Cancelar',
          true: { text: 'Aceptar', color: 'primary', flat: false }
        }
      ) {
        return this.dialogPlugin.prompt({ text, title, actions })
      },
      closePersistentDialogs () {
        for (const dialog of this.showingPersistent) {
          dialog.close()
        }
      },
    }
  }
}
