import BaseOfflineResource from '@/offline/base'


export default class SolicitudMaterialOfflineResource extends BaseOfflineResource {

  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'llsolicitudMaterial.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }

  async deleteSync (idllsolicitudMaterial) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'llsolicitudMaterial.deleteSync',
      params: {
        idllsolicitud_material: idllsolicitudMaterial
      }
    })
    await this.Vue.$offline.llsolicitudMaterial.delete({
      where: tables.llsolicitud_material.idllsolicitud_material.eq(idllsolicitudMaterial)
    })
  }

  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'llsolicitudMaterial.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
}
