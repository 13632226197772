<template>
  <div
    v-if="visibleButtons.length > 0"
  >
    <v-bottom-navigation
      fixed
      background-color="primary"
      dark
      grow
      app
    >
      <v-btn
        v-for="(button, index) in visibleButtons"
        :key="index"
        @click="clickBottomNavBarButton(button, index)"
      >
        <span>{{ button.title }}</span>
        <v-badge
          v-if="button.badge"
          overlap
          :color="button.badgeColor"
          :content="button.badge"
        >
          <v-icon
            :color="button.color"
            class="mb-1"
          >
            {{ $vuetify.icons.values[button.icon] }}
          </v-icon>
        </v-badge>
        <v-icon
          v-else
          :color="button.color"
          class="mb-1"
        >
          {{ $vuetify.icons.values[button.icon] }}
        </v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    visibleButtons() {
      return this.buttons.filter(button => button.visible)
    }
  },
  methods: {
    clickBottomNavBarButton (button, index) {
      this.$emit('click-bottom-nav-bar-button', { button, index })
    },
  }
}
</script>