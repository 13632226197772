import { load } from '@/router/utils'

export default [
  {
    path: '',
    name: 'tiempos__tiempo-list',
    component: load('tiempos/tiempoTrabajadoList/TiempoTrabajadoList'),
  },
  {
    path: 'add',
    name: 'tiempos__tiempo-add',
    component: load('tiempos/tiempoAdd/TiempoAdd'),
  },
  {
    path: 'agenda',
    name: 'tiempos__tiempo-agenda',
    component: load('tiempos/tiempoAgenda/TiempoAgenda'),
  },
  {
    path: 'iniciar',
    name: 'tiempos__cronometro-iniciar',
    component: load('tiempos/tiempoCronometroIniciar/TiempoCronometroIniciar'),
  },
  {
    path: 'detener',
    name: 'tiempos__cronometro-detener',
    component: load('tiempos/tiempoCronometroDetener/TiempoCronometroDetener'),
  },
]
