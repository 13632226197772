import BaseOfflineResource from '@/offline/base'
import filters from '@/utils/filters'
import { nonEmpty } from '@/utils/templates'
import _ from '@/utils/lodash'
import { LORDEN_TRABAJO } from '@/utils/consts'

export default class SolicitudMaterialOfflineResource extends BaseOfflineResource {
  title (row) {
    return `${row.descripcion} (${row.codigo})`
  }
  async subtitle (row) {
    /*
    por base de datos llama al pkg_solicitud_materia.unidadesLinea, porque puede haber más unidade pedidas desde otra solicitud
    en local no me descargo todas las solicitudes de la OT, por lo que puedo traerme el dato del PKG cuando descargo el parte
    o puedo calcularlo descargando todas las llsolicitud_material
    */
    const tables = this.db.tables
    const llsolicitudMaterialDummy = this.Vue.$offline.llsolicitudMaterial
    const llsolicitudMaterial = await this.db
      .select()
      .from(tables.llsolicitud_material)
      .innerJoin(llsolicitudMaterialDummy.dummyTable, llsolicitudMaterialDummy.pk.eq(llsolicitudMaterialDummy.dummyPk))
      .where(
        tables.llsolicitud_material.idlsolicitud_material.eq(row.idlsolicitud_material)
      )
      .exec()
    const unidadesLinea = _.sumBy(llsolicitudMaterial, 'llsolicitud_material.unidades')
    return filters.linebreaksBr(nonEmpty`
      Unidades solicitadas: ${unidadesLinea} - Unidades reservadas: ${row.unidades_reservadas}`
    )
  }
  async insertSync (values = {}, options = {}) {
    const allowedRemoteParams = [
      'codigo',
      'descripcion',
      'estado',
      'idarticulo',
      'idest_lsolicitud_material',
      'idlsolicitud_material',
      'idsolicitud_material',
      'idusuario',
      'unidades_reservadas',
    ]
    await this.Vue.$offline.sync.insert({
      method: 'lsolicitudMaterial.insertSync',
      params: {
        values: _.pickBy(values, (value, key) => allowedRemoteParams.indexOf(key) >= 0),
      },
    })
    return await super.insert(values, options)
  }

  async deleteSync (idlsolicitudMaterial) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'lsolicitudMaterial.deleteSync',
      params: {
        idlsolicitud_material: idlsolicitudMaterial
      }
    })
    await this.Vue.$offline.lsolicitudMaterial.delete({
      where: tables.lsolicitud_material.idlsolicitud_material.eq(idlsolicitudMaterial)
    })
  }

  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'lsolicitudMaterial.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }

  async deleteUpdateLordenTrabajo (idlsolicitudMaterial, deleteOT) {
    const tables = this.Vue.$offline.db.tables
    const detalleSolicitudMaterial = await this.Vue.$offline.db
      .select()
      .from(tables.llsolicitud_material)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.Vue.$offline.db.op.and(
          tables.llsolicitud_material.idlsolicitud_material.eq(idlsolicitudMaterial)
        )
      )
      .exec()
    for (const ll of detalleSolicitudMaterial) {
      await this.Vue.$offline.llsolicitudMaterial.deleteSync(ll.llsolicitud_material.idllsolicitud_material)
      if (deleteOT) {
        const lordenTrabajoDetalle = await this.Vue.$offline.lordenTrabajoDetalle.row(ll.llsolicitud_material.idlorden_trabajo_detalle)
        const lordenTrabajo = await this.Vue.$offline.lordenTrabajo.row(lordenTrabajoDetalle.lorden_trabajo_detalle.idlorden_trabajo)
        // descontar las unidades de la línea que se elimina
        const unidadesLOTDetalle = lordenTrabajoDetalle.lorden_trabajo_detalle.unidades - ll.llsolicitud_material.unidades
        await this.Vue.$offline.lordenTrabajoDetalle.updateSync({
          idlorden_trabajo_detalle: ll.llsolicitud_material.idlorden_trabajo_detalle,
          unidades: unidadesLOTDetalle,
        })
        if (unidadesLOTDetalle == 0) {
          // Poner anulado si no quedan nada más en el escandallo
          const lordenTrabajoDetalleCompleto = await this.Vue.$offline.lordenTrabajoDetalle.selectEscandalloDeIdlordenTrabajo(
            null, // filter,
            null, // search,
            null, // sorter,
            0, // page,
            lordenTrabajo.lorden_trabajo.idlorden_trabajo, // idlordenTrabajo
          )
          const totalUnidades = _.sumBy(lordenTrabajoDetalleCompleto[0], 'lorden_trabajo_detalle.unidades');
          if (totalUnidades == 0) {
            await this.Vue.$offline.lordenTrabajo.updateSync({
              idlorden_trabajo: lordenTrabajo.lorden_trabajo.idlorden_trabajo,
              unidades: unidadesLOTDetalle,
              idlest_orden_trabajo: LORDEN_TRABAJO.estados.anulado
            })
          }
        }
      }
    }
    await this.Vue.$offline.lsolicitudMaterial.deleteSync(idlsolicitudMaterial)
  }
}
