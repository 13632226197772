import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      periodoCuota: {
        label: 'Período',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idperiodo_cuota',
        selectItemValue: 'idperiodo_cuota',
        selectItemText: 'descripcion',
      },
      conceptoCuota: {
        label: 'Concepto',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idconcepto_cuota',
        selectItemValue: 'idconcepto_cuota',
        selectItemText: 'descripcion_cuota',
      }
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.periodoCuota.items = []
      state.filter.conceptoCuota.items = []
    }
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
