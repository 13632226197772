import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'puestosservicio__puesto-servicio-list',
    component: load('puestosServicio/puestoServicioList/PuestoServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio',
    name: 'puestosservicio__puesto-servicio-view',
    component: load('puestosServicio/puestoServicioView/PuestoServicioView'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicio.id,
        access: ACCESS.view,
      },
    },
  },
  // novedades del puesto
  {
    path: ':idpuesto_servicio/novedades',
    name: 'puestosservicio__lparte-novedad-list',
    component: load('lpartesNovedad/lparteNovedadList/LparteNovedadList'),
    props: {
      addRoute: 'puestosservicio__lparte-novedad-add',
      viewRoute: 'puestosservicio__lparte-novedad-view',
    },
  },
  {
    path: ':idpuesto_servicio/novedades/:idparte_novedad/add',
    name: 'puestosservicio__lparte-novedad-add',
    component: load('lpartesNovedad/lparteNovedadAdd/LparteNovedadAdd'),
    props: {
      viewRoute: 'puestosservicio__lparte-novedad-view',
    },
  },
  {
    path: ':idpuesto_servicio/novedades/:idparte_novedad/:idlparte_novedad',
    name: 'puestosservicio__lparte-novedad-view',
    component: load('lpartesNovedad/lparteNovedadView/LparteNovedadView'),
    props: {
      editRoute: 'puestosservicio__lparte-novedad-edit',
    },
  },
  {
    path: ':idpuesto_servicio/novedades/:idparte_novedad/:idlparte_novedad/edit',
    name: 'puestosservicio__lparte-novedad-edit',
    component: load('lpartesNovedad/lparteNovedadEdit/LparteNovedadEdit'),
    props: {
      viewRoute: 'puestosservicio__lparte-novedad-view',
    },
  },
  {
    path: ':idpuesto_servicio/servicios',
    name: 'puestosservicio__servicio-list',
    component: load('puestosServicio/servicioList/ServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.servicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio',
    name: 'puestosservicio__servicio-view',
    component: load('puestosServicio/servicioView/ServicioView'),
    meta: {
      permission: {
        idobjeto: permissions.servicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/turnos',
    name: 'puestosservicio__turno-servicio-list',
    component: load('puestosServicio/turnoServicioList/TurnoServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.tturnoServicio.id,
        access: ACCESS.view,
      },
    },
  },
  // novedades del servicio
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/novedades',
    name: 'puestosservicio__servicio__lparte-novedad-list',
    component: load('lpartesNovedad/lparteNovedadList/LparteNovedadList'),
    props: {
      addRoute: 'puestosservicio__servicio__lparte-novedad-add',
      viewRoute: 'puestosservicio__servicio__lparte-novedad-view',
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/novedades/add',
    name: 'puestosservicio__servicio__lparte-novedad-add',
    component: load('lpartesNovedad/lparteNovedadAdd/LparteNovedadAdd'),
    props: {
      viewRoute: 'puestosservicio__servicio__lparte-novedad-view',
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/novedades/:idlparte_novedad',
    name: 'puestosservicio__servicio__lparte-novedad-view',
    component: load('lpartesNovedad/lparteNovedadView/LparteNovedadView'),
    props: {
      editRoute: 'puestosservicio__servicio__lparte-novedad-edit',
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/novedades/:idlparte_novedad/edit',
    name: 'puestosservicio__servicio__lparte-novedad-edit',
    component: load('lpartesNovedad/lparteNovedadEdit/LparteNovedadEdit'),
    props: {
      viewRoute: 'puestosservicio__servicio__lparte-novedad-view',
    },
  },
]
