import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class NotaOnlineResource extends BaseOnlineResource {
  buildApiFilterVisualizacion (identificador, idtabla, esTecnico, esComercial, esVigilante) {
    const apiNotaFilter = new APIFilter()
    apiNotaFilter
      .addExact('identificador', identificador)
      .addExact('idtabla', idtabla)
      .addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    if (esTecnico || esComercial || esVigilante) {
      apiFilterVisualizacion
        .setOperator(API_FILTER_OPERATORS.OR)
      if (esTecnico) {
        apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
      }
      if (esComercial) {
        apiFilterVisualizacion.addExact('visible_movilidad_comercial', esComercial)
      }
      if (esVigilante) {
        apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esVigilante)
      }
      apiNotaFilter.addNestedFilter(apiFilterVisualizacion)
    }
    return apiNotaFilter
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('titulo', value)
  }
}
