import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'
import { TINCLUSION, ACCION_EJEC } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'

export default class AccionCorrectoraOtOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'accionCorrectoraOt.insertSync',
      params:  { values },
    })
    const localValues = _.cloneDeep(values)
    localValues.estado = 1
    return await super.insert(localValues, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'accionCorrectoraOt.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idaccionCorrectoraOt) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'accionCorrectoraOt.deleteSync',
      params: {
        idaccion_correctora_ot: idaccionCorrectoraOt,
      }
    })
    await this.Vue.$offline.accionCorrectoraOt.delete({
      where: tables.accion_correctora_ot.idaccion_correctora_ot.eq(idaccionCorrectoraOt)
    })
  }
  async marcarRealizada (idaccionCorrectoraOt, idparteTrabajo) {
    const tables = this.Vue.$offline.db.tables
    const accionCorrectoraOt = await this.Vue.$offline.accionCorrectoraOt.row(idaccionCorrectoraOt)
    const ordenTrabajoMatsist = (await this.Vue.$offline.db
      .select()
      .from(tables.orden_trabajo_matsist)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(tables.tanomalia_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.accion_correctora_ot,
        tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(tables.accion_correctora_ot.idtanomalia_checklist_ot)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.sistema,
        tables.subsis.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(this.Vue.$offline.db.op.and(
        tables.accion_correctora_ot.idaccion_correctora_ot.eq(idaccionCorrectoraOt)
      ))
      .exec()
    )[0]
    let articuloFacturar = null
    let unidades = 1
    let facturar = false
    let idaccionEjec = null
    if (accionCorrectoraOt.accion_correctora_ot.idmaccion) {
      const maccion = await this.Vue.$offline.maccion.row(accionCorrectoraOt.accion_correctora_ot.idmaccion)
      idaccionEjec = uuidv4()
      await this.Vue.$offline.accionEjec.insertSync({
        idaccion_ejec: idaccionEjec,
        idorden_trabajo_matsist: ordenTrabajoMatsist.orden_trabajo_matsist.idorden_trabajo_matsist,
        idmaccion: accionCorrectoraOt.accion_correctora_ot.idmaccion,
        idest_accion_ejec: ACCION_EJEC.estados.realizada,
        facturable: maccion.maccion.facturable,
        idparte_trabajo: idparteTrabajo,
        idaccion_correctora_ot: idaccionCorrectoraOt,
      })
      facturar = maccion.maccion.facturable
      // No lo hago con this.Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync porque tengo que meter otras observaciones y notas
      // Si la acción es facturable, busco el artículo para insertarlo luego
      articuloFacturar = (await this.Vue.$offline.accionEjec.selectArticuloFacturar(
        accionCorrectoraOt.accion_correctora_ot.idmaccion, ordenTrabajoMatsist.material_sistema.idarticulo
      ))[0]
    } else if (accionCorrectoraOt.accion_correctora_ot.idarticulo) {
      articuloFacturar = await this.Vue.$offline.articulo.row(accionCorrectoraOt.accion_correctora_ot.idarticulo)
      // las unidades las saco de la definición de la acción correctora
      unidades = accionCorrectoraOt.accion_correctora_ot.unidades
      facturar = true
    }
    if (articuloFacturar) {
      let descripcionTraducida = await this.Vue.$offline.idioma.traducir(
        'articulo', 'descripcion', articuloFacturar.articulo.idarticulo, ordenTrabajoMatsist.cliente.ididioma
      )
      const numeroElemento = ordenTrabajoMatsist.material_sistema.nelemento ? `${ordenTrabajoMatsist.material_sistema.nelemento} - ` : ''
      const numeroSerie = ordenTrabajoMatsist.material_sistema.nserie ? ` - Nº Serie: ${ordenTrabajoMatsist.material_sistema.nserie}` : ''
      const ubicacion = ordenTrabajoMatsist.material_sistema.ubicacion ? ` - Ubicación: ${ordenTrabajoMatsist.material_sistema.ubicacion}` : ''
      const descripcionArticulo = `${descripcionTraducida} (Material: ${numeroElemento}${ordenTrabajoMatsist.material_sistema.descripcion}${numeroSerie}${ubicacion})`
      const observaciones = `Originada por la anomalía ${ordenTrabajoMatsist.tanomalia_checklist_ot.descripcion}`
      return await this.Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
        idparteTrabajo,
        articuloFacturar.articulo.codigo,
        unidades,
        ordenTrabajoMatsist.material_sistema.idsubsis,
        TINCLUSION.suministro,
        facturar,
        null,
        descripcionArticulo,
        0,
        idaccionEjec,
        observaciones,
        idaccionCorrectoraOt,
      )
    }
  }
}
