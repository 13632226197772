import axios from 'axios'
import Url from 'domurl'
import store from '@/store'
import { API_RESULT_DATATYPES } from '@/utils/api'
import { ReadFileBase64 } from '@/utils/files'
import _ from '@/utils/lodash'
import { ORA } from '@/utils/consts'
import { toDateTime, formatISO } from '@/utils/date'

export class APIApplicationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'APIApplicationError'
  }
}

export class APISessionTerminatedError extends Error {
  constructor() {
    super('APISessionTerminatedError')
    this.name = 'APISessionTerminatedError'
  }
}

export class APIVersionError extends Error {
  constructor(version) {
    super('APIVersionError')
    this.name = `APIVersionError ${version}`
    // BUG: este campo no le está llegando al beforeSend del sentry
    this.version = version
  }
}

export class APISilentError extends Error {
  constructor(message) {
    super(message)
    this.name = 'APISilentError'
  }
}

Date.prototype.toJSON = function() {
  // https://github.com/axios/axios/issues/1548
  // enviar a la API dates con zona horaria
  return formatISO(this)
}

export default {
  install: (Vue) => {
    Vue.$api = Vue.prototype.$api = {
      alertPlugin: null,
      routerPlugin: null,
      ITEMS_PER_PAGE: 50,
      ITEMS_PER_PAGE_AUTOCOMPLETE: 10,
      init (alertPlugin, routerPlugin) {
        this.alertPlugin = alertPlugin
        this.routerPlugin = routerPlugin
      },
      _getDatetimeCols (metadata) {
        return metadata.columns.filter((item) => {
          return item.type === API_RESULT_DATATYPES.datetime
        })
      },
      _getBooleanCols (metadata) {
        return metadata.columns.filter((item) => {
          return item.type === API_RESULT_DATATYPES.boolean
        })
      },
      _parseResponseDataRow (row, datetimeCols, booleanCols) {
        datetimeCols.map((col) => {
          if (row[col.name] !== null) {
            row[col.name] = toDateTime(row[col.name])
          }
        })
        booleanCols.map((col) => {
          if (row[col.name] !== null) {
            row[col.name] = row[col.name] === 1
          }
        })
      },
      _parseResponseData (data) {
        if (data.result && data.result.dataset && data.result.metadata) {
          const datetimeCols = this._getDatetimeCols(data.result.metadata)
          const booleanCols = this._getBooleanCols(data.result.metadata)
          if (Array.isArray(data.result.dataset)) {
            for (let row of data.result.dataset) {
              this._parseResponseDataRow(row, datetimeCols, booleanCols)
            }
          } else {
            this._parseResponseDataRow(data.result.dataset, datetimeCols, booleanCols)
          }
          data.result.metadata.getColumn = (columnName) => {
            const colIndex = _.findIndex(data.result.metadata.columns, { name: columnName })
            if (colIndex >= 0) {
              return data.result.metadata.columns[colIndex]
            }
          }
        }
      },
      _parseResponse (response) {
        if (response.data) {
          this._parseResponseData(response.data)
        }
        return response
      },
      _parseResponseBatch (response, request) {
        for (let item of response.data) {
          // asociar cada request/response por name
          const relatedRequest = _.find(request, { id: item.id })
          if (relatedRequest && relatedRequest.name) {
            item.name = relatedRequest.name
          }
          if (item.result) {
            this._parseResponseData(item)
          }
        }
        // transformar response de:
        //   data: Array(4)
        //     0: {id: 1, jsonrpc: "2.0", result: {…}, name: "call_1"}
        //     1: {id: 2, jsonrpc: "2.0", result: {…}, name: "call_2"}
        //     ...
        // a:
        //   data: {
        //     call_1: {id: 1, jsonrpc: "2.0", result: {…}}
        //     call_2: {id: 2, jsonrpc: "2.0", result: {…}}
        //     ...
        let transformedData = {}
        for (let item of response.data) {
          transformedData[item.name] = item
        }
        response.data = transformedData
        return response
      },
      async ajaxDownload (url) {
        let baseURL = ''
        if (process.env.NODE_ENV === 'development') {
          baseURL = `https://${process.env.VUE_APP_DOMAIN}:${process.env.VUE_APP_API_PORT}/`
        }
        const response = await axios({
          baseURL: baseURL,
          url: url,
          method: 'get',
          headers: {
            'Authorization': `Token ${store.get('usuario/token')}`
          },
          responseType: 'blob',
        })
        return await ReadFileBase64(response.request.response)
      },
      getMediaFileURL (url) {
        let baseURL = ''
        if (process.env.NODE_ENV === 'development') {
          baseURL = `https://${process.env.VUE_APP_DOMAIN}:${process.env.VUE_APP_API_PORT}`
        }
        let urlMediaFile = new Url(`${baseURL}${url}`)
        return urlMediaFile.toString()
      },
      openMediaFileURL (url) {
        window.open(this.getMediaFileURL(url))
      },
      async call (method, params, options = {}) {
        const defaultOptions = {
          disableUI: true,
          silentMessages: false,
        }
        const finalOptions = { ...defaultOptions, ...options }
        let baseURL = ''
        if (process.env.NODE_ENV === 'development') {
          baseURL = `https://${process.env.VUE_APP_DOMAIN}:${process.env.VUE_APP_API_PORT}/`
        }
        let data = {
          jsonrpc: '2.0',
          id: 1,
          method: `${method}`
        }
        if (params) {
          data.params = params
        }
        if (!store.get('loading/manualLoading')) {
          await store.dispatch('loading/show', { disableUI: finalOptions.disableUI })
        }
        try {
          const response = await axios({
            baseURL: baseURL,
            url: '/api/',
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': `Token ${store.get('usuario/token')}`,
            },
            data,
            withCredentials: true,
          })
          if (Object.keys(response.headers).includes('api-version') && response.headers['api-version'] !== __BETA10_VERSION__) {
            return Promise.reject(new APIVersionError(response.headers['api-version']))
          }
          if (response.data?.error) {
            if (response.data.error.code === ORA.errors.ORA_APPLICATION_ERROR) {
              return Promise.reject(new APIApplicationError(response.data.error.data))
            } else {
              let errorData = ''
              if (response.data.error.data) {
                errorData = response.data.error.data
              }
              if (finalOptions.silentMessages) {
                return Promise.reject(new APISilentError(`${response.data.error.message}\n${errorData} (${method})`))
              } else {
                var codeExists = Object.values(ORA.errors).includes(response.data.error.code)
                if (codeExists) {
                  console.error(`${errorData} (${method})`)
                  return Promise.reject(new Error(`${response.data.error.message}`, { cause: errorData }))
                }
                return Promise.reject(new Error(`${response.data.error.message}\n${errorData} (${method})`))
              }
            }
          } else {
            this._parseResponse(response)
            return Promise.resolve(response)
          }
        } catch (error) {
          if (error.response?.status === 403) {
            return Promise.reject(new APISessionTerminatedError())
          } else if (finalOptions.silentMessages) {
            return Promise.reject(new APISilentError(error))
          } else {
            return Promise.reject(new Error(error))
          }
        } finally {
          if (!store.get('loading/manualLoading')) {
            await store.dispatch('loading/hide')
          }
        }
      },
      async batchCall (data, options = {}) {
        const defaultOptions = {
          disableUI: true,
          silentMessages: false,
          silentErrors: false,
        }
        const finalOptions = { ...defaultOptions, ...options }
        let baseURL = ''
        if (process.env.NODE_ENV === 'development') {
          baseURL = `https://${process.env.VUE_APP_DOMAIN}:${process.env.VUE_APP_API_PORT}/`
        }
        let callId = 1
        for (let item of data) {
          item.jsonrpc = '2.0'
          if (!item.id || item.id.toString() === '') {
            item.id = callId
          }
          if (!item.name) {
            item.name = item.id
          }
          callId++
        }
        if (!store.get('loading/manualLoading')) {
          await store.dispatch('loading/show', { disableUI: finalOptions.disableUI })
        }
        try {
          const batchResponse = await axios({
            baseURL: baseURL,
            url: '/api/',
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': `Token ${store.get('usuario/token')}`,
            },
            withCredentials: true,
            data,
          })
          if (Object.keys(batchResponse.headers).includes('api-version') && batchResponse.headers['api-version'] !== __BETA10_VERSION__) {
            return Promise.reject(new APIVersionError(batchResponse.headers['api-version']))
          }
          // errores generales (por ejemplo: Error: Internal error: Request body exceeded settings.DATA_UPLOAD_MAX_MEMORY_SIZE)
          if (batchResponse.data?.error?.message) {
            return Promise.reject(new Error(batchResponse.data.error.message))
          }
          // errores por cada llamada
          const callsWithErrors = _.filter(batchResponse.data, call => { return !!call.error })
          if (callsWithErrors.length > 0 && !finalOptions.silentErrors) {
            const errorMessages = _.map(callsWithErrors, (error) => {
              return `${error.error.message}: ${error.error.data}`;
            });
            return Promise.reject(new Error(errorMessages.join('\n')))
          }
          this._parseResponseBatch(batchResponse, data)
          return Promise.resolve(batchResponse)
        } catch (error) {
          if (error.response && error.response.status === 403) {
            return Promise.reject(new APISessionTerminatedError())
          } else if (finalOptions.silentMessages) {
            return Promise.reject(new APISilentError(error))
          } else {
            return Promise.reject(new Error(error))
          }
        } finally {
          if (!store.get('loading/manualLoading')) {
            await store.dispatch('loading/hide')
          }
        }
      },
      async fetchMediaFile(image) {
        if (image.url) {
          const absoluteMediaURL = this.getMediaFileURL(image.url)
          try {
            const response = await fetch(absoluteMediaURL, {
              method: 'GET',
              headers: {
                'Authorization': `Token ${store.get('usuario/token')}`,
              }
            })
            if (response.ok) {
              return response
            } else {
              return Promise.reject(new Error('Error al descargar la imagen.'))
            }
          } catch (error) {
            return Promise.reject(new Error(`Error durante la descarga de la imagen: ${error}`))
          }
        }
      },
      async getBlobUrl (image) {
        const response = await this.fetchMediaFile(image)
        if (response) {
          const blob = await response.blob()
          return window.URL.createObjectURL(blob)
        }
      },
      downloadBlob(blobUrl, filename) {
        // Crea un enlace temporal para la descarga
        const a = document.createElement('a')
        a.href = blobUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(blobUrl)
      }
    }
  }
}
