import BaseOfflineResource from '@/offline/base'

export default class ProyectoContableOfflineResource extends BaseOfflineResource {
  async select(idproyectoContable) {
    const tables = this.Vue.$offline.db.tables
    return (await this.Vue.$offline.db
      .select()
      .from(tables.proyecto_contable)
      .where(
        this.Vue.$offline.db.op.and(
          tables.proyecto_contable.estado.gt(0),
          tables.proyecto_contable.idproyecto_contable.eq(idproyectoContable),
        )
      )
      .exec())[0]
  }

  async lordenTrabajoImputaAlProyecto(idproyectoContable, articuloEsManoDeObra) {
    // tiene proyecto y el proyecto no tiene almacén
    let proyectoContable = {}
    if (idproyectoContable) {
      proyectoContable = await this.select(idproyectoContable)
    }
    return !articuloEsManoDeObra && !!idproyectoContable && !proyectoContable?.idalmacen
  }

  async lparteTrabajoImputaAlProyecto(idproyectoContable, idalmacenLparteTrabajo, articuloEsManoDeObra) {
    // tiene proyecto la OT, el proyecto tiene almacén y es distinto al de la línea, o el proyecto no tiene almacén
    let proyectoContable = {}
    if (idproyectoContable) {
      proyectoContable = await this.select(idproyectoContable)
    }
    const toReturn =  !articuloEsManoDeObra && !!idproyectoContable &&
      (!proyectoContable?.idalmacen || proyectoContable.idalmacen !== idalmacenLparteTrabajo)
    return toReturn
  }
}
