import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import {  nonEmpty } from '@/utils/templates'
import { PARTE_TRABAJO } from '@/utils/consts'

export default class ParteTrabajoOnlineResource extends BaseOnlineResource {
  title (row) {
    let nabonado = ''
    if (row.sistema_nabonado1) {
      nabonado = `(Abonado Nº${row.sistema_nabonado1})`
    }
    if (row.estado === PARTE_TRABAJO.estados.pendiente) {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.fparte)} - ${row.orden_trabajo_serie_numero}>${row.idparte_trabajo} - ${row.cliente_nombre} (${row.idcliente}) ${nabonado}`
      )
    } else {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.ffinparte)} - ${row.orden_trabajo_serie_numero}>${row.idparte_trabajo} - ${row.cliente_nombre} (${row.idcliente}) ${nabonado}`
      )
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`
      ${row.tactuacion_descripcion} de ${row.tsistema_descripcion} de sistema ${row.sistema_descripcion} (${row.sistema_codigo})
      ${row.sistema_direccion}
      ${row.observacion}
    `)
  }
  async insert (values, idsubsisAfectados, agregarMaterialAfectado, crearParteTrabajo) {
    const secIdordenTrabajo = await this.Vue.$api.call('ordenTrabajo.nextVal')
    values['idorden_trabajo'] = secIdordenTrabajo.data.result.dataset[0].nextval
    let apiCalls = []
    apiCalls.push({
      name: `insertOrdenTrabajo`,
      method: 'ordenTrabajo.insert',
      params: {
        values: values,
      },
    })
    for (const idsubsis of idsubsisAfectados) {
      apiCalls.push({
        name: `insertSubsis${idsubsis}`,
        method: 'ordenTrabajoSubsis.insert',
        params: {
          values: {
            estado: 1,
            idorden_trabajo: values['idorden_trabajo'],
            idsubsis,
          },
        },
      })
      if (agregarMaterialAfectado) {
        apiCalls.push({
          name: `insertMaterialAfectadoSubsis${idsubsis}`,
          method: 'ordenTrabajo.anandirOrdenTrabajoMatsistPorSubsis',
          params: {
            idorden_trabajo: values['idorden_trabajo'],
            idsubsis: idsubsis,
          },
        })
      }
    }
    if (crearParteTrabajo) {
      apiCalls.push({
        name: `crearParteTrabajoDesdeOT`,
        method: 'ordenTrabajo.crearParteTrabajoDesdeOT',
        params: {
          idorden_trabajo: values['idorden_trabajo'],
          idtecnico: values.idtecnico,
        },
      })
    }
    return await this.Vue.$api.batchCall(apiCalls)
  }
  async anular (idparteTrabajo) {
    await this.Vue.$api.call('parteTrabajo.anular', { idparte_trabajo: idparteTrabajo })
  }
}