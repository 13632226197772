<template>
  <v-dialog
    v-model="internalShowing"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-card-text
        class="pt-2"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <div
            v-for="(field, key) in defaultFields"
            :key="key"
          >
            <v-text-field
              v-if="field.type === 'string'"
              :ref="key"
              v-model="field.value"
              :label="field.label"
              clearable
              :disabled="field.options && field.options.fixed"
            />
            <v-text-field
              v-if="field.type === 'number'"
              :ref="key"
              v-model="field.value"
              :label="field.label"
              type="number"
              clearable
              :disabled="field.options && field.options.fixed"
            />
            <v-text-field
              v-if="field.type === 'search'"
              :ref="key"
              v-model="field.value"
              :label="field.label"
              clearable
              :disabled="field.options && field.options.fixed"
            />
            <b10-date-picker
              v-if="field.type === 'date'"
              :ref="key"
              v-model="field.value"
              :title="field.label"
              :disabled="field.options && field.options.fixed"
            />
            <b10-autocomplete
              v-if="field.type === 'select'"
              :ref="key"
              v-model="field.value"
              :items="field.items"
              :item-value="field.selectItemValue"
              :item-text="field.selectItemText"
              :label="field.label"
              clearable
              :disabled="field.options && field.options.fixed"
            />
            <b10-autocomplete
              v-if="field.type === 'multiple-select'"
              :ref="key"
              v-model="field.value"
              :items="field.items"
              :item-value="field.selectItemValue"
              :item-text="field.selectItemText"
              :label="field.label"
              clearable
              multiple
              chips
              deletable-chips
              small-chips
              :disabled="field.options && field.options.fixed"
            />
            <div
              v-if="field.type === 'check'"
            >
              <v-checkbox
                :ref="key"
                v-model="field.value"
                :label="field.label"
                :false-value="false"
                :true-value="true"
                :disabled="field.options && field.options.fixed"
                :indeterminate="field.value === null"
                :append-icon="$vuetify.icons.values.delete"
                @click:append="setNull(field)"
              />
            </div>
          </div>
          <b10-autocomplete
            v-if="optionalFields.length > 0"
            v-model="selectedOptionalFields"
            :items="optionalFields"
            item-text="label"
            item-value="field"
            label="Agregar más campos..."
            multiple
            chips
            deletable-chips
            small-chips
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="clickSubmit"
        >
          Filtrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    fields: {
      type: Object,
      required: true,
      validator: (value) => {
        for (let field in value) {
          if ((!_.has(value[field], 'label')) ||
              (!_.has(value[field], 'type')) ||
              (!_.has(value[field], 'value')) ||
              (!_.has(value[field], 'field')) ||
              (typeof value[field].label !== 'string') ||
              (typeof value[field].type !== 'string') ||
              (typeof value[field].field !== 'string')
          ) {
            return false
          }
        }
        return true
      }
    }
  },
  data () {
    return {
      selectedOptionalFields: [],
    }
  },
  computed: {
    defaultFields () {
      // se muestra por defecto si:
      // - no es opcional
      // - ya se agregó desde los opcionales
      // - tiene valor asignado
      return _.filter(this.fields, (field) => {
        if (
          !field.optional ||
          this.selectedOptionalFields.indexOf(field.field) > -1 ||
          field.value
        ) {
          return field
        }
      })
    },
    optionalFields () {
      return _.filter(this.fields, (field) => {
        if (field.optional) {
          return field
        }
      })
    },
  },
  methods: {
    clickSubmit () {
      this.$emit('click-submit')
      this.internalShowing = false
    },
    clickCancel () {
      this.internalShowing = false
    },
    setNull(field) {
      field.value = null
    }
  }
}
</script>
