<template>
  <div
    v-if="withValidationProvider"
  >
    <ValidationProvider
      v-slot="{ errors }"
      :rules="validationRules"
      :name="name"
    >
      <v-text-field
        v-bind="$props"
        :error-messages="errors"
        @input="$emit('input', $event)"
      >
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </v-text-field>
    </ValidationProvider>
  </div>
  <div
    v-else
  >
    <v-text-field
      v-bind="$props"
      @input="$emit('input', $event)"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { VTextField } from 'vuetify/lib'

// https://stackoverflow.com/questions/59767835/vuetify-custom-v-text-field-component-is-not-updating-the-v-model
export default {
  extends: VTextField,
  props: {
    withValidationProvider: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    validationRules: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: ''
    },
  },
}
</script>
