<template>
  <span
    :class="{ 'text-wrap': textWrap }"
    @click="textWrapToggle"
  >
    <slot />
  </span>
</template>

<script>
export default {
  data() {
    return {
      textWrap: false,
    }
  },
  methods: {
    textWrapToggle () {
      this.textWrap = !this.textWrap
    },
  },
}
</script>
